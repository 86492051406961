
const formateFnObject = (item, functionName) => {
    const comparisonOperators = ["<", ">", "<=", ">="];
  
    if (functionName && functionName.length > 1) {
      const lastFunctionValue = functionName[functionName.length - 1];
      const functionValues = lastFunctionValue.split(",");
      console.log("functionValues", functionValues, item)
      const includesComparisonOperator = comparisonOperators.some((operator) =>
        lastFunctionValue.includes(operator)
      );
  
      const comparatorObject = includesComparisonOperator
        ? { comparator: lastFunctionValue }
        : {};
  
      if (functionValues.length === 5) {
        if (item.name === "Bollinger %B") {
          const newItem = {
            ...item,
            function: functionName || null,
            field: functionValues.length > 0 ? functionValues[0] : null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
            deviation: functionValues.length > 0 ? functionValues[2] : null,
            type: functionValues.length > 0 ? functionValues[3] : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
            ...comparatorObject,
          };
  
          if (
            (newItem.field &&
              newItem.period &&
              newItem.deviation &&
              newItem.type) ||
            newItem.offset === 0
          )
            return newItem;
        };
        if(item.name === "Standard Deviation"){
          const newItem = {
            ...item,
            function: functionName || null,
            stdPeriod: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            stdField: functionValues.length > 0 ? functionValues[1] : null,
            stdDev: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
            maType:
              functionValues.length > 0 ? functionValues[3] : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
            ...comparatorObject,
          };
            return newItem;
        }
      }
      if (functionValues.length === 4) {
        if (item.name === "RSI Moving Average") {
          const newItem = {
            ...item,
            function: functionName || null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            maType: functionValues.length > 0 ? functionValues[1] : null,
            maPeriod:
              functionValues.length > 0 ? parseInt(functionValues[2]) : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
            ...comparatorObject,
          };
  
          return newItem;
        }
        if (item.name === "MACD (Moving Average Convergence/Divergence)") {
          const newItem = {
            ...item,
            function: functionName || null,
            period1:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            period2:
              functionValues.length > 0 ? parseInt(functionValues[1]) : null,
            period3:
              functionValues.length > 0 ? parseInt(functionValues[2]) : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
            ...comparatorObject,
          };
  
          if (
            newItem.period1 ||
            newItem.period2 ||
            newItem.period3 === 0 ||
            newItem.offset === 0
          )
            return newItem;
        }
        if (item.name === "MACD-signal") {
          const newItem = {
            ...item,
            function: functionName || null,
            period1:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            period2:
              functionValues.length > 0 ? parseInt(functionValues[1]) : null,
            period3:
              functionValues.length > 0 ? parseInt(functionValues[2]) : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
            ...comparatorObject,
          };
  
          if (
            newItem.period1 ||
            newItem.period2 ||
            newItem.period3 === 0 ||
            newItem.offset === 0
          )
            return newItem;
        }
        if (item.name === "Donchian Channel") {
          const newItem = {
            ...item,
            function: functionName || null,
            channel: functionValues.length > 0 ? functionValues[0] : null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[1]) : null,
            period1:
              functionValues.length > 0 ? parseInt(functionValues[2]) : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
            ...comparatorObject,
          };
  
          if (
            newItem.channel ||
            newItem.period ||
            newItem.period1 === 0 ||
            newItem.offset === 0
          )
            return newItem;
        }
        if (item.name === "Stochastic") {
          const newItem = {
            ...item,
            function: functionName || null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            type: functionValues.length > 0 ? functionValues[1] : null,
            smoothen: functionValues.length > 0 ? functionValues[2] : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
            ...comparatorObject,
          };
  
          if (
            newItem.channel ||
            newItem.period ||
            newItem.period1 === 0 ||
            newItem.offset === 0
          )
            return newItem;
        }
  
        if (
          item.name === "MFI Moving Average (Money Flow Index Moving Average)"
        ) {
          const newItem = {
            ...item,
            function: functionName || null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            maType: functionValues.length > 0 ? functionValues[1] : null,
            maPeriod:
              functionValues.length > 0 ? parseInt(functionValues[2]) : null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
            ...comparatorObject,
          };
  
          if (
            (newItem.period && newItem.maType) ||
            newItem.period === 0 ||
            newItem.offset === 0
          )
            return newItem;
        }
      }
  
      if (functionValues.length === 1) {
        if (item.name === "Number") {
          const newItem = {
            ...item,
            function: functionName || null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            ...comparatorObject,
          };
          // Push the modified item to the current array
          if (newItem.period || newItem.period === 0) return newItem;
        } else if (item.name === "Close") {
          const newItem = {
            ...item,
            function: functionName || null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            ...comparatorObject,
          };
          // Push the modified item to the current array
          if (newItem.offset || newItem.offset === 0) return newItem;
        } else if (
          item.name === "Volume of a candle" ||
          item.name === "OBV (On Balance Volume)" ||
          item.name === "High price" ||
          item.name === "Low price" ||
          item.name === "Open price" ||
          "VWAP (Volume Weighted Average Price)" ||
          "Awesome Oscillator" ||
          "TR (True Range)"
        ) {
          const newItem = {
            ...item,
            function: functionName || null,
            offset:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            ...comparatorObject,
          };
          // Push the modified item to the current array
          return newItem;
        }
      }
  
      if (functionValues.length === 2) {
        const newItem = {
          ...item,
          function: functionName || null,
          period:
            functionValues.length > 0 ? parseInt(functionValues[0], 10) : null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      }
  
      if (functionValues.length === 3) {
        if (item.name === "Vortex Indicator") {
          const newItem = {
            ...item,
            function: functionName || null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            signal: functionValues.length > 1 ? functionValues[1] : null,
            offset:
              functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
            ...comparatorObject,
          };
          // Push the modified item to the current array
          return newItem;
        } else if (item.name === "Parabolic SAR") {
          const newItem = {
            ...item,
            function: functionName || null,
            minAF: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            maxAF: functionValues.length > 1 ? parseInt(functionValues[1]) : null,
            offset:
              functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
            ...comparatorObject,
          };
          // Push the modified item to the current array
          return newItem;
        } else if (item.name === "Supertrend") {
          const newItem = {
            ...item,
            function: functionName || null,
            period:
              functionValues.length > 0 ? parseInt(functionValues[0]) : null,
            multiplier:
              functionValues.length > 1 ? parseFloat(functionValues[1]) : null,
            offset:
              functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
            ...comparatorObject,
          };
          // Push the modified item to the current array
          return newItem;
        } else {
          const newItem = {
            ...item,
            function: functionName || null,
            field: functionValues.length > 0 ? functionValues[0] : null,
            period:
              functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
            offset:
              functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
            ...comparatorObject,
          };
          console.log("PRICERATE", newItem);
          // Push the modified item to the current array
          return newItem;
        }
      }
  
      if (item.name === "Ichimoku") {
        const newItem = {
          ...item,
          function: functionName || null,
          conversion: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          base: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
          leadingSpanB: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
          laggingSpan:
            functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
          line : functionValues.length > 0 ? functionValues[4] : null,
          cloudshift : functionValues.length > 0 ? functionValues[5] : null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[6], 10) : null,
          ...comparatorObject,
        };
          return newItem;
      }
      
    }
  };
  
  export default formateFnObject;
  
const indicatorsSwitchCase = (obj, option,prefix ) => {
    console.log("GETING", option)
    switch (option) {
      case "Ulcer":
          obj[`${prefix}ulcerField`] = "close";
          obj[`${prefix}ulcerPeriod`] = 14;
          obj[`${prefix}ulcerOffset`] = 0;
          break;
      case "TriMA (Triangular Moving Average)":
          obj[`${prefix}tmaField`] = "close";
          obj[`${prefix}tmaPeriod`] = 20;
          obj[`${prefix}tmaOffset`] = 0;
          break;
      case "VWMA (Volume Weighted Moving Average)":
          obj[`${prefix}vwmaField`] = "close";
          obj[`${prefix}vwmaPeriod`] = 20;
          obj[`${prefix}vwmaOffset`] = 0;
          break;
      case "DEMA (Double Exponential Moving Average)":
          obj[`${prefix}field`] = "close";
          obj[`${prefix}period`] = 20;
          obj[`${prefix}offset`] = 0;
          break;
      case "TEMA (Triple Exponential Moving Average)":
          obj[`${prefix}temaField`] = "close";
          obj[`${prefix}temaPeriod`] = 20;
          obj[`${prefix}temaOffset`] = 0;
          break;
      case "Open price":
          obj[`${prefix}period`] = 2;
          obj[`${prefix}offset`] = 0;
          break;
      case "Low price":
          obj[`${prefix}lowOffset`] = 0;
          break;
      case "High price":
          obj[`${prefix}highOffset`] = 0;
          break;
      case "Close":
          obj[`${prefix}closeOffset`] = 0;
          break;
      case "SMA (Simple Moving Average)":
          obj[`${prefix}field`] = "close";
          obj[`${prefix}period`] = 20;
          obj[`${prefix}offset`] = 0;
          break;
      case "WMA (Weighted Moving Average)":
          obj[`${prefix}wmaField`] = "close";
          obj[`${prefix}wmaPeriod`] = 20;
          obj[`${prefix}wmaOffset`] = 0;
          break;
      case "EMA (Exponential Moving Average)":
          obj[`${prefix}field`] = "close";
          obj[`${prefix}period`] = 20;
          obj[`${prefix}offset`] = 0;
          break;
      case "Money Flow Index":
          obj[`${prefix}mfiPeriod`] = 20;
          obj[`${prefix}mfiOffset`] = 0;
          break;
      case "Williams %R":
          obj[`${prefix}wrOffset`] = 0;
          obj[`${prefix}wrPeriod`] = 14;
          break;
      case "Moving Average Deviation (MA Dev)":
          obj[`${prefix}madField`] = "close";
          obj[`${prefix}madPeriod`] = 2;
          obj[`${prefix}madType`] = 0;
          obj[`${prefix}madOffset`] = 0;
          obj[`${prefix}madPop`] = 0;
          break;
      case "MACD (Moving Average Convergence/Divergence)":
        obj[`${prefix}macdsFastMaPeriod`] = 12;
        obj[`${prefix}macdsSlowMaPeriod`] = 26;
        obj[`${prefix}macdsSignalPeriod`] = 9;
        obj[`${prefix}macdsOffset`] = 0;
          break;
      case "OBV (On Balance Volume)":
          obj[`${prefix}obvOffset`] = 0;
          break;
      case "Aroon-Down":
          obj[`${prefix}aroonDownPeriod`] = 14;
          obj[`${prefix}aroonDownOffset`] = 0;
          break;
      case "Aroon-Up":
          obj[`${prefix}aroonUpPeriod`] = 14;
          obj[`${prefix}aroonUpOffset`] = 0;
          break;
      case "Awesome Oscillator":
          obj[`${prefix}aOscilatorOffset`] = 0;
          break;
      case "VWAP (Volume Weighted Average Price)":
          obj[`${prefix}vwapOffset`] = 0;
          break;
      case "Parabolic SAR":
          obj[`${prefix}psarMinimumAF`] = 0.02;
          obj[`${prefix}psarMaximumAF`] = 0.2;
          obj[`${prefix}psarOffset`] = 0;
          break;
      case "Vortex Indicator":
          obj[`${prefix}vortexIPeriod`] = 14;
          obj[`${prefix}vortexISignal`] = "+vi";
          obj[`${prefix}vortexIOffset`] = 0;
          break;
      case "Number":
          obj[`${prefix}period`] = 35;
          break;
      case "Volume of a candle":
          obj[`${prefix}volume`] = 0;
          break;
      case "Donchian Channel":
          obj[`${prefix}donchianChannel`] = "lower";
          obj[`${prefix}donchianHighPeriod`] = 20;
          obj[`${prefix}donchianLowPeriod`] = 20;
          obj[`${prefix}donchianOffset`] = 0;
          break;
      case "Stochastic":
          obj[`${prefix}stochasticPeriod`] = 14;
          obj[`${prefix}stochasticType`] = "%k";
          obj[`${prefix}stochasticSmooth`] = 0;
          obj[`${prefix}stochasticOffset`] = 0;
          break;
      case "Chaikin Money Flow":
          obj[`${prefix}chaikinPeriod`] = 20;
          obj[`${prefix}chaikinOffset`] = 0;
          break;
      case "MACD-signal":
          obj[`${prefix}macdsFastMaPeriod`] = 12;
          obj[`${prefix}macdsSlowMaPeriod`] = 26;
          obj[`${prefix}macdsSignalPeriod`] = 9;
          obj[`${prefix}macdsOffset`] = 0;
          break;
      case "MACD-histogram":
        obj[`${prefix}macdsFastMaPeriod`] = 12;
        obj[`${prefix}macdsSlowMaPeriod`] = 26;
        obj[`${prefix}macdsSignalPeriod`] = 9;
        obj[`${prefix}macdsOffset`] = 0;
        break;
      case "Commodity Channel Index":
          obj[`${prefix}cciPeriod`] = 20;
          obj[`${prefix}cciOffset`] = 0;
          break;
      case "Price Rate of Change":
          obj[`${prefix}rocField`] = "close";
          obj[`${prefix}rocPeriod`] = 14;
          obj[`${prefix}rocOffset`] = 0;
          break;
      case "RSI (Relative Strength Index)":
          obj[`${prefix}rsiPeriod`] = 14;
          obj[`${prefix}rsiOffset`] = 0;
          break;
      case "RSI Moving Average":
          obj[`${prefix}rsimaPeriod`] = 14;
          obj[`${prefix}rsimaType`] = "SMA";
          obj[`${prefix}rsimaMaPeriod`] = 20;
          obj[`${prefix}rsimaOffset`] = 0;
          break;
      case "Supertrend":
          obj[`${prefix}superTPeriod`] = 7;
          obj[`${prefix}superTMultiply`] = 3;
          obj[`${prefix}superTOffset`] = 0;
          break;
      case "TR (True Range)":
          obj[`${prefix}trOffset`] = 0;
          break;
      case "ATR (Average True Range)":
          obj[`${prefix}atrPeriod`] = 14;
          obj[`${prefix}atrOffset`] = 0;
          break;
      case "NATR (Normalized Average True Range)":
          obj[`${prefix}natrPeriod`] = 14;
          obj[`${prefix}natrOffset`] = 0;
          break;
      case "Bollinger %B":
          obj[`${prefix}bolingerBField`] = "close";
          obj[`${prefix}bolingerBPeriod`] = 20;
          obj[`${prefix}bolingerBDev`] = 2;
          obj[`${prefix}bolingerBType`] = "SMA";
          obj[`${prefix}bolingerBOffset`] = 0;
          break;
      case "MFI Moving Average (Money Flow Index Moving Average)":
          obj[`${prefix}mfiMAPeriod`] = 20;
          obj[`${prefix}mfiMAMAPeriod`] = 20;
          obj[`${prefix}mfiMAType`] = "SMA";
          obj[`${prefix}mfiMAOffset`] = 0;
          break;
      case "Know Sure Thing":
          obj[`${prefix}kstField`] = "close";
          obj[`${prefix}kstLTRoc`] = 10;
          obj[`${prefix}kstLTSma`] = 10;
          obj[`${prefix}kstRoc`] = 15;
          obj[`${prefix}kstLSma`] = 10;
          obj[`${prefix}kstHRoc`] = 20;
          obj[`${prefix}kstHSma`] = 10;
          obj[`${prefix}kstHeRoc`] = 30;
          obj[`${prefix}kstHeSma`] = 15;
          obj[`${prefix}kstSPeriod`] = 9;
          obj[`${prefix}kstType`] = "kst";
          obj[`${prefix}kstOffset`] = 0;
          break;
      case "Ichimoku":
          obj[`${prefix}ichiConversion`] = 9;
          obj[`${prefix}ichiBase`] = 26;
          obj[`${prefix}leadingSpanB`] = 52;
          obj[`${prefix}laggingSpan`] = 26;
          obj[`${prefix}ichiLine`] = "conversion";
          obj[`${prefix}cloudShift`] = "yes";
          obj[`${prefix}ichiOffset`] = 0;
          break;
    
      case "Standard Deviation":
          obj[`${prefix}stdPeriod`] = 14;
          obj[`${prefix}stdField`] = "close";
          obj[`${prefix}stdDev`] = 2;
          obj[`${prefix}maType`] = "simple" ;
          obj[`${prefix}offset`] = 0;
         break;
      case "Ultimate Oscillator" : 
          obj[`${prefix}length1`] = 7;
            obj[`${prefix}length2`] = 14;
            obj[`${prefix}length3`] = 28 ;
            obj[`${prefix}offset`] = 0;
            break;
      case "Nth Candle" : 
            obj[`${prefix}field`] = "high";
              obj[`${prefix}range`] = "min";
              obj[`${prefix}num`] = 1;
              break;
      case "Elder Force Index" :
      case "MOM (Momentum Indicator)":
      case "Chande Momentum Oscillator":
      case "Median Price":
      case "Choppiness Index" :
                obj[`${prefix}period`] = 14;
                obj[`${prefix}offset`] = 0;
                break;
    
      case "ATR Trailing Stoploss" : 
                    obj[`${prefix}multiplier`] = 3;
                    obj[`${prefix}period`] = 14;
                    obj[`${prefix}offset`] = 0;
                    break;
      case "Camarilla Pivot Points" :
      case "Central Pivot Range" : 
                    obj[`${prefix}signal`] = "pp";
                    break;
     case "Pivot Points" : 
                         obj[`${prefix}signal`] = "r1";
                        obj[`${prefix}type`] = "standard";
                        obj[`${prefix}continous`] = "yes";
                         break;
    case "Elder Ray Index" : 
                         obj[`${prefix}period`] = 14;
                         obj[`${prefix}power`] = "bull";
                         obj[`${prefix}offset`] = 0;
            break;
        case "UBB (Upper Bollinger Band)" :
        case "MBB (Middle Bollinger Band)" :
        case "LBB (Lower Bollinger Band)" :
            obj[`${prefix}field`] = "close";
            obj[`${prefix}period`] = 20;
            obj[`${prefix}dev`] = 2;
            obj[`${prefix}type`] = "SMA";
            obj[`${prefix}offset`] = 0;
            break;
      default:
          console.warn(`Option "${option}" not recognized.`);
          break;
  }
}

export default indicatorsSwitchCase;
import React, { useState, useMemo, useContext, useEffect,Profiler } from "react";
import ScannerParameterComponent from "./ScannerParameterComponent";
import {
  getIndices,
  getScannerResultApi,
  getStrategyListapi,
} from "../../api/scannerapi";
import { getInstrumentsAPI } from "../../api/api";
import { getUser, getZerodhaToken } from "../../Session";
import { useLocation} from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const MenuContext = React.createContext();

const ScannerCreateComponent = (props) => {
  const location = useLocation();
  const token = getZerodhaToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const scannerName = searchParams.get("scannername");
  const scannerid = searchParams.get("scannid");
  const Category=searchParams.get("Category");
  const [stocks, setStocks] = useState([]);
  const [allStocks, setAllStocks] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [storeAllStocks, setStoreAllStocks] = useState();
  const [stockItemName, setStockItemName] = useState();
  const [activeButton, setActiveButton] = useState(1);
  const user = getUser();
  const [positionFormData, setPositionFormData] = useState({
    chart_type: activeButton && activeButton,
    function_name: 2,
    scanner_name: scannerName,
    // scanner_id:scannerid,
    stock_name: "Nifty 50",
    position_id: 1,
    holding_type: "MIS",
    candleIntervalId: 1,
    candle_interval_id: "1m",
    quantity: 1,
    category_id:Category,
    entry_condition: "",
    isAdmin:1,
    stop_loss: "5",
    target_profit: "5",
    trailing_sl: "",
    max_allocation: 0,
    max_quantity: 0,
    position_sizing_type: "-",
    start_time: "00:00",
    end_time: "23:59",
    initial_capital: "100000",
    start_date: moment().subtract(29, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    description: "",
    user_id: JSON.parse(sessionStorage.getItem("user"))?.id,
    exch: "b",
    exchType: "c",
    scripCode: "500112",
    interval: "1m",
    fromDate: "",
    endDate: "",
    conditionTab: false,
    selectedOption1: "",
    selectedOption2: "",
    mode: "",
    comparator: "higher than",
    comparator2: "lower than",
    comparator3: "higher than",
    noStock: true,
    function_id: 83,
    tpslType: "per",
    firstFunctionId: "",
    secondFunctionId: "",
    functionsMainBox: [],
    functionsBox: [],
    functionsBox2: [],
    functionsBox777: [],
    inner_function1: {},
    inner_function2: {},
    inner_function3: {},
    operator: "and",
    function_id1: "",
    functionText1: "",
    exit_condition: "lower than",
    count: 0,

    ulcerField: "close",
    ulcerPeriod: "12",
    ulcerOffset: "0",
    tmaField: "close",
    tmaPeriod: "12",
    tmaOffset: "0",
    vwmaField: "close",
    vwmaPeriod: "12",
    vwmaOffset: "0",
    lowOffset: "",
    openOffset: "",
    openPeriod: "",
    closeOffset: "",
    highOffset: "",
    mfiPeriod: "",
    mfiOffset: "",
    wrOffset: "",
    aroonDownPeriod: "",
    aroonDownOffset: "",
    aroonUpPeriod: "",
    aroonUpOffset: "",
    obvOffset: "",
    macd_maPeriod: "",
    macd_slowMaPeriod: "",
    macd_signalPeriod: "",
    madField: "",
    madPeriod: "",
    madType: "",
    madOffset: "",
    vwapOffset: "",
    psarMinimumAF: "",
    psarMaximumAF: "",
    psarOffset: "",
    aOscilatorOffset: "",
    vortexIPeriod: "",
    vortexISignal: "",
    vortexIOffset: "",
    volume: "",
    donchianChannel: "",
    donchianHighPeriod: "",
    donchianLowPeriod: "",
    donchianOffset: "",
    stochasticPeriod: "",
    stochasticType: "",
    stochasticSmooth: "",
    chaikinOffset: "",
    chaikinPeriod: "",
    cciPeriod: "",
    cciOffset: "",
    macdsFastMaPeriod: "",
    macdsSlowMaPeriod: "",
    macdsSignalPeriod: "",
    macdsOffset: "",
    rocField: "",
    rocPeriod: "",
    rocOffset: "",
    rsimaPeriod: "",
    rsimaType: "",
    rsimaMaPeriod: "",
    rsimaOffset: "",

    IndicatorFormula: "",
    indicators: {},
    order: [],
    stocks: [],
    starttime: dayjs(
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    ),
    endtime: dayjs(
      moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
    ),
    exit_indicators: {},
    functionsBox888: [],
    functionsBox3: [],
    previous_day: 1,

    api_key: "3wxj01wpd2rnnn9c",
    access_token: token,
    selectedIndices: "",
  });

  const [menu, setMenu] = useState("parameter");
  const isNavigate = localStorage.getItem("IsNavigate");
useEffect(()=>{

},[])
  useEffect(() => {
    if (location?.state?.editDiscover) {
      location.state.data.scanner_name = scannerName;
      console.log("LOCATION STATE1",location.state);
      setPositionFormData(location.state.data);
    } else if (location.state) {
      console.log("LOCATION STATE", location.state);
    setPositionFormData(location.state);
    setActiveButton(location.state?.chart_type);
    }
  }, [location.state]);

  const handlePositionFormChange = (newFormData) => {
    setPositionFormData(newFormData);
  };
  const fetchScannerData = async () => {
    try {
      const result = await getScannerResultApi({
        id: scannerid,
        user_id: user.id,
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
      });
      console.log("RESULT SCANNER",result);

      const entireObject = result?.data[0]?.entire_object;
      const parsedObject = JSON.parse(entireObject);

      setPositionFormData({ scanner_id : scannerid , ...parsedObject });
      console.log("++++get scanner result", parsedObject )

    } catch (error) {
      console.warn("No data found in the result");
    }
  };
  useEffect(() => {
    if (isNavigate) {
      fetchScannerData();
      localStorage.removeItem("IsNavigate");
    }
  }, [isNavigate]);
  const fetchInstruments = async () => {
    const token = getZerodhaToken();

    if (token) {
      try {
        const response = await getInstrumentsAPI({
          api_key: "3wxj01wpd2rnnn9c",
          access_token: token,
        });

        const data = response.data;

        const lines = data.split("\n");

        // Get field names from the first line
        const fieldNames = lines[0].split(",").map((field) => field.trim());

        // Create JSON objects for each line
        const jsonData = [];
        for (let i = 1; i < lines?.length; i++) {
          const values = lines[i].split(",");

          // Check if the line has the correct number of fields
          if (values?.length === fieldNames?.length) {
            const jsonEntry = {};
            for (let j = 0; j < fieldNames?.length; j++) {
              jsonEntry[fieldNames[j]] = values[j].trim();
            }
            jsonData.push(jsonEntry);
          } else {
            console.error(
              `Line ${
                i + 1
              } does not have the correct number of fields. Content: ${
                lines[i]
              }`
            );
          }
        }

        let sortedJsonData = jsonData?.sort(
          (a, b) => a.tradingsymbol.length - b.tradingsymbol.length
        );
        setStoreAllStocks(sortedJsonData);

        const first20Stocks = jsonData?.slice(0, 20);
        setAllStocks(first20Stocks);
        // setNewStockes(first20Stocks);
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
      }
    }
  };

  const fetchIndices = async () => {
    try {
      // Fetch your stocks data from the API
      const response = await getIndices();

      setStocks(response);
      // setNewStockes(first20Stocks);
    } catch (error) {
      console.error("Error fetching stocks:", error);
    }
  };

  useEffect(() => {
    fetchInstruments();
    fetchIndices();
  }, []);

  const handleChangeCharType = (buttonNumber) => {
    setActiveButton(buttonNumber);
    setPositionFormData((prevFormData) => ({
      ...prevFormData,
      chart_type: buttonNumber,
    }));
  };
  const renderComponent = useMemo(() => {
    switch (menu) {
      case "parameter":
        return (
          <ScannerParameterComponent
            formData={positionFormData}
            onFormChange={handlePositionFormChange}
            stocks={stocks}
            setStocks={setStocks}
            scannerName={ scannerName}
            edit={false}
            // editDiscover={location.state?.editDiscover}
            handleChangeCharType={handleChangeCharType}
            setActiveButton={setActiveButton}
            activeButton={activeButton}
          />
        );
      default:
        return null;
    }
  }, [menu, positionFormData, stocks, activeButton]);

  return (
    <>
      <MenuContext.Provider
        value={{
          menu,
          setMenu,
          allStocks,
          setAllStocks,
          selectedItems,
          setSelectedItems,
          storeAllStocks,
          setStockItemName,
          stockItemName,
        }}
      >
        <section className="mobile-view">
          <div className="container web-cotainer">
            <br />
            <br />
            <div className="pt-0">
              <Profiler
                id="ScannerCreateComponent"
                onRender={(id, phase, actualDuration) => {
                  console.log("Profiler",{ id, phase, actualDuration });
                }}
              >
                {renderComponent}
              </Profiler>
            </div>
         </div>
        </section>
      </MenuContext.Provider>
    </>
  );
};
export default ScannerCreateComponent;

export const useMenuContextScanner = () => useContext(MenuContext);

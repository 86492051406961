import axios from "axios";
const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
export const GetDashboardDetailsApi = async () => {
    try{
        const response = await axios.get(`${API_BASE_URL}/adminpanel/GetDashboardDetails`);
          if (response.data.success) {
            return response?.data;
          } else {
            return false;
          }

    }catch(error){
        console.log("Error : ", error);
    }
}

export const GetSingleUser = async (id) => {
  try{
      const response = await axios.post(`${API_BASE_URL}/adminpanel/GetSingleUserDetails`,{id:id});
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }

  }catch(error){
      console.log("Error : ", error);
  }
}
export const Exportfile = async () => {
  try{
      const response = await axios.post(`${API_BASE_URL}/adminpanel/exportRenewals`);
        if (response.data.success) {
          console.log("link",response.data)
          return response?.data;
        } else {
          return false;
        }

  }catch(error){
      console.log("Error : ", error);
  }
}
export const ExportExpired = async () => {
  try{
      const response = await axios.post(`${API_BASE_URL}/adminpanel/exportExpired`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }

  }catch(error){
      console.log("Error : ", error);
  }
}
export const ExportUsers = async () => {
  try{
      const response = await axios.post(`${API_BASE_URL}/adminpanel/exportUsers`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }

  }catch(error){
      console.log("Error : ", error);
  }
}
// Exportsalelist
export const ExportActive = async () => {
  try{
      const response = await axios.post(`${API_BASE_URL}/adminpanel/exportActive`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }

  }catch(error){
      console.log("Error : ", error);
  }
}
export const Exportsalelist = async () => {
  try{
      const response = await axios.post(`${API_BASE_URL}/adminpanel/exportSaleList`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }

  }catch(error){
      console.log("Error : ", error);
  }
}
export const GetUsersListApi = async () => {
    try{
        const response = await axios.post(`${API_BASE_URL}/adminpanel/GetUsersList`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }
    }catch(error){
        console.log("Error" ,error)
    }
}
export const GetBlogs = async () => {
  try{
      const response = await axios.get(`${API_BASE_URL}/adminpanel/GetBlogLists`);
      if (response.data.success) {
        console.log("AAAfirst",response.data.data);
        return response?.data.data;
      } else {
        return false;
      }
  }catch(error){
      console.log("Error" ,error)
  }
}
export const GetCount=async()=>{
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/GetSaleDetails`);
    if (response.data.success) {
      return response?.data;
    } else {
      return false;
    }
}catch(error){
    console.log("Error" ,error)
}
}
export const GetAllSaleList=async()=>{
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/GetAllSaleList`);
    if (response.data.success) {
      return response?.data;
    } else {
      return false;
    }
}catch(error){
    console.log("Error" ,error)
}
}
export const GetActiveList=async()=>{
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/GetActiveList`);
    if (response.data.success) {
      return response?.data;
    } else {
      return false;
    }
}catch(error){
    console.log("Error" ,error)
}
}
export const GetExpiringListApi = async () => {
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/GetExpiringList`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }
  }catch(error){
    console.log("Error :" , error);
  }
}
export const GetExpiredListApi = async () => {
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/GetExpiredList`);
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }
  }catch(error){
    console.log("Error :" , error);
  }
}
export const AddNewBlog=async(data)=>{
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/AddNewBlog`,data,
       {headers: {
      'Content-Type': 'multipart/form-data'
  }});
        if (response.data.success) {
          return response?.data;
        } else {
          return false;
        }
  }catch(error){
    console.log("Error :" , error);
  }
}
export const UpdateBlogs=async(data)=>{
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/UpdateBlog`,data,
       {headers: {
      'Content-Type': 'multipart/form-data'
  }});
        if (response.data.success) {
          console.log("77first",response);
          return response?.data;
        } else {
          return false;
        }
  }catch(error){
    console.log("Error :" , error);
  }
}
// /adminpanel/DeleteBlog
export const RemoveBlogs=async(data)=>{
  try{
    const response = await axios.post(`${API_BASE_URL}/adminpanel/DeleteBlog`,data);
        if (response.data.success) {
          return response?.data.success;
        } else {
          return false;
        }
  }catch(error){
    console.log("Error :" , error);
  }
}
import axios from "axios";

const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://192.168.1.5:8008/api/v1";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const checkExistEmail = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/email-valid`, {
      email: email,
    });
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const checkExistPhoneNo = async (phone) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/phone-valid`, {
      phone: phone,
    });
    console.log(response.data.success);
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const signUpUser = async (data) => {
  try {
    console.log("userData", data);
    const response = await axios.post(`${API_BASE_URL}/users/sign-up`, data);
    console.log("response", response);
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const genrateOtp = async (phoneNo) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/send-signup-otp`, {
      phone: phoneNo,
    });
    if (response.data.success) {
      const data = { otp: response.data.otp };
      console.log("@@", response);
      return data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const VerifyOtpAfterSignUp = async (otp, phoneNo) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/verify-signup-otp`,
      {
        phone: phoneNo,
        otp: otp,
      }
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const expireOtpApi = async (phoneNo, otp) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/users/expire-signup-otp`,
      {
        phone: phoneNo,
        otp: otp,
      }
    );
    if (responce.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const loginApi = async (phoneNo) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/login`, {
      phone: phoneNo,
    });
    if (response.data.success) {
      return response.data?.userDetails[0];
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getAllStocks = async () => {
  try {
    const response = await axios.get(
      "https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
    );

    if (response.data.success) return response.data;
  } catch (error) {
    console.error("Error fetching stocks:", error);
  }
};

export const addNewStrategy = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/addNewStrategy`,
      data
    );

    if (response.data.success) return response.data;
  } catch (error) {
    console.error("Error Adding New Strategy:", error);
  }
};

export const getSingleSavedStrategyData = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getSingleSavedDiscoveredStrategyDetails`,
      data
    );

    if (response.data.success) return response.data;
  } catch (error) {
    console.error("Error getSingleStrategyData:", error);
  }
};

export const getStockLTPDataAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/zerodhaGetMarketLiveStream`,
      data
    );

    if (response.data.success) return response.data;
  } catch (error) {
    console.error("Error getStockData zerodhaGetMarketLiveStream:", error);
  }
};

export const getInstrumentsAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getInstrumentList`,
      data
    );

    if (response.data.success) return response.data;
  } catch (error) {
    console.error("Error getInstrumentsAPI:", error);
  }
};

export const addNewScannerAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/addNewScanner`,
      data
    );

    if (response.data.success) 
      console.log("%&%&",response.data)
      return response.data;
  } catch (error) {
    console.error("Error Adding New Scanner:", error);
  }
};

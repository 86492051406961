import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const SMAModal = ({
  showSMA,
  handleSMAClickedPage,
  setIsShow,
  type,
  typeName,
  mainData,
  setMainData,
}) => {
  // console.log("type????", type);
  const [formData, setFormData] = useState(mainData);

  const { offset, maPeriod, rsiOffset2, rsiPeriod2, rsiType, number } =
    formData;

  const handleClose = () => {
    setIsShow(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    console.log("innnnn..", name, value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDone = () => {
    handleSMAClickedPage(formData);
  };

  return (
    <>
      <Modal show={showSMA} onHide={handleClose} animation={false} centered>
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
          <Form>
            <h5>{typeName}</h5>
            <br />

            {type === "SMA (Simple Moving Average)" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Field</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="field"
                    value={formData.field}
                    onChange={handelChange}
                  >
                    <option>Open this select menu</option>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">high</option>
                    <option value="low">low</option>
                    <option value="close">close</option>
                    <option value="volume">volume</option>
                  </Form.Select>
                </Col>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.periods}
                    name="periods"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.offset1}
                    name="offset1"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}

            {type === "EMA (Exponential Moving Average)" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Field</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="efield"
                    value={formData.efield}
                    onChange={handelChange}
                  >
                    <option>Open this select menu</option>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">high</option>
                    <option value="low">low</option>
                    <option value="close">close</option>
                    <option value="volume">volume</option>
                  </Form.Select>
                </Col>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.eperiod}
                    name="eperiod"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.eoffset}
                    name="eoffset"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}
            {type === "RSI (Relative Strength Index)" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.rsiPeriod}
                    name="rsiPeriod"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{}}>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.rsiOffset}
                    name="rsiOffset"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}

            {type === "RSI Moving Average" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={rsiPeriod2}
                    name="rsiPeriod2"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="rsiType"
                    value={rsiType}
                    onChange={handelChange}
                  >
                    <option>Open this select menu</option>
                    <option value="simple">simple</option>
                    <option value="exponential">exponential</option>
                    <option value="weighted">weighted</option>
                    <option value="triangular">triangular</option>
                    <option value="double">double</option>
                    <option value="hull">hull</option>
                    <option value="mesa">mesa</option>
                    <option value="variable">variable</option>
                    <option value="kaufman">kaufman</option>
                    <option value="vidya">vidya</option>
                  </Form.Select>
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>MA Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={maPeriod}
                    name="maPeriod"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type="text"
                    value={rsiOffset2}
                    name="rsiOffset2"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}

            {/* {(type === "Low price" ||
              type === "High price" ||
              type === "Open price" ||
              type === "Close") && (
              <Row>
                <Col sm="6" style={{ margin: "auto" }}>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type="text"
                    value={offset}
                    name="offset"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )} */}

            {type === "Number" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={number}
                    name="number"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}

            {type === "Exit" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Field</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="exit_field1"
                    value={formData.exit_field1}
                    onChange={handelChange}
                  >
                    <option>Open this select menu</option>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">high</option>
                    <option value="low">low</option>
                    <option value="close">close</option>
                    <option value="volume">volume</option>
                  </Form.Select>
                </Col>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.exit_period1}
                    name="exit_period1"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.exit_offset1}
                    name="exit_offset1"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}
            <br />
            <Button variant="primary" onClick={handleDone}>
              Done
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SMAModal;

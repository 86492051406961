import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Login from "./Pages/Login";
import Userlist from "./Pages/Userlist";
import Salelist from "./Pages/Salelist";
import Subscriptions from "./Pages/Subscriptions";
import Dashboard from "./Pages/Dashboard";
import MiniDrawer from "./Components/MiniDrawer";
import Strategies from "./Pages/Strategies";
import StrategyCreateComonent from "./Components/Strategies/StrategyCreateComponent";
import Scanner from "./Pages/Scanner";
import ScannerCreateComponent from "./Components/scanner/ScannerCreateComponent";
import SingleStrategy from "./Components/Strategies/SingleStrategy.jsx";
import SingleScanner from "./Components/scanner/SingleScanner.jsx";
import SingleUser from "./Components/SingleUser";
import Demorequest from "./Pages/Demorequest";
import Blogs from "./Pages/Blogs";
import Feedback from "./Pages/Feedback";
import Contactuserdetail from "./Pages/Contactuserdetail";
import Editblogs from "./Pages/Editblogs";
import Coupons from "./Pages/Coupons.js";
import Zerodha from "./Pages/Zerodha.js";
function App() {
  return (
    <Router>
      <div>
        <MiniDrawer>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Userlist" element={<Userlist />} />
            <Route path="/Salelist" element={<Salelist />} />
            <Route path="/Subscriptions" element={<Subscriptions />} />
            <Route path="/Strategies" element={<Strategies/>} />
            {/* <Route path="/Strategies/create" element={<StrategyCreateComonent />} /> */}
            <Route path="/scanner" element={<Scanner />} />
            <Route path="/Demorequest" element={<Demorequest/>} /> 
            <Route path="/coupon" element={<Coupons/>}/>
            <Route
              path="/createstrategies"
              element={<StrategyCreateComonent />}
            />
            <Route
              path="/scanners/page/"
              element={<ScannerCreateComponent />}
            ></Route>
            <Route path="/strategies/:id" element={<SingleStrategy />}></Route>
            <Route path="/scanner/result/:scanid" element={<SingleScanner />}></Route>
            <Route path="/user/:id" element={<SingleUser/>}></Route>
            <Route path="/user/feedback" element={<Feedback/>}></Route>
            <Route path="/user/blogs" element={<Blogs/>}></Route>
            <Route path="/user/edit-blog/:id" element={<Editblogs/>}></Route>
            <Route path="/user/contactuserdetails" element={<Contactuserdetail/>}></Route>
            <Route path="/zerodha" element={<Zerodha/>}></Route>
            {/* <Route path="/user/blogs" element={<Blogs/>}></Route> */}
            {/* <Route path="/user/blogs" element={<Blogs/>}></Route> */}
          </Routes>
        </MiniDrawer>
        {/* </MiniDrawer> */}
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { GetDashboardDetailsApi, GetExpiredListApi, GetExpiringListApi } from "../api/adminapi";
import { Exportfile,ExportExpired } from "../api/adminapi";
import DataTable from "react-data-table-component";
import { Button, Form } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";


function Dashboard() {
  const navigate=useNavigate();
  const [usersCount ,setUsersCount] = useState('');
  const [upcomingRenewals , setUpcomingRenewals] = useState([]);
  const [upcomingRenewalsAll , setUpcomingRenewalsAll] = useState([]);
  const [expiredSubscriptionsAll , setExpiredSubscriptionsAll] = useState([]);
  const [expiredSubscriptions , setExpiredSubscriptions] = useState([]);
 const [search,setSearch]=useState("");
 const [sortedsubscription,setSortedsubscription]=useState([]);

  const fetchUsersData = async() =>{
    const result = await GetDashboardDetailsApi();
    console.log("result",result);
    setUsersCount(result);
  } 
useEffect(()=>{
    console.log("#subscription",expiredSubscriptions)
},[expiredSubscriptions])

  const fetchUpcomingRenewals = async () => {
    const result = await GetExpiringListApi();
    console.log("result",result);
    setUpcomingRenewalsAll(result?.data);
    setUpcomingRenewals(result?.data)
  }

  const fetchExpiredSubscriptions = async () => {
    const result =  await GetExpiredListApi();
    console.log("result@",result);
    setExpiredSubscriptionsAll(result?.data);
    setExpiredSubscriptions(result?.data);
  }

  useEffect(() => {
    fetchUsersData();
    fetchUpcomingRenewals();
    fetchExpiredSubscriptions();
    // handleFilterESubscription();
  },[]);

  const customStyles = {
    rows: {
      style: {
        border : "1px solid #8080805e", // override the row height
        minHeight: '40px',
      },
    },
    headCells: {
      style: {
        border : "1px solid #8080805e",
        fontSize : "medium",
        fontWeight : "bold",
        borderBottom : "none",
        minHeight: '30px',
      },
    },
    cells: {
      style: {
        borderRight : "1px solid #8080805e", // override the row height
       
        // height : "2px"
      },
    },
    
  };

  const columns =  [
    {
      id : "id",
      selector : row => row.id,
      omit : true
    },
    {
      name : "Name",
      selector : row => row.user_name,
      // sortable :true
    },
    {
      name : "Phone",
      selector : row => row.phone
    },
    {
      name : "Email",
      selector : row => row.email,
    },
    {
      name : "Plan",
      cell :(row)=> row.plan_id ===1 ? "Premium" : "Free"
    },
    {
      name : "Days Remaining",
      selector : row => row.start_date
    }
    
  ];


  const columnsesubscription =  [
    {
      id : "id",
      selector : row => row.id,
      omit : true
    },
    {
      name : "Name",
      selector : row => row.user_name,
      // sortable :true
    },
    {
      name : "Phone",
      selector : row => row.phone
    },
    {
      name : "Email",
      selector : row => row.email,
    },
    {
      name : "Plan",
      cell :(row)=> row.plan_id ===1 ? "Premium" : "Free"
    },
    {
      name : "Expiry Date",
      selector : row => row.start_date
    }
    
  ];

  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const newData = upcomingRenewalsAll?.filter(row => 
      (row.user_name?.toLowerCase().includes(searchTerm) || 
      row.email?.toLowerCase().includes(searchTerm))
  );
    setUpcomingRenewalsAll(newData);
  };
const gotouserlist=()=>{
  navigate("/Userlist");
}
const gotosubscription=()=>{
  navigate("/Subscriptions");
}
  // const handleFilterESubscription = (e) => {
  //   const searchTerm = e.target.value.toLowerCase();
  //   setSearch(searchTerm);  
  // }
  const handleFilterESubscription = (event) => {
    const searchTerm = event.target.value?.toLowerCase();
    // const newData = data?.filter(row =>  row.user_name?.toLowerCase().includes(event.target.value?.toLowerCase()));
    const newData = expiredSubscriptionsAll?.filter(row => 
      (row.user_name?.toLowerCase().includes(searchTerm) || 
      row.email?.toLowerCase().includes(searchTerm))
  );
  setExpiredSubscriptions(newData);
  }

  const downloadFile = () => {
    window.location.href = "https://yoursite.com/src/assets/files/exampleDoc.pdf"
  }

 const handleExportRenewals = async() => {
     const result=await Exportfile();
     console.log("Exported file",result);
     const downloadUrl = `https://dalrotibackend.shrikanttapkeer.com/exports/${result.filename}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
     link.setAttribute('download', result.filename);
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
};
const handleExportExpired = async() => {
  const result=await ExportExpired();
  console.log("Exported file",result);
  const downloadUrl = `https://dalrotibackend.shrikanttapkeer.com/exports/${result.filename}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
  // link.setAttribute('download', result.filename);
  document.body.appendChild(link);
  link.click();
  console.log("link@@",downloadUrl,link)
  document.body.removeChild(link);
};


  return (
    <>
      <div className="page">
        <div className="page-content container-fluid">
          <div className="row">
            <div className="col-xl-9 col-lg-9">
              <div className="panel">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    Upcoming Renewals{" "}
                    <small className="font-size-12">
                      (Subscriptions Expiring in Next 5 Days)
                    </small>
                  </h3>
                  <div className="panel-actions text-white">
                    <button
                      className="btn btn-success float-right color-white"
                      id="export-renewals" 
                      onClick={handleExportRenewals}
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>{" "}
                      Export
                    </button>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="mb-2">
                <Form className="d-flex align-items-baseline">
                <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                <Form.Control className="w-25 ms-2" size="sm" type="text" placeholder="Search..." onChange={handleFilter} />
                </Form>
              </div>
              
              <DataTable 
                columns={columns}
                data = {upcomingRenewals}
                pagination
                fixedHeader
                customStyles={customStyles}
                highlightOnHover
                striped
                pointerOnHover
                responsive
              >
              </DataTable>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading">
                  {/* <h3 className="panel-title">Expired Subscriptions</h3>
                  <div className="panel-actions text-white" style={{margin:"30px 30px"}}>
                    <Button className="btn btn-success"  id="export-renewals" 
                      onClick={handleExportExpired}>
                      <i className="fa fa-download"></i> Export
                    </Button>
                  </div> */}
                </div>
                <div className="panel-heading pt-2">
                  <h3 className="panel-title">
                  Expired Subscriptions
                  </h3>
                  <div className="panel-actions text-white">
                    <button
                      className="btn btn-success float-right color-white"
                      id="export-renewals" 
                     onClick={handleExportExpired}
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>{" "}
                      Export
                    </button>
                  </div>
                </div>
                <div className="panel-bodyEXP" style={{padding:"22px"}}>
                 <div className="text-end mb-2">
                 <Form className="d-flex align-items-baseline">
                <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                <Form.Control className="w-25 ms-2" size="sm" type="text" placeholder="Search..." onChange={handleFilterESubscription} />
                </Form>
              </div>
              <DataTable
                columns={columnsesubscription}
                data = {expiredSubscriptions}
                pagination
                fixedHeader
                customStyles={customStyles}
                highlightOnHover
                striped
                pointerOnHover
                responsive
              >
              </DataTable>
                </div>
              </div>

              {/* <DataTable columns={columns} data={data} /> */}
              <div></div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="row">
                <div className="col-lg-12" onClick={()=>gotouserlist()}>
                  <div
                    className="card card-block d-flex justify-content-center"
                    style={{ backgroundColor: "#FFDEBD", padding: "25px",width:"90%",height:"175px", }}
                   
                  >
                    <div className="counter counter-lg" style={{cursor:"pointer"}}>
                      <span className="counter-number"> {usersCount?.totalUsers}</span>
                      <div className="counter-label text-uppercase">
                        <FontAwesomeIcon icon={faUsers} />{" "}
                        <div className="text-gray pl-2 d-inline" style={{ textDecorationLine:"none",color:"#76838F"}}
                        >
                          {/* <i className="icon fa fa-users mr-5"></i> */}
                          Total Users
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 my-3"  onClick={()=>gotouserlist()}>
                  <div
                    className="card card-block  d-flex justify-content-center"
                    style={{ backgroundColor: "rgb(221,216,255)", padding: "25px",width:"90%",height:"175px", cursor:"pointer"}}
                  >
                    <div className="counter counter-lg">
                      <div className="counter-number-group">
                        <span className="counter-number"> {usersCount?.newUsers}</span>
                      </div>
                      <div className="counter-label text-uppercase">
                        <FontAwesomeIcon icon={faUserPlus} />
                        <div
                          onClick={()=>gotouserlist()}
                          className="d-inline text-gray pl-2" style={{ textDecorationLine:"none",color:"#76838F"}}
                        >
                          New Users
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12"  onClick={()=>gotosubscription()}>
                  <div
                    className="card card-block  d-flex justify-content-center"
                    style={{ backgroundColor: "#D6EEFC",width:"90%",height:"175px",cursor:"pointer" }}
                  >
                    <div className="counter counter-lg">
                      <div className="counter-number-group">
                        <span className="counter-number">{usersCount?.newSubscriptions}</span>
                      </div>
                      <div className="counter-label text-uppercase">
                        <FontAwesomeIcon icon={faUsers} />
                        <div className="d-inline text-gray pl-2" style={{textDecorationLine:"none",color:"#76838F"}}>
                          Today's Subscriptions
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

// InputBox.js

import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";


const InputBox = ({ type, placeholder, value, onChange }) => {
  return (

    <Form.Control
     
      className="form-control"
      id="outlined-basic"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default InputBox;

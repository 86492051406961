import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import NumberInput from "../NumberInput";

const LBB = ({
  show,
  setIsShow,
  handleModalData,
  mainData,
  clickedFunction,
  exit,
  index,
  instance,
}) => {

let data;

if (exit) {
  const exitIndicators = mainData?.exit_indicators?.[clickedFunction];

  if (exitIndicators?.body) {
    data = exitIndicators.body[index];
  } else if (instance && exitIndicators?.body1) {
    data = exitIndicators[instance]?.[0];
  } else if (exitIndicators?.body2) {
    data = exitIndicators[instance]?.[0];
  } else {
    data = exitIndicators;
  }
} else {
  const indicators = mainData?.indicators?.[clickedFunction];

  if (indicators?.body) {
    data = indicators.body[index];
  } else if (instance && indicators?.body1) {
    data = indicators[instance]?.[0];
  } else if (indicators?.body2) {
    data = indicators[instance]?.[0];
  } else {
    data = indicators;
  }
}

const [formData, setFormData] = useState(data);

useEffect(() => {
  // Update formData when data changes
  setFormData(data);
}, [data]);
const handleClose = () => {
  setIsShow(false);
};

const handelChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};
const handleDone = () => {
  handleModalData(formData);
  setIsShow(false);
};
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">LBB</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <Form>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Field
                </Form.Label>
                <Col sm="3">
                  <Form.Select
                    size="sm"
                    name={exit ? "e_field" : "field"}
                    value={exit ? formData?.e_field : formData?.field}
                    onChange={handelChange}
                  >
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">High</option>
                    <option value="low">Low</option>
                    <option value="close">Close</option>
                  </Form.Select>
                </Col>
                <Form.Label column sm="3">
                  Period
                </Form.Label>
                <Col sm="3">
                  <NumberInput
                    type="number"
                    size="sm"
                    name={exit ? "e_period" : "period"}
                    value={exit ? formData?.e_period : formData?.period}
                    onChange={handelChange}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Standard Deviations
                </Form.Label>
                <Col sm="3">
                  <NumberInput
                    type="number"
                    size="sm"
                    name={exit ? "e_dev" : "dev"}
                    value={exit ? formData?.e_dev : formData?.dev}
                    onChange={handelChange}
                  />
                </Col>
                <Form.Label column sm="3">
                  Type
                </Form.Label>
                <Col sm="3">
                  <Form.Select
                    size="sm"
                    name={exit ? "e_type" : "type"}
                    value={exit ? formData?.e_type : formData?.type}
                    onChange={handelChange}
                  >
                    <option value="SMA">SMA</option>
                    <option value="EMA">EMA</option>
                    <option value="DEMA">DEMA</option>
                    <option value="TEMA">TEMA</option>
                    <option value="TriMA">TriMA</option>
                    <option value="HULL">HULL</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Offset
                </Form.Label>
                <Col sm="3">
                  <NumberInput
                    type="number"
                    size="sm"
                    name={exit ? "e_offset" : "offset"}
                    value={
                      exit
                        ? formData?.e_offset
                        : formData?.offset
                    }
                    onChange={handelChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="danger" size="sm" onClick={handleClose}>
            Delete
          </Button>
          <Button variant="primary" size="sm" onClick={handleDone}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LBB;

import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import axios from "axios";
// import API_BASE_URL 
const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// import { getCategoryStrategy } from "../api/strategyapi";
const CreateStrategyPopup = ({
  showCreatePopup,
  strategiesNameError,
  handelClickCreateStrategy,
  setShowCreatePopup,
  setStrategiesNameError,
  nameError,
}) => {
  const [show, setShow] = useState();
  const [strategiesName, setStrategiesName] = useState();
  const [isDisable, setIsDisable] = useState(true);
  const [Category, setCategory] = React.useState();
 const [Categoryarr,setCategoryarray]=React.useState([])
// const Categoryarr=["aa","dd"]
const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
    handelClickCreateStrategy(strategiesName,Category);
  }
};
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const handleClose = () => {
    setShowCreatePopup(false);
  };
  const create=()=>{
    if(strategiesName && Category)
    {
      handelClickCreateStrategy(strategiesName,Category);
    } 
    else
    {
      alert("Please Enter all details to create Strategy");
    }    
  }
  const handelChange = (e) => {
    const value = e.target.value;
    setStrategiesNameError(false);
    if (value.length > 2) {
      setIsDisable(false);
    }
    setStrategiesName(value);
  };
  
useEffect(()=>{
  // const result=getCategoryStrategy();
  getCategoryStrategy();

  // setCategoryarray(result);

},[])
const getCategoryStrategy=async()=>{
  try {
   const response=await axios.get(`${API_BASE_URL}/strategies/getStrategyCategories`);
   if (response.data.success) {
    console.log("CATEGORY",response.data.data);
    setCategoryarray(response.data.data);
 
  } else {
      console.log("Error")
  }
} catch (error) {
  console.log("Error :", error);
}
};
  return (
    <>
      <Modal
        show={showCreatePopup}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
         
          <Form>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Create New Strategy</Form.Label>
              <Form.Control
                type="text"
                placeholder="Strategy Name"
                value={strategiesName}
                onChange={handelChange}
                onKeyDown={handleKeyPress}
                autoComplete="off" style={{height:"50px",fontSize:"14px"}}
              />
              {strategiesNameError && (
                <span className="text-error">Name is already exist </span>
              )}
            </Form.Group>
            <Form.Label>Select Category</Form.Label><br></br>
            {/* <Box sx={{ minWidth: 120 }}>        
      <FormControl sx={{ m: 1, minWidth: 120,height:"40px" }}>
      <InputLabel id="demo-select-small-label">Category</InputLabel>
      <Select className="form-control"
        labelId="demo-select-small-label"
        value={Category}
        label="Age"
        onChange={handleChangeCategory} 
        style={{height:"40px",padding:"0px"}}
      >
        {Categoryarr?.map((item)=>{
          return(
            <MenuItem value={item?.id}>{item?.name}</MenuItem>
          );
        })}
      
      </Select>
            </FormControl>
            </Box> */}
      <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Category}
        label="Age"
        style={{height:"50px"
        }}
        onChange={handleChangeCategory} 
        >
          {Categoryarr?.map((item)=>{
          return(
            <MenuItem value={item?.id}>{item?.name}</MenuItem>
          );
        })}
        </Select>
      </FormControl>
    </Box>
            <Button
              className="btn-color mt-3"
              variant="primary"
              disabled={isDisable}
              onClick={() => create()}
              size="sm"
            >
              Create
            </Button>
            <br />
            <Button variant="default" onClick={handleClose} size="sm">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateStrategyPopup;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import CreateStrategyPopup from "../Components/CreateStrategyPopup";
import { getUser ,getActiveAccount} from "../Session";
import { existStrategyNameApi, getAllStrategyApi,deletingStrategy,getAllDiscoveredStrategyApi } from "../api/strategyapi";
import moment from "moment";
import StrategyCreateComonent from "../Components/Strategies/StrategyCreateComponent";
import CircularProgress from "@mui/material/CircularProgress";
import "../Components/componentCommon/myspinner.css";
const Strategies = () => {
  const styles = {
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50vh', // Full height of the viewport
    },
  };
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [strategiesNameError, setStrategiesNameError] = useState(false);
  const [createStratgyPage, setCreateStratgyPage] = useState(false);
  const [strategyName, setStrategyName] = useState("");
  const [categorynm,setCategoryname]=useState();
  const [ strategiesList , setStrategiesList ] = useState([]);
 const [del,setDelete]=useState();
 const [loading,setLoading]=useState(true)
  const navigate = useNavigate();

    const handleClickStrategyModal = () => {
        setShowCreatePopup(true);
      };

      const handelClickCreateStrategy = async (name,Category) => {
        const user = getUser();
          const data = await existStrategyNameApi({
            strategy_name: name?.trim(),
            user_id: user?.id,
          });
          if (data?.data?.length >= 1) {
            setStrategiesNameError(true);
          } else {
            setStrategiesNameError(false);
            setShowCreatePopup(false);
            setCreateStratgyPage(true);
            setStrategyName(name);
            setCategoryname(Category);
          }
        // } else {
          // alert(
          //   "To create strategy you need to activate broker account from dashboard"
          // );
          // navigate("/dashboard");
        // }
      };
      const featchListOfStrategy = async () => {
        const user = getUser();
        const result = await getAllDiscoveredStrategyApi({user_id : user?.id});
        setStrategiesList(result);
        setLoading(false);  
      }

      const handleConfirmDelete=async(id)=>{
        // console.log("strategyID11", id);
        const isconfirmed=window.confirm("Are you sure you want to delete this strategy?");
        if(isconfirmed)
          {
            const result=await deletingStrategy({strategy_id:id});
            setDelete(id);
            featchListOfStrategy();
          }
      }

      useEffect(() => {
        featchListOfStrategy();
      },[del]);

    return(
    <>
    {createStratgyPage?(<>
       <StrategyCreateComonent
        strategyName={strategyName}
        categorynm={categorynm}
        setStrategiesNameError={setStrategiesNameError}
        setCreateStratgyPage={setCreateStratgyPage}
      /></>):(
      <>
      <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Strategies</h3>

              <div className="panel-actions text-white">
                <button  className="btn btn-primary" 
                onClick={handleClickStrategyModal} disabled={loading}>
                  <span className="hidden-sm-down">
                    <i className="fa fa-plus" aria-hidden="true"></i> Add New
                    Strategies
                  </span>
                </button>
              </div>
            </div>
            <div className="panel-body">
            {loading && 
              <>  
                <div style={styles.spinnerContainer}>
                <CircularProgress color="inherit" />
                </div>
              </>
              }
              <table
                className="table table-hover table-bordered dataTable table-striped w-full"
                id="user-list"
              >
                {!loading && 
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Strategy Name</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                }
                <tbody>
                  {strategiesList?.map((data,i) => (
                    <tr key={i}>
                      <td>{++i}</td>
                      <td>{data?.strategy_name.length>20?(data?.strategy_name.slice(0,20))+'...':data?.strategy_name }</td>
                      <td>{moment(data?.strategy_details.created_at).format('DD-MM-YYYY')}</td>
                      <td className="">
                        
                        <Button onClick={() => navigate(`/strategies/${data?.strategy_id}`)} varient="success"><i className="fa fa-eye"></i> View </Button>
                        {/* <Button varient="success" className="mx-3"><i className="fa fa-edit"></i> Edit </Button> */}
                        <Button varient="success" className="ms-3"  onClick={() => handleConfirmDelete(data.strategy_id)}><i className="fa fa-trash"></i> Delete </Button>
                      </td>
                  </tr>
                  ))}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CreateStrategyPopup
          showCreatePopup={showCreatePopup}
          strategiesNameError={strategiesNameError}
          setStrategiesNameError={ setStrategiesNameError}
          handelClickCreateStrategy={handelClickCreateStrategy}
          setShowCreatePopup={setShowCreatePopup}/>
          </>
          )}
      
    </>
    )
}

export default Strategies;
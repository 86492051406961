// import React, { useState, useRef, useEffect } from "react";
// import { useMenuContextScanner } from "./ScannerCreateComponent";
// import { Button, Card, Form, ListGroup } from "react-bootstrap";
// import { getfunctionTypeWisefunction } from "../../api/strategyapi";
// import FormulaTab from "../Strategies/FormulaTab";
// import CompartorModal from "../Strategies/ComparatorModal";
// import Ulcer from "../modal/Ulcer";
// import TriMA from "../modal/TriMA";
// import Vwma from "../modal/Vwma";
// import DEMA from "../modal/DEMA";
// import TEMA from "../modal/TEMA";
// import Open from "../modal/Open";
// import Low from "../modal/Low";
// import Close from "../modal/Close";
// import HighPrice from "../modal/HighPrice";
// import EMA from "../modal/EMA";
// import SMA from "../modal/SMA";
// import Number from "../modal/Number";
// import MoneyFlowIndex from "../modal/MoneyFlowIndex";
// import WilliamsR from "../modal/WilliamsR";
// import MovingAverageDeviation from "../modal/MovingAverageDeviation";
// import MACD from "../modal/MACD";
// import OBV from "../modal/OBV";
// import MOM from "../modal/MOM";
// import AroonDown from "../modal/AroonDown";
// import AroonUp from "../modal/AroonUp";
// import ParabolicSAR from "../modal/ParabolicSAR";
// import VortexIndicator from "../modal/VortexIndicator";
// import DonchianChannel from "../modal/DonchianChannel";
// import Stochastic from "../modal/Stochastic";
// import ChaikinMoneyFlow from "../modal/ChaikinMoneyFlow";
// import CCI from "../modal/CommodityChannelIndex";
// import PriceRateofChange from "../modal/PriceRateofChange";
// import MACDsignal from "../modal/MACDsignal";
// import AwesomeOscillator from "../modal/AwesomeOscillator";
// import VWAP from "../modal/VWAP";
// import Volume from "../modal/Volume";
// import moment from "moment";
// import RSI from "../modal/RSI";
// import RSIMovingAverage from "../modal/RSIMovingAverage";
// import BollingerB from "../modal/BollingerB";
// import TR from "../modal/TR";
// import ATR from "../modal/ATR";
// import NATR from "../modal/NATR";
// import Supertrend from "../modal/Supertrend";
// import MfiMovingAverage from "../modal/MfiMovingAverage";
// import KnowSureThing from "../modal/KnowSureThing";
// import Ichimoku from "../modal/Ichimoku";
// import CandleInterval from "../modal/CandleInterval";
// import WMA from "../modal/WMA";
// import PeriodMaxModal from "../modal/PeriodMaxModal";
// import PeriodMinModal from "../modal/PeriodMinModal";

// const addButton = {
//   backgroundColor: "transparent", // No background color
//   color: "#0e6efd", // Text color
//   fontWeight: "500",
//   fontSize: "16px",
//   height: "30px",
//   padding: "0 16px",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   border: "2px dashed #1579c9", // Dashed border
//   borderRadius: "4px",
//   cursor: "pointer",
//   transition: "border-color 0.3s", // Only transition for border color
//   marginRight: "5px",
// };

// let eflag = 0;

// const ScannerConditionComponent = ({
//   formData,
//   onFormChange,
//   allStocks,
//   getText,
// }) => {
//   const { menu, setMenu, storeAllStocks } = useMenuContextScanner();

//   const divRef = useRef(null);
//   const conditionDropdownRef = useRef(null);

//   const [flag, setFlag] = useState(0);

//   const [showDropdown, setShowDropdown] = useState(false);

//   const [functionType, setFunctionType] = useState([]);

//   const [showSMA, setIsShow] = useState(false);
//   const [showSMA2, setIsShow2] = useState(false);
//   const [showSMA3, setIsShow3] = useState(false);

//   const [conditionTab, setConditionTab] = useState(false);

//   const [mode, setMode] = useState("");
//   const [remove, setRemove] = useState(false);

//   const [selectedOption1, setSelectedOption1] = useState(false);
//   const [selectedOption2, setSelectedOption2] = useState(false);

//   const [selectedItem, setSelectedItem] = useState("1");

//   const [currentId, setCurrentId] = useState("1");

//   const [searchQuery, setSearchQuery] = useState("");

//   const [innerAdd, setInnerAdd] = useState("");
//   const [outerAdd, setOuterAdd] = useState("");

//   //Modal state variables
//   const [showUlcer, setShowUlcer] = useState(false);
//   const [showTMA, setShowTMA] = useState(false);
//   const [showVWMA, setShowVWMA] = useState(false);
//   const [showDEMA, setShowDEMA] = useState(false);
//   const [showTEMA, setShowTEMA] = useState(false);

//   const [showOpen, setShowOpen] = useState(false);
//   const [showLowPrice, setShowLowPrice] = useState(false);
//   const [showHighPrice, setShowHighPrice] = useState(false);
//   const [showClose, setShowClose] = useState(false);
//   const [showSMA1, setShowSMA] = useState(false);
//   const [showEMA, setShowEMA] = useState(false);
//   const [showNumber, setShowNumber] = useState(false);
//   const [showMoneyFlowIndex, setShowMoneyFlowIndex] = useState(false);
//   const [showWilliams, setShowWilliams] = useState(false);
//   const [showMad, setShowMad] = useState(false);
//   const [showMacd, setShowMacd] = useState(false);
//   const [showObv, setShowObv] = useState(false);
//   const [showOscilator, setShowOscilator] = useState(false);
//   const [showAroonUp, setShowAroonUp] = useState(false);
//   const [showAroonDown, setShowAroonDown] = useState(false);
//   const [showpsar, setShowpsar] = useState(false);
//   const [showVortexI, setShowVortexI] = useState(false);
//   const [showDonchian, setShowDonchian] = useState(false);
//   const [showStochastic, setShowStochastic] = useState(false);
//   const [showChaikin, setShowChaikin] = useState(false);
//   const [showCCI, setShowCCI] = useState(false);
//   const [showRoc, setShowRoc] = useState(false);
//   const [showMacdSignal, setShowMacdSignal] = useState(false);
//   const [showVWAP, setShowVWAP] = useState(false);
//   const [showVolume, setShowVolume] = useState(false);
//   const [showRSI, setShowRSI] = useState(false);
//   const [showRSIMA, setShowRSIMA] = useState(false);
//   const [showBB, setShowBB] = useState(false);
//   const [showTR, setShowTR] = useState(false);
//   const [showATR, setShowATR] = useState(false);
//   const [showNATR, setShowNATR] = useState(false);
//   const [showSuperT, setShowSuperT] = useState(false);
//   const [showMfiMA, setShowMfiMa] = useState(false);
//   const [showKST, setShowKST] = useState(false);
//   const [showIchimoku, setShowIchimoku] = useState(false);
//   const [showWMA, setShowWMA] = useState(false);
//   const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
//   const [showPeriodMinModal, setPeriodMinModal] = useState(false);
//   const [showCandleModal, setShowCandleModal] = useState(false);
//   const [showRemoveModal, setShowRemoveModal] = useState(false);
//   const [confirmRemove, setconfirmRemove] = useState(false);

//   const [isSelectMax, setIsSelectMax] = useState(false);
//   const [isSelectMin, setIsSelectMin] = useState(false);

//   const [itemIndex, setItemIndex] = useState(0);
//   const [instance, setInstance] = useState();
//   const [removedInsideMath, setRemovedInsideMath] = useState(false);
//   const [showMOM, setShowMOM] = useState(false);
//   const isIncludeMathFunction = useRef(null);
//   const [mathIndexRemove, setMathIndexRemove] = useState(-1);

//   const selectedItemStyle = {
//     backgroundColor: "#00aeee", // Customize the background color as needed
//     fontWeight: "bold", // Add additional styles if needed
//     border: "1px solid blue",
//   };
//  console.log("SCANNERDATA", formData);
//  useEffect(()=>{
//     console.log("<><><>scannercondition",formData?.candleIntervalId);
//  },[formData?.candleIntervalId]);
 
//   const [showInstead, setAddInstead] = useState({
//     function_id: 0,
//     function_type_id: 0,
//     isOpen: false,
//     index: -1,
//   });
//   const handleEdit = (instanceData, index) => {
//     console.log("instanceData", instanceData, index );
//     setAddInstead({
//       function_id: instanceData?.function_id,
//       function_type_id: instanceData?.function_type_id,
//       isOpen: true,
//       index: index,
//     });

//     // call dropdown function particular type id //
//     handleClickAll(instanceData?.function_type_id?.toString());

//   };

//   const replaceNewData = (formData, item) => {
//     let newFormData = { ...formData };
//     let indicators = newFormData?.indicators;
//     let candleId = getCandleId();

//     let objOf = {
//       function_id: item?.id,
//       name: item.name,
//       function_type_id: item?.function_type_id,
//       candleIntervalId: candleId,
//     };
//     switch (item.name) {
//       case "Ulcer":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.ulcerField = "close";
//         objOf.ulcerPeriod = 14;
//         objOf.ulcerOffset = 0;
//         break;
//       case "TriMA (Triangular Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.tmaField = "close";
//         objOf.tmaPeriod = 20;
//         objOf.tmaOffset = 0;
//         break;
//       case "VWMA (Volume Weighted Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.vwmaField = "close";
//         objOf.vwmaPeriod = 20;
//         objOf.vwmaOffset = 0;
//         break;
//       case "DEMA (Double Exponential Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.field = "close";
//         objOf.period = 20;
//         objOf.offset = 0;
//         break;
//       case "TEMA (Triple Exponential Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.temaField = "close";
//         objOf.temaPeriod = 20;
//         objOf.temaOffset = 0;
//         break;
//       case "Open price":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.period = 2;
//         objOf.offset = 0;
//         break;
//       case "Low price":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.lowOffset = 0;
//         break;
//       case "High price":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.offset = 0;
//         break;
//       case "Close":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.closeOffset = 0;
//         break;
//       case "SMA (Simple Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.field = "close";
//         objOf.period = 20;
//         objOf.offset = 0;
//         break;
//       case "WMA (Weighted Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.wmaField = "close";
//         objOf.wmaPeriod = 20;
//         objOf.wmaOffset = 0;
//         break;
//       case "EMA (Exponential Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.field = "close";
//         objOf.period = 20;
//         objOf.offset = 0;
//         break;
//       case "Money Flow Index":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.mfiPeriod = 20;
//         objOf.mfiOffset = 0;
//         break;
//       case "Williams %R":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.wrOffset = 0;
//         objOf.wrPeriod = 14;
//         break;
//       case "Moving Average Deviation (MA Dev)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.madField = "close";
//         objOf.madPeriod = 2;
//         objOf.madType = 0;
//         objOf.madOffset = 0;
//         objOf.madPop = 0;
//         break;
//       case "MACD (Moving Average Convergence/Divergence)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.macd_fastMaPeriod = 12;
//         objOf.macd_slowMaPeriod = 26;
//         objOf.macd_signalPeriod = 9;
//         objOf.macdOffset = 0;
//         break;
//       case "OBV (On Balance Volume)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.obvOffset = 0;
//         break;
//       case "Aroon-Down":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.aroonDownPeriod = 14;
//         objOf.aroonDownOffset = 0;
//         break;
//       case "Aroon-Up":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.aroonUpPeriod = 14;
//         objOf.aroonUpOffset = 0;
//         break;
//       case "Awesome Oscillator":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.aOscilatorOffset = 0;
//         break;
//       case "VWAP (Volume Weighted Average Price)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.vwapOffset = 0;
//         break;
//       case "Parabolic SAR":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.psarMinimumAF = 0.02;
//         objOf.psarMaximumAF = 0.2;
//         objOf.psarOffset = 0;
//         break;
//       case "Vortex Indicator":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.vortexIPeriod = 14;
//         objOf.vortexISignal = "+vi";
//         objOf.vortexIOffset = 0;
//         break;
//       case "Number":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.period = 35;
//         break;
//       case "Volume of a candle":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.volume = 0;
//         break;
//       case "Donchian Channel":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.donchianChannel = "lower";
//         objOf.donchianHighPeriod = 2;
//         objOf.donchianLowPeriod = 2;
//         objOf.donchianOffset = 0;
//         break;
//       case "Stochastic":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.stochasticPeriod = 14;
//         objOf.stochasticType = "%k";
//         objOf.stochasticSmooth = 0;
//         objOf.stochasticOffset = 0;
//         break;
//       case "Chaikin Money Flow":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.chaikinPeriod = 20;
//         objOf.chaikinOffset = 0;
//         break;
//       case "MACD-signal":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.macdsFastMaPeriod = 12;
//         objOf.macdsSlowMaPeriod = 26;
//         objOf.macdsSignalPeriod = 9;
//         objOf.macdsOffset = 0;
//         break;
//       case "Commodity Channel Index":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.cciPeriod = 20;
//         objOf.cciOffset = 0;
//         break;
//       case "Price Rate of Change":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.rocField = "close";
//         objOf.rocPeriod = 14;
//         objOf.rocOffset = 0;
//         break;
//       case "RSI (Relative Strength Index)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.rsiPeriod = 14;
//         objOf.rsiOffset = 0;
//         break;
//       case "RSI Moving Average":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.rsimaPeriod = 14;
//         objOf.rsimaType = "SMA";
//         objOf.rsimaMaPeriod = 20;
//         objOf.rsimaOffset = 0;
//         break;
//       case "Supertrend":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.superTPeriod = 7;
//         objOf.superTMultiply = 3;
//         objOf.superTOffset = 0;
//         break;
//       case "TR (True Range)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.trOffset = 0;
//         break;
//       case "ATR (Average True Range)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.atrPeriod = 14;
//         objOf.atrOffset = 0;
//         break;
//       case "NATR (Normalized Average True Range)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.natrPeriod = 14;
//         objOf.natrOffset = 0;
//         break;
//       case "Bollinger %B":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.bolingerBField = "close";
//         objOf.bolingerBPeriod = 20;
//         objOf.bolingerBDev = 2;
//         objOf.bolingerBType = "SMA";
//         objOf.bolingerBOffset = 0;
//         break;
//       case "MFI Moving Average (Money Flow Index Moving Average)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.mfiMAPeriod = 20;
//         objOf.mfiMAMAPeriod = 20;
//         objOf.mfiMAType = "SMA";
//         objOf.mfiMAOffset = 0;
//         break;
//       case "Know Sure Thing":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.kstField = "close";
//         objOf.kstLTRoc = 10;
//         objOf.kstLTSma = 10;
//         objOf.kstRoc = 15;
//         objOf.kstLSma = 10;
//         objOf.kstHRoc = 20;
//         objOf.kstHSma = 10;
//         objOf.kstHeRoc = 30;
//         objOf.kstHeSma = 15;
//         objOf.kstSPeriod = 9;
//         objOf.kstType = "kst";
//         objOf.kstOffset = 0;
//         break;

//       case "Ichimoku":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.ichiConversion = 9;
//         objOf.ichiBase = 26;
//         objOf.leadingSpanB = 52;
//         objOf.laggingSpan = 26;
//         objOf.ichiLine = "conversion";
//         objOf.cloudShift = "yes";
//         objOf.ichiOffset = 0;
//         break;

//       case "MOM (Momentum Indicator)":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.period = 26;
//         objOf.offset = 52;
//         break;

//       case "CHANDE MOMENTUM OSCILLATOR":
//         objOf.function_id = item?.id;
//         objOf.name = item.name;
//         objOf.period = 26;
//         objOf.offset = 52;
//         break;
//     }

//     const getMaxSuffix = (indicatorObj, item) => {
//       let maxSuffix = 0;
//       Object.keys(indicatorObj).forEach((key) => {
//         if (key.startsWith(item?.name)) {
//           const suffix = key?.slice(item?.name?.length).match(/\d+$/);
//           if (suffix) {
//             maxSuffix = Math.max(maxSuffix, parseInt(suffix[0], 10));
//           }
//         }
//       });
//       return maxSuffix;
//     };
//     console.log("showInstead", showInstead, item);
//     let index = showInstead?.index;
//     let entries = Object.entries(indicators);
//     if (index >= 0 && index < entries.length) {
//       let [keyToDelete, value] = entries[index];
//       let numericSuffix = keyToDelete.match(/\d+$/);
//       let num = numericSuffix;
//       let keyName;
//       console.log("keyChange", keyName, item.name, num);
//       if(item?.function_type_id == 4) {
//         keyName = "operators" + num ;
//      }
//       else if (item?.function_type_id == 5) {
//         keyName = "comparator" + num;
//       } else if (item?.function_type_id == 6) {
//         if (newFormData?.indicators) {
//         }
//         keyName = item.name + (getMaxSuffix(newFormData?.indicators, item) + 1);
//       } else {
//         keyName = item.name + (getMaxSuffix(newFormData?.indicators, item) + 1);
//       }

//       entries[index] = [keyName, objOf];
//       let newData = Object.fromEntries(entries);
//       newFormData.clickedFunction = keyName ;
//       newFormData = { ...newFormData, indicators: newData };
//       console.log("newData111", objOf);
//       if (newFormData?.order) {
//         newFormData?.order.splice(showInstead.index, 1, keyName);
//       }
//     }
//     // function array edit //
//     for (let i = 0; i < newFormData?.functions?.length; i++) {
//       let replaceObje = {
//         function_id: item?.id,
//         name: item.name,
//         function_type_id: item?.function_type_id,
//         candleIntervalId: candleId,
//       };

//       if (item.function_type_id == 3) {
//         switch (item.name) {
//           case "Ulcer":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.ulcerField;
//             replaceObje.period = objOf?.ulcerPeriod;
//             replaceObje.offset = objOf?.ulcerOffset;
//             break;
//           case "TriMA (Triangular Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.tmaField;
//             replaceObje.period = objOf?.tmaPeriod;
//             replaceObje.offset = objOf?.tmaOffset;
//             break;
//           case "VWMA (Volume Weighted Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.Field = objOf?.vwmaField;
//             replaceObje.Period = objOf?.vwmaPeriod;
//             replaceObje.Offset = objOf?.vwmaOffset;
//             break;
//           case "DEMA (Double Exponential Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.field;
//             replaceObje.period = objOf?.period;
//             replaceObje.offset = objOf?.offset;
//             break;
//           case "TEMA (Triple Exponential Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.Field = objOf?.temaField;
//             replaceObje.Period = objOf?.temaPeriod;
//             replaceObje.Offset = objOf?.temaOffset;
//             break;
//           case "Open price":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.period;
//             replaceObje.offset = objOf?.offset;
//             break;
//           case "Low price":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.offset = objOf?.lowOffset;
//             break;
//           case "High price":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.offset = objOf?.offset;
//             break;
//           case "Close":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.closeOffset = objOf?.closeOffset;
//             break;
//           case "SMA (Simple Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.field;
//             replaceObje.period = objOf?.period;
//             replaceObje.offset = objOf?.offset;
//             break;
//           case "WMA (Weighted Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.wmaField;
//             replaceObje.period = objOf?.wmaPeriod;
//             replaceObje.offset = objOf?.wmaOffset;
//             break;
//           case "EMA (Exponential Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.field;
//             replaceObje.period = objOf?.period;
//             replaceObje.offset = objOf?.offset;
//             break;
//           case "Money Flow Index":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.Period = objOf?.mfiPeriod;
//             replaceObje.Offset = objOf?.mfiOffset;
//             break;
//           case "Williams %R":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.Offset = objOf?.wrOffset;
//             replaceObje.Period = objOf?.wrPeriod;
//             break;
//           case "Moving Average Deviation (MA Dev)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.Field = objOf?.madField;
//             replaceObje.Period = objOf?.madPeriod;
//             replaceObje.Type = objOf?.madType;
//             replaceObje.Offset = objOf?.madOffset;
//             replaceObje.Pop = objOf?.madPop;
//             break;
//           case "MACD (Moving Average Convergence/Divergence)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.MaPeriod = objOf?.macd_fastMaPeriod;
//             replaceObje.macd_slowMaPeriod = objOf?.macd_slowMaPeriod;
//             replaceObje.macd_signalPeriod = objOf?.macd_signalPeriod;
//             replaceObje.macdOffset = objOf?.macdOffset;
//             break;
//           case "OBV (On Balance Volume)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.offset = objOf?.obvOffset;
//             break;
//           case "Aroon-Down":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.aroonDownPeriod;
//             replaceObje.offset = objOf?.aroonDownOffset;
//             break;
//           case "Aroon-Up":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.aroonUpPeriod;
//             replaceObje.offset = objOf?.aroonUpOffset;
//             break;
//           case "Awesome Oscillator":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.offset = objOf?.aOscilatorOffset;
//             break;
//           case "VWAP (Volume Weighted Average Price)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.offset = objOf?.vwapOffset;
//             break;
//           case "Parabolic SAR":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.minAF = objOf?.psarMinimumAF;
//             replaceObje.maxAF = objOf?.psarMaximumAF;
//             replaceObje.offset = objOf?.psarOffset;
//             break;
//           case "Vortex Indicator":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.vortexIPeriod;
//             replaceObje.signal = objOf?.vortexISignal;
//             replaceObje.offset = objOf?.vortexIOffset;
//             break;
//           case "Number":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.period;
//             break;
//           case "Volume of a candle":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.volume = objOf?.volume;
//             replaceObje.offset = objOf?.offset;
//             break;
//           case "Donchian Channel":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.channel = objOf?.donchianChannel;
//             replaceObje.period = objOf?.donchianHighPeriod;
//             replaceObje.period1 = objOf?.donchianLowPeriod;
//             replaceObje.offset = objOf?.donchianOffset;
//             break;
//           case "Stochastic":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.stochasticPeriod;
//             replaceObje.type = objOf?.stochasticType;
//             replaceObje.smooth = objOf?.stochasticSmooth;
//             replaceObje.offset = objOf?.stochasticOffset;
//             break;
//           case "Chaikin Money Flow":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.chaikinPeriod;
//             replaceObje.offset = objOf?.chaikinOffset;
//             break;
//           case "MACD-signal":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period1 = objOf?.macdsFastMaPeriod;
//             replaceObje.period2 = objOf?.macdsSlowMaPeriod;
//             replaceObje.period3 = objOf?.macdsSignalPeriod;
//             replaceObje.offset = objOf?.macdsOffset;
//             break;
//           case "Commodity Channel Index":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.cciPeriod;
//             replaceObje.offset = objOf?.cciOffset;
//             break;
//           case "Price Rate of Change":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.rocField;
//             replaceObje.period = objOf?.rocPeriod;
//             replaceObje.offset = objOf?.rocOffset;
//             break;
//           case "RSI (Relative Strength Index)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.rsiPeriod;
//             replaceObje.offset = objOf?.rsiOffset;
//             break;
//           case "RSI Moving Average":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.rsimaPeriod;
//             replaceObje.maType = objOf?.rsimaType;
//             replaceObje.maPeriod = objOf?.rsimaMaPeriod;
//             replaceObje.offset = objOf?.rsimaOffset;
//             break;
//           case "Supertrend":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.superTPeriod;
//             replaceObje.multiply = objOf?.superTMultiply;
//             replaceObje.offset = objOf?.superTOffset;
//             break;
//           case "TR (True Range)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.offset = objOf?.trOffset;
//             break;
//           case "ATR (Average True Range)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.atrPeriod;
//             replaceObje.offset = objOf?.atrOffset;
//             break;
//           case "NATR (Normalized Average True Range)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.natrPeriod;
//             replaceObje.offset = objOf?.natrOffset;
//             break;
//           case "Bollinger %B":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.bolingerBField;
//             replaceObje.period = objOf?.bolingerBPeriod;
//             replaceObje.deviation = objOf?.bolingerBDev;
//             replaceObje.type = objOf?.bolingerBType;
//             replaceObje.offset = objOf?.bolingerBOffset;
//             break;
//           case "MFI Moving Average (Money Flow Index Moving Average)":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.mfiMAPeriod;
//             replaceObje.maPeriod = objOf?.mfiMAMAPeriod;
//             replaceObje.maType = objOf?.mfiMAType;
//             replaceObje.offset = objOf?.mfiMAOffset;
//             break;
//           case "Know Sure Thing":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.field = objOf?.kstField;
//             replaceObje.LTRoc = objOf?.kstLTRoc;
//             replaceObje.LTSma = objOf?.kstLTSma;
//             replaceObje.Roc = objOf?.kstRoc;
//             replaceObje.LSma = objOf?.kstLSma;
//             replaceObje.HRoc = objOf?.kstHRoc;
//             replaceObje.HSma = objOf?.kstHSma;
//             replaceObje.HeRoc = objOf?.kstHeRoc;
//             replaceObje.HeSma = objOf?.kstHeSma;
//             replaceObje.SPeriod = objOf?.kstSPeriod;
//             replaceObje.Type = objOf?.kstType;
//             replaceObje.offset = objOf?.kstOffset;
//             break;

//           case "Ichimoku":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.Conversion = objOf?.ichiConversion;
//             replaceObje.Base = objOf?.ichiBase;
//             replaceObje.leadingSpanB = objOf?.leadingSpanB;
//             replaceObje.laggingSpan = objOf?.laggingSpan;
//             replaceObje.Line = objOf?.ichiLine;
//             replaceObje.cloudShift = objOf?.cloudShift;
//             replaceObje.offset = objOf?.ichiOffset;
//             break;

//           case "MOM (Momentum Indicator)":
//             console.log("MOMfirst",item)
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.period;
//             replaceObje.offset = objOf?.offset;
//             break;

//           case "CHANDE MOMENTUM OSCILLATOR":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.period = objOf?.period;
//             replaceObje.offset = objOf?.offset;
//             break;
//         }
//       } else if(item.function_type_id == 4) {
//         replaceObje.comparator = item?.name ;
//       }
//       else if (item.function_type_id == 5) {
//         switch (item.name) {
//           case "crosses above":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = ">";
//             replaceObje.isCrossover = 2;
//             break;
//           case "crosses below":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = "<";
//             replaceObje.isCrossover = 1;
//             break;
//           case "higher than":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = ">";
//             break;
//           case "lower than":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = "<";
//             break;
//           case "lower than equal to":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = "<=";
//             break;
//           case "higher than equal to":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = ">=";
//             break;
//           case "equal to":
//             replaceObje.function_id = item?.id;
//             replaceObje.name = item.name;
//             replaceObje.comparator = ">=";
//             break;
//         }
//       }
//       let index = newFormData?.functions[i].findIndex(
//         (obj) =>
//           obj.function_id == showInstead.function_id &&
//           obj.function_type_id == showInstead.function_type_id
//       );
      
//       if (index !== -1) {
//         newFormData.functions[i][index] = replaceObje;
//         console.log("isIt", newFormData.functions[i][index]);
//         break;
//       }
//     }
//     if (newFormData && newFormData.functionsBox) {
//       newFormData.functionsBox?.splice(showInstead.index, 1, item?.name);
//     }
//     if (newFormData && newFormData.functionsBox777) {
//       const entries = Object.entries(formData.indicators);
//       const [keyAtIndex, nestedObject] = entries[index];
//       const keys = Object.keys(formData.indicators);
//       const keysBeforeIndex = keys.slice(0, index);
//       const counts = {
//         periodCount: 0,
//         minCount: 0,
//         maxCount: 0,
//         symbolCount: 0,
//         floorCount: 0,
//         ceilCount: 0,
//         absCount: 0,
//       };
//       keysBeforeIndex.forEach((key) => {
//         if (/Period/.test(key)) counts.periodCount++;
//         if (/Min/.test(key)) counts.minCount++;
//         if (/Max/.test(key)) counts.maxCount++;
//         if (/Symbol/.test(key)) counts.symbolCount++;
//         if (/Floor/.test(key)) counts.floorCount++;
//         if (/Ceil/.test(key)) counts.ceilCount++;
//         if (/Abs/.test(key)) counts.absCount++;
//       });
//       let adjustedIndex = showInstead.index;
//       if (Object.values(counts).some((count) => count > 0)) {
//         adjustedIndex +=
//           counts.periodCount * 1 +
//           (counts.minCount + counts.maxCount) * 2 +
//           counts.symbolCount * 1 +
//           counts.floorCount * 1 +
//           counts.ceilCount * 1 +
//           counts.absCount * 1;
          
//           console.log("FINDISIT",adjustedIndex )
//         newFormData?.functionsBox777?.splice(adjustedIndex, 1, objOf);
//       } else {
//         newFormData?.functionsBox777?.splice(adjustedIndex, 1, objOf);
//       }
//     }
//     let obj12 = {
//       function_id: objOf?.function_id,
//       name: objOf?.name,
//       function_type_id: objOf?.function_type_id,
//     };

//     if (newFormData && newFormData.functionsBox2) {
//       newFormData.functionsBox2 = newFormData.functionsBox2.map(
//         (func, index) => {
//           if (
//             func.function_id == showInstead?.function_id &&
//             func.function_type_id == showInstead?.function_type_id &&
//             index == showInstead?.index
//           ) {
//             return {
//               function_type_id: objOf?.function_type_id,
//               function_id: obj12.function_id,
//               name: obj12.name,
//             };
//           } else {
//             return func;
//           }
//         }
//       );
//     }
//     console.log("newData>>", newFormData);
//     return newFormData;
//   };
//   const handleGetFunction1 = (item) => {
//     console.log("click", item, formData);
//     setSearchQuery("");
//     setShowDropdown(false);
//     let replaceData = replaceNewData(formData, item);
//     formData = replaceData;
//     // console.log("REPLACE", formData )
//     if (replaceData) {
//       setAddInstead({
//         function_id: 0,
//         function_type_id: 0,
//         isOpen: false,
//         index: -1,
//       });
//     }
//   };
//   useEffect(() => {
//     // console.log("33first",formData);
//   }, [formData]);
//   const handleSetInstead = () => {
//     setAddInstead({
//       function_id: 0,
//       function_type_id: 0,
//       isToggle: false,
//     });
//   };
//   const handleRemiceInstead = () => {
//     setAddInstead((prev) => ({ ...prev, isOpen: false }));
//   };
//   const handleGetFunction = (item) => {
//     console.log("GETFUN",item)
//     setInnerAdd(false);
//     setOuterAdd(true);
//     handleRemiceInstead();
//     if (formData?.selectedOption1) {
//       formData.selectedOption1 = item?.name;
//     }

//     if (formData?.indicators) {
//       const existingKeys = Object.keys(formData?.indicators);

//       // Find existing instances for the same indicator type
//       const existingInstances = existingKeys?.filter((key) =>
//         key?.startsWith(item?.name)
//       );

//       if (existingInstances?.length > 0) {
//         // If instances for the same indicator type exist, find the last number
//         let lastNumber = 0;

//         existingInstances.forEach((key) => {
//           const number = parseInt(key.replace(item?.name, ""));
//           if (!isNaN(number) && number > lastNumber) {
//             lastNumber = number;
//           }
//         });
//         if (formData?.clickedFunction) {
//           formData.clickedFunction = `${item?.name}${lastNumber + 1}`;
//         }

//         console.log(
//           `${item?.name} is present in formData.indicators`,
//           formData?.clickedFunction
//         );
//       } else {
//         // If the name doesn't exist, append "1" to the name
//         if (formData?.clickedFunction) {
//           formData.clickedFunction = `${item?.name}1`;
//         }

//         console.log(
//           `${item?.name} is not present in formData.indicators`,
//           formData?.clickedFunction
//         );
//       }
//     } else {
//       // If formData.indicators doesn't exist, create the first instance
//       if (formData?.clickedFunction) {
//         formData.clickedFunction = `${item?.name}1`;
//       }

//       // console.log(
//       //   `${item?.name} is not present in formData.indicators (formData.indicators doesn't exist)`,
//       //   formData?.clickedFunction
//       // );
//     }

//     if (formData) {
//       formData.count++;
//       formData.conditionTab = true;
//     }

//     onFormChange({
//       ...formData,
//       formData,
//     });
//     if(formData) {
//       formData.functionsBox.push(item?.name);
//     }
    
//     let obj = {};
//     obj.function_id = item?.id;

//     const comparisonOperators = [
//       "higher than",
//       "lower than",
//       "higher than equal to",
//       "lower than equal to",
//       "crosses above",
//       "crosses below",
//     ];

//     // Check if item?.name includes any of the comparison operators
//     const includesComparisonOperator = comparisonOperators.some((operator) =>
//       item?.name.includes(operator)
//     );

//     if (includesComparisonOperator) {
//       if (item?.name.includes("crosses above")) {
//         obj.isCrossover = 2;
//         obj.comparator = ">";
//       } else if (item?.name.includes("crosses below")) {
//         obj.isCrossover = 1;
//         obj.comparator = "<";
//       } else {
//         obj.comparator = item?.name;
//       }
//     } else {
//       obj.name = item?.name;
//     }
//     obj.field = "";
//     obj.period = "";
//     obj.offset = "";
//     obj.maPeriod = "";
//     obj.maType = "";
//     obj.function_type_id = item.function_type_id;

//     let candleId = getCandleId();

//     obj.candleIntervalId = candleId;
//     if (removedInsideMath) {
//       formData.functionsBox777.splice(mathIndexRemove, 0, obj);
//       console.log("MATHINDEX",mathIndexRemove )
//     } else {
//       formData.functionsBox777.push(obj);
//     }
    

//     setCurrentId(item?.id);
//     // console.log("Came5-", formData.functionsBox777);
//     // console.log("value---", item);
//     if (item.function_type_id === 3) handleClickAll("5");
//     if (item.function_type_id === 5) handleClickAll("3");
//     if (item.function_type_id === 2) handleClickAll("3");
//      if(formData) {
//       formData.selectedOption1 = item?.name;

//      }

//     setConditionTab(true);

//     if (flag === 0) {
//       setSelectedOption1(item?.name);
//       if (formData) {
//         formData.firstFunctionId = item?.id;
//       }

//       onFormChange({
//         ...formData,
//         formData,
//       });

//       if (item?.function_type_id === 2) {
//         setMode("Function");

//         // formData.mode = "Function";
//         onFormChange({
//           ...formData,
//           formData,
//         });

//         onFormChange({
//           ...formData,
//           mode: "Function",
//           conditionTab: true,
//           selectedOption1: item?.name,
//           function_name: item?.id,
//         });
//         let obj = {};
//         obj.function_id = item?.id;
//         obj.function_type_id = item.function_type_id;
//         obj.name = item?.name;
//         formData.functionsBox2.push(obj);
//       }

//       if (item?.function_type_id === 3) {
//         setMode("Indicators");
//         onFormChange({
//           ...formData,
//           mode: "Indicators",
//           conditionTab: true,
//           selectedOption1: item?.name,
//           function_name: item?.id,
//         });
//       }

//       setFlag(1);
//     }

//     if (
//       formData?.functionsBox2[0]?.function_type_id === 2 &&
//       flag > 0 &&
//       !outerAdd
//     ) {
//       let obj2 = {};
//       obj2.function_id = item?.id;
//       obj2.name = item?.name;
//       if (formData?.functionsBox2[0].body)
//         formData?.functionsBox2[0].body.push(obj2);
//       else {
//         formData.functionsBox2[0].body = [];
//         // formData.functionsBox2[0].inner.push(obj2);
//       }

//       // formData.functionsBox2[0].inner = obj2;
//     }

//     if (
//       item?.function_type_id === 3 ||
//       item?.function_type_id === 2 ||
//       item?.function_type_id === 1 ||
//       item?.function_type_id === 6
//     ) {
//       // Assuming 'item' is the function object from your data
//       let functionName = item?.name;

//       // Check if the formData.indicators object exists
//       if (!formData?.indicators) {
//         formData.indicators = {};
//       }

//       // Find existing instances for the same indicator type
//       const existingInstances = Object.keys(formData.indicators).filter((key) =>
//         key?.startsWith(functionName)
//       );

//       // Create a new instance name based on the function name and the number of existing instances
//       let instanceName;
//       if (existingInstances?.length > 0) {
//         let lastNumber = 0;

//         existingInstances.forEach((key) => {
//           const number = parseInt(key.replace(functionName, ""));
//           if (!isNaN(number) && number > lastNumber) {
//             lastNumber = number;
//           }
//         });

//         instanceName = `${functionName}${lastNumber + 1}`;
//       } else {
//         // If no existing instances, start with "1"
//         instanceName = `${functionName}1`;
//       }

//       let candleId = getCandleId();

//       let obj = {
//         function_id: item?.id,
//         name: functionName,
//         function_type_id: item?.function_type_id,
//         candleIntervalId: candleId,
//         // Add any additional properties as needed
//       };

//       switch (formData?.selectedOption1) {
//         case "Ulcer":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.ulcerField = "close";
//           obj.ulcerPeriod = 14;
//           obj.ulcerOffset = 0;
//           break;
//         case "TriMA (Triangular Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.tmaField = "close";
//           obj.tmaPeriod = 20;
//           obj.tmaOffset = 0;
//           break;
//         case "VWMA (Volume Weighted Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.vwmaField = "close";
//           obj.vwmaPeriod = 20;
//           obj.vwmaOffset = 0;
//           break;
//         case "DEMA (Double Exponential Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.field = "close";
//           obj.period = 20;
//           obj.offset = 0;
//           break;
//         case "TEMA (Triple Exponential Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.temaField = "close";
//           obj.temaPeriod = 20;
//           obj.temaOffset = 0;
//           break;
//         case "Open price":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.period = 2;
//           obj.offset = 0;
//           break;
//         case "Low price":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.lowOffset = 0;
//           break;
//         case "High price":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.offset = 0;
//           break;
//         case "Close":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.closeOffset = 0;
//           break;
//         case "SMA (Simple Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.field = "close";
//           obj.period = 20;
//           obj.offset = 0;
//           break;
//         case "WMA (Weighted Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.wmaField = "close";
//           obj.wmaPeriod = 20;
//           obj.wmaOffset = 0;
//           break;
//         case "EMA (Exponential Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.field = "close";
//           obj.period = 20;
//           obj.offset = 0;
//           break;
//         case "Money Flow Index":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.mfiPeriod = 20;
//           obj.mfiOffset = 0;
//           break;
//         case "Williams %R":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.wrOffset = 0;
//           obj.wrPeriod = 14;
//           break;
//         case "Moving Average Deviation (MA Dev)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.madField = "close";
//           obj.madPeriod = 2;
//           obj.madType = 0;
//           obj.madOffset = 0;
//           obj.madPop = 0;
//           break;
//         case "MACD (Moving Average Convergence/Divergence)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.macd_fastMaPeriod = 12;
//           obj.macd_slowMaPeriod = 26;
//           obj.macd_signalPeriod = 9;
//           obj.macdOffset = 0;
//           break;
//         case "OBV (On Balance Volume)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.obvOffset = 0;
//           break;
//         case "Aroon-Down":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.aroonDownPeriod = 14;
//           obj.aroonDownOffset = 0;
//           break;
//         case "Aroon-Up":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.aroonUpPeriod = 14;
//           obj.aroonUpOffset = 0;
//           break;
//         case "Awesome Oscillator":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.aOscilatorOffset = 0;
//           break;
//         case "VWAP (Volume Weighted Average Price)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.vwapOffset = 0;
//           break;
//         case "Parabolic SAR":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.psarMinimumAF = 0.02;
//           obj.psarMaximumAF = 0.2;
//           obj.psarOffset = 0;
//           break;
//         case "Vortex Indicator":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.vortexIPeriod = 14;
//           obj.vortexISignal = "+vi";
//           obj.vortexIOffset = 0;
//           break;
//         case "Number":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.period = 35;
//           break;
//         case "Volume of a candle":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.volume = 0;
//           break;
//         case "Donchian Channel":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.donchianChannel = "lower";
//           obj.donchianHighPeriod = 2;
//           obj.donchianLowPeriod = 2;
//           obj.donchianOffset = 0;
//           break;
//         case "Stochastic":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.stochasticPeriod = 14;
//           obj.stochasticType = "%k";
//           obj.stochasticSmooth = 0;
//           obj.stochasticOffset = 0;
//           break;
//         case "Chaikin Money Flow":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.chaikinPeriod = 20;
//           obj.chaikinOffset = 0;
//           break;
//         case "MACD-signal":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.macdsFastMaPeriod = 12;
//           obj.macdsSlowMaPeriod = 26;
//           obj.macdsSignalPeriod = 9;
//           obj.macdsOffset = 0;
//           break;
//         case "Commodity Channel Index":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.cciPeriod = 20;
//           obj.cciOffset = 0;
//           break;
//         case "Price Rate of Change":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.rocField = "close";
//           obj.rocPeriod = 14;
//           obj.rocOffset = 0;
//           break;
//         case "RSI (Relative Strength Index)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.rsiPeriod = 14;
//           obj.rsiOffset = 0;
//           break;
//         case "RSI Moving Average":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.rsimaPeriod = 14;
//           obj.rsimaType = "SMA";
//           obj.rsimaMaPeriod = 20;
//           obj.rsimaOffset = 0;
//           break;
//         case "Supertrend":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.superTPeriod = 7;
//           obj.superTMultiply = 3;
//           obj.superTOffset = 0;
//           break;
//         case "TR (True Range)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.trOffset = 0;
//           break;
//         case "ATR (Average True Range)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.atrPeriod = 14;
//           obj.atrOffset = 0;
//           break;
//         case "NATR (Normalized Average True Range)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.natrPeriod = 14;
//           obj.natrOffset = 0;
//           break;
//         case "Bollinger %B":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.bolingerBField = "close";
//           obj.bolingerBPeriod = 20;
//           obj.bolingerBDev = 2;
//           obj.bolingerBType = "SMA";
//           obj.bolingerBOffset = 0;
//           break;
//         case "MFI Moving Average (Money Flow Index Moving Average)":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.mfiMAPeriod = 20;
//           obj.mfiMAMAPeriod = 20;
//           obj.mfiMAType = "SMA";
//           obj.mfiMAOffset = 0;
//           break;
//         case "Know Sure Thing":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.kstField = "close";
//           obj.kstLTRoc = 10;
//           obj.kstLTSma = 10;
//           obj.kstRoc = 15;
//           obj.kstLSma = 10;
//           obj.kstHRoc = 20;
//           obj.kstHSma = 10;
//           obj.kstHeRoc = 30;
//           obj.kstHeSma = 15;
//           obj.kstSPeriod = 9;
//           obj.kstType = "kst";
//           obj.kstOffset = 0;
//           break;
//         case "Ichimoku":
//           obj.function_id = item?.id;
//           obj.name = functionName;
//           obj.ichiConversion = 9;
//           obj.ichiBase = 26;
//           obj.leadingSpanB = 52;
//           obj.laggingSpan = 26;
//           obj.ichiLine = "conversion";
//           obj.cloudShift = "yes";
//           obj.ichiOffset = 0;
//           break;
//           case "MOM (Momentum Indicator)":
//             obj.function_id = item?.id;
//             obj.name = functionName;
//             obj.period = 26;
//             obj.offset = 52;
//             break;
//           default:
//           console.log("Condition wont matches");
//         // Add cases for other options in a similar manner
//       }

//       // Add the new instance to the object with a unique name
//       if (item?.function_type_id === 2) {
//         formData.isIncludeMathFunction = true;
//         isIncludeMathFunction.current = true;
//         if (item.name === "Max") {
//           obj.body1 = [];
//           obj.body2 = [];
//           setIsSelectMax(true);
//           formData.indicators[instanceName] = obj;
//         } else if (item.name === "Min") {
//           obj.body1 = [];
//           obj.body2 = [];
//           setIsSelectMin(true);
//           formData.indicators[instanceName] = obj;
//         } else if (
//           item.name === "Ceil" ||
//           item.name === "Floor" ||
//           item.name === "Abs"
//         ) {
//           obj.body = [];
//           formData.indicators[instanceName] = obj;
//         } else if (item.name === "Symbol") {
//           obj.body = [];
//           obj.scripCode = "";
//           formData.indicators[instanceName] = obj;
//         } else {
//           obj.body = [];
//           obj.period = 10;
//           formData.indicators[instanceName] = obj;
//         }
//       }
//       if (!formData?.selectedOption3 || outerAdd)
//         formData.indicators[instanceName] = obj;

//       if (formData?.indicators[formData.selectedOption3]?.body && innerAdd)
//         formData.indicators[formData.selectedOption3]?.body.push(obj);

//       if (instance === "body1" && innerAdd)
//         formData.indicators[formData.selectedOption3]?.body1.push(obj);

//       if (instance === "body2" && innerAdd)
//         formData.indicators[formData.selectedOption3]?.body2.push(obj);

//       if (formData?.mode === "Function" && !outerAdd) {
//         obj.instance_name = instanceName;
//         formData.functionsBox2[0]?.body?.push(obj);
//       }

//       // Update the order array
//       if (formData) {
//         formData.order.push(instanceName);
//       }

//       setMode("Indicators");
//     }

//     if (item?.function_type_id === 4) {
//       // Assuming 'item' is the function object from your data
//       let functionName = item?.name;

//       // Check if the formData.indicators object exists
//       if (!formData?.indicators) {
//         formData.indicators = {};
//       }

//       // Find existing instances for the same indicator type
//       const existingInstances = Object.keys(formData?.indicators).filter(
//         (key) => key.startsWith("operators")
//       );

//       // Create a new instance name based on the function name and the number of existing instances
//       let instanceName;
//       if (existingInstances?.length > 0) {
//         let lastNumber = 0;

//         existingInstances.forEach((key) => {
//           const number = parseInt(key.replace("operators", ""));
//           if (!isNaN(number) && number > lastNumber) {
//             lastNumber = number;
//           }
//         });

//         instanceName = `${"operators"}${lastNumber + 1}`;
//       } else {
//         // If no existing instances, start with "1"
//         instanceName = `${"operators"}1`;
//       }

//       let obj = {
//         function_id: item?.id,
//         name: item?.name,
//         function_type_id: item?.function_type_id,
//         candleIntervalId: candleId,
//         operator: item?.name,
//         // Add any additional properties as needed
//       };

//       if (formData?.indicators[formData?.selectedOption3]?.body && innerAdd)
//         formData?.indicators[formData?.selectedOption3]?.body.push(obj);
//       else 
//         formData.indicators[instanceName] = obj ;
//     }

//     if (item?.function_type_id === 5) {
//       let functionName = item?.name;

//       // Use "comparator" as the key instead of functionName
//       let comparatorObject = formData?.indicators;

//       // If formData.indicators doesn't exist, create it
//       if (!comparatorObject) {
//         formData.indicators = {};
//         comparatorObject = formData.indicators;
//       }

//       // Find existing instances for the same comparator type
//       const existingInstances = Object.keys(comparatorObject);

//       // Create a new instance name based on "comparator" and the number of existing instances
//       let instanceName;
//       if (existingInstances?.length > 0) {
//         let lastNumber = 0;

//         existingInstances.forEach((key) => {
//           const number = parseInt(key.replace("comparator", ""));
//           if (!isNaN(number) && number > lastNumber) {
//             lastNumber = number;
//           }
//         });

//         instanceName = `comparator${lastNumber + 1}`;
//       } else {
//         // If no existing instances, start with "1"
//         instanceName = "comparator1";
//       }

//       // Create a new instance of the function
//       let obj = {
//         function_id: item?.id,
//         name: functionName, // Use "comparator" as the name
//         function_type_id: item?.function_type_id,
//         // Add any additional properties as needed
//       };

//       // Add the new instance directly to formData.indicators without the top-level "comparator" key
//       if (formData) {
//         formData.indicators[instanceName] = obj;
//         formData.order.push(instanceName);
//       }

//       // Update the order array

//       setMode("Indicators");
//     }

//     if (outerAdd) {
//       let obj2 = {};
//       obj2.function_id = item?.id;
//       obj2.name = item?.name;
//       formData?.functionsBox2.push(obj2);
//     }
//     if (formData) {
//       formData.secondFunctionId = item?.id;
//     }
//     onFormChange({
//       ...formData,
//       formData,
//     });

//     setSearchQuery("");
//     setShowDropdown(false);
//     if (innerAdd) setRemovedInsideMath(false);

//   };

//   const handleCandleModal = (data) => {
//     console.log("IntervalData", data)
//     if (data?.candleIntervalId && formData)
//       formData.indicators[formData.clickedFunction].candleIntervalId =
//         data?.candleIntervalId;

//     formData.functionsBox777[itemIndex].candleIntervalId =
//       data.candleIntervalId;

//     if (formData?.indicators[formData?.clickedFunction]?.body)
//       formData.indicators[formData?.clickedFunction].body[
//         itemIndex
//       ].candleIntervalId = data?.candleIntervalId;

//     // console.log(
//     //   "formData sosp",
//     //   formData.indicators[formData?.clickedFunction]?.body
//     // );
//     console.log("INTERVAL", formData)
//     onFormChange(formData);
//     handleNextEnteryBtn();
//   };

 
//   useEffect(() => {
//     // console.log("functionsBox777>", formData.functionsBox777);
//     if (eflag === 1) {
//       updateCandleIntervalId();
//       updateCandleInterval();
//     }
//     handleClickAll("1");
//   }, [formData?.candleIntervalId]);
//   useEffect(() => {
//     handleNextEnteryBtn();
//   }, [getText]);

//   const getCandleId = () => {
//     let candleId;
//     if (formData?.candleIntervalId === 1) {
//       candleId = "1minute";
//     } else if (formData?.candleIntervalId === 3) {
//       candleId = "3minute";
//     } else if (formData?.candleIntervalId === 5) {
//       candleId = "5minute";
//     } else if (formData?.candleIntervalId === 10) {
//       candleId = "10minute";
//     } else if (formData?.candleIntervalId === 15) {
//       candleId = "15minute";
//     } else if (formData?.candleIntervalId === 30) {
//       candleId = "30minute";
//     } else if (formData?.candleIntervalId === 60) {
//       candleId = "60minute";
//     } else if (formData?.candleIntervalId === "1day") {
//       candleId = "1day";
//     }
//     // else if (formData.candleIntervalId === "2h") {
//     //   candleId = "2h";
//     // } else if (formData.candleIntervalId === "3h") {
//     //   candleId = "3h";
//     // } else if (formData.candleIntervalId === "4h") {
//     //   candleId = "4h";
//     // }
//     return candleId;
//   };

//   const updateCandleInterval = () => {
//     // Create a copy of the original data
//     let updatedData = [];
//     if (Array.isArray(formData?.functionsBox777)) {
//       updatedData = [...formData.functionsBox777];
//     } else {
//       console.log("undefined formData.functionsBox777");
//     }
//     let candleId = getCandleId();

//     updatedData.forEach((item) => {
//       item.candleIntervalId = candleId;
//     });
//     if (formData) formData.functionsBox777 = updatedData;
//     onFormChange(formData);
//   };

//   const updateCandleIntervalId = () => {
//     // const updatedIndicators;

//     // if(formData?.indicators)
//     const updatedIndicators = { ...formData?.indicators };

//     let candleId = getCandleId();
//     for (const key in updatedIndicators) {
//       if (updatedIndicators.hasOwnProperty(key)) {
//         const indicator = updatedIndicators[key];
//         // Check if candleIntervalId property exists
//         if (indicator.hasOwnProperty("candleIntervalId")) {
//           // Update candleIntervalId property value

//           indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
//         }
//         if(indicator?.body) {
//           if (Array.isArray(indicator.body)) {
//             indicator.body.forEach(item => {
//               if (item.hasOwnProperty("candleIntervalId")) {
//                 item.candleIntervalId = candleId;
//               }
//             });
//           }
//         } ;
//         if(indicator?.body1) {
//           if (Array.isArray(indicator.body1)) {
//             indicator.body1.forEach(item => {
//               if (item.hasOwnProperty("candleIntervalId")) {
//                 item.candleIntervalId = candleId;
//               }
//             });
//           }
//         } ;
//         if(indicator?.body2) {
//           if (Array.isArray(indicator.body2)) {
//             indicator.body2.forEach(item => {
//               if (item.hasOwnProperty("candleIntervalId")) {
//                 item.candleIntervalId = candleId;
//               }
//             });
//           }
//         }
//       }
//     }
//     // if(indicators)
//     if (formData) {
//       formData.indicators = updatedIndicators;
//     }

//     onFormChange(formData);
//   };

//   const handleClickAll = async (type) => {
//     setSelectedItem(type);
//     const data = await getfunctionTypeWisefunction({ type_id: type });

//     setFunctionType(data);
//   };

//   useEffect(() => {
//     handleClickAll("1");
//   }, []);

//   useEffect(() => {
//     console.log("storeAllStocks>>", storeAllStocks);
//   }, [storeAllStocks]);

//   useEffect(() => {
//     setTimeout(() => {
//       eflag = 1;
//     }, 2000);
//   }, []); // This effect runs once when the component mounts

//   const getEntryText = async () => {
//     // Get the element by its ID
//     const divElement = document.getElementById("strategyBox");

//     // Check if the div element exists
//     if (divElement) {
//       // Get the existing text content of the div
//       let existingContent = divElement.innerText;

//       // Replace {input1Placeholder} with formData?.input1 value
//       existingContent = existingContent.replace(
//         "{input1Placeholder}",
//         formData?.input1
//       );
//       let text1 = handleReplacement(existingContent);

//       if (formData) {
//         formData.functionText1 = existingContent;
//       }
//       onFormChange(formData);
//       // setGotText(!gotText);

//       // Do something with the modified text content
//     }
//   };

//   const toggleDropdown = () => {
//     setRemove(false);
//     setShowDropdown(true);
//   };

//   const handleModalData = (data) => {
//     setIsShow(false);

//     if (
//       formData?.clickedFunction === "Period min1" ||
//       formData?.clickedFunction === "Period max1" ||
//       formData?.clickedFunction === "Symbol1" ||
//       formData?.clickedFunction === "Floor1" ||
//       formData?.clickedFunction === "Ceil1" ||
//       formData?.clickedFunction === "Abs1"
//     ) {
//       // update period max and min period
//       switch (data.name) {
//         case "Period max":
//           formData.indicators[formData.clickedFunction].period = data?.period;
//         case "Period min":
//           formData.indicators[formData.clickedFunction].period = data?.period;
//         default:
//           break;
//       }
//       // Update or add properties based on selectedOption1
//       switch (formData?.selectedOption1) {
//         case "Ulcer":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ulcerField = data?.ulcerField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ulcerPeriod = parseInt(data?.ulcerPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ulcerOffset = parseInt(data?.ulcerOffset);
//           break;
//         case "TriMA (Triangular Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].tmaField = data?.tmaField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].tmaPeriod = parseInt(data?.tmaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].tmaOffset = parseInt(data?.tmaOffset);
//           break;
//         case "VWMA (Volume Weighted Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vwmaField = data?.vwmaField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vwmaPeriod = parseInt(data?.vwmaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vwmaOffset = parseInt(data?.vwmaOffset);
//           break;
//         case "DEMA (Double Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[itemIndex].field =
//             data?.field;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].period = parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].offset = parseInt(data?.offset);
//           break;
//         case "TEMA (Triple Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].temaField = data?.temaField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].temaPeriod = parseInt(data?.temaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].temaOffset = parseInt(data?.temaOffset);
//           break;
//         case "Open price":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].period = parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].offset = parseInt(data?.offset);
//           break;
//         case "Low price":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].lowOffset = parseInt(data?.lowOffset);
//           break;
//         case "High price":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].offset = parseInt(data?.offset);
//           break;
//         case "Close":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].closeOffset = parseInt(data?.closeOffset);
//           break;
//         case "SMA (Simple Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[itemIndex].field =
//             data?.field;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].period = parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].offset = parseInt(data?.offset);
//           break;
//         case "EMA (Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[itemIndex].field =
//             data?.field;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].period = parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].offset = parseInt(data?.offset);
//           break;
//         case "Money Flow Index":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].mfiPeriod = parseInt(data?.mfiPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].mfiOffset = parseInt(data?.mfiOffset);
//           break;
//         case "Williams %R":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].wrPeriod = parseInt(data?.wrPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].wrOffset = parseInt(data?.wrOffset);
//           break;
//         case "Moving Average Deviation (MA Dev)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].madField = data?.madField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].madPeriod = parseInt(data?.madPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].madType = data?.madType;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].madOffset = parseInt(data?.madOffset);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].madPop = data?.madPop;
//           break;
//         case "MACD (Moving Average Convergence/Divergence)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macd_fastMaPeriod = parseInt(data?.macd_fastMaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macd_slowMaPeriod = parseInt(data?.macd_slowMaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macd_signalPeriod = parseInt(data?.macd_signalPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macdOffset = parseInt(data?.macdOffset);
//           break;
//         case "OBV (On Balance Volume)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].obvOffset = parseInt(data?.obvOffset);
//           break;
//         case "Aroon-Down":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].aroonDownPeriod = parseInt(data?.aroonDownPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].aroonDownOffset = parseInt(data?.aroonDownOffset);
//           break;
//         case "Aroon-Up":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].aroonUpPeriod = parseInt(data?.aroonUpPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].aroonUpOffset = parseInt(data?.aroonUpOffset);
//           break;
//         case "Awesome Oscillator":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].aOscilatorOffset = parseInt(data?.aOscilatorOffset);
//           break;
//         case "VWAP (Volume Weighted Average Price)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vwapOffset = parseInt(data?.vwapOffset);
//           break;
//         case "Parabolic SAR":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].psarMinimumAF = parseFloat(data?.psarMinimumAF);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].psarMaximumAF = parseFloat(data?.psarMaximumAF);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].psarOffset = parseInt(data?.psarOffset);
//           break;
//         case "Vortex Indicator":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vortexIPeriod = parseInt(data?.vortexIPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vortexISignal = data?.vortexISignal;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].vortexIOffset = parseInt(data?.vortexIOffset);
//           break;
//         case "Number":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].period = parseFloat(data?.period);
//           break;
//         case "Volume of a candle":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].volume = parseInt(data?.volume);
//           break;
//         case "Donchian Channel":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].donchianChannel = data?.donchianChannel;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].donchianHighPeriod = parseInt(data?.donchianHighPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].donchianLowPeriod = parseInt(data?.donchianLowPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].donchianOffset = parseInt(data?.donchianOffset);
//           break;
//         case "Stochastic":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].stochasticPeriod = parseInt(data?.stochasticPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].stochasticType = data?.stochasticType;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].stochasticSmooth = data?.stochasticSmooth;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].stochasticOffset = parseInt(data?.stochasticOffset);
//           break;
//         case "Chaikin Money Flow":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].chaikinPeriod = parseInt(data?.chaikinPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].chaikinOffset = parseInt(data?.chaikinOffset);
//           break;
//         case "MACD-signal":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macdsFastMaPeriod = parseInt(data?.macdsFastMaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macdsSlowMaPeriod = parseInt(data?.macdsSlowMaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macdsSignalPeriod = parseInt(data?.macdsSignalPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].macdsOffset = parseInt(data?.macdsOffset);
//           break;
//         case "Commodity Channel Index":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].cciPeriod = parseInt(data?.cciPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].cciOffset = parseInt(data?.cciOffset);
//           break;
//         case "Price Rate of Change":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rocField = data?.rocField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rocPeriod = parseInt(data?.rocPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rocOffset = parseInt(data?.rocOffset);
//           break;
//         case "RSI (Relative Strength Index)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rsiPeriod = parseInt(data?.rsiPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rsiOffset = parseInt(data?.rsiOffset);
//           break;
//         case "RSI Moving Average":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rsimaPeriod = parseInt(data?.rsimaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rsimaType = data?.rsimaType;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rsimaMaPeriod = parseInt(data?.rsimaMaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].rsimaOffset = parseInt(data?.rsimaOffset);
//           break;
//         case "Supertrend":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].superTPeriod = parseInt(data?.superTPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].superTMultiply = parseFloat(data?.superTMultiply);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].superTOffset = parseInt(data?.superTOffset);
//           break;
//         case "TR (True Range)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].trOffset = parseInt(data?.trOffset);
//           break;
//         case "ATR (Average True Range)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].atrPeriod = parseInt(data?.atrPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].atrOffset = parseInt(data?.atrOffset);
//           break;
//         case "NATR (Normalized Average True Range)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].natrPeriod = parseInt(data?.natrPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].natrOffset = parseInt(data?.natrOffset);
//           break;
//         case "Bollinger %B":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].bolingerBField = data?.bolingerBField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].bolingerBPeriod = parseInt(data?.bolingerBPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].bolingerBDev = parseInt(data?.bolingerBDev);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].bolingerBType = data?.bolingerBType;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].bolingerBOffset = parseInt(data?.bolingerBOffset);
//           break;
//         case "MFI Moving Average (Money Flow Index Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].mfiMAPeriod = parseInt(data?.mfiMAPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].mfiMAMAPeriod = parseInt(data?.mfiMAMAPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].mfiMAType = data?.mfiMAType;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].mfiMAOffset = parseInt(data?.mfiMAOffset);
//           break;
//         case "Know Sure Thing":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstField = data?.kstField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstLTRoc = data?.kstLTRoc;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstLTSma = data?.kstLTSma;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstRoc = data?.kstRoc;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstLSma = data?.kstLSma;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstHRoc = data?.kstHRoc;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstHSma = data?.kstHSma;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstHeRoc = data?.kstHeRoc;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstHeSma = data?.kstHeSma;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstSPeriod = parseInt(data?.kstSPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstType = data?.kstType;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].kstOffset = parseInt(data?.kstOffset);
//           break;
//         case "Ichimoku":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ichiConversion = data?.ichiConversion;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ichiBase = data?.ichiBase;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].leadingSpanB = data?.leadingSpanB;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].laggingSpan = data?.laggingSpan;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ichiLine = data?.ichiLine;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].cloudShift = data?.cloudShift;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].ichiOffset = parseInt(data?.ichiOffset);
//           break;
//         case "WMA (Weighted Moving Average)":
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].wmaField = data?.wmaField;
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].wmaPeriod = parseInt(data?.wmaPeriod);
//           formData.indicators[formData?.clickedFunction].body[
//             itemIndex
//           ].wmaOffset = parseInt(data?.wmaOffset);
//           break;
//         case "MOM (Momentum Indicator)":
//           formData.indicators[formData.clickedFunction].body[itemIndex].period =
//               parseInt(data?.period);
//           formData.indicators[formData.clickedFunction].body[itemIndex].offset =
//               parseInt(data?.offset);
//             break;
//         default:
//           break;
//       }

//       setTimeout(() => {
//         getEntryText();
//       }, 1000);
//       onFormChange(formData);
//     } else if (
//       formData?.clickedFunction === "Max1" ||
//       formData?.clickedFunction === "Min1"
//     ) {
//       // Update or add properties based on selectedOption1
//       switch (formData?.selectedOption1) {
//         case "Ulcer":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].ulcerField = data?.ulcerField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].ulcerPeriod = parseInt(data?.ulcerPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].ulcerOffset = parseInt(data?.ulcerOffset);
//           break;
//         case "TriMA (Triangular Moving Average)":
//           formData.indicators[formData?.clickedFunction][instance][0].tmaField =
//             data?.tmaField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].tmaPeriod = parseInt(data?.tmaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].tmaOffset = parseInt(data?.tmaOffset);
//           break;
//         case "VWMA (Volume Weighted Moving Average)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vwmaField = data?.vwmaField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vwmaPeriod = parseInt(data?.vwmaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vwmaOffset = parseInt(data?.vwmaOffset);
//           break;
//         case "DEMA (Double Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction][instance][0].field =
//             data?.field;
//           formData.indicators[formData?.clickedFunction][instance][0].period =
//             parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction][instance][0].offset =
//             parseInt(data?.offset);
//           break;
//         case "TEMA (Triple Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].temaField = data?.temaField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].temaPeriod = parseInt(data?.temaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].temaOffset = parseInt(data?.temaOffset);
//           break;
//         case "Open price":
//           formData.indicators[formData?.clickedFunction][instance][0].period =
//             parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction][instance][0].offset =
//             parseInt(data?.offset);
//           break;
//         case "Low price":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].lowOffset = parseInt(data?.lowOffset);
//           break;
//         case "High price":
//           formData.indicators[formData?.clickedFunction][instance][0].offset =
//             parseInt(data?.offset);
//           break;
//         case "Close":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].closeOffset = parseInt(data?.closeOffset);
//           break;
//         case "SMA (Simple Moving Average)":
//           formData.indicators[formData?.clickedFunction][instance][0].field =
//             data?.field;
//           formData.indicators[formData?.clickedFunction][instance][0].period =
//             parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction][instance][0].offset =
//             parseInt(data?.offset);
//           break;
//         case "EMA (Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction][instance][0].field =
//             data?.field;
//           formData.indicators[formData?.clickedFunction][instance][0].period =
//             parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction][instance][0].offset =
//             parseInt(data?.offset);
//           break;
//         case "Money Flow Index":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].mfiPeriod = parseInt(data?.mfiPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].mfiOffset = parseInt(data?.mfiOffset);
//           break;
//         case "Williams %R":
//           formData.indicators[formData?.clickedFunction][instance][0].wrPeriod =
//             parseInt(data?.wrPeriod);
//           formData.indicators[formData?.clickedFunction][instance][0].wrOffset =
//             parseInt(data?.wrOffset);
//           break;
//         case "Moving Average Deviation (MA Dev)":
//           formData.indicators[formData?.clickedFunction][instance][0].madField =
//             data?.madField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].madPeriod = parseInt(data?.madPeriod);
//           formData.indicators[formData?.clickedFunction][instance][0].madType =
//             data?.madType;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].madOffset = parseInt(data?.madOffset);
//           formData.indicators[formData?.clickedFunction][instance][0].madPop =
//             data?.madPop;
//           break;
//         case "MACD (Moving Average Convergence/Divergence)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macd_fastMaPeriod = parseInt(data?.macd_fastMaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macd_slowMaPeriod = parseInt(data?.macd_slowMaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macd_signalPeriod = parseInt(data?.macd_signalPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macdOffset = parseInt(data?.macdOffset);
//           break;
//         case "OBV (On Balance Volume)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].obvOffset = parseInt(data?.obvOffset);
//           break;
//         case "Aroon-Down":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].aroonDownPeriod = parseInt(data?.aroonDownPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].aroonDownOffset = parseInt(data?.aroonDownOffset);
//           break;
//         case "Aroon-Up":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].aroonUpPeriod = parseInt(data?.aroonUpPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].aroonUpOffset = parseInt(data?.aroonUpOffset);
//           break;
//         case "Awesome Oscillator":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].aOscilatorOffset = parseInt(data?.aOscilatorOffset);
//           break;
//         case "VWAP (Volume Weighted Average Price)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vwapOffset = parseInt(data?.vwapOffset);
//           break;
//         case "Parabolic SAR":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].psarMinimumAF = parseFloat(data?.psarMinimumAF);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].psarMaximumAF = parseFloat(data?.psarMaximumAF);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].psarOffset = parseInt(data?.psarOffset);
//           break;
//         case "Vortex Indicator":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vortexIPeriod = parseInt(data?.vortexIPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vortexISignal = data?.vortexISignal;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].vortexIOffset = parseInt(data?.vortexIOffset);
//           break;
//         case "Number":
//           formData.indicators[formData?.clickedFunction][instance][0].period =
//             parseFloat(data?.period);
//           break;
//         case "Volume of a candle":
//           formData.indicators[formData?.clickedFunction][instance][0].volume =
//             parseInt(data?.volume);
//           break;
//         case "Donchian Channel":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].donchianChannel = data?.donchianChannel;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].donchianHighPeriod = parseInt(data?.donchianHighPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].donchianLowPeriod = parseInt(data?.donchianLowPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].donchianOffset = parseInt(data?.donchianOffset);
//           break;
//         case "Stochastic":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].stochasticPeriod = parseInt(data?.stochasticPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].stochasticType = data?.stochasticType;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].stochasticSmooth = data?.stochasticSmooth;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].stochasticOffset = parseInt(data?.stochasticOffset);
//           break;
//         case "Chaikin Money Flow":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].chaikinPeriod = parseInt(data?.chaikinPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].chaikinOffset = parseInt(data?.chaikinOffset);
//           break;
//         case "MACD-signal":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macdsFastMaPeriod = parseInt(data?.macdsFastMaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macdsSlowMaPeriod = parseInt(data?.macdsSlowMaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macdsSignalPeriod = parseInt(data?.macdsSignalPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].macdsOffset = parseInt(data?.macdsOffset);
//           break;
//         case "Commodity Channel Index":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].cciPeriod = parseInt(data?.cciPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].cciOffset = parseInt(data?.cciOffset);
//           break;
//         case "Price Rate of Change":
//           formData.indicators[formData?.clickedFunction][instance][0].rocField =
//             data?.rocField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rocPeriod = parseInt(data?.rocPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rocOffset = parseInt(data?.rocOffset);
//           break;
//         case "RSI (Relative Strength Index)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rsiPeriod = parseInt(data?.rsiPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rsiOffset = parseInt(data?.rsiOffset);
//           break;
//         case "RSI Moving Average":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rsimaPeriod = parseInt(data?.rsimaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rsimaType = data?.rsimaType;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rsimaMaPeriod = parseInt(data?.rsimaMaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].rsimaOffset = parseInt(data?.rsimaOffset);
//           break;
//         case "Supertrend":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].superTPeriod = parseInt(data?.superTPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].superTMultiply = parseFloat(data?.superTMultiply);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].superTOffset = parseInt(data?.superTOffset);
//           break;
//         case "TR (True Range)":
//           formData.indicators[formData?.clickedFunction][instance][0].trOffset =
//             parseInt(data?.trOffset);
//           break;
//         case "ATR (Average True Range)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].atrPeriod = parseInt(data?.atrPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].atrOffset = parseInt(data?.atrOffset);
//           break;
//         case "NATR (Normalized Average True Range)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].natrPeriod = parseInt(data?.natrPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].natrOffset = parseInt(data?.natrOffset);
//           break;
//         case "Bollinger %B":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].bolingerBField = data?.bolingerBField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].bolingerBPeriod = parseInt(data?.bolingerBPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].bolingerBDev = parseInt(data?.bolingerBDev);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].bolingerBType = data?.bolingerBType;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].bolingerBOffset = parseInt(data?.bolingerBOffset);
//           break;
//         case "MFI Moving Average (Money Flow Index Moving Average)":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].mfiMAPeriod = parseInt(data?.mfiMAPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].mfiMAMAPeriod = parseInt(data?.mfiMAMAPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].mfiMAType = data?.mfiMAType;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].mfiMAOffset = parseInt(data?.mfiMAOffset);
//           break;
//         case "Know Sure Thing":
//           formData.indicators[formData?.clickedFunction][instance][0].kstField =
//             data?.kstField;
//           formData.indicators[formData?.clickedFunction][instance][0].kstLTRoc =
//             data?.kstLTRoc;
//           formData.indicators[formData?.clickedFunction][instance][0].kstLTSma =
//             data?.kstLTSma;
//           formData.indicators[formData?.clickedFunction][instance][0].kstRoc =
//             data?.kstRoc;
//           formData.indicators[formData?.clickedFunction][instance][0].kstLSma =
//             data?.kstLSma;
//           formData.indicators[formData?.clickedFunction][instance][0].kstHRoc =
//             data?.kstHRoc;
//           formData.indicators[formData?.clickedFunction][instance][0].kstHSma =
//             data?.kstHSma;
//           formData.indicators[formData?.clickedFunction][instance][0].kstHeRoc =
//             data?.kstHeRoc;
//           formData.indicators[formData?.clickedFunction][instance][0].kstHeSma =
//             data?.kstHeSma;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].kstSPeriod = parseInt(data?.kstSPeriod);
//           formData.indicators[formData?.clickedFunction][instance][0].kstType =
//             data?.kstType;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].kstOffset = parseInt(data?.kstOffset);
//           break;
//         case "Ichimoku":
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].ichiConversion = data?.ichiConversion;
//           formData.indicators[formData?.clickedFunction][instance][0].ichiBase =
//             data?.ichiBase;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].leadingSpanB = data?.leadingSpanB;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].laggingSpan = data?.laggingSpan;
//           formData.indicators[formData?.clickedFunction][instance][0].ichiLine =
//             data?.ichiLine;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].cloudShift = data?.cloudShift;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].ichiOffset = parseInt(data?.ichiOffset);
//           break;
//         case "WMA (Weighted Moving Average)":
//           formData.indicators[formData?.clickedFunction][instance][0].wmaField =
//             data.wmaField;
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].wmaPeriod = parseInt(data.wmaPeriod);
//           formData.indicators[formData?.clickedFunction][
//             instance
//           ][0].wmaOffset = parseInt(data.wmaOffset);
//           break;

//         case "MOM (Momentum Indicator)":
//           formData.indicators[formData?.clickedFunction][instance][0].period =
//               parseInt(data?.period);
//           formData.indicators[formData?.clickedFunction][instance][0].offset =
//               parseInt(data?.offset);
//           break;
//         default:
//           break;
//       }
//       console.log(
//         "InMath2>",
//         formData?.clickedFunction,
//         formData.indicators[formData?.clickedFunction][instance][0],
//         instance
//         // formData.indicators[formData?.clickedFunction]?.body[itemIndex]
//       );
//       setTimeout(() => {
//         getEntryText();
//       }, 1000);

//       onFormChange(formData);
//     } else {
//       // Check if formData.indicators object exists
//       if (!formData?.indicators) {
//         formData.indicators = {};
//       }

//       // Check if the instance exists in formData.indicators
//       if (!formData?.indicators[formData?.clickedFunction]) {
//         formData.indicators[formData?.clickedFunction] = {};
//       }
//       if (formData?.clickedFunction?.startsWith("comparator")) {
//         let c = formData.indicators?.[formData?.clickedFunction];
//         if (c) {
//           formData.indicators[formData?.clickedFunction].name = data?.name;
//         }
//       }

//       // Update or add properties based on selectedOption1
//       switch (formData?.selectedOption1) {
//         case "Ulcer":
//           formData.indicators[formData?.clickedFunction].ulcerField =
//             data?.ulcerField;
//           formData.indicators[formData?.clickedFunction].ulcerPeriod =
//             data?.ulcerPeriod;
//           formData.indicators[formData?.clickedFunction].ulcerOffset =
//             data?.ulcerOffset;
//           break;
//         case "TriMA (Triangular Moving Average)":
//           formData.indicators[formData?.clickedFunction].tmaField =
//             data?.tmaField;
//           formData.indicators[formData?.clickedFunction].tmaPeriod =
//             data?.tmaPeriod;
//           formData.indicators[formData?.clickedFunction].tmaOffset =
//             data?.tmaOffset;
//           break;
//         case "VWMA (Volume Weighted Moving Average)":
//           formData.indicators[formData?.clickedFunction].vwmaField =
//             data?.vwmaField;
//           formData.indicators[formData?.clickedFunction].vwmaPeriod =
//             data?.vwmaPeriod;
//           formData.indicators[formData?.clickedFunction].vwmaOffset =
//             data?.vwmaOffset;
//           break;
//         case "DEMA (Double Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction].field = data?.field;
//           formData.indicators[formData?.clickedFunction].period = data?.period;
//           formData.indicators[formData?.clickedFunction].offset = data?.offset;
//           break;
//         case "TEMA (Triple Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction].temaField =
//             data?.temaField;
//           formData.indicators[formData?.clickedFunction].temaPeriod =
//             data?.temaPeriod;
//           formData.indicators[formData?.clickedFunction].temaOffset =
//             data?.temaOffset;
//           break;
//         case "Open price":
//           formData.indicators[formData?.clickedFunction].period = data?.period;
//           formData.indicators[formData?.clickedFunction].offset = data?.offset;
//           break;
//         case "Low price":
//           formData.indicators[formData?.clickedFunction].lowOffset =
//             data?.lowOffset;
//           break;
//         case "High price":
//           formData.indicators[formData?.clickedFunction].offset = data?.offset;
//           break;
//         case "Close":
//           formData.indicators[formData?.clickedFunction].closeOffset =
//             data?.closeOffset;
//           break;
//         case "SMA (Simple Moving Average)":
//           formData.indicators[formData?.clickedFunction].field = data?.field;
//           formData.indicators[formData?.clickedFunction].period = data?.period;
//           formData.indicators[formData?.clickedFunction].offset = data?.offset;
//           break;
//         case "EMA (Exponential Moving Average)":
//           formData.indicators[formData?.clickedFunction].field = data?.field;
//           formData.indicators[formData?.clickedFunction].period = data?.period;
//           formData.indicators[formData?.clickedFunction].offset = data?.offset;
//           break;
//         case "Money Flow Index":
//           formData.indicators[formData?.clickedFunction].mfiPeriod =
//             data?.mfiPeriod;
//           formData.indicators[formData?.clickedFunction].mfiOffset =
//             data?.mfiOffset;
//           break;
//         case "Williams %R":
//           formData.indicators[formData?.clickedFunction].wrOffset =
//             data?.wrOffset;
//           formData.indicators[formData?.clickedFunction].wrPeriod =
//             data?.wrPeriod;
//           break;

//         case "Moving Average Deviation (MA Dev)":
//           formData.indicators[formData?.clickedFunction].madField =
//             data?.madField;
//           formData.indicators[formData?.clickedFunction].madPeriod =
//             data?.madPeriod;
//           formData.indicators[formData?.clickedFunction].madType =
//             data?.madType;
//           formData.indicators[formData?.clickedFunction].madOffset =
//             data?.madOffset;
//           formData.indicators[formData?.clickedFunction].madPop = data?.madPop;
//           break;

//         case "MACD (Moving Average Convergence/Divergence)":
//           formData.indicators[formData?.clickedFunction].macd_fastMaPeriod =
//             data?.macd_fastMaPeriod;
//           formData.indicators[formData?.clickedFunction].macd_slowMaPeriod =
//             data?.macd_slowMaPeriod;
//           formData.indicators[formData?.clickedFunction].macd_signalPeriod =
//             data?.macd_signalPeriod;
//           formData.indicators[formData?.clickedFunction].macdOffset =
//             data?.macdOffset;
//           break;

//         case "OBV (On Balance Volume)":
//           formData.indicators[formData?.clickedFunction].obvOffset =
//             data?.obvOffset;
//           break;

//         case "Aroon-Down":
//           formData.indicators[formData?.clickedFunction].aroonDownPeriod =
//             data?.aroonDownPeriod;
//           formData.indicators[formData?.clickedFunction].aroonDownOffset =
//             data?.aroonDownOffset;
//           break;

//         case "Aroon-Up":
//           formData.indicators[formData?.clickedFunction].aroonUpPeriod =
//             data?.aroonUpPeriod;
//           formData.indicators[formData?.clickedFunction].aroonUpOffset =
//             data?.aroonUpOffset;
//           break;

//         case "Awesome Oscillator":
//           formData.indicators[formData?.clickedFunction].aOscilatorOffset =
//             data?.aOscilatorOffset;
//           break;

//         case "VWAP (Volume Weighted Average Price)":
//           formData.indicators[formData?.clickedFunction].vwapOffset =
//             data?.vwapOffset;
//           break;

//         case "Parabolic SAR":
//           formData.indicators[formData?.clickedFunction].psarMinimumAF =
//             data?.psarMinimumAF;
//           formData.indicators[formData?.clickedFunction].psarMaximumAF =
//             data?.psarMaximumAF;
//           formData.indicators[formData?.clickedFunction].psarOffset =
//             data?.psarOffset;
//           break;

//         case "Vortex Indicator":
//           formData.indicators[formData?.clickedFunction].vortexIPeriod =
//             data?.vortexIPeriod;
//           formData.indicators[formData?.clickedFunction].vortexISignal =
//             data?.vortexISignal;
//           formData.indicators[formData?.clickedFunction].vortexIOffset =
//             data?.vortexIOffset;
//           break;

//         case "Number":
//           formData.indicators[formData?.clickedFunction].period = data?.period;
//           break;

//         case "Volume of a candle":
//           formData.indicators[formData?.clickedFunction].volume = data?.volume;
//           break;

//         case "Donchian Channel":
//           formData.indicators[formData?.clickedFunction].donchianChannel =
//             data?.donchianChannel;
//           formData.indicators[formData?.clickedFunction].donchianHighPeriod =
//             data?.donchianHighPeriod;
//           formData.indicators[formData?.clickedFunction].donchianLowPeriod =
//             data?.donchianLowPeriod;
//           formData.indicators[formData?.clickedFunction].donchianOffset =
//             data?.donchianOffset;
//           break;

//         case "Stochastic":
//           formData.indicators[formData?.clickedFunction].stochasticPeriod =
//             data?.stochasticPeriod;
//           formData.indicators[formData?.clickedFunction].stochasticType =
//             data?.stochasticType;
//           formData.indicators[formData?.clickedFunction].stochasticSmooth =
//             data?.stochasticSmooth;
//           formData.indicators[formData?.clickedFunction].stochasticOffset =
//             data?.stochasticOffset;

//           break;

//         case "Chaikin Money Flow":
//           formData.indicators[formData?.clickedFunction].chaikinPeriod =
//             data?.chaikinPeriod;
//           formData.indicators[formData?.clickedFunction].chaikinOffset =
//             data?.chaikinOffset;
//           break;

//         case "MACD-signal":
//           formData.indicators[formData?.clickedFunction].macdsFastMaPeriod =
//             data?.macdsFastMaPeriod;
//           formData.indicators[formData?.clickedFunction].macdsSlowMaPeriod =
//             data?.macdsSlowMaPeriod;
//           formData.indicators[formData?.clickedFunction].macdsSignalPeriod =
//             data?.macdsSignalPeriod;
//           formData.indicators[formData?.clickedFunction].macdsOffset =
//             data?.macdsOffset;

//           break;

//         case "Commodity Channel Index":
//           formData.indicators[formData?.clickedFunction].cciPeriod =
//             data?.cciPeriod;
//           formData.indicators[formData?.clickedFunction].cciOffset =
//             data?.cciOffset;
//           break;

//         case "Price Rate of Change":
//           formData.indicators[formData?.clickedFunction].rocField =
//             data?.rocField;
//           formData.indicators[formData?.clickedFunction].rocPeriod =
//             data?.rocPeriod;
//           formData.indicators[formData?.clickedFunction].rocOffset =
//             data?.rocOffset;
//           break;

//         case "RSI (Relative Strength Index)":
//           formData.indicators[formData?.clickedFunction].rsiPeriod =
//             data?.rsiPeriod;
//           formData.indicators[formData?.clickedFunction].rsiOffset =
//             data?.rsiOffset;
//           break;

//         case "RSI Moving Average":
//           formData.indicators[formData?.clickedFunction].rsimaPeriod =
//             data?.rsimaPeriod;
//           formData.indicators[formData?.clickedFunction].rsimaType =
//             data?.rsimaType;
//           formData.indicators[formData?.clickedFunction].rsimaMaPeriod =
//             data?.rsimaMaPeriod;
//           formData.indicators[formData?.clickedFunction].rsimaOffset =
//             data?.rsimaOffset;
//           break;

//         case "Supertrend":
//           formData.indicators[formData?.clickedFunction].superTPeriod =
//             data?.superTPeriod;
//           formData.indicators[formData?.clickedFunction].superTMultiply =
//             data?.superTMultiply;
//           formData.indicators[formData?.clickedFunction].superTOffset =
//             data?.superTOffset;
//           break;

//         case "TR (True Range)":
//           formData.indicators[formData?.clickedFunction].trOffset =
//             data?.trOffset;
//           break;

//         case "ATR (Average True Range)":
//           formData.indicators[formData?.clickedFunction].atrPeriod =
//             data?.atrPeriod;
//           formData.indicators[formData?.clickedFunction].atrOffset =
//             data?.atrOffset;
//           break;

//         case "NATR (Normalized Average True Range)":
//           formData.indicators[formData?.clickedFunction].natrPeriod =
//             data?.natrPeriod;
//           formData.indicators[formData?.clickedFunction].natrOffset =
//             data?.natrOffset;
//           break;

//         case "Bollinger %B":
//           formData.indicators[formData?.clickedFunction].bolingerBField =
//             data?.bolingerBField;
//           formData.indicators[formData?.clickedFunction].bolingerBPeriod =
//             data?.bolingerBPeriod;
//           formData.indicators[formData?.clickedFunction].bolingerBDev =
//             data?.bolingerBDev;
//           formData.indicators[formData?.clickedFunction].bolingerBType =
//             data?.bolingerBType;
//           formData.indicators[formData?.clickedFunction].bolingerBOffset =
//             data?.bolingerBOffset;
//           break;

//         case "MFI Moving Average (Money Flow Index Moving Average)":
//           formData.indicators[formData?.clickedFunction].mfiMAPeriod =
//             data?.mfiMAPeriod;
//           formData.indicators[formData?.clickedFunction].mfiMAMAPeriod =
//             data?.mfiMAMAPeriod;
//           formData.indicators[formData?.clickedFunction].mfiMAType =
//             data?.mfiMAType;
//           formData.indicators[formData?.clickedFunction].mfiMAOffset =
//             data?.mfiMAOffset;
//           break;

//         case "Know Sure Thing":
//           formData.indicators[formData?.clickedFunction].kstField =
//             data?.kstField;
//           formData.indicators[formData?.clickedFunction].kstLTRoc =
//             data?.kstLTRoc;
//           formData.indicators[formData?.clickedFunction].kstLTSma =
//             data?.kstLTSma;
//           formData.indicators[formData?.clickedFunction].kstRoc = data?.kstRoc;
//           formData.indicators[formData?.clickedFunction].kstLSma =
//             data?.kstLSma;
//           formData.indicators[formData?.clickedFunction].kstHRoc =
//             data?.kstHRoc;
//           formData.indicators[formData?.clickedFunction].kstHSma =
//             data?.kstHSma;
//           formData.indicators[formData?.clickedFunction].kstHeRoc =
//             data?.kstHeRoc;
//           formData.indicators[formData?.clickedFunction].kstHeSma =
//             data?.kstHeSma;
//           formData.indicators[formData?.clickedFunction].kstSPeriod =
//             data?.kstSPeriod;
//           formData.indicators[formData?.clickedFunction].kstType =
//             data?.kstType;
//           formData.indicators[formData?.clickedFunction].kstOffset =
//             data?.kstOffset;
//           break;

//         case "Ichimoku":
//           formData.indicators[formData?.clickedFunction].ichiConversion =
//             data?.ichiConversion;
//           formData.indicators[formData?.clickedFunction].ichiBase =
//             data?.ichiBase;
//           formData.indicators[formData?.clickedFunction].leadingSpanB =
//             data?.leadingSpanB;
//           formData.indicators[formData?.clickedFunction].laggingSpan =
//             data?.laggingSpan;
//           formData.indicators[formData?.clickedFunction].ichiLine =
//             data?.ichiLine;
//           formData.indicators[formData?.clickedFunction].cloudShift =
//             data?.cloudShift;
//           formData.indicators[formData?.clickedFunction].ichiOffset =
//             data?.ichiOffset;

//           break;

//         case "WMA (Weighted Moving Average)":
//           formData.indicators[formData?.clickedFunction].wmaField =
//             data?.wmaField;
//           formData.indicators[formData?.clickedFunction].wmaPeriod =
//             data?.wmaPeriod;
//           formData.indicators[formData?.clickedFunction].wmaOffset =
//             data?.wmaOffset;
//           break;
//         case "MOM (Momentum Indicator)":
//           formData.indicators[formData.clickedFunction].period =
//               parseInt(data?.period);
//           formData.indicators[formData.clickedFunction].offset =
//               parseInt(data?.offset);
//           break;
//         default:
//           break;
//       }

//       setTimeout(() => {
//         getEntryText();
//       }, 1000);

//       onFormChange(formData);
//     }
//   };

//   const handleSMAComparator = (data) => {
//     setIsShow3(false);
//     if (formData?.ctype === "c1") {
//       onFormChange({
//         ...formData,
//         comparator: data?.field,
//       });
//     }
//     if (formData?.ctype === "c2") {
//       onFormChange({
//         ...formData,
//         comparator2: data?.comparator2,
//       });
//     }
//     if (formData?.ctype === "c3") {
//       onFormChange({
//         ...formData,
//         comparator3: data?.comparator3,
//       });
//     }
//   };

//   const handleSearch = (event) => {
//     const query = event.target.value;
//     setSearchQuery(query);
//     toggleDropdown();
//   };

//   const handleReplacement = (text) => {
//     console.log("checkText-", text);
//     let modifiedText = text?.replace(/lower than or equal to/gi, "<=");
//     modifiedText = modifiedText.replace(/higher than or equal to/gi, ">=");
//     modifiedText = modifiedText.replace(/lower than/gi, "<");
//     modifiedText = modifiedText.replace(/higher than/gi, ">");
//     modifiedText = modifiedText.replace(/> equal to/gi, ">=");
//     modifiedText = modifiedText.replace(/< equal to/gi, "<=");
//     modifiedText = modifiedText.replace(/and/gi, "&&");
//     modifiedText = modifiedText.replace(/or/gi, "||");
//     modifiedText = modifiedText.replace(/equal to/gi, "===");
//     modifiedText = modifiedText.replace(/crosses above/gi, ">");
//     modifiedText = modifiedText.replace(/crosses below/gi, ">");

//     // Add more replacements as needed

//     return modifiedText;
//   };

//   const handleAddButtonClick = (instanceName, bodyInstance) => {
//     if (formData) {
//       formData.selectedOption3 = instanceName;
//     }

//     setInstance(bodyInstance);
//   };

//   const handleRemoveButtonClick = (
//     instanceToRemove,
//     indexToRemove,
//     mInstance
//   ) => {
//     console.log(
//       "instanceToRemove",
//       instanceToRemove,
//       indexToRemove,
//       formData?.indicators
//     );

//     if (
//       instanceToRemove === "Period min1" ||
//       instanceToRemove === "Period max1" ||
//       instanceToRemove === "Symbol1" ||
//       instanceToRemove === "Floor1" ||
//       instanceToRemove === "Ceil1" ||
//       instanceToRemove === "Abs1"
//     ) {
//       const updatedIndicators = { ...formData?.indicators };

//       updatedIndicators[instanceToRemove]?.body.splice(indexToRemove, 1);

//       let filteredBox = formData?.functionsBox?.filter(
//         (item, index) => index !== indexToRemove
//       );
//       let keyToFind = instanceToRemove;
//       const keys = Object.keys(formData?.indicators);
//       const indexRemove = keys.indexOf(keyToFind);
//       console.log("keyToFind", indexRemove);
//       setMathIndexRemove(indexRemove + 1);
     
//       let filteredBox2 = formData?.functionsBox777?.filter(
//         (item, index) => index !== indexRemove + 1
//       );
//       formData.functionsBox = filteredBox;
//       formData.functionsBox777 = filteredBox2;
//       setRemovedInsideMath(true);
//       onFormChange(formData);

//       onFormChange((prevState) => ({
//         ...prevState,
//         indicators: updatedIndicators,
//       }));
//     } else if (instanceToRemove === "Max1" || instanceToRemove === "Min1") {
//       const updatedIndicators = { ...formData?.indicators };

//       updatedIndicators[instanceToRemove][mInstance]?.splice(indexToRemove, 1);

//       let filteredBox = formData?.functionsBox?.filter(
//         (item, index) => index !== indexToRemove
//       );
//       let keyToFind = instanceToRemove;
//       const keys = Object.keys(formData?.indicators);
//       const indexRemove = keys.indexOf(keyToFind);
//       console.log("keyToFind", indexRemove);
//       let filteredBox2 = formData?.functionsBox777?.filter((item, index) => {
//         if (mInstance == "body2") {
//           setMathIndexRemove(indexRemove + 2);
//           return index !== indexRemove + 2;
//         } else {
//           setMathIndexRemove(indexRemove + 1);
//           return index !== indexRemove + 1;
//         }
//       });
      
//       formData.functionsBox = filteredBox;
//       formData.functionsBox777 = filteredBox2;
//       onFormChange(formData);

//       setRemovedInsideMath(true);
//       onFormChange((prevState) => ({
//         ...prevState,
//         indicators: updatedIndicators,
//       }));
//     } else {
//       // Make a copy of the formData?.indicators object to avoid directly modifying the state
//       const updatedIndicators = { ...formData?.indicators };

//       // Check if the instanceToRemove is an "and" instance
//       if (updatedIndicators.hasOwnProperty(instanceToRemove)) {
//         // Remove the "and" instance
//         delete updatedIndicators[instanceToRemove];
//       } else {
//         // Remove the regular instance
//         Object.keys(updatedIndicators).forEach((key) => {
//           if (updatedIndicators[key].hasOwnProperty(instanceToRemove)) {
//             delete updatedIndicators[key][instanceToRemove];
//           }
//         });
//       }
//       if (isIncludeMathFunction.current) {
//         let filteredBox = formData?.functionsBox?.filter(
//           (item, index) => index !== indexToRemove + 1
//         );
//         let filteredBox2 = formData?.functionsBox777?.filter(
//           (item, index) => index !== indexToRemove + 1
//         );
//         if (formData) {
//           formData.functionsBox = filteredBox;
//           formData.functionsBox777 = filteredBox2;
//         }
//       } else {
//         let filteredBox = formData?.functionsBox?.filter(
//           (item, index) => index !== indexToRemove
//         );
//         let filteredBox2 = formData?.functionsBox777?.filter(
//           (item, index) => index !== indexToRemove
//         );
//         if (formData) {
//           formData.functionsBox = filteredBox;
//           formData.functionsBox777 = filteredBox2;
//         }
//       }

//       onFormChange(formData);
//       // Update the state with the new indicators object
//       onFormChange((prevState) => ({
//         ...prevState,
//         indicators: updatedIndicators,
//       }));
//     }
//   };

//   const handleButtonClick = (index) => {};

//   const handleAdd = (instanceData, index) => {
//     toggleDropdown();
//   };


//   const mergeArrays = (firstArray, secondArray) => {
//     console.log("mergeArray", firstArray, secondArray )
//     const comparisonOperators = ["<", ">", "<=", ">="];
//     const mathOperators = ["+", "-", "*", "/"] ;

//     let updatedArrays = [];
//     let currentArray = [];

//     firstArray.forEach((item, index) => {
//       const functionName = secondArray[index]
//         ? secondArray[index].replace(/[()]/g, "").split(/\s+/)
//         : null;
//       console.log("functionName", functionName)
//       if (functionName && functionName?.length === 1) {
//         const functionValues1 = functionName[0].split(",");
//         const includesLogicalOperator = /&&|\|\|/.test(
//           functionValues1.join(" ")
//         );

//         if (includesLogicalOperator) {
//           // Push the current array if it is not empty
//           if (currentArray?.length > 0) {
//             updatedArrays.push(currentArray);
//             currentArray = [];
//           }
//         }

//         const includesComparisonOperator = comparisonOperators.some(
//           (operator) => functionValues1.includes(operator)
//         );
//         const includeMathOperator = mathOperators.some(
//           (operator) => functionValues1.includes(operator)
//         )
//         const operatorObject = includesLogicalOperator
//           ? { operator: functionValues1.join(" ") }
//           : {};
//         const mathOperatorObject = includeMathOperator 
//           ? {comparator : functionValues1.join(" ") } 
//           : {} ;
//         if (includesLogicalOperator) {
//           // Push the operator object in a separate array
//           updatedArrays.push([{ ...item, ...operatorObject }]);
//         } else if (includesComparisonOperator) {
//           currentArray.push({
//             ...item,
//             name : item?.name,
//             function: functionName || null,
//             field: null,
//             period: null,
//             offset: null,
//             ...(
//               item?.name === "crosses above"
//                 ? { isCrossover: 2 }
//                 : item?.name === "crosses below"
//                 ? { isCrossover: 1 }
//                 : {} 
//             ),
//             comparator: includesComparisonOperator
//               ? (getComparatorValue(item?.name) || functionValues1.join(" "))
//               : null,
//             ...operatorObject,
//           });
//         }
//          else if(includeMathOperator) {
//           currentArray.push({
//             function_id : item?.function_id ,
//             function_type_id : item?.function_type_id ,
//             name : item?.name ,
//             ...mathOperatorObject 
//           })
//         }
//       }

//       function getComparatorValue(name) {
//         switch (name) {
//           case "crosses above":
//             return ">";
//           case "crosses below":
//             return "<";
//           case "higher than":
//             return ">";
//           case "lower than":
//             return "<";
//           case "lower than equal to":
//             return "<=";
//           case "higher than equal to":
//             return ">=";
//           case "equal to":
//             return "==";
//           default:
//             return null;
//         }
//       }
//       if (functionName && functionName?.length > 1) {
//         const lastFunctionValue = functionName[functionName?.length - 1];
//         const functionValues = lastFunctionValue.split(",");
//         console.log("lastFunctionValue", lastFunctionValue)
//         const includesComparisonOperator = comparisonOperators.some(
//           (operator) => lastFunctionValue.includes(operator)
//         );

//         const comparatorObject = includesComparisonOperator
//           ? { comparator: lastFunctionValue }
//           : {};
//         console.log("functionValues", functionValues)
//         if (functionValues?.length === 5) {
//           if (item.name === "Bollinger %B") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               field: functionValues?.length > 0 ? functionValues[0] : null,
//               period:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[1], 10)
//                   : null,
//               deviation: functionValues?.length > 0 ? functionValues[2] : null,
//               type: functionValues?.length > 0 ? functionValues[3] : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[4], 10)
//                   : null,
//               ...comparatorObject,
//             };

//             if (
//               (newItem.field &&
//                 newItem.period &&
//                 newItem.deviation &&
//                 newItem.type) ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }
//         }
//         if (functionValues?.length === 4) {
//           if (item.name === "RSI Moving Average") {
//             console.log("RSI Moving Average", item)
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               maType: functionValues?.length > 0 ? functionValues[1] : null,
//               maPeriod:
//                 functionValues?.length > 0 ? parseInt(functionValues[2]) : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[3], 10)
//                   : null,
//               ...comparatorObject,
//             };
//             console.log("RSI Moving Average", item)
//             if (
//               (newItem.period && newItem.maType) ||
//               newItem.period === 0 ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }
//           if (item.name === "MACD (Moving Average Convergence/Divergence)") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period1:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               period2:
//                 functionValues?.length > 0 ? parseInt(functionValues[1]) : null,
//               period3:
//                 functionValues?.length > 0 ? parseInt(functionValues[2]) : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[3], 10)
//                   : null,
//               ...comparatorObject,
//             };

//             if (
//               newItem.period1 ||
//               newItem.period2 ||
//               newItem.period3 === 0 ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }
//           if (item.name === "MACD-signal") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period1:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               period2:
//                 functionValues?.length > 0 ? parseInt(functionValues[1]) : null,
//               period3:
//                 functionValues?.length > 0 ? parseInt(functionValues[2]) : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[3], 10)
//                   : null,
//               ...comparatorObject,
//             };

//             if (
//               newItem.period1 ||
//               newItem.period2 ||
//               newItem.period3 === 0 ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }
//           if (item.name === "Donchian Channel") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               channel: functionValues?.length > 0 ? functionValues[0] : null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[1]) : null,
//               period1:
//                 functionValues?.length > 0 ? parseInt(functionValues[2]) : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[3], 10)
//                   : null,
//               ...comparatorObject,
//             };

//             if (
//               newItem.channel ||
//               newItem.period ||
//               newItem.period1 === 0 ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }
//           if (item.name === "Stochastic") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               type: functionValues?.length > 0 ? functionValues[1] : null,
//               smoothen: functionValues?.length > 0 ? functionValues[2] : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[3], 10)
//                   : null,
//               ...comparatorObject,
//             };

//             if (
//               newItem.channel ||
//               newItem.period ||
//               newItem.period1 === 0 ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }

//           if (
//             item.name === "MFI Moving Average (Money Flow Index Moving Average)"
//           ) {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               maType: functionValues?.length > 0 ? functionValues[1] : null,
//               maPeriod:
//                 functionValues?.length > 0 ? parseInt(functionValues[2]) : null,
//               offset:
//                 functionValues?.length > 0
//                   ? parseInt(functionValues[3], 10)
//                   : null,
//               ...comparatorObject,
//             };

//             if (
//               (newItem.period && newItem.maType) ||
//               newItem.period === 0 ||
//               newItem.offset === 0
//             )
//               currentArray.push(newItem);
//           }
//         }

//         if (functionValues?.length === 1) {
//           if (item.name === "Number") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             if (newItem.period || newItem.period === 0)
//               currentArray.push(newItem);
//           } else if (item.name === "Close") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               offset:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             if (newItem.offset || newItem.offset === 0)
//               currentArray.push(newItem);
//           } else if (
//             item.name === "Volume of a candle" ||
//             item.name === "OBV (On Balance Volume)" ||
//             item.name === "High price" ||
//             item.name === "Low price" ||
//             item.name === "Open price" ||
//             "VWAP (Volume Weighted Average Price)" ||
//             "Awesome Oscillator" ||
//             "TR (True Range)"
//           ) {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               offset:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             currentArray.push(newItem);
//           }
//         }

//         if (functionValues?.length === 2) {
//           const newItem = {
//             ...item,
//             function: functionName || null,
//             period:
//               functionValues?.length > 0
//                 ? parseInt(functionValues[0], 10)
//                 : null,
//             offset:
//               functionValues?.length > 0
//                 ? parseInt(functionValues[1], 10)
//                 : null,
//             ...comparatorObject,
//           };
//           // Push the modified item to the current array
//           currentArray.push(newItem);
//         }

//         if (functionValues?.length === 3) {
//           if (item.name === "Vortex Indicator") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               signal: functionValues?.length > 1 ? functionValues[1] : null,
//               offset:
//                 functionValues?.length > 2
//                   ? parseInt(functionValues[2], 10)
//                   : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             currentArray.push(newItem);
//           } else if (item.name === "Parabolic SAR") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               minAF:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               maxAF:
//                 functionValues?.length > 1 ? parseInt(functionValues[1]) : null,
//               offset:
//                 functionValues?.length > 2
//                   ? parseInt(functionValues[2], 10)
//                   : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             currentArray.push(newItem);
//           } else if (item.name === "Supertrend") {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               period:
//                 functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//               multiplier:
//                 functionValues?.length > 1 ? parseInt(functionValues[1]) : null,
//               offset:
//                 functionValues?.length > 2
//                   ? parseInt(functionValues[2], 10)
//                   : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             currentArray.push(newItem);
//           } else {
//             const newItem = {
//               ...item,
//               function: functionName || null,
//               field: functionValues.length > 0 ? functionValues[0] : null,
//               period:
//                 functionValues?.length > 1
//                   ? parseInt(functionValues[1], 10)
//                   : null,
//               offset:
//                 functionValues?.length > 2
//                   ? parseInt(functionValues[2], 10)
//                   : null,
//               ...comparatorObject,
//             };
//             // Push the modified item to the current array
//             currentArray.push(newItem);
//           }
//         }

//         if (item.name === "Ichimoku") {
//           const newItem = {
//             ...item,
//             function: functionName || null,
//             period1:
//               functionValues?.length > 0 ? parseInt(functionValues[0]) : null,
//             period2:
//               functionValues?.length > 0 ? parseInt(functionValues[1]) : null,
//             period3:
//               functionValues?.length > 0 ? parseInt(functionValues[2]) : null,
//             offset:
//               functionValues?.length > 0
//                 ? parseInt(functionValues[3], 10)
//                 : null,
//             offset:
//               functionValues?.length > 0
//                 ? parseInt(functionValues[4], 10)
//                 : null,
//             offset:
//               functionValues?.length > 0
//                 ? parseInt(functionValues[5], 10)
//                 : null,
//             offset:
//               functionValues?.length > 0
//                 ? parseInt(functionValues[6], 10)
//                 : null,
//             ...comparatorObject,
//           };

//           if (
//             newItem.period1 ||
//             newItem.period2 ||
//             newItem.period3 === 0 ||
//             newItem.offset === 0
//           )
//             currentArray.push(newItem);
//         }
//       }
//       console.log("ForC", currentArray);
//     });

//     console.log("wowow", currentArray);

//     // Push the remaining items in the current array
//     if (currentArray.length > 0) {
//       // Push a copy of currentArray to updatedArrays
//       updatedArrays.push(currentArray);
//       // console.log("currentArrayIMP--", currentArray, updatedArrays);
//     }

//     // console.log("merged>>", updatedArrays);
//     formData.functions = updatedArrays;
//     onFormChange(formData);
//    console.log("SCANNERDATA>>>", formData)
//     if (formData.isIncludeMathFunction || isIncludeMathFunction.current)
//       generateMathOutput(formData);
//   };
//   const handleNextEnteryBtn = () => {
//     getEntryText();

//     // console.log("functionText check", formData?.functionText1);

//     let text1 = handleReplacement(formData?.functionText1);
//     // console.log("formula>", formData?.functionsBox777, text1?.split("\n"));

//     // console.log(
//     //   "formula1>",
//     //   formData.functionsBox777,
//     //   text1
//     //     .split("\n")
//     //     .map((line) => line.trim())
//     //     .filter(
//     //       (line) =>
//     //         line !== "" &&
//     //         !line.startsWith("1minute") &&
//     //         !line.startsWith("3minute") &&
//     //         !line.startsWith("5minute") &&
//     //         !line.startsWith("10minute") &&
//     //         !line.startsWith("15minute") &&
//     //         !line.startsWith("30minute") &&
//     //         !line.startsWith("60minute") &&
//     //         !line.startsWith("1day")
//     //     )
//     // );

//     mergeArrays(
//       formData?.functionsBox777,
//       text1
//         .split("\n")
//         .map((line) => line.trim())
//         .filter(
//           (line) =>
//             line !== "" &&
//             !line?.startsWith("1minute") &&
//             !line?.startsWith("3minute") &&
//             !line?.startsWith("5minute") &&
//             !line?.startsWith("10minute") &&
//             !line?.startsWith("15minute") &&
//             !line?.startsWith("30minute") &&
//             !line?.startsWith("60minute") &&
//             !line?.startsWith("1day") &&
//             !line?.startsWith("week") &&
//             !line?.startsWith("month") &&
//             !line?.startsWith("2h") &&
//             !line?.startsWith("3h") &&
//             !line?.startsWith("4h")
//         )
//     );

//     // setMenu("review");
//   };

//   const getIndex = (index, instance) => {
//     setItemIndex(index);
//     setInstance(instance);
//   };

//   const generateMathOutput = (formData) => {
//     const outputData = [];
//     const items = Object.values(formData.indicators);

//     let currentGroup = [];
//     let encounteredAndOr = false;

//     const comparatorMapping = {
//       "higher than": ">",
//       "lower than": "<",
//       "higher than equal to": ">=",
//       "lower than equal to": "<=",
//       "crosses above": ">",
//       "crosses below": "<",
//     };

//     // Function to rename properties in the body array
//     const renameBodyProperties = (bodyArray) => {
//       return bodyArray.map((item) => {
//         const newItem = { ...item };
//         for (const key in newItem) {
//           if (key.includes("Field")) {
//             newItem.field = newItem[key];
//             // delete newItem[key]; // Optionally delete the old property
//           } else if (key.includes("Period")) {
//             newItem.period = newItem[key];
//             // delete newItem[key]; // Optionally delete the old property
//           } else if (key.includes("Offset")) {
//             newItem.offset = newItem[key];
//             // delete newItem[key]; // Optionally delete the old property
//           }
//         }
//         return newItem;
//       });
//     };

//     // console.log("items-----", items);
//     items.forEach((item) => {
//       if (item.name === "&&" || item.name === "||") {
//         // Push the current group of items into outputData
//         if (currentGroup?.length > 0) {
//           outputData.push(currentGroup);
//           currentGroup = []; // Reset currentGroup for the next group
//         }
//         // Push the "&&" or "||" item into a new array
//         outputData.push([item]);
//         encounteredAndOr = true;
//       } else {
//         // Add comparator property based on the item's name
//         if (comparatorMapping[item.name]) {
//           item.comparator = comparatorMapping[item.name];
//         }

//         // If the item has a body array, rename its properties
//         if (item.body && Array.isArray(item.body)) {
//           item.body = renameBodyProperties(item.body);
//         }
//         // Add the current item to currentGroup
//         currentGroup.push(item);
//       }
//     });

//     // If "&&" or "||" is not encountered, add the entire currentGroup to outputData
//     if (!encounteredAndOr) {
//       outputData.push(currentGroup);
//     } else {
//       // If "&&" or "||" is encountered, add the remaining currentGroup to outputData
//       if (currentGroup?.length > 0) {
//         outputData.push(currentGroup);
//       }
//     }
//     const formattedOutputData = outputData.map((group) => {
//       return group.map((item) => {
//         if (item.comparator) {
//           return {
//             ...item,
//             name: item?.name,
//             function: item?.name || null,
//             field: null,
//             period: null,
//             offset: null,
//             ...(item?.name === "crosses above"
//               ? { isCrossover: 2 }
//               : item?.name === "crosses below"
//               ? { isCrossover: 1 }
//               : {}),
//             comparator: getComparatorValue(item?.name),
//           };
//         }  else if (item.body) {
//           if (item.scripCode) {
//             return {
//               function_id: item.function_id,
//               function_type_id: item?.function_type_id,
//               body: item.body,
//               name : item?.name ,
//               scripCode: item.scripCode,
//             };
//           } else
//             return {
//               function_id: item.function_id,
//               function_type_id: item?.function_type_id,
//               period: parseInt(item.period, 10),
//               body: item.body,
//               name : item?.name ,
//               scripCode: item.scripCode,
//             };
//         } else if (item?.name === "&&" || item?.name === "||") {
//           return item;
//         }
//         else if(item?.operator) {
//           return item;
//         }else {
//           return createMathFunction(item);
//         }
//       });
//     });

//     function getComparatorValue(name) {
//       switch (name) {
//         case "crosses above":
//           return ">";
//         case "crosses below":
//           return "<";
//         case "higher than":
//           return ">";
//         case "lower than":
//           return "<";
//         case "lower than equal to":
//           return "<=";
//         case "higher than equal to":
//           return ">=";
//         case "equal to":
//           return "==";
//         default:
//           return null;
//       }
//     }
//     // Outputting the result in the expected format
//     // console.log(JSON.stringify(outputData));
//     if (formattedOutputData) {
//       formData.functions = formattedOutputData;
//     }
//     // console.log("MathIndicator>", formData);
//     onFormChange(formData);
//     // Use outputData in your React component as needed
//   };
//   function createMathFunction(item) {
//     let replaceObje = {
//       function_type_id: item?.function_type_id,
//       candleIntervalId: item?.candleIntervalId,
//     };
//     switch (item.name) {
//       case "Ulcer":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.ulcerField;
//         replaceObje.period = parseInt(item?.ulcerPeriod, 10);
//         replaceObje.offset = parseInt(item?.ulcerOffset, 10);
//         break;
//       case "TriMA (Triangular Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.tmaField;
//         replaceObje.period = parseInt(item?.tmaPeriod, 10);
//         replaceObje.offset = parseInt(item?.tmaOffset, 10);
//         break;
//       case "VWMA (Volume Weighted Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.Field = item?.vwmaField;
//         replaceObje.Period = parseInt(item?.vwmaPeriod, 10);
//         replaceObje.Offset = parseInt(item?.vwmaOffset, 10);
//         break;
//       case "DEMA (Double Exponential Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.field;
//         replaceObje.period = parseInt(item?.period, 10);
//         replaceObje.offset = parseInt(item?.offset, 10);
//         break;
//       case "TEMA (Triple Exponential Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.Field = item?.temaField;
//         replaceObje.Period = parseInt(item?.temaPeriod, 10);
//         replaceObje.Offset = parseInt(item?.temaOffset, 10);
//         break;
//       case "Open price":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.period,10);
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;
//       case "Low price":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.offset = parseInt(item?.lowOffset, 10);
//         break;
//       case "High price":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;
//       case "Close":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.closeOffset = parseInt(item?.closeOffset,10);
//         break;
//       case "SMA (Simple Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.field;
//         replaceObje.period = parseInt(item?.period,10);
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;
//       case "WMA (Weighted Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.wmaField;
//         replaceObje.period = parseInt(item?.wmaPeriod,10);
//         replaceObje.offset = parseInt(item?.wmaOffset,10);
//         break;
//       case "EMA (Exponential Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.field;
//         replaceObje.period = parseInt(item?.period,10);
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;
//       case "Money Flow Index":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.Period = parseInt(item?.mfiPeriod,10);
//         replaceObje.Offset = parseInt(item?.mfiOffset,10);
//         break;
//       case "Williams %R":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.Offset = parseInt(item?.wrOffset,10);
//         replaceObje.Period = parseInt(item?.wrPeriod,10);
//         break;
//       case "Moving Average Deviation (MA Dev)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.Field = item?.madField;
//         replaceObje.Period = parseInt(item?.madPeriod,10);
//         replaceObje.Type = item?.madType;
//         replaceObje.Offset = parseInt(item?.madOffset,10);
//         replaceObje.Pop = item?.madPop;
//         break;
//       case "MACD (Moving Average Convergence/Divergence)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.MaPeriod = parseInt(item?.macd_fastMaPeriod,10);
//         replaceObje.macd_slowMaPeriod = parseInt(item?.macd_slowMaPeriod,10);
//         replaceObje.macd_signalPeriod = parseInt(item?.macd_signalPeriod,10);
//         replaceObje.macdOffset = parseInt(item?.macdOffset,10);
//         break;
//       case "OBV (On Balance Volume)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.offset = parseInt(item?.obvOffset,10);
//         break;
//       case "Aroon-Down":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.aroonDownPeriod, 10);
//         replaceObje.offset = parseInt(item?.aroonDownOffset,10);
//         break;
//       case "Aroon-Up":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.aroonUpPeriod,10);
//         replaceObje.offset = parseInt(item?.aroonUpOffset,10);
//         break;
//       case "Awesome Oscillator":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.offset = item?.aOscilatorOffset;
//         break;
//       case "VWAP (Volume Weighted Average Price)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.offset = parseInt(item?.vwapOffset,10);
//         break;
//       case "Parabolic SAR":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.minAF = parseInt(item?.psarMinimumAF,10);
//         replaceObje.maxAF = parseInt(item?.psarMaximumAF,10);
//         replaceObje.offset = parseInt(item?.psarOffset,10);
//         break;
//       case "Vortex Indicator":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.vortexIPeriod,10);
//         replaceObje.signal = item?.vortexISignal;
//         replaceObje.offset = parseInt(item?.vortexIOffset,10);
//         break;
//       case "Number":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.period,10);
//         break;
//       case "Volume of a candle":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.volume = item?.volume;
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;
//       case "Donchian Channel":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.channel = item?.donchianChannel;
//         replaceObje.period = parseInt(item?.donchianHighPeriod,10);
//         replaceObje.period1 = parseInt(item?.donchianLowPeriod,10);
//         replaceObje.offset = parseInt(item?.donchianOffset,10);
//         break;
//       case "Stochastic":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.stochasticPeriod,10);
//         replaceObje.type = item?.stochasticType;
//         replaceObje.smooth = parseInt(item?.stochasticSmooth,10);
//         replaceObje.offset = parseInt(item?.stochasticOffset,10);
//         break;
//       case "Chaikin Money Flow":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.chaikinPeriod,10);
//         replaceObje.offset = parseInt(item?.chaikinOffset,10);
//         break;
//       case "MACD-signal":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period1 = parseInt(item?.macdsFastMaPeriod,10);
//         replaceObje.period2 = parseInt(item?.macdsSlowMaPeriod,10);
//         replaceObje.period3 = parseInt(item?.macdsSignalPeriod,10);
//         replaceObje.offset = parseInt(item?.macdsOffset,10);
//         break;
//       case "Commodity Channel Index":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.cciPeriod,10);
//         replaceObje.offset = parseInt(item?.cciOffset,10);
//         break;
//       case "Price Rate of Change":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.rocField;
//         replaceObje.period = parseInt(item?.rocPeriod,10);
//         replaceObje.offset = parseInt(item?.rocOffset,10);
//         break;
//       case "RSI (Relative Strength Index)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.rsiPeriod,10);
//         replaceObje.offset = parseInt(item?.rsiOffset,10);
//         break;
//       case "RSI Moving Average":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.rsimaPeriod,10);
//         replaceObje.maType = item?.rsimaType;
//         replaceObje.maPeriod = parseInt(item?.rsimaMaPeriod,10);
//         replaceObje.offset = parseInt(item?.rsimaOffset,10);
//         break;
//       case "Supertrend":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.superTPeriod,10);
//         replaceObje.multiply = parseInt(item?.superTMultiply,10);
//         replaceObje.offset = parseInt(item?.superTOffset,10);
//         break;
//       case "TR (True Range)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.offset = parseInt(item?.trOffset,10);
//         break;
//       case "ATR (Average True Range)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.atrPeriod,10);
//         replaceObje.offset = parseInt(item?.atrOffset,10);
//         break;
//       case "NATR (Normalized Average True Range)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.natrPeriod,10);
//         replaceObje.offset = parseInt(item?.natrOffset,10);
//         break;
//       case "Bollinger %B":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.bolingerBField;
//         replaceObje.period = parseInt(item?.bolingerBPeriod,10);
//         replaceObje.deviation = item?.bolingerBDev;
//         replaceObje.type = item?.bolingerBType;
//         replaceObje.offset = parseInt(item?.bolingerBOffset,10);
//         break;
//       case "MFI Moving Average (Money Flow Index Moving Average)":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.mfiMAPeriod,10);
//         replaceObje.maPeriod = parseInt(item?.mfiMAMAPeriod,10);
//         replaceObje.maType = item?.mfiMAType;
//         replaceObje.offset = parseInt(item?.mfiMAOffset,10);
//         break;
//       case "Know Sure Thing":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.field = item?.kstField;
//         replaceObje.LTRoc = item?.kstLTRoc;
//         replaceObje.LTSma = item?.kstLTSma;
//         replaceObje.Roc = item?.kstRoc;
//         replaceObje.LSma = item?.kstLSma;
//         replaceObje.HRoc = item?.kstHRoc;
//         replaceObje.HSma = item?.kstHSma;
//         replaceObje.HeRoc = item?.kstHeRoc;
//         replaceObje.HeSma = item?.kstHeSma;
//         replaceObje.SPeriod = parseInt(item?.kstSPeriod,10);
//         replaceObje.Type = item?.kstType;
//         replaceObje.offset = parseInt(item?.kstOffset,10);
//         break;

//       case "Ichimoku":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.Conversion = item?.ichiConversion;
//         replaceObje.Base = item?.ichiBase;
//         replaceObje.leadingSpanB = item?.leadingSpanB;
//         replaceObje.laggingSpan = item?.laggingSpan;
//         replaceObje.Line = item?.ichiLine;
//         replaceObje.cloudShift = item?.cloudShift;
//         replaceObje.offset = parseInt(item?.ichiOffset,10);
//         break;

//       case "MOM (Momentum Indicator)":
//         console.log("MOM2first",item);
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.period,10);
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;

//       case "CHANDE MOMENTUM OSCILLATOR":
//         replaceObje.function_id = item?.function_id;
//         replaceObje.name = item.name;
//         replaceObje.period = parseInt(item?.period,10);
//         replaceObje.offset = parseInt(item?.offset,10);
//         break;
//     }
//     return replaceObje;
//   }
//   const RemoveFormula=()=>{
//     {
//       let ans=window.confirm("Are you sure, you want to remove formula?");
//     if(ans===true)
//     {
//       setFlag(0);
//       setRemove(true);
//       onFormChange((prevState) => ({
//         ...prevState,
//         functions: [],
//         functionsBox: [],
//         functionsBox2: [],
//         functionsBox777: [],
//         functionsMainBox: [],
//         indicators: {},
//         mode: "",
//         selectedOption1: "",
//         order: [],
//         isIncludeMathFunction: false,
//       }));
//       handleSetInstead();
//       isIncludeMathFunction.current = false;
//      }
//     }
//   }
//   return (
//     <div className="row" id="scanner-condition-tab">
//       <div className=" mt-5 pb-2 d-flex align-items-center justify-content-between">
//         <div>
//           <h6 className="fw-bolder">Entry Condition</h6>
//         </div>
//         <div>
//         </div>
//       </div>
//       <div className="input-group mb-3" id="tab-function-type-dropdown">
//         <div className="d-flex row" style={{ minWidth: "300px" }}>
//           {/* Input box */}
//           <div
//             // ref={conditionDropdownRef}
//             style={{
//               display: "flex",
//               justifyContent: "space-around",
//               alignItems: "center",
//             }}
//           >
//             <Form.Control
//               type="text"
//               placeholder="What would you like to add?"
//               className="strategy-entry-search"
//               onClick={(event) => {
//                 event.stopPropagation();
//                 toggleDropdown();
//               }}
//               value={searchQuery}
//               onChange={handleSearch}
//             />
//             <span
//               className="close-icon"
//               onClick={() => {
//                 setSearchQuery("");
//                 setShowDropdown(false);
//               }}
//               style={{ cursor: "pointer", marginLeft: "-25px" }}
//             >
//               X
//             </span>
//           </div>
//           {/* Dropdown menu */}
//           {showDropdown && (
//               <ListGroup style={{ maxHeight: "300px", overflowY: "auto" }}>
//                 <ListGroup.Item>
//                   {showInstead.function_type_id == 3 &&
//                   (formData.functionsBox777[
//                     formData.functionsBox777?.length - 1
//                   ]?.function_type_id == 3 ||
//                     isSelectMax ||
//                     formData.selectedOption3 === "Max1" ||
//                     formData.selectedOption3 === "Min1" ||
//                     removedInsideMath) ? (
//                     <ListGroup.Item
//                       onClick={() => {
//                         handleClickAll("3");
//                       }}
//                       className="m-1 btn-color"
//                       style={
//                         showInstead.function_type_id == 3
//                           ? selectedItemStyle
//                           : { cursor: "pointer" }
//                       }
//                     >
//                       Indicators
//                     </ListGroup.Item>
//                   ) : showInstead.function_type_id === 2 ? (
//                     <ListGroup.Item
//                       onClick={() => {
//                         handleClickAll("2");
//                       }}
//                       className="m-1 btn-color"
//                       style={
//                         showInstead.function_type_id == 2
//                           ? selectedItemStyle
//                           : { cursor: "pointer" }
//                       }
//                     >
//                       Math Functions
//                     </ListGroup.Item>
//                   ) : showInstead.function_type_id === 5 ? (
//                     <ListGroup.Item
//                       onClick={() => {
//                         handleClickAll("5");
//                       }}
//                       className="m-1 btn-color"
//                       style={
//                         selectedItem === "5"
//                           ? selectedItemStyle
//                           : { cursor: "pointer" }
//                       }
//                     >
//                       Comparators
//                     </ListGroup.Item>
//                   ) : showInstead.function_type_id === 4 ? (
//                     <ListGroup.Item
//                       onClick={() => {
//                         handleClickAll("4");
//                       }}
//                       className="m-1 btn-color"
//                       style={
//                         selectedItem === "4"
//                           ? selectedItemStyle
//                           : { cursor: "pointer" }
//                       }
//                     >
//                       Math Operators
//                     </ListGroup.Item>
//                   ) : showInstead.function_type_id === 6 ? (
//                     <ListGroup.Item
//                       onClick={() => {
//                         handleClickAll("6");
//                       }}
//                       className="m-1 btn-color"
//                       style={
//                         selectedItem === "6"
//                           ? selectedItemStyle
//                           : { cursor: "pointer" }
//                       }
//                     >
//                       Logical Operators
//                     </ListGroup.Item>
//                   ) : (
//                     <ListGroup horizontal className="tab-function-type">
//                       {!remove ? (
//                         <>
//                           {formData?.functionsBox777[
//                             formData?.functionsBox777?.length - 1
//                           ]?.function_type_id !== 3 &&
//                             formData?.functionsBox777[
//                               formData?.functionsBox777?.length - 1
//                             ]?.function_type_id !== 2 &&
//                             formData?.functionsBox777[
//                               formData?.functionsBox777?.length - 1
//                             ]?.function_type_id !== 5 && (
//                               <ListGroup.Item
//                                 onClick={() => {
//                                   handleClickAll("1");
//                                 }}
//                                 className="m-1 btn-color"
//                                 style={
//                                   selectedItem === "1"
//                                     ? selectedItemStyle
//                                     : { cursor: "pointer" }
//                                 }
//                               >
//                                 All
//                               </ListGroup.Item>
//                             )}
//                           {formData.functionsBox777[
//                             formData.functionsBox777?.length - 1
//                           ]?.function_type_id !== 3 &&
//                             formData.functionsBox777[
//                               formData.functionsBox777?.length - 1
//                             ]?.function_type_id !== 2 && (
//                               <ListGroup.Item
//                                 onClick={() => {
//                                   handleClickAll("2");
//                                 }}
//                                 className="m-1 btn-color"
//                                 style={
//                                   selectedItem === "2"
//                                     ? selectedItemStyle
//                                     : { cursor: "pointer" }
//                                 }
//                               >
//                                 Math Functions
//                               </ListGroup.Item>
//                             )}
//                           {(formData.functionsBox777[
//                             formData.functionsBox777?.length - 1
//                           ]?.function_type_id !== 3 ||
//                             isSelectMax ||
//                             formData.selectedOption3 === "Max1" ||
//                             formData.selectedOption3 === "Min1" ||
//                             removedInsideMath) && (
//                             <ListGroup.Item
//                               onClick={() => {
//                                 handleClickAll("3");
//                               }}
//                               className="m-1 btn-color"
//                               style={
//                                 selectedItem === "3"
//                                   ? selectedItemStyle
//                                   : { cursor: "pointer" }
//                               }
//                             >
//                               Indicators
//                             </ListGroup.Item>
//                           )}
//                           <ListGroup.Item
//                             onClick={() => {
//                               handleClickAll("4");
//                             }}
//                             className="m-1 btn-color"
//                             style={
//                               selectedItem === "4"
//                                 ? selectedItemStyle
//                                 : { cursor: "pointer" }
//                             }
//                           >
//                             Math Operators
//                           </ListGroup.Item>
//                           {formData.functionsBox777[
//                             formData.functionsBox777?.length - 1
//                           ]?.function_type_id !== 5 && (
//                             <ListGroup.Item
//                               onClick={() => {
//                                 handleClickAll("5");
//                               }}
//                               className="m-1 btn-color"
//                               style={
//                                 selectedItem === "5"
//                                   ? selectedItemStyle
//                                   : { cursor: "pointer" }
//                               }
//                             >
//                               Comparators
//                             </ListGroup.Item>
//                           )}
//                           <ListGroup.Item
//                             onClick={() => {
//                               handleClickAll("6");
//                             }}
//                             className="m-1 btn-color"
//                             style={
//                               selectedItem === "6"
//                                 ? selectedItemStyle
//                                 : { cursor: "pointer" }
//                             }
//                           >
//                             Logical Operators
//                           </ListGroup.Item>
//                         </>
//                       ) : (
//                         formData?.mode === "Function" &&
//                         !remove && (
//                           <ListGroup.Item
//                             onClick={() => {
//                               handleClickAll("3");
//                             }}
//                             className="m-1 btn-color"
//                           >
//                             Indicators
//                           </ListGroup.Item>
//                         )
//                       )}
//                     </ListGroup>
//                   )}
//                 </ListGroup.Item>
//                 {showInstead.isOpen
//                   ? functionType
//                       ?.filter(
//                         (item) =>
//                           item.function_type_id ===
//                             showInstead.function_type_id &&
//                           item.name
//                             .toLowerCase()
//                             .includes(searchQuery.toLowerCase())
//                       )
//                       .map((item) => (
//                         <ListGroup.Item
//                           key={item?.id}
//                           onClick={() => {
//                             handleGetFunction1(item);
//                             setTimeout(() => {
//                               getEntryText();
//                             }, 1000);
//                           }}
//                         >
//                           {item?.name}
//                         </ListGroup.Item>
//                       ))
//                   : functionType
//                       ?.filter((item) =>
//                         item.name
//                           .toLowerCase()
//                           .includes(searchQuery.toLowerCase())
//                       )
//                       .map((item) => (
//                         <ListGroup.Item
//                           key={item?.id}
//                           onClick={() => {
//                             handleGetFunction(item);
//                             setTimeout(() => {
//                               getEntryText();
//                             }, 1000);
//                           }}
//                         >
//                           {item?.name}
//                         </ListGroup.Item>
//                       ))}

//                 {functionType?.length === 0 && (
//                   <ListGroup.Item>No data available</ListGroup.Item>
//                 )}
//               </ListGroup>
//             )}
//         </div>
//       </div>
//       {formData?.conditionTab ? (
//         <div className="row" id="strategy-entry-condition-card">
//           <div className="col-lg-12 p-0">
//             <Card>
//               <Card.Body>
//                 <div className="row">
//                   <div
//                     className="col-lg-12 p-3"
//                     id="strategyBox"
//                     style={{
//                       display: "contents",
//                       // alignItems: "center",
//                       // width: "100%",
//                       // flexWrap: "wrap",
//                       // display: "flex",
//                     }}
//                   >
//                     <FormulaTab
//                       formData={formData}
//                       handleRemoveButtonClick={handleRemoveButtonClick}
//                       setIsShow={setIsShow}
//                       setIsShow3={setIsShow3}
//                       onFormChange={onFormChange}
//                       handleButtonClick={handleButtonClick}
//                       setShowDropdown={setShowDropdown}
//                       setInnerAdd={setInnerAdd}
//                       setOuterAdd={setOuterAdd}
//                       handleAddButtonClick={handleAddButtonClick}
//                       outerAdd={outerAdd}
//                       setShowClose={setShowClose}
//                       setShowOpen={setShowOpen}
//                       setShowLowPrice={setShowLowPrice}
//                       setShowHighPrice={setShowHighPrice}
//                       setShowUlcer={setShowUlcer}
//                       setShowDEMA={setShowDEMA}
//                       setShowTEMA={setShowTEMA}
//                       setShowTMA={setShowTMA}
//                       setShowVWMA={setShowVWMA}
//                       setShowEMA={setShowEMA}
//                       setShowSMA={setShowSMA}
//                       setShowNumber={setShowNumber}
//                       setShowMoneyFlowIndex={setShowMoneyFlowIndex}
//                       setShowWilliams={setShowWilliams}
//                       setShowMad={setShowMad}
//                       setShowMOM={setShowMOM}
//                       setShowObv={setShowObv}
//                       setShowAroonDown={setShowAroonDown}
//                       setShowAroonUp={setShowAroonUp}
//                       setShowOscilator={setShowOscilator}
//                       setShowVWAP={setShowVWAP}
//                       setShowpsar={setShowpsar}
//                       setShowVortexI={setShowVortexI}
//                       setShowVolume={setShowVolume}
//                       setShowDonchian={setShowDonchian}
//                       setShowStochastic={setShowStochastic}
//                       setShowChaikin={setShowChaikin}
//                       setShowMacdSignal={setShowMacdSignal}
//                       setShowCCI={setShowCCI}
//                       setShowRoc={setShowRoc}
//                       setShowRSI={setShowRSI}
//                       setShowRSIMA={setShowRSIMA}
//                       setShowMacd={setShowMacd}
//                       setShowBB={setShowBB}
//                       setShowTR={setShowTR}
//                       setShowATR={setShowATR}
//                       setShowNATR={setShowNATR}
//                       setShowSuperT={setShowSuperT}
//                       setShowMfiMa={setShowMfiMa}
//                       setShowKST={setShowKST}
//                       setShowIchimoku={setShowIchimoku}
//                       setShowCandleModal={setShowCandleModal}
//                       getIndex={getIndex}
//                       setShowWMA={setShowWMA}
//                       allStocks={storeAllStocks}
//                       setAddInstead={setAddInstead}
//                       showInstead={showInstead}
//                       handleEdit={handleEdit}
//                       handleAdd={handleAdd}
//                       setPeriodMaxModal={setPeriodMaxModal}
//                       setPeriodMinModal={setPeriodMinModal}
//                     />
//                     &nbsp; &nbsp;
//                     {formData.isIncludeMathFunction && (
//                       <button
//                         onClick={() => {
//                           setShowDropdown(true);
//                           setOuterAdd(true);
//                         }}
//                         style={addButton}
//                       >
//                         {"Add"}
//                       </button>
//                     )}
//                   </div>
//                 </div>
//               </Card.Body>
//             </Card>
//             <div
//               className="row"
//               style={{ marginTop: "12px", marginBottom: "16px" }}
//             >
//               <div className="col-lg-12 d-flex flex-row-reverse">
//                 {/* <span className="text-muted">Disabled</span> &ensp;
//                 <span className="text-muted">Copy</span>&ensp; */}
//                 <span
//                   className="text-danger"
//                   style={{ cursor: "pointer" }}
//                   onClick={() => RemoveFormula()}
//                 >
//                   Remove
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         // {/* <Form.Control type="text" placeholder="what would you like to add?"  className="search w-25" /> */}
//         <div className="box my-3">
//           <div className="text-center">
//             <div className="filecircle mx-auto">
//               <i className="fa-regular fa-file"></i>
//             </div>
//             <h5 className="fw-bolder text-dark pt-3 pb-1">Add a condition</h5>
//             <p className="para">
//               Add an entity condition by searching <br></br>for indicators and
//               comparators
//             </p>
//           </div>
//         </div>
//       )}
//       <CompartorModal
//         showSMA={showSMA3}
//         setIsShow={setIsShow3}
//         handleSMAClickedPage={handleSMAComparator}
//         type={formData?.ctype}
//         typeName={formData?.selectedOption2}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Ulcer
//         show={showUlcer}
//         setIsShow={setShowUlcer}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <TriMA
//         show={showTMA}
//         setIsShow={setShowTMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Vwma
//         show={showVWMA}
//         setIsShow={setShowVWMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <MOM
//         show={showMOM}
//         setIsShow={setShowMOM}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <DEMA
//         show={showDEMA}
//         setIsShow={setShowDEMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <TEMA
//         show={showTEMA}
//         setIsShow={setShowTEMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Open
//         show={showOpen}
//         setIsShow={setShowOpen}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Low
//         show={showLowPrice}
//         setIsShow={setShowLowPrice}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <HighPrice
//         show={showHighPrice}
//         setIsShow={setShowHighPrice}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Close
//         show={showClose}
//         setIsShow={setShowClose}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <EMA
//         show={showEMA}
//         setIsShow={setShowEMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         handleRemoveButtonClick={handleRemoveButtonClick}
//         index={itemIndex}
//         instance={instance}
//       />
//       <SMA
//         show={showSMA1}
//         setIsShow={setShowSMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <WMA
//         show={showWMA}
//         setIsShow={setShowWMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Number
//         show={showNumber}
//         setIsShow={setShowNumber}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <MoneyFlowIndex
//         show={showMoneyFlowIndex}
//         setIsShow={setShowMoneyFlowIndex}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <WilliamsR
//         show={showWilliams}
//         setIsShow={setShowWilliams}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <MovingAverageDeviation
//         show={showMad}
//         setIsShow={setShowMad}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <MACD
//         show={showMacd}
//         setIsShow={setShowMacd}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <OBV
//         show={showObv}
//         setIsShow={setShowObv}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <AroonDown
//         show={showAroonDown}
//         setIsShow={setShowAroonDown}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <AroonUp
//         show={showAroonUp}
//         setIsShow={setShowAroonUp}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <AwesomeOscillator
//         show={showOscilator}
//         setIsShow={setShowOscilator}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <VWAP
//         show={showVWAP}
//         setIsShow={setShowVWAP}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <ParabolicSAR
//         show={showpsar}
//         setIsShow={setShowpsar}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <VortexIndicator
//         show={showVortexI}
//         setIsShow={setShowVortexI}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Volume
//         show={showVolume}
//         setIsShow={setShowVolume}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <DonchianChannel
//         show={showDonchian}
//         setIsShow={setShowDonchian}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Stochastic
//         show={showStochastic}
//         setIsShow={setShowStochastic}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <ChaikinMoneyFlow
//         show={showChaikin}
//         setIsShow={setShowChaikin}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <CCI
//         show={showCCI}
//         setIsShow={setShowCCI}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <PriceRateofChange
//         show={showRoc}
//         setIsShow={setShowRoc}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <MACDsignal
//         show={showMacdSignal}
//         setIsShow={setShowMacdSignal}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <RSI
//         show={showRSI}
//         setIsShow={setShowRSI}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <RSIMovingAverage
//         show={showRSIMA}
//         setIsShow={setShowRSIMA}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <BollingerB
//         show={showBB}
//         setIsShow={setShowBB}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <TR
//         show={showTR}
//         setIsShow={setShowTR}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <ATR
//         show={showATR}
//         setIsShow={setShowATR}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <NATR
//         show={showNATR}
//         setIsShow={setShowNATR}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Supertrend
//         show={showSuperT}
//         setIsShow={setShowSuperT}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <MfiMovingAverage
//         show={showMfiMA}
//         setIsShow={setShowMfiMa}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <KnowSureThing
//         show={showKST}
//         setIsShow={setShowKST}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <Ichimoku
//         show={showIchimoku}
//         setIsShow={setShowIchimoku}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />

//       <CandleInterval
//         show={showCandleModal}
//         setIsShow={setShowCandleModal}
//         handleModalData={handleCandleModal}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         instance={instance}
//       />
//       <PeriodMaxModal
//         setIsShow={setPeriodMaxModal}
//         showPeriodMaxModal={showPeriodMaxModal}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//       <PeriodMinModal
//         setIsShow={setPeriodMinModal}
//         showPeriodMinModal={showPeriodMinModal}
//         handleModalData={handleModalData}
//         mainData={formData}
//         clickedFunction={formData?.clickedFunction}
//         index={itemIndex}
//         instance={instance}
//       />
//     </div>
//   );
// };

// export default ScannerConditionComponent;
import React, { useState, useRef, useEffect } from "react";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { getfunctionTypeWisefunction } from "../../api/strategyapi";
import FormulaTab from "../Strategies/FormulaTab";
import CompartorModal from "../Strategies/ComparatorModal";
import Ulcer from "../modal/Ulcer";
import TriMA from "../modal/TriMA";
import Vwma from "../modal/Vwma";
import DEMA from "../modal/DEMA";
import TEMA from "../modal/TEMA";
import Open from "../modal/Open";
import Low from "../modal/Low";
import Close from "../modal/Close";
import HighPrice from "../modal/HighPrice";
import EMA from "../modal/EMA";
import SMA from "../modal/SMA";
import Number from "../modal/Number";
import MoneyFlowIndex from "../modal/MoneyFlowIndex";
import WilliamsR from "../modal/WilliamsR";
import MovingAverageDeviation from "../modal/MovingAverageDeviation";
import MACD from "../modal/MACD";
import OBV from "../modal/OBV";
import MOM from "../modal/MOM";
import AroonDown from "../modal/AroonDown";
import AroonUp from "../modal/AroonUp";
import ParabolicSAR from "../modal/ParabolicSAR";
import VortexIndicator from "../modal/VortexIndicator";
import DonchianChannel from "../modal/DonchianChannel";
import Stochastic from "../modal/Stochastic";
import ChaikinMoneyFlow from "../modal/ChaikinMoneyFlow";
import CCI from "../modal/CommodityChannelIndex";
import PriceRateofChange from "../modal/PriceRateofChange";
import MACDsignal from "../modal/MACDsignal";
import AwesomeOscillator from "../modal/AwesomeOscillator";
import VWAP from "../modal/VWAP";
import Volume from "../modal/Volume";
import moment from "moment";
import RSI from "../modal/RSI";
import RSIMovingAverage from "../modal/RSIMovingAverage";
import BollingerB from "../modal/BollingerB";
import TR from "../modal/TR";
import ATR from "../modal/ATR";
import NATR from "../modal/NATR";
import Supertrend from "../modal/Supertrend";
import MfiMovingAverage from "../modal/MfiMovingAverage";
import KnowSureThing from "../modal/KnowSureThing";
import Ichimoku from "../modal/Ichimoku";
import CandleInterval from "../modal/CandleInterval";
import WMA from "../modal/WMA";
import PeriodMaxModal from "../modal/PeriodMaxModal";
import PeriodMinModal from "../modal/PeriodMinModal";
import indicatorsSwitchCase from "../../Utils/indicatorsSwitchCase";
import {
  helperModalReplaceFn777,
  helperModalSwitchFn,
  helperModalSwitchForMath,
} from "../../Utils/helperModalSwitchFn";
import {
  calculateAdjustedIndex,
  filterFunctionsBox777,
} from "../../Utils/indextoRemoveMath";
import replaceNewData from "../../Utils/replaceNewData";
import formateFnObject from "../../Utils/formateFnObject";
import generateMathOutput from "../../Utils/CommonFunction/generateMathOutput";
import getCandleId from "../../Utils/CommonFunction/getCandleId";
import updateHandleModalData from "../../Utils/CommonFunction/handleModalData";
import GetFunctionofHandle from "../../Utils/CommonFunction/GetFunctionofHandle";
import handleRemoveCondition from "../../Utils/CommonFunction/handleRemoveCondition";
import FunctionList from "../componentCommon/FunctionList";
import mergeArrays from "../../Utils/CommonFunction/mergeArrays";
import VwapStandarddeviation from "../modal/VwapStandarddeviation";
import UltimateOscillator from "../modal/UltimateOscillator";

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};

let eflag = 0;

const ScannerConditionComponent = ({
  formData,
  onFormChange,
  allStocks,
  getText,
}) => {
  const { menu, setMenu, storeAllStocks } = useMenuContextScanner();
  const divRef = useRef(null);
  const conditionDropdownRef = useRef(null);
  const [flag, setFlag] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [functionType, setFunctionType] = useState([]);
  const [showSMA, setIsShow] = useState(false);
  const [showSMA2, setIsShow2] = useState(false);
  const [showSMA3, setIsShow3] = useState(false);
  const [conditionTab, setConditionTab] = useState(false);
  const [mode, setMode] = useState("");
  const [remove, setRemove] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [currentId, setCurrentId] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [innerAdd, setInnerAdd] = useState("");
  const [outerAdd, setOuterAdd] = useState("");
  //Modal state variables
  const [showUlcer, setShowUlcer] = useState(false);
  const [showTMA, setShowTMA] = useState(false);
  const [showVWMA, setShowVWMA] = useState(false);
  const [showDEMA, setShowDEMA] = useState(false);
  const [showTEMA, setShowTEMA] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [showLowPrice, setShowLowPrice] = useState(false);
  const [showHighPrice, setShowHighPrice] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showSMA1, setShowSMA] = useState(false);
  const [showEMA, setShowEMA] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [showMoneyFlowIndex, setShowMoneyFlowIndex] = useState(false);
  const [showWilliams, setShowWilliams] = useState(false);
  const [showMad, setShowMad] = useState(false);
  const [showMacd, setShowMacd] = useState(false);
  const [showObv, setShowObv] = useState(false);
  const [showOscilator, setShowOscilator] = useState(false);
  const [showAroonUp, setShowAroonUp] = useState(false);
  const [showAroonDown, setShowAroonDown] = useState(false);
  const [showpsar, setShowpsar] = useState(false);
  const [showVortexI, setShowVortexI] = useState(false);
  const [showDonchian, setShowDonchian] = useState(false);
  const [showStochastic, setShowStochastic] = useState(false);
  const [showChaikin, setShowChaikin] = useState(false);
  const [showCCI, setShowCCI] = useState(false);
  const [showRoc, setShowRoc] = useState(false);
  const [showMacdSignal, setShowMacdSignal] = useState(false);
  const [showVWAP, setShowVWAP] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [showRSI, setShowRSI] = useState(false);
  const [showRSIMA, setShowRSIMA] = useState(false);
  const [showBB, setShowBB] = useState(false);
  const [showTR, setShowTR] = useState(false);
  const [showATR, setShowATR] = useState(false);
  const [showNATR, setShowNATR] = useState(false);
  const [showSuperT, setShowSuperT] = useState(false);
  const [showMfiMA, setShowMfiMa] = useState(false);
  const [showKST, setShowKST] = useState(false);
  const [showIchimoku, setShowIchimoku] = useState(false);
  const [showWMA, setShowWMA] = useState(false);
  const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
  const [showPeriodMinModal, setPeriodMinModal] = useState(false);
  const [showCandleModal, setShowCandleModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmRemove, setconfirmRemove] = useState(false);
  const [isSelectMax, setIsSelectMax] = useState(false);
  const [isSelectMin, setIsSelectMin] = useState(false);
  const [showStdDeviation, setisStdDeviation] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [instance, setInstance] = useState();
  const [removedInsideMath, setRemovedInsideMath] = useState(false);
  const [showMOM, setShowMOM] = useState(false);
  const isIncludeMathFunction = useRef(null);
  const [showUltimateOscillator, setShowUltimateOscillator] = useState(false);
  const [mathIndexRemove, setMathIndexRemove] = useState(-1);

  const selectedItemStyle = {
    backgroundColor: "#00aeee", // Customize the background color as needed
    fontWeight: "bold", // Add additional styles if needed
    border: "1px solid blue",
  };
  console.log("SCANNERDATA", formData, onFormChange);
  const [showInstead, setAddInstead] = useState({
    function_id: 0,
    function_type_id: 0,
    isOpen: false,
    index: -1,
  });
  const handleEdit = (instanceData, index) => {
    console.log("instanceData", instanceData, index);
    setAddInstead({
      function_id: instanceData?.function_id,
      function_type_id: instanceData?.function_type_id,
      isOpen: true,
      index: index,
    });

    // call dropdown function particular type id //
    handleClickAll(instanceData?.function_type_id?.toString());
  };

  const handleGetFunction1 = (item) => {
    console.log("click", item, formData);
    setSearchQuery("");
    setShowDropdown(false);
    let replaceData = replaceNewData(formData, item, showInstead);
    formData = replaceData;
    // console.log("REPLACE", formData )
    if (replaceData) {
      setAddInstead({
        function_id: 0,
        function_type_id: 0,
        isOpen: false,
        index: -1,
      });
    }
  };
  useEffect(() => {
    // console.log("33first",formData);
  }, [formData]);
  const handleSetInstead = () => {
    setAddInstead({
      function_id: 0,
      function_type_id: 0,
      isToggle: false,
    });
  };
  const handleRemiceInstead = () => {
    setAddInstead((prev) => ({ ...prev, isOpen: false }));
  };
  const handleGetFunction = (item) => {
    console.log("GETFUN", item);
    GetFunctionofHandle(
      item,
      formData,
      onFormChange,
      setInnerAdd,
      setOuterAdd,
      handleRemiceInstead,
      setCurrentId,
      setConditionTab,
      handleClickAll,
      setSelectedOption1,
      setMode,
      setIsSelectMax,
      setIsSelectMin,
      setSearchQuery,
      setShowDropdown,
      setRemovedInsideMath,
      removedInsideMath,
      mathIndexRemove,
      flag,
      setFlag,
      outerAdd,
      isIncludeMathFunction,
      innerAdd,
      instance
    );
  };

  const handleCandleModal = (data) => {
    console.log("IntervalData", data);
    if (data?.candleIntervalId && formData)
      formData.indicators[formData.clickedFunction].candleIntervalId =
        data?.candleIntervalId;

    formData.functionsBox777[itemIndex].candleIntervalId =
      data.candleIntervalId;

    if (formData?.indicators[formData?.clickedFunction]?.body)
      formData.indicators[formData?.clickedFunction].body[
        itemIndex
      ].candleIntervalId = data?.candleIntervalId;

    console.log("INTERVAL", formData);
    onFormChange(formData);
    handleNextEnteryBtn();
  };

  useEffect(() => {
    if (eflag === 1) {
      updateCandleIntervalId();
      updateCandleInterval();
    }
    handleClickAll("1");
  }, [formData?.candleIntervalId]);
  useEffect(() => {
    handleNextEnteryBtn();
  }, [getText]);
  const updateCandleInterval = () => {
    // Create a copy of the original data
    let updatedData = [];
    if (Array.isArray(formData?.functionsBox777)) {
      updatedData = [...formData.functionsBox777];
    } else {
      console.log("undefined formData.functionsBox777");
    }
    let candleId = getCandleId(formData);

    updatedData.forEach((item) => {
      item.candleIntervalId = candleId;
    });
    if (formData) formData.functionsBox777 = updatedData;
    onFormChange(formData);
  };

  const updateCandleIntervalId = () => {
    // const updatedIndicators;

    // if(formData?.indicators)
    const updatedIndicators = { ...formData?.indicators };

    let candleId = getCandleId(formData);
    for (const key in updatedIndicators) {
      if (updatedIndicators.hasOwnProperty(key)) {
        const indicator = updatedIndicators[key];
        // Check if candleIntervalId property exists
        if (indicator?.hasOwnProperty("candleIntervalId")) {
          // Update candleIntervalId property value

          indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
        }
        if (indicator?.body) {
          if (Array.isArray(indicator.body)) {
            indicator.body.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body1) {
          if (Array.isArray(indicator.body1)) {
            indicator.body1.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body2) {
          if (Array.isArray(indicator.body2)) {
            indicator.body2.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
      }
    }
    // if(indicators)
    if (formData) {
      formData.indicators = updatedIndicators;
    }

    onFormChange(formData);
  };

  const handleClickAll = async (type) => {
    setSelectedItem(type);
    const data = await getfunctionTypeWisefunction({ type_id: type });

    setFunctionType(data);
  };

  useEffect(() => {
    handleClickAll("1");
  }, []);

  useEffect(() => {
    console.log("storeAllStocks>>", storeAllStocks);
  }, [storeAllStocks]);

  useEffect(() => {
    setTimeout(() => {
      eflag = 1;
    }, 2000);
  }, []); // This effect runs once when the component mounts

  const getEntryText = async () => {
    // Get the element by its ID
    const divElement = document.getElementById("strategyBox");

    // Check if the div element exists
    if (divElement) {
      // Get the existing text content of the div
      let existingContent = divElement.innerText;

      // Replace {input1Placeholder} with formData?.input1 value
      existingContent = existingContent.replace(
        "{input1Placeholder}",
        formData?.input1
      );
      let text1 = handleReplacement(existingContent);

      if (formData) {
        formData.functionText1 = existingContent;
      }
      onFormChange(formData);
      // setGotText(!gotText);

      // Do something with the modified text content
    }
  };

  const toggleDropdown = () => {
    setRemove(false);
    setShowDropdown(true);
  };

  const handleModalData = (data) => {
    updateHandleModalData(
      data,
      formData,
      onFormChange,
      getEntryText,
      itemIndex,
      setIsShow,
      instance
    );
  };

  const handleSMAComparator = (data) => {
    setIsShow3(false);
    if (formData?.ctype === "c1") {
      onFormChange({
        ...formData,
        comparator: data?.field,
      });
    }
    if (formData?.ctype === "c2") {
      onFormChange({
        ...formData,
        comparator2: data?.comparator2,
      });
    }
    if (formData?.ctype === "c3") {
      onFormChange({
        ...formData,
        comparator3: data?.comparator3,
      });
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    toggleDropdown();
  };

  const handleReplacement = (text) => {
    console.log("checkText-", text);
    let modifiedText = text?.replace(/lower than or equal to/gi, "<=");
    modifiedText = modifiedText?.replace(/higher than or equal to/gi, ">=");
    modifiedText = modifiedText?.replace(/lower than/gi, "<");
    modifiedText = modifiedText?.replace(/higher than/gi, ">");
    modifiedText = modifiedText?.replace(/> equal to/gi, ">=");
    modifiedText = modifiedText?.replace(/< equal to/gi, "<=");
    modifiedText = modifiedText?.replace(/and/gi, "&&");
    modifiedText = modifiedText?.replace(/or/gi, "||");
    modifiedText = modifiedText?.replace(/equal to/gi, "===");
    modifiedText = modifiedText?.replace(/crosses above/gi, ">");
    modifiedText = modifiedText?.replace(/crosses below/gi, ">");

    // Add more replacements as needed

    return modifiedText;
  };

  const handleAddButtonClick = (instanceName, bodyInstance) => {
    if (formData) {
      formData.selectedOption3 = instanceName;
    }

    setInstance(bodyInstance);
  };

  const handleRemoveButtonClick = (
    instanceToRemove,
    indexToRemove,
    mInstance
  ) => {
    handleRemoveCondition(
      formData,
      onFormChange,
      instanceToRemove,
      indexToRemove,
      mInstance,
      setMathIndexRemove,
      setRemovedInsideMath,
      isIncludeMathFunction
    );
  };

  const handleButtonClick = (index) => {};

  const handleAdd = (instanceData, index) => {
    toggleDropdown();
  };

  const handleNextEnteryBtn = () => {
    getEntryText();
    let text1 = handleReplacement(formData?.functionText1);
    mergeArrays(
      formData?.functionsBox777,
      text1
        ?.split("\n")
        .map((line) => line.trim())
        .filter(
          (line) =>
            line !== "" &&
            !line?.startsWith("1minute") &&
            !line?.startsWith("3minute") &&
            !line?.startsWith("5minute") &&
            !line?.startsWith("10minute") &&
            !line?.startsWith("15minute") &&
            !line?.startsWith("30minute") &&
            !line?.startsWith("60minute") &&
            !line?.startsWith("1day") &&
            !line?.startsWith("week") &&
            !line?.startsWith("month") &&
            !line?.startsWith("2h") &&
            !line?.startsWith("3h") &&
            !line?.startsWith("4h")
        ),
      onFormChange,
      formData,
      isSelectMax,
      isSelectMin,
      isIncludeMathFunction
    );

    // setMenu("review");
  };

  const getIndex = (index, instance) => {
    setItemIndex(index);
    setInstance(instance);
  };
  const RemoveFormula = () => {
    let ans = window.confirm("Are you sure, you want to remove formula?");
    if (ans === true) {
      setFlag(0);
      setRemove(true);
      onFormChange((prevState) => ({
        ...prevState,
        functions: [],
        functionsBox: [],
        functionsBox2: [],
        functionsBox777: [],
        functionsMainBox: [],
        indicators: {},
        mode: "",
        selectedOption1: "",
        order: [],
        isIncludeMathFunction: false,
      }));
      handleSetInstead();
      isIncludeMathFunction.current = false;
    }
  };
  return (
    <div className="row" id="scanner-condition-tab">
      <div className=" mt-5 pb-2 d-flex align-items-center justify-content-between">
        <div>
          <h6 className="fw-bolder">Entry Condition</h6>
        </div>
        <div></div>
      </div>
      <div className="input-group mb-3" id="tab-function-type-dropdown">
        <div className="d-flex row" style={{ minWidth: "300px" }}>
          {/* Input box */}
          <div
            // ref={conditionDropdownRef}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Form.Control
              type="text"
              placeholder="What would you like to add?"
              className="strategy-entry-search"
              onClick={(event) => {
                event.stopPropagation();
                toggleDropdown();
              }}
              value={searchQuery}
              onChange={handleSearch}
            />
            <span
              className="close-icon"
              onClick={() => {
                setSearchQuery("");
                setShowDropdown(false);
              }}
              style={{ cursor: "pointer", marginLeft: "-25px" }}
            >
              X
            </span>
          </div>
          {/* Dropdown menu */}
          {showDropdown && (
            <FunctionList
              showInstead={showInstead}
              formData={formData}
              isSelectMax={isSelectMax}
              removedInsideMath={removedInsideMath}
              selectedItem={selectedItem}
              selectedItemStyle={selectedItemStyle}
              handleClickAll={handleClickAll}
              handleGetFunction1={handleGetFunction1}
              handleGetFunction={handleGetFunction}
              getEntryText={getEntryText}
              searchQuery={searchQuery}
              functionType={functionType}
              remove={remove}
              isIncludeMathFunction={isIncludeMathFunction}
              onFormChange={onFormChange}
            />
          )}
        </div>
      </div>
      {formData?.conditionTab ? (
        <div className="row" id="strategy-entry-condition-card">
          <div className="col-lg-12 p-0">
            <Card>
              <Card.Body>
                <div className="row">
                  <div
                    className="col-lg-12 p-3"
                    id="strategyBox"
                    style={{
                      display: "contents",
                    }}
                  >
                    <FormulaTab
                      formData={formData}
                      handleRemoveButtonClick={handleRemoveButtonClick}
                      setIsShow={setIsShow}
                      setIsShow3={setIsShow3}
                      onFormChange={onFormChange}
                      handleButtonClick={handleButtonClick}
                      setShowDropdown={setShowDropdown}
                      setInnerAdd={setInnerAdd}
                      setOuterAdd={setOuterAdd}
                      handleAddButtonClick={handleAddButtonClick}
                      outerAdd={outerAdd}
                      setShowClose={setShowClose}
                      setShowOpen={setShowOpen}
                      setShowLowPrice={setShowLowPrice}
                      setShowHighPrice={setShowHighPrice}
                      setShowUlcer={setShowUlcer}
                      setShowDEMA={setShowDEMA}
                      setShowTEMA={setShowTEMA}
                      setShowTMA={setShowTMA}
                      setShowVWMA={setShowVWMA}
                      setShowEMA={setShowEMA}
                      setShowSMA={setShowSMA}
                      setShowNumber={setShowNumber}
                      setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                      setShowWilliams={setShowWilliams}
                      setShowMad={setShowMad}
                      setShowMOM={setShowMOM}
                      setShowObv={setShowObv}
                      setShowAroonDown={setShowAroonDown}
                      setShowAroonUp={setShowAroonUp}
                      setShowOscilator={setShowOscilator}
                      setShowVWAP={setShowVWAP}
                      setShowpsar={setShowpsar}
                      setShowVortexI={setShowVortexI}
                      setShowVolume={setShowVolume}
                      setShowDonchian={setShowDonchian}
                      setShowStochastic={setShowStochastic}
                      setShowChaikin={setShowChaikin}
                      setShowMacdSignal={setShowMacdSignal}
                      setShowCCI={setShowCCI}
                      setShowRoc={setShowRoc}
                      setShowRSI={setShowRSI}
                      setShowRSIMA={setShowRSIMA}
                      setShowMacd={setShowMacd}
                      setShowBB={setShowBB}
                      setShowTR={setShowTR}
                      setShowATR={setShowATR}
                      setShowNATR={setShowNATR}
                      setShowSuperT={setShowSuperT}
                      setShowMfiMa={setShowMfiMa}
                      setShowKST={setShowKST}
                      setShowIchimoku={setShowIchimoku}
                      setShowCandleModal={setShowCandleModal}
                      getIndex={getIndex}
                      setShowWMA={setShowWMA}
                      allStocks={storeAllStocks}
                      setAddInstead={setAddInstead}
                      showInstead={showInstead}
                      handleEdit={handleEdit}
                      handleAdd={handleAdd}
                      setPeriodMaxModal={setPeriodMaxModal}
                      setPeriodMinModal={setPeriodMinModal}
                      setisStdDeviation={setisStdDeviation}
                      setShowUltimateOscillator={setShowUltimateOscillator}
                    />
                    &nbsp; &nbsp;
                    {formData.isIncludeMathFunction && (
                      <button
                        onClick={() => {
                          setShowDropdown(true);
                          setOuterAdd(true);
                        }}
                        style={addButton}
                      >
                        {"Add"}
                      </button>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div
              className="row"
              style={{ marginTop: "12px", marginBottom: "16px" }}
            >
              <div className="col-lg-12 d-flex flex-row-reverse">
                <span
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => RemoveFormula()}
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="box my-3">
          <div className="text-center">
            <div className="filecircle mx-auto">
              <i className="fa-regular fa-file"></i>
            </div>
            <h5 className="fw-bolder text-dark pt-3 pb-1">Add a condition</h5>
            <p className="para">
              Add an entity condition by searching <br></br>for indicators and
              comparators
            </p>
          </div>
        </div>
      )}
      <CompartorModal
        showSMA={showSMA3}
        setIsShow={setIsShow3}
        handleSMAClickedPage={handleSMAComparator}
        type={formData?.ctype}
        typeName={formData?.selectedOption2}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Ulcer
        show={showUlcer}
        setIsShow={setShowUlcer}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <TriMA
        show={showTMA}
        setIsShow={setShowTMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Vwma
        show={showVWMA}
        setIsShow={setShowVWMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MOM
        show={showMOM}
        setIsShow={setShowMOM}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <DEMA
        show={showDEMA}
        setIsShow={setShowDEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <TEMA
        show={showTEMA}
        setIsShow={setShowTEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Open
        show={showOpen}
        setIsShow={setShowOpen}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Low
        show={showLowPrice}
        setIsShow={setShowLowPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <HighPrice
        show={showHighPrice}
        setIsShow={setShowHighPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Close
        show={showClose}
        setIsShow={setShowClose}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <EMA
        show={showEMA}
        setIsShow={setShowEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        handleRemoveButtonClick={handleRemoveButtonClick}
        index={itemIndex}
        instance={instance}
      />
      <SMA
        show={showSMA1}
        setIsShow={setShowSMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <WMA
        show={showWMA}
        setIsShow={setShowWMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Number
        show={showNumber}
        setIsShow={setShowNumber}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MoneyFlowIndex
        show={showMoneyFlowIndex}
        setIsShow={setShowMoneyFlowIndex}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <WilliamsR
        show={showWilliams}
        setIsShow={setShowWilliams}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MovingAverageDeviation
        show={showMad}
        setIsShow={setShowMad}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MACD
        show={showMacd}
        setIsShow={setShowMacd}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <OBV
        show={showObv}
        setIsShow={setShowObv}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <AroonDown
        show={showAroonDown}
        setIsShow={setShowAroonDown}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <AroonUp
        show={showAroonUp}
        setIsShow={setShowAroonUp}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <AwesomeOscillator
        show={showOscilator}
        setIsShow={setShowOscilator}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <VWAP
        show={showVWAP}
        setIsShow={setShowVWAP}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ParabolicSAR
        show={showpsar}
        setIsShow={setShowpsar}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <VortexIndicator
        show={showVortexI}
        setIsShow={setShowVortexI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Volume
        show={showVolume}
        setIsShow={setShowVolume}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <DonchianChannel
        show={showDonchian}
        setIsShow={setShowDonchian}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Stochastic
        show={showStochastic}
        setIsShow={setShowStochastic}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ChaikinMoneyFlow
        show={showChaikin}
        setIsShow={setShowChaikin}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CCI
        show={showCCI}
        setIsShow={setShowCCI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PriceRateofChange
        show={showRoc}
        setIsShow={setShowRoc}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MACDsignal
        show={showMacdSignal}
        setIsShow={setShowMacdSignal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <RSI
        show={showRSI}
        setIsShow={setShowRSI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <RSIMovingAverage
        show={showRSIMA}
        setIsShow={setShowRSIMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <BollingerB
        show={showBB}
        setIsShow={setShowBB}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <TR
        show={showTR}
        setIsShow={setShowTR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ATR
        show={showATR}
        setIsShow={setShowATR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <NATR
        show={showNATR}
        setIsShow={setShowNATR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Supertrend
        show={showSuperT}
        setIsShow={setShowSuperT}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MfiMovingAverage
        show={showMfiMA}
        setIsShow={setShowMfiMa}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <KnowSureThing
        show={showKST}
        setIsShow={setShowKST}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Ichimoku
        show={showIchimoku}
        setIsShow={setShowIchimoku}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <CandleInterval
        show={showCandleModal}
        setIsShow={setShowCandleModal}
        handleModalData={handleCandleModal}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        instance={instance}
      />
      <PeriodMaxModal
        setIsShow={setPeriodMaxModal}
        showPeriodMaxModal={showPeriodMaxModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodMinModal
        setIsShow={setPeriodMinModal}
        showPeriodMinModal={showPeriodMinModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <VwapStandarddeviation
        show={showStdDeviation}
        setIsShow={setisStdDeviation}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <UltimateOscillator
        show={showUltimateOscillator}
        setIsShow={setShowUltimateOscillator}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
    </div>
  );
};

export default ScannerConditionComponent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.functionButton {
  display: flex;
  align-items: center;
  color: #225bb3;
  font-weight: 500;
}

.buttonStyle {
  padding: 3px;
  margin-right: 10px;
  font-size: 14px;
  background-color: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #1579c9;
  color: #0e6efd;
  font-weight: "500";
  position: relative;
}

.hover-underline:hover {
  text-decoration: underline;
  text-decoration-color: #0e6efd;
}
.hover-underline {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Strategies/Strategy.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".functionButton {\n  display: flex;\n  align-items: center;\n  color: #225bb3;\n  font-weight: 500;\n}\n\n.buttonStyle {\n  padding: 3px;\n  margin-right: 10px;\n  font-size: 14px;\n  background-color: white;\n  color: black;\n  border-radius: 5px;\n  cursor: pointer;\n  border: 1px solid #1579c9;\n  color: #0e6efd;\n  font-weight: \"500\";\n  position: relative;\n}\n\n.hover-underline:hover {\n  text-decoration: underline;\n  text-decoration-color: #0e6efd;\n}\n.hover-underline {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

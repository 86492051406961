import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { fetchingdemonumbers,update_status } from '../api/dashboardApi';
import moment from 'moment';
import { Button } from "react-bootstrap";
function Demorequest() {
    const [demodata,setDemodata]=useState([]);
    const [done,setDone]=useState();
    const [update,setUpdate]=useState(false);
    const customStyles = {
        rows: {
          style: {
            border : "1px solid #8080805e", // override the row height
            minHeight: '40px',
          },
        },
        headCells: {
          style: {
            border : "1px solid #8080805e",
            fontSize : "medium",
            fontWeight : "bold",
            borderBottom : "none",
            minHeight: '30px',
          },
        },
        cells: {
          style: {
            borderRight : "1px solid #8080805e", // override the row height
           
            // height : "2px"
          },
        },
        
      };
    
    useEffect(()=>{
        apitofetchdemonumbers();
      },[update]);

      const apitofetchdemonumbers=async()=>
      {
        const result=await fetchingdemonumbers();
        console.log("==++",result);
        setDemodata(result);
      }
      const markasdone=(i,data)=>{
        setDone(i);
        console.log("marking as done",i,data);
            updatestatusAPI(data);
      }
      const updatestatusAPI=async(phone)=>{
          const result=await update_status(phone);
          if(result===true)
            {
                  apitofetchdemonumbers();
                  setUpdate(true);
            }
      }
      const complete=()=>{
        alert("Already Demo Completed");
      }
  return (
   
          <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Demo Request</h3>

              <div className="panel-actions text-white">
                {/* <a className="text-dark mr-10" id="reset-filter-btn">
                  Reset Filter <i className="fa fa-times-circle-o"></i>
                </a> */}
              </div>
            </div>
            <div className="panel-body">
              <table
                className="table table-hover table-bordered dataTable table-striped w-full"
                id="user-list"
              >
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {demodata?.map((data,i) => (
                    <tr key={i}>
                      <td>{++i}</td>
                      <td>{data.phone}</td>
                      <td>{data.email}</td>
                      <td>
                        {data.status===1 ? 
                        (
                        <Button varient="success" onClick={()=> complete()}> Done 
                        </Button>
                        ):(
                        <Button varient="success" onClick={()=> markasdone(i,data?.phone)}> Demo Pending
                        </Button>
                        )}
                      
                      </td>
                  </tr>
                  ))}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
   
  )
}

export default Demorequest;
import {
    helperModalReplaceFn777,
    helperModalSwitchFn,
    helperModalSwitchForMath,
  } from "../helperModalSwitchFn";
  import { calculateAdjustedIndex } from "../indextoRemoveMath";
  
  const updateHandleModalData = (
    data,
    formData,
    onFormChange,
    getEntryText,
    itemIndex,
    setIsShow,
    instance
  ) => {
    setIsShow(false);
    const validFunctions = [
      "Period min1",
      "Period max1",
      "Symbol1",
      "Floor1",
      "Ceil1",
      "Abs1",
    ];
    const additionalValidFunctions = ["Max1", "Min1"];
    if (validFunctions?.includes(formData?.clickedFunction)) {
      // update period max and min period
      switch (data?.name) {
        case "Period max":
          formData.indicators[formData.clickedFunction].period = data?.period;
        case "Period min":
          formData.indicators[formData.clickedFunction].period = data?.period;
        default:
          break;
      }
      // Update or add properties based on selectedOption1
  
      helperModalSwitchForMath(
        formData,
        formData.clickedFunction,
        itemIndex,
        data,
        "body"
      );
      setTimeout(() => {
        getEntryText();
      }, 1000);
      console.log("formData??", formData);
      onFormChange(formData);
    } else if (additionalValidFunctions.includes(formData.clickedFunction)) {
      // Update or add properties based on selectedOption1
      helperModalSwitchForMath(
        formData,
        formData.clickedFunction,
        itemIndex,
        data,
        instance
      );
      setTimeout(() => {
        getEntryText();
      }, 1000);
      console.log("formData??", formData);
      onFormChange(formData);
    } else {
      let keys = Object.keys(formData.indicators);
      let index = keys.indexOf(formData.clickedFunction);
      console.log("InIndi>", index);
      // Check if formData.indicators object exists
      if (!formData.indicators) {
        formData.indicators = {};
      }
      // Check if the instance exists in formData.indicators
      if (!formData.indicators[formData.clickedFunction]) {
        formData.indicators[formData.clickedFunction] = {};
      }
      if (formData.clickedFunction.startsWith("comparator")) {
        let c = formData.indicators?.[formData.clickedFunction];
        if (c) {
          formData.indicators[formData.clickedFunction].name = data?.name;
          formData.indicators[formData.clickedFunction].function_id =
            data?.function_id;
          formData.functionsBox777[index] = data;
          formData.functionsBox[index] = data.name;
        }
      }
      if (formData?.functionsBox777) {
        let replaceObje = {
          function_id: data?.function_id,
          name: data?.name,
          function_type_id: data?.function_type_id,
        };
        helperModalReplaceFn777(data, replaceObje);
        const keysBeforeIndex = keys.slice(0, index);
        let adjustedIndex = calculateAdjustedIndex(keysBeforeIndex);
        formData.functionsBox777[index + adjustedIndex] = replaceObje;
      }
      // Update or add properties based on selectedOption1
      helperModalSwitchFn(formData, formData.clickedFunction, itemIndex, data);
      setTimeout(() => {
        getEntryText();
      }, 1000);
  
      onFormChange(formData);
    }
  };
  
  export default updateHandleModalData;
  
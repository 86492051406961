import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import { Dashboard } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ListIcon from '@mui/icons-material/List';
import SegmentIcon from '@mui/icons-material/Segment';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { useEffect } from "react";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  console.log("children",children);
  const { pathname } = useLocation();
  console.log("PathName" ,pathname);
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [activemenuhover, setActiveMenuhover] = React.useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logo = {
    width: "180px",
  };
  const handleOnClick = (index) => {
    setActiveMenu(index);
    setOpen(true);
    if (index === 0) navigate("/Dashboard");
    if (index === 1) navigate("/Userlist");
    if (index === 2) navigate("/Salelist");
    if (index === 3) navigate("/Subscriptions");
    if (index === 4) navigate("/Strategies");
    if (index === 5) navigate("/Scanner");
    if (index === 6) navigate("/Demorequest");
    if (index === 7) navigate("/user/Feedback");
    if (index === 8) navigate("/user/Blogs");
    if (index === 9) navigate("/user/contactuserdetails");
  };
  const logout=()=>{
    navigate("/");    
  }
  const gotoprofile=()=>{
    const id=sessionStorage.getItem("Logginuser");
    navigate(`/user/${id}`)
  }
  const location = useLocation();

useEffect(() => {
  switch (location.pathname) {
    case "/Dashboard":
      setActiveMenu(0);
      break;
    case "/Userlist":
      setActiveMenu(1);
      break;
    case "/Salelist":
      setActiveMenu(2);
      break;
    case "/Subscriptions":
      setActiveMenu(3);
      break;
    case "/Strategies":
      setActiveMenu(4);
      break;
    case "/Scanner":
      setActiveMenu(5);
      break;
    case "/Demorequest":
      setActiveMenu(6);
      break;
    case "/user/Feedback":
      setActiveMenu(7);
      break;
    case "/user/Blogs":
      setActiveMenu(8);
      break;
    case "/user/contactuserdetails":
      setActiveMenu(9);
      break;
    default:
      setActiveMenu(-1); // or any default state you prefer
  }
}, [location.pathname]);

  const drawermenu = ["Dashboard", "Users list", "Sale List", "Subscriptions","Strategies","Scanner","Demo Request","Feedback","Blogs","Contact users"];
  return (
    <>
    {pathname != '/' ? (
      <Box sx={{ display: "flex", padding: "0px" }}>
        <AppBar
          position="fixed"
          open={open}
          sx={{ background: "#ffff", padding: "0px" }}
        >
          <Toolbar style={{ padding: "0px" }}>
            <img alt="nt found"
              src="/dalrotismall.png"
              style={{
                width: "40px",
                height: "40px",
                marginLeft: "6px",
                ...(open && { display: "none" }),
              }}
            ></img>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                // marginRight: 5,
                marginLeft: 2,
                ...(open && { display: "none" }),
                color: "black",
              }}
            >
              <MenuIcon />
            </IconButton>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button  {...bindTrigger(popupState)} style={{marginLeft : "auto"}}>
                      <img alt="profileImg" src="/software-engineer.png"
                        style={{
                          width: "40px",
                          height: "40px",
                          // marginLeft: "100px",
                          // marginLeft:0
                          
                        }}></img>
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={()=>{
                        popupState.close();
                        gotoprofile();
                      }}>Profile</MenuItem>
                      {/* <MenuItem onClick={popupState.close}>My account</MenuItem> */}
                      <MenuItem onClick={()=>{
                        popupState.close();
                        logout()}}>Logout</MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            {/* <Typography variant="h6" noWrap component="div">
            <img src="./dalrotilogo.png" style={logo}></img>
          </Typography> */}
          </Toolbar>
        </AppBar>
       
        {/* <div
          style={{
            backgroundColor: "#9ec7e8",
            minWidth: "100px",
            maxWidth: "100px",
            display: "flex",
            flex: 1,
          }}
        > */}
        <Drawer
          variant="permanent"
          sx={{
            background: "#000068", // Change background color here
            color: "white", // Change text color here
            "& .MuiDrawer-paper": {
              background: "#000068", // Change background color for the drawer paper
              color: "white", // Change text color for the drawer paper
            },
          }}
          open={open}
        >
          <DrawerHeader sx={{ backgroundColor: "white" }}>
            <img src="/dalrotilogo.png" style={logo}></img>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon sx={{ color: "black" }} />
              ) : (
                <ChevronLeftIcon sx={{ color: "black" }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {drawermenu.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  // px: 2.5,
                  color:
                    activeMenu === index ? theme.palette.primary.main : "white",
                  backgroundColor:
                    activeMenu === index ? "white" : "transparent",
                  borderRadius:
                    activeMenu === index ? "20px 0px 0px 20px" : "0px",
                  "&:hover": {
                    color: theme.palette.primary.main,
                    // backgroundColor: "white",
                  },
                }}
                onMouseEnter={() => setActiveMenuhover(index)}
                onClick={() => handleOnClick(index)}
              >
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index===0? (<DashboardIcon
                        sx={{
                          color:
                            activeMenu === index
                              ? theme.palette.primary.main
                              : "white",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: "white",
                          },
                        }}
                      />):index===1?( <ListIcon
                        sx={{
                          color:
                            activeMenu === index
                              ? theme.palette.primary.main
                              : "white",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: "white",
                          },
                        }}
                      />):index === 2  ? (
                      <SegmentIcon
                        sx={{
                          color:
                            activeMenu === index
                              ? theme.palette.primary.main
                              : "white",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: "white",
                          },
                        }}
                      />
                    ): index===3?( <SubscriptionsIcon
                      sx={{
                        color:
                          activeMenu === index
                            ? theme.palette.primary.main
                            : "white",
                        "&:hover": {
                          color: theme.palette.primary.main,
                          backgroundColor: "white",
                        },
                      }}
                    />) : index===4?( 
                    <PsychologyAltIcon 
                      sx={{
                        color:
                          activeMenu === index
                            ? theme.palette.primary.main
                            : "white",
                        "&:hover": {
                          color: theme.palette.primary.main,
                          backgroundColor: "white",
                        },
                      }}
                    />):index === 5 ? (
                      <i className="fa fa-search fa-2x"
                      // style={{color : "#fff"}}
                      style={{
                        color:
                          activeMenu === index
                            ? theme.palette.primary.main 
                            : "white",
                        "&:hover": {
                          color: theme.palette.primary.main,
                          backgroundColor: "white",
                        },
                      }}
                    />
                    ) : index===6 ?( <PsychologyAltIcon 
                      sx={{
                        color:
                          activeMenu === index
                            ? theme.palette.primary.main
                            : "white",
                        "&:hover": {
                          color: theme.palette.primary.main,
                          backgroundColor: "white",
                        },
                      }}
                    />): (
                      <MailIcon
                        sx={{
                          color:
                            activeMenu === index
                              ? theme.palette.primary.main
                              : "white",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: "white",
                          },
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* <Main open={open}> */}
        {/* <DrawerHeader /> */}
        {children}
        {/* <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography> */}
        {/* </Main> */}
        {/* {children} */}
        {/* </div> */}
      </Box>
      ) : <>{children}</>}
    </>
  );
}

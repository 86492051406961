import { Navigate } from "react-router-dom";

export const saveloginuser=(loginuser)=>{
  if(loginuser)
    {
  sessionStorage.setItem("Logginuserdetails",JSON.stringify(loginuser?.userDetails));
  sessionStorage.setItem("Logginuser",JSON.stringify(loginuser?.userDetails?.id));
    }
    else
    {
      console.log("Error in saving data...");
    }
}
export const getid=()=>{
   const id=sessionStorage.getItem("Logginuser");
   return JSON.parse(id);
}
export const saveData = (user) => {
  console.log("session", user);
  if (user) {
    sessionStorage.setItem("useraccdetails", JSON.stringify(user));
    sessionStorage.setItem("id", user?.accountDetails[0]?.user_id);
    sessionStorage.setItem(
      "user",
      JSON.stringify({ id: user?.accountDetails[0]?.user_id })
    );
    sessionStorage.setItem("logged_user", true);
    sessionStorage.setItem("ActiveAccount", "zerodha");
    sessionStorage.setItem(
      "zerodhatoken",
      JSON.stringify(user?.accountDetails[0]?.access_token)
    );
    // if (user?.activePlan?.length !== 0) {
    //   sessionStorage.setItem(
    //     "subscribe_plan",
    //     JSON.stringify(user?.activePlan[0])
    //   );
    // }
  } else {
  }
};

export const savePlanSession = (planDetails) => {
  sessionStorage.setItem("subscribe_plan", JSON.stringify(planDetails));
};

export const getUser = () => {
  const user = sessionStorage.getItem("user");
  return JSON.parse(user);
};

export const getUserPlan = () => {
  const plan = sessionStorage.getItem("subscribe_plan");
  if (plan) {
    const planDetails = JSON.parse(plan);
    console.log("planDetails", planDetails?.name);
    const planName = planDetails.name;
    return planName;
  }
};

export const saveActiveAccount = (token, data) => {
  sessionStorage.setItem("accountData", JSON.stringify(data));
  sessionStorage.setItem("ActiveAccount", JSON.stringify(token));
};
export const getActiveAccount = () => {
  const token = sessionStorage.getItem("ActiveAccount");
  if (token) return JSON.parse(token);
  // return 'acacacdacd';
};

export const getActiveAccountdata = () => {
  const accountDetails = sessionStorage.getItem("accountData");
  return JSON.parse(accountDetails);
};
export const clearSessionData = () => {
  //Removing username from sesstion storage
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("logged_user");
  sessionStorage.removeItem("subscribe_plan");
  sessionStorage.removeItem("5paisaToken");
  sessionStorage.clear();
  window.location.reload();
  // setIsCleared(true);
  // setTimeout(() => {
  // //   setIsCleared(false);
  // }, 2000);
};

export const save5paisaToken = (token) => {
  // token='acadcdccd';
  sessionStorage.setItem("5paisaToken", JSON.stringify(token));
};

export const get5paisaToken = () => {
  const token = sessionStorage.getItem("5paisaToken");
  return JSON.parse(token);
};
export const getZerodhaToken = () => {
  const token = sessionStorage.getItem("zerodhatoken");
  return JSON.parse(token);
};

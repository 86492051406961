import getCandleId from "./CommonFunction/getCandleId";
import { helperModalReplaceFn888 } from "./helperModalSwitchFn";

  const replaceNewExistData = (formData,item, showInstead) => {
    let newFormData = { ...formData };
    let indicators = newFormData?.exit_indicators;
    let candleId = getCandleId(formData);
    let baseObj = {
        function_id: item?.id,
        name: item.name,
        function_type_id: item?.function_type_id,
        candleIntervalId: candleId,
    };
    if (item?.function_type_id === 2) {
      formData.isIncludeMathFunction = true;
      if (item.name === "Max") {
        baseObj.body1 = [];
        baseObj.body2 = [];
      } else if (item.name === "Min") {
        baseObj.body1 = [];
        baseObj.body2 = [];
      } else if (
        item.name === "Ceil" ||
        item.name === "Floor" ||
        item.name === "Abs"
      ) {
        baseObj.body = [];
      } else if (item.name === "Symbol") {
        baseObj.body = [];
        baseObj.scripCode = "";
      } else {
        baseObj.body = [];
        baseObj.period = 10;
      }
    }
    const indicatorConfigs = {
      "Ulcer": { e_ulcerField: "close", e_ulcerPeriod: 14, e_ulcerOffset: 0 },
      "TriMA (Triangular Moving Average)": { e_tmaField: "close", e_tmaPeriod: 20, e_tmaOffset: 0 },
      "VWMA (Volume Weighted Moving Average)": { e_vwmaField: "close", e_vwmaPeriod: 20, e_vwmaOffset: 0 },
      "DEMA (Double Exponential Moving Average)": { e_field: "close", e_period: 20, e_offset: 0 },
      "TEMA (Triple Exponential Moving Average)": { e_temaField: "close", e_temaPeriod: 20, e_temaOffset: 0 },
      "Open price": { e_period: 2, e_offset: 0 },
      "Low price": { e_lowOffset: 0 },
      "High price": { e_highOffset: 0 },
      "Close": { e_closeOffset: 0 },
      "SMA (Simple Moving Average)": { e_field: "close", e_period: 20, e_offset: 0 },
      "WMA (Weighted Moving Average)": { e_wmaField: "close", e_wmaPeriod: 20, e_wmaOffset: 0 },
      "EMA (Exponential Moving Average)": { e_eField: "close", e_ePeriod: 20, e_eOffset: 0 },
      "Money Flow Index": { e_mfiPeriod: 20, e_mfiOffset: 0 },
      "Williams %R": { e_wrOffset: 0, e_wrPeriod: 14 },
      "Moving Average Deviation (MA Dev)": { e_madField: "close", e_madPeriod: 2, e_madType: 0, e_madOffset: 0, e_madPop: 0 },
      "MACD (Moving Average Convergence/Divergence)": { e_macdsFastMaPeriod: 12, e_macdsSlowMaPeriod: 26, e_macdsSignalPeriod: 9, e_macdsOffset: 0  },
      "OBV (On Balance Volume)": { e_obvOffset: 0 },
      "Aroon-Down": { e_aroonDownPeriod: 14, e_aroonDownOffset: 0 },
      "Aroon-Up": { e_aroonUpPeriod: 14, e_aroonUpOffset: 0 },
      "Awesome Oscillator": { e_aOscilatorOffset: 0 },
      "VWAP (Volume Weighted Average Price)": { e_vwapOffset: 0 },
      "Parabolic SAR": { e_psarMinimumAF: 0.02, e_psarMaximumAF: 0.2, e_psarOffset: 0 },
      "Vortex Indicator": { e_vortexIPeriod: 14, e_vortexISignal: "+vi", e_vortexIOffset: 0 },
      "Number": { e_period: 35 },
      "Volume of a candle": { e_volume: 0 },
      "Donchian Channel": { e_donchianChannel: "lower", e_donchianHighPeriod: 2, e_donchianLowPeriod: 2, e_donchianOffset: 0 },
      "Stochastic": { e_stochasticPeriod: 14, e_stochasticType: "%k", e_stochasticSmooth: 0, e_stochasticOffset: 0 },
      "Chaikin Money Flow": { e_chaikinPeriod: 20, e_chaikinOffset: 0 },
      "MACD-signal": { e_macdsFastMaPeriod: 12, e_macdsSlowMaPeriod: 26, e_macdsSignalPeriod: 9, e_macdsOffset: 0 },
      "Commodity Channel Index": { e_cciPeriod: 20, e_cciOffset: 0 },
      "Price Rate of Change": { e_rocField: "close", e_rocPeriod: 14, e_rocOffset: 0 },
      "RSI (Relative Strength Index)": { e_rsiPeriod: 14, e_rsiOffset: 0 },
      "RSI Moving Average": { e_rsimaPeriod: 14, e_rsimaType: "SMA", e_rsimaMaPeriod: 20, e_rsimaOffset: 0 },
      "Supertrend": { e_superTPeriod: 7, e_superTMultiply: 3, e_superTOffset: 0 },
      "TR (True Range)": { e_trOffset: 0 },
      "ATR (Average True Range)": { e_atrPeriod: 14, e_atrOffset: 0 },
      "NATR (Normalized Average True Range)": { e_natrPeriod: 14, e_natrOffset: 0 },
      "Bollinger %B": { e_bolingerBField: "close", e_bolingerBPeriod: 20, e_bolingerBDev: 2, e_bolingerBType: "SMA", e_bolingerBOffset: 0 },
      "MFI Moving Average (Money Flow Index Moving Average)": { e_mfiMAPeriod: 20, e_mfiMAMAPeriod: 20, e_mfiMAType: "SMA", e_mfiMAOffset: 0 },
      "Know Sure Thing": { e_kstField: "close", e_kstLTRoc: 10, e_kstLTSma: 10, e_kstRoc: 15, e_kstLSma: 10, e_kstHRoc: 20, e_kstHSma: 10, e_kstHeRoc: 30, e_kstHeSma: 15, e_kstSPeriod: 9, e_kstType: "kst", e_kstOffset: 0 },
      "Ichimoku": { e_ichiConversion: 9, e_ichiBase: 26, e_leadingSpanB: 52, e_laggingSpan: 26, e_ichiLine: "conversion", e_cloudShift: "yes", e_ichiOffset: 0 },
      "MOM (Momentum Indicator)": { period: 26, offset: 52 },
      "Standard Deviation" : {e_stdPeriod : 14,e_stdField:"close",e_stdDev:2 ,e_maType:"simple" ,e_offset: 0},
      "Ultimate Oscillator" : {e_length1 : 7, e_length2 : 14 , e_length3 : 28 , e_offset : 0},
      "Nth Candle" : {e_field : "high", e_range : "min", e_num : 1},
      "MACD-histogram": { e_macdsFastMaPeriod: 12, e_macdsSlowMaPeriod: 26, e_macdsSignalPeriod: 9, e_macdsOffset: 0 },
      "Median Price" : {e_period : 14, e_offset : 0},
      "Chande Momentum Oscillator": { e_period: 14, e_offset: 0 },
      "ATR Trailing Stoploss": { e_multiplier : 2 , e_period : 14, e_offset : 0},
      "Choppiness Index": { e_period: 14, e_offset: 0 },
      "Central Pivot Range" : {e_signal : "pp"},
      "Camarilla Pivot Points" : {e_signal : "pp"} ,
      "Pivot Points": { e_signal: "pp", e_type: "standard", e_continous  : "no"} ,
      "Elder Ray Index": { e_period: 14, e_power: "bull", e_offset : 0 },
      "Elder Force Index" : {period : 14, offset : 0},
      "UBB (Upper Bollinger Band)": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 },
      "MBB (Middle Bollinger Band)": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 } ,
      "LBB (Lower Bollinger Band)": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 },
    };
  const valuesArray = Object.values(indicators);
  const itemAtIndex = valuesArray[showInstead?.index];
  console.log("itemAtIndex", itemAtIndex, baseObj);
    const specificConfig = indicatorConfigs[item?.name] || {};
    const objOf = { ...baseObj, ...specificConfig };

    const getMaxSuffix = (indicatorObj, item) => {
        let maxSuffix = 0;
        Object.keys(indicatorObj).forEach((key) => {
          if (key.startsWith(item?.name)) {
            const suffix = key?.slice(item?.name?.length).match(/\d+$/);
            if (suffix) {
              maxSuffix = Math.max(maxSuffix, parseInt(suffix[0], 10));
            }
          }
        });
        return maxSuffix;
    };
    const createMinMaxIndex = (itemAtIndex) => {
      const body1Exists = itemAtIndex.hasOwnProperty("body1");
      const body2Exists = itemAtIndex.hasOwnProperty("body2");
      const body1Count = body1Exists ? itemAtIndex?.body1?.length : 0;
      const body2Count = body2Exists ? itemAtIndex?.body2?.length : 0;
      const endCount = body1Count + body2Count + 1;
      return endCount;
    };
    const createMathFnIndex = (itemAtIndex) => {
      const body1Exists = itemAtIndex.hasOwnProperty("body");
      const body1Count = body1Exists ? itemAtIndex?.body?.length : 0;
      const endCount = body1Count + 1;
      return endCount;
    };
    let index = showInstead?.index;
    let entries = Object.entries(indicators);
    if (index >= 0 && index < entries.length) {
      let [keyToDelete, value] = entries[index];
      let numericSuffix = keyToDelete.match(/\d+$/);
      let num = numericSuffix;
      let keyName;
      console.log("keyChange", keyName, item.name, num);
      if (item?.function_type_id == 4) {
        keyName = "operators" + num;
      } else if (item?.function_type_id == 5) {
        keyName = "comparator" + num;
      } else if (item?.function_type_id == 6) {
        keyName = item.name + (getMaxSuffix(newFormData?.exit_indicators, item) + 1);
      } else {
        keyName = item.name + (getMaxSuffix(newFormData?.exit_indicators, item) + 1);
      }

      entries[index] = [keyName, objOf];
      let newData = Object.fromEntries(entries);
      newFormData.clickedFunction = keyName;
      newFormData = { ...newFormData, exit_indicators: newData };
      console.log("newData111", objOf);
      if (newFormData?.order) {
        newFormData?.order.splice(showInstead.index, 1, keyName);
      }
    }
    for (let i = 0; i < newFormData?.exit_functions?.length; i++) {
        let replaceObje = {
          function_id: item?.id,
          name: item.name,
          function_type_id: item?.function_type_id,
          candleIntervalId: candleId,
        };
  
        if (item.function_type_id === 3) {
          helperModalReplaceFn888(objOf,replaceObje, item )
        
        } else if (item.function_type_id === 4) {
          replaceObje.comparator = item?.name;
        } else if (item.function_type_id === 5) {
          switch (item.name) {
            case "crosses above":
              replaceObje.comparator = ">";
              replaceObje.isCrossover = 2;
              break;
            case "crosses below":
              replaceObje.comparator = "<";
              replaceObje.isCrossover = 1;
              break;
            case "higher than":
              replaceObje.comparator = ">";
              break;
            case "lower than":
              replaceObje.comparator = "<";
              break;
            case "lower than equal to":
              replaceObje.comparator = "<=";
              break;
            case "higher than equal to":
              replaceObje.comparator = ">=";
              break;
            case "equal to":
              replaceObje.comparator = ">=";
              break;
          }
        }
        let index = newFormData?.exit_functions[i].findIndex(
          (obj) =>
            obj.function_id == showInstead.function_id &&
            obj.function_type_id == showInstead.function_type_id
        );
  
        if (index !== -1) {
          newFormData.exit_functions[i][index] = replaceObje;
          console.log("isIt", newFormData.exit_functions[i][index]);
          break;
        }
      }
      if (newFormData && newFormData.functionsBox) {
        newFormData.functionsBox?.splice(showInstead.index, 1, item?.name);
      }
      if (newFormData && newFormData.functionsBox888) {
        const entries = Object.entries(formData.exit_indicators);
        const [keyAtIndex, nestedObject] = entries[index];
        const keys = Object.keys(formData.exit_indicators);
        const keysBeforeIndex = keys.slice(0, index);
        const counts = {
          periodCount: 0,
          minCount: 0,
          maxCount: 0,
          symbolCount: 0,
          floorCount: 0,
          ceilCount: 0,
          absCount: 0,
        };
        keysBeforeIndex.forEach((key) => {
          if (/Period/.test(key)) counts.periodCount++;
          if (/Min/.test(key)) counts.minCount++;
          if (/Max/.test(key)) counts.maxCount++;
          if (/Symbol/.test(key)) counts.symbolCount++;
          if (/Floor/.test(key)) counts.floorCount++;
          if (/Ceil/.test(key)) counts.ceilCount++;
          if (/Abs/.test(key)) counts.absCount++;
        });
        let adjustedIndex = showInstead.index;
  
        if (Object.values(counts).some((count) => count > 0)) {
          adjustedIndex +=
            counts.periodCount * 1 +
            (counts.minCount + counts.maxCount) * 2 +
            counts.symbolCount * 1 +
            counts.floorCount * 1 +
            counts.ceilCount * 1 +
            counts.absCount * 1;
            
            if (showInstead.function_id == 2 || showInstead.function_id == 3) {
              const endCount = createMinMaxIndex(itemAtIndex);
              newFormData?.functionsBox888?.splice(adjustedIndex, endCount, objOf);
            } else if (
              showInstead.function_id == 1 ||
              showInstead.function_id == 4 ||
              showInstead.function_id == 5 ||
              showInstead.function_id == 6 ||
              showInstead.function_id == 7 ||
              showInstead.function_id == 8
            ) {
              const endCount = createMathFnIndex(itemAtIndex);
              newFormData?.functionsBox888?.splice(adjustedIndex, endCount, objOf);
            } else {
              newFormData?.functionsBox888?.splice(adjustedIndex, 1, objOf);
            }
        } else {
          newFormData?.functionsBox888?.splice(adjustedIndex, 1, objOf);
        }
      }
      let obj12 = {
        function_id: objOf?.function_id,
        name: objOf?.name,
        function_type_id: objOf?.function_type_id,
      };
      if (newFormData && newFormData.functionsBox3) {
        newFormData.functionsBox3 = newFormData.functionsBox3.map(
          (func, index) => {
            if (
              func.function_id == showInstead?.function_id &&
              func.function_type_id == showInstead?.function_type_id &&
              index == showInstead?.index
            ) {
              return {
                function_type_id: objOf?.function_type_id,
                function_id: obj12.function_id,
                name: obj12.name,
              };
            } else {
              return func;
            }
          }
        );
      }
     return newFormData;
}

export default replaceNewExistData ;
import React, { useEffect, useState } from "react";
import MiniDrawer from "../Components/MiniDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import IOSSwitch from "../Components/IOSSwitch";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { ExportUsers, GetUsersListApi } from "../api/adminapi";


// Inside your component's render method

function Userlist() {
  const navigate = useNavigate();
  const [userRecords , setUserRecords] = useState([]);
  const [userAllData,setUserAllData] = useState([]);
  // ExportUsers
  const handleExport = async() => {
    const result=await ExportUsers();
    console.log("Exported file",result);
    const downloadUrl = `https://dalrotibackend.shrikanttapkeer.com/exports/${result.filename}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', result.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const customStyles = {
    rows: {
      style: {
        border : "1px solid #8080805e", // override the row height
        minHeight: '40px',
      },
    },
    // columns : {
    //   style: {
    //     border : "1px solid grey", 
    //   }
    // },
    headCells: {
      style: {
        border : "1px solid #8080805e",
        fontSize : "medium",
        fontWeight : "bold",
        borderBottom : "none",
        minHeight: '30px',
      },
    },
    cells: {
      style: {
        borderRight : "1px solid #8080805e", // override the row height
        // color:"#76838F"
        // height : "2px"
      },
    },
    
  };

  const columns =  [
    {
      id : "id",
      selector : row => row.id,
      omit : true
    },
    {
      name : "Name",
      selector : row => row.user_name,
      // sortable :true
    },
    {
      name : "Email",
      selector : row => row.email,
    },
    {
      name : "Phone",
      selector : row => row.phone
    },
    {
      name : "Plan",
      cell :(row)=> row.plan_id ===1 ? "Premium" : "Free"
    },
    {
      name : "From",
      selector : row => row.start_date
    },
    {
      name : "To",
      selector : row => row.end_date,
      sortable : true

    },
    {
      name : "Subscription",
      cell:(row) =>  <span className={row.is_active === 1 ? "active-class" : "expired-class"} >  {row.is_active === 1 ? "Active" : "Expired"}</span>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
    name : "Status",
    cell:(row) => <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} _id={row.id} handleChange={handleChange} defaultChecked />}  label=""/>,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    }
  ]

  const handleChange = (id) => {
    console.log("handlechange",id);
  }



const fetchUsersData = async() =>{
  const result = await GetUsersListApi();

  console.log("result@@",result);
  setUserRecords(result?.data);
  setUserAllData(result?.data);
}
useEffect(() => {
  fetchUsersData();
} ,[]);



  const handleFilter = (event) => {
    const searchTerm = event.target.value?.toLowerCase();
    // const newData = data?.filter(row =>  row.user_name?.toLowerCase().includes(event.target.value?.toLowerCase()));
    const newData = userAllData?.filter(row => 
      (row.user_name?.toLowerCase().includes(searchTerm) || 
      row.email?.toLowerCase().includes(searchTerm))
  );
    setUserRecords(newData);
  }

  const handleShowUser = row =>{
    navigate(`/user/${row?.id}`);
  }

  return (
    <>
      <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Users</h3>

              <div className="panel-actions text-white">
                <a className="btn btn-success mx-2" id="export-users" onClick={handleExport}>
                  <i className="fa fa-download"></i> Export
                </a>
                
              </div>
            </div>
            <div className="panel-body">
              <div className="mb-2">
                <Form className="d-flex align-items-baseline">
                <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                <Form.Control className="w-25 float-end ms-2" size="sm" type="text" 
                placeholder="Search..." style={{color:"#76838F"}}
                onChange={handleFilter} />
                </Form>
              </div>
              
              <DataTable 
                columns={columns}
                data = {userRecords}
                pagination
                fixedHeader
                customStyles={customStyles}
                highlightOnHover
                striped
                pointerOnHover
                responsive
                onRowClicked={handleShowUser}
              >

              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Userlist;

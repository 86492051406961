import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import NumberInput from "../NumberInput";

const UltimateOscillator = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">
            Ultimate Oscillator
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Period
                </Form.Label>
                <Col sm="4">
                  <NumberInput type="text" size="sm" />
                </Col>
                <Form.Label column sm="2">
                  Field
                </Form.Label>
                <Col sm="4">
                  <Form.Select>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">High</option>
                    <option value="low">Low</option>
                    <option value="close">Close</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Offset
                </Form.Label>
                <Col sm="4">
                  <NumberInput type="text" size="sm" />
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="danger" size="sm" onClick={handleClose}>
            Delete
          </Button>
          <Button variant="primary" size="sm">
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UltimateOscillator;

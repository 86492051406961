import React from "react";
import Chart from "react-apexcharts";

const LogBarChart = ({ data }) => {
  // Format data for ApexCharts
  const chartData = {
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
        },
      },
      // xaxis: {
      //   type: "datetime",
      //   labels: {
      //     datetimeUTC: false,
      //   },
      // },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (value, timestamp) {
            // Use JavaScript's built-in Date object to format the time
            return new Date(timestamp).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          },
          tickAmount: 4, // Display only 4 ticks
        },
      },
      // tooltip: {
      //   x: {
      //     format: "yyyy-MM-dd HH:mm",
      //   },
      // },
      tooltip: {
        x: {
          formatter: function (value) {
            // Format the tooltip to show the full datetime
            return new Date(value).toLocaleString(); // Adjust format as needed
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "20px", // Adjust the width of the bars here
          endingShape: "flat", // Optional: Choose the ending shape of the bars
        },
      },
      dataLabels: {
        enabled: false, // Disable data labels for all series
      },
    },
    series: [
      {
        name: "Result Count",
        data: data?.map((item) => ({
          x: new Date(item.time_interval).getTime(),
          y: item.result_count,
        })),
      },
    ],
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default LogBarChart;

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import IOSSwitch from "../Components/IOSSwitch";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { GetAllSaleList, Exportsalelist} from "../api/adminapi";
import { GetCount } from "../api/adminapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons"; 

function Salelist() {
  const navigate = useNavigate();
  const [userRecords , setUserRecords] = useState([]);
  const [userAllData,setUserAllData] = useState([]);
  const [usersCount ,setUsersCount] = useState('');
  
  const customStyles = {
    rows: {
      style: {
        border : "1px solid #8080805e", // override the row height
        minHeight: '40px',
      },
    },
    color:"#ffffff",
    headCells: {
      style: {
        border : "1px solid #8080805e",
        fontSize : "medium",
        fontWeight : "bold",
        borderBottom : "none",
        minHeight: '30px',
      },
    },
    cells: {
      style: {
        borderRight : "1px solid #8080805e", // override the row height
        // color:"#76838F"
        // height : "2px"
      },
    },
    
  };

  const columns =  [
    {
      id : "id",
      selector : row => row.id,
      omit : true
    },
    {
      name : "Name",
      selector : row => row.user_name,
      // sortable :true
    },
    {
      name : "Sale Id",
      selector : row => row.sale_id,
      // sortable :true
    },
    {
      name : "Plan",
      selector : row => row.plan_name,
    },
    {
      name : "Amount",
      selector : row => row.paid_amount
    },
    {
      name : "Transaction ID",
      selector :row=> row.razorpay_transaction_id
    },
    {
      name: "Date",
      selector: row => {
        const transactionDate = new Date(row.transaction_date);
        const date = transactionDate.toLocaleDateString();
        const time = transactionDate.toLocaleTimeString();
        return `${date} ${time}`;
      }
    }
  
  ]
  const fetchUsersData = async() =>{
    const result = await GetAllSaleList();
  
    console.log("result@@",result);
    setUserRecords(result?.data);
    setUserAllData(result?.data);
  }
  const fetchOrderCount=async()=>{
    const result=await GetCount();
    console.log("##result",result);
    setUsersCount(result);

  }
  const handleExport=async()=>{
    const result=await Exportsalelist();
    console.log("Exported file",result);
    const downloadUrl = `https://dalrotibackend.shrikanttapkeer.com/exports/${result.filename}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', result.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  useEffect(() => {
    fetchUsersData();
    fetchOrderCount();
  } ,[]);
  const handleFilter = (event) => {
    const searchTerm = event.target.value?.toLowerCase();
    const newData = userAllData?.filter(row => 
      (row.user_name?.toLowerCase().includes(searchTerm) || 
      row.email?.toLowerCase().includes(searchTerm))
  );
    setUserRecords(newData);
  }

  
  return (
    <>
      <div className="page">
        <div className="page-content container-fluid">
        <h3 className="panel-title">Sale List</h3>
        <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div
                    className="card card-block mx-auto d-flex justify-content-center"
                    style={{ backgroundColor: "#FFDEBD", padding: "25px",width:"90%",height:"175px", }}
                  >
                    <div className="counter counter-lg">
                      <span className="counter-number"> {usersCount?.totalOrders}</span>
                      <div className="counter-label text-uppercase">
                        <FontAwesomeIcon icon={faUsers} />{" "}
                        <a
                          href="/app/users"
                          target="_blank"
                          className="pl-2" style={{
                            color:"#76838F",
                              textDecorationLine:"none",
                          }}
                        >
                          {/* <i className="icon fa fa-users mr-5"></i> */}
                          Total Orders
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="card card-block p-25 mx-auto d-flex justify-content-center"
                    style={{ backgroundColor: "rgb(221,216,255)", padding: "25px",width:"90%",height:"175px", }}
                  >
                    <div className="counter counter-lg">
                      <div className="counter-number-group">
                        <span className="counter-number"> {usersCount?.todaysOrders}</span>
                      </div>
                      <div className="counter-label text-uppercase">
                        <FontAwesomeIcon icon={faUserPlus} />
                        <a
                          href="/app/users"
                          target="_blank"
                          className="text-gray pl-2" style={{color:"#76838F", textDecorationLine:"none",}}
                        >
                          Todays Order
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="card card-block p-25 mx-auto d-flex justify-content-center"
                    style={{ backgroundColor: "#D6EEFC", padding: "25px",width:"90%",height:"175px", }}
                  >
                    <div className="counter counter-lg">
                      <div className="counter-number-group">
                        <span className="counter-number">{usersCount?. todaysSale}</span>
                      </div>
                      <div className="counter-label text-uppercase">
                        {/* <FontAwesomeIcon icon={faSubscription} /> */}
                        <FontAwesomeIcon icon={faUsers} />{" "}
                        <a
                          href="/app/subscriptions"
                          target="_blank"
                          className="text-gray pl-2" style={{color:"#76838F", textDecorationLine:"none"}}
                        >
                          Today's Sale
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        
     
          <div className="row mt-4 pt-4">
            <div className="col-xl-12 col-lg-12">
             <div className="panel">
            <div className="panel-heading pt-3">
              <div className="panel-actions text-white mt-4">
                <a className="btn btn-success mx-2" id="export-users" onClick={handleExport}>
                  <i className="fa fa-download"></i> Export
                </a>
                
              </div>
            </div>
           <div className="panel-body ">
              <div className="mb-2 pt-4">
                <Form className="d-flex align-items-baseline justify-content-end mt-4">
                <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                <Form.Control className="w-25 float-end ms-2" size="sm" type="text" 
                placeholder="Search..." style={{color:"#76838F"}}
                onChange={handleFilter} />
                </Form>
              </div>
              
              <DataTable 
                columns={columns}
                data = {userRecords}
                pagination
                fixedHeader
                customStyles={customStyles}
                highlightOnHover
                striped
                pointerOnHover
                responsive
               
              >

              </DataTable>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
    </>
  );
}

export default Salelist;


import React from 'react'
import { useState,useEffect } from 'react';
import Addzerodhamodal from "../Components/modal/Addzerodhamodal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import {zerodhaAdminGetAccountApi,AddNewAccount,checkzerodhaacc,EditNewAccount,DeleteAccount, getZerodhaLogin} from "../api/zerodhaapi";
function Zerodha() {
    const [showModal, setShowModal] = useState(false);
    const [zerodhadata,setZerodhadata]=useState({accountName:'',apiKey:'',apiSecret:''})
    const [editzerodhamodal,setEditzerodhamodal]=useState(false);
    const [userRecords , setUserRecords] = useState([]);
    const [userAllData,setUserAllData] = useState([]);
    const [userid,setUserid]=useState();
    const [rowid,setRowid]=useState();
    useEffect(()=>{
        const id=sessionStorage.getItem('Logginuser');
        setUserid(id);
    },[])
    const handleshow=()=>
    {
        setShowModal(true);
        setEditzerodhamodal(false);
        setZerodhadata({});
    }  
    const handleClose=()=>setShowModal(false);
    const customStyles = {
        rows: {
          style: {
            border : "1px solid #8080805e", // override the row height
            minHeight: '40px',
          },
        },
        color:"#ffffff",
        headCells: {
          style: {
            border : "1px solid #8080805e",
            fontSize : "medium",
            fontWeight : "bold",
            borderBottom : "none",
            minHeight: '30px',
          },
        },
        cells: {
          style: {
            borderRight : "1px solid #8080805e", // override the row height
            // color:"#76838F"
            // height : "2px"
          },
        },
        
      };
      const columns =  [
        { 
            name: "Sr. No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: '150px',
          },
          {
            name: "Account Name",
            selector: row => row.account_name,
            cell: row => <div 
            style={{ textAlign: 'left', 
              fontSize:"18px" }}>{row.account_name}</div>,
            fontSize:"17px",
            headerStyle: {
              textAlign: 'left', 
            },
          },
          {
            name: "Token Status",
            selector: row => row.token_status,
            textAlign:"center",
            cell: row=>{
                const isvalid=row.token_status==='Valid';
                return (
                  <>
                  <div className='mx-auto'>
                <span style={{
                    fontWeight:"bolder",
                    color: isvalid ? 'green' : 'red', 
                  }}>{row.token_status}</span>
                  {!isvalid && <span className='generatebtn ms-2' onClick={()=>generate()}>Generate access token</span>}
                  </div>
                  </>
                );
            }
          },
          {
            name: 'Actions',
            textAlign:"center",
            cell: row => (
              <>
                <EditIcon
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => handleEditClick(row)}
                />
                <DeleteIcon
                  style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                  onClick={() => handleDeleteClick(row.id)}
                />
              </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
      
      ]
    const addnewaccount=async()=>{
        const result=await AddNewAccount({user_id:parseInt(userid),...zerodhadata});
        if(result)
          alert("New Account Added successfully!!!...");
        fetchUsersData();
        setShowModal(false);      
    }
    const updatenewaccount=async()=>{
        const result=await EditNewAccount({user_id:parseInt(userid),id:rowid,...zerodhadata});
        if(result)
          alert("Record updated successfully!!!...")
        setShowModal(false);
        fetchUsersData();
    }
    const handleEditClick=(data)=>{
        setShowModal(true);
        setEditzerodhamodal(true);
        setRowid(data.id);
        setZerodhadata({accountName:data.account_name,apiKey:data.api_key,apiSecret:data.api_secret})
    }
    const handleDeleteClick=async(id)=>{
          const isconfirmed=window.confirm("Are you sure you want to delete this account?");
          if(isconfirmed)
          {
            const result=await DeleteAccount({id:id});
            fetchUsersData();
          }    
    }
    const handleFilter=(e)=>{
        const searchTerm=e.target.value.toLowerCase();
        const filtered=userAllData.filter(row=>row.account_name.toLowerCase().includes(searchTerm));
        setUserRecords(filtered);
    }
    const generate=async()=>{
      const url = await getZerodhaLogin({user_id : userid});
        sessionStorage.setItem("tokenReceived", "true");
        // window.location.href=url;
        window.open(url,"_blank");
        const urlParams=new URLSearchParams(window.location.search);
        const token=urlParams.get("request_token");
        // window.location.href = url ;
        if(url) {
          //  window.open(url);
        }
    }
    useEffect(() => {
        fetchUsersData(); 
      } ,[]);
  
      const fetchUsersData = async() =>{
        const result = await zerodhaAdminGetAccountApi(); 
        if(result?.accountDetails)
        {
          const statuses=await Promise?.all(
            result?.accountDetails.map(account=>checkzerodhaacc({
            api_key:account.api_key,
            access_token:account.api_secret})
          ));
  
          const updatedrecords=result.accountDetails.map((account,index)=>{
                const isvalid=statuses[index]?.success;
                return {
                  ...account,
                  token_status:isvalid?'Valid':'Invalid'
                };
          })
          
        setUserRecords(updatedrecords);
        setUserAllData(updatedrecords)
        }
      }
   
    const onChange=(e)=>{
            const {name,value}=e.target;
            setZerodhadata({...zerodhadata,[name]:value});
    }
  return (
    <>
    <div className="page">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Zerodha</h3>

              <div className="panel-actions text-white">
                <button  className="btn btn-primary" 
                // disabled={loading}
                >
                  <span className="hidden-sm-down" onClick={handleshow}>
                    <i className="fa fa-plus" aria-hidden="true"></i> Add New
                    Account
                  </span>
                </button>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12">
            <div className="panel">
   
            <div className="panel-body w-100 ">   
                <div className="mb-2">
                  <Form className="d-flex align-items-baseline justify-content-end mt-4">
                  <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                  <Form.Control className="w-25 float-end ms-2" size="sm" type="text" 
                  placeholder="Search..." style={{color:"#76838F"}}
                  onChange={handleFilter} />
                  </Form>
                </div>   
             <DataTable 
        columns={columns}
        data = {userRecords}
        pagination
        fixedHeader
        customStyles={customStyles}
        highlightOnHover
        striped
        pointerOnHover
        responsive 
      >
             </DataTable>
         </div>
         </div>
         </div>
          </div>
        </div>
        <Addzerodhamodal show={showModal} 
        handleClose={handleClose}
        zerodhadata={zerodhadata}
        onChange={onChange}
        addnewaccount={addnewaccount}
        editzerodhamodal={editzerodhamodal}
        updatenewaccount={updatenewaccount}/>
         </div>
       
    </>
  )
}

export default Zerodha;
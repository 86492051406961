import React from "react";
import MiniDrawer from "../Components/MiniDrawer";

function Home() {
  return (
    <div>
      <MiniDrawer />
    </div>
  );
}

export default Home;

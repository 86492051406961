const replaceExistModal = (
    formData,
    clickedFunction,
    itemIndex,
    data,
    instance
  ) => {
    switch (formData.selectedOption2) {
      case "Ulcer":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ulcerField = data?.e_ulcerField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ulcerPeriod = parseInt(data?.e_ulcerPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ulcerOffset = parseInt(data?.e_ulcerOffset);
        break;
      case "TriMA (Triangular Moving Average)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_tmaField = data?.e_tmaField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_tmaPeriod = parseInt(data?.e_tmaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_tmaOffset = parseInt(data?.e_tmaOffset);
        break;
      case "VWMA (Volume Weighted Moving Average)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vwmaField = data?.e_vwmaField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vwmaPeriod = parseInt(data?.e_vwmaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vwmaOffset = parseInt(data?.e_vwmaOffset);
        break;
      case "DEMA (Double Exponential Moving Average)":
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_field =
          data?.e_field;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_period =
          parseInt(data?.e_period);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_eoffset =
          parseInt(data?.e_offset);
        break;
      case "TEMA (Triple Exponential Moving Average)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_temaField = data?.e_temaField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_temaPeriod = parseInt(data?.e_temaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_temaOffset = parseInt(data?.e_temaOffset);
        break;
      case "Open price":
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_period =
          parseInt(data?.e_period);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_offset =
          parseInt(data?.e_offset);
        break;
      case "Low price":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_lowOffset = parseInt(data?.e_lowOffset);
        break;
      case "High price":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_highOffset = parseInt(data?.e_highOffset);
        break;
      case "Close":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_closeOffset = parseInt(data?.e_closeOffset);
        break;
      case "SMA (Simple Moving Average)":
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_field =
          data?.e_field;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_period =
          parseInt(data?.e_period);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_offset =
          parseInt(data?.e_offset);
        break;
      case "EMA (Exponential Moving Average)":
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_eField =
          data?.e_eField;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_ePeriod =
          parseInt(data?.e_ePeriod);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_eOffset =
          parseInt(data?.e_eOffset);
        break;
      case "Money Flow Index":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_mfiPeriod = parseInt(data?.e_mfiPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_mfiOffset = parseInt(data?.e_mfiOffset);
        break;
      case "Williams %R":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_wrPeriod = parseInt(data?.e_wrPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_wrOffset = parseInt(data?.e_wrOffset);
        break;
      case "Moving Average Deviation (MA Dev)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_madField = data?.e_madField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_madPeriod = parseInt(data?.e_madPeriod);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_madType =
          data?.e_madType;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_madOffset = parseInt(data?.e_madOffset);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_madPop =
          data?.e_madPop;
        break;
      case "MACD-histogram":
      case "MACD (Moving Average Convergence/Divergence)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdfastMaPeriod = parseInt(data?.e_macdfastMaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdslowMaPeriod = parseInt(data?.e_macdslowMaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdsignalPeriod = parseInt(data?.e_macdsignalPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdOffset = parseInt(data?.e_macdOffset);
        break;
      case "OBV (On Balance Volume)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_obvOffset = parseInt(data?.e_obvOffset);
        break;
      case "Aroon-Down":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_aroonDownPeriod = parseInt(data?.e_aroonDownPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_aroonDownOffset = parseInt(data?.e_aroonDownOffset);
        break;
      case "Aroon-Up":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_aroonUpPeriod = parseInt(data?.e_aroonUpPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_aroonUpOffset = parseInt(data?.e_aroonUpOffset);
        break;
      case "Awesome Oscillator":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_aOscilatorOffset = parseInt(data?.e_aOscilatorOffset);
        break;
      case "VWAP (Volume Weighted Average Price)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vwapOffset = parseInt(data?.e_vwapOffset);
        break;
      case "Parabolic SAR":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_psarMinimumAF = parseFloat(data?.e_psarMinimumAF);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_psarOffset = parseFloat(data?.e_psarOffset);
        break;
      case "Vortex Indicator":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vortexIPeriod = parseInt(data?.e_vortexIPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vortexISignal = data?.e_vortexISignal;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_vortexIOffset = parseInt(data?.e_vortexIOffset);
        break;
      case "Number":
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_period =
          parseFloat(data?.e_period);
        break;
      case "Volume of a candle":
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_volume =
          parseInt(data?.e_volume);
        break;
      case "Donchian Channel":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_donchianChannel = data?.e_donchianChannel;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_donchianHighPeriod = parseInt(data?.e_donchianHighPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_donchianLowPeriod = parseInt(data?.e_donchianLowPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_donchianOffset = parseInt(data?.e_donchianOffset);
        break;
      case "Stochastic":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_stochasticPeriod = parseInt(data?.e_stochasticPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_stochasticType = data?.e_stochasticType;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_stochasticSmooth = data?.e_stochasticSmooth;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_stochasticOffset = parseInt(data?.e_stochasticOffset);
        break;
      case "Chaikin Money Flow":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_chaikinPeriod = parseInt(data?.e_chaikinPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_chaikinOffset = parseInt(data?.e_chaikinOffset);
        break;
      case "MACD-signal":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdsFastMaPeriod = parseInt(data?.e_macdsFastMaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdsSlowMaPeriod = parseInt(data?.e_macdsSlowMaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdsSignalPeriod = parseInt(data?.e_macdsSignalPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_macdsOffset = parseInt(data?.e_macdsOffset);
        break;
      case "Commodity Channel Index":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_cciPeriod = parseInt(data?.e_cciPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_cciOffset = parseInt(data?.e_cciOffset);
        break;
      case "Price Rate of Change":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rocField = data?.e_rocField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rocPeriod = parseInt(data?.e_rocPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rocOffset = parseInt(data?.e_rocOffset);
        break;
      case "RSI (Relative Strength Index)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rsiPeriod = parseInt(data?.e_rsiPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rsiOffset = parseInt(data?.e_rsiOffset);
        break;
      case "RSI Moving Average":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rsimaPeriod = parseInt(data?.e_rsimaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rsimaType = data?.e_rsimaType;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rsimaMaPeriod = parseInt(data?.e_rsimaMaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_rsimaOffset = parseInt(data?.e_rsimaOffset);
        break;
      case "Supertrend":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_superTPeriod = parseInt(data?.e_superTPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_superTMultiply = parseFloat(data?.e_superTMultiply);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_superTOffset = parseInt(data?.e_superTOffset);
        break;
      case "TR (True Range)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_trOffset = parseInt(data?.e_trOffset);
        break;
      case "ATR (Average True Range)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_atrPeriod = parseInt(data?.e_atrPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_atrOffset = parseInt(data?.e_atrOffset);
        break;
      case "NATR (Normalized Average True Range)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_natrPeriod = parseInt(data?.e_natrPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_natrOffset = parseInt(data?.e_natrOffset);
        break;
      case "Bollinger %B":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_bolingerBField = data?.e_bolingerBField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_bolingerBPeriod = parseInt(data?.e_bolingerBPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_bolingerBDev = parseInt(data?.e_bolingerBDev);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_bolingerBType = data?.e_bolingerBType;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_bolingerBOffset = parseInt(data?.e_bolingerBOffset);
        break;
      case "MFI Moving Average (Money Flow Index Moving Average)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_mfiMAPeriod = parseInt(data?.e_mfiMAPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_mfiMAMAPeriod = parseInt(data?.e_mfiMAMAPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_mfiMAType = data?.e_mfiMAType;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_mfiMAOffset = parseInt(data?.e_mfiMAOffset);
        break;
      case "Know Sure Thing":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstField = data?.e_kstField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstLTRoc = data?.e_kstLTRoc;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstLTSma = data?.e_kstLTSma;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_kstRoc =
          data?.e_kstRoc;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_kstLSma =
          data?.e_kstLSma;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_kstHRoc =
          data?.e_kstHRoc;
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_kstHSma =
          data?.e_kstHSma;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstHeRoc = data?.e_kstHeRoc;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstHeSma = data?.e_kstHeSma;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstSPeriod = parseInt(data?.e_kstSPeriod);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_kstType =
          data?.e_kstType;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_kstOffset = parseInt(data?.e_kstOffset);
        break;
      case "Ichimoku":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ichiConversion = data?.e_ichiConversion;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ichiBase = data?.e_ichiBase;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_leadingSpanB = data?.e_leadingSpanB;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_laggingSpan = data?.e_laggingSpan;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ichiLine = data?.e_ichiLine;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_cloudShift = data?.e_cloudShift;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_ichiOffset = parseInt(data?.e_ichiOffset);
        break;
      case "WMA (Weighted Moving Average)":
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_wmaField = data?.e_wmaField;
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_wmaPeriod = parseInt(data?.e_wmaPeriod);
        formData.exit_indicators[clickedFunction][instance][
          itemIndex
        ].e_wmaOffset = parseInt(data?.e_wmaOffset);
        break;
      case "MOM (Momentum Indicator)":
        formData.exit_indicators[clickedFunction][instance][itemIndex].period =
          parseInt(data.period);
        formData.exit_indicators[clickedFunction][instance][itemIndex].offset =
          parseInt(data.offset);
        break; 
      case "Chande Momentum Oscillator":
        formData.exit_indicators[clickedFunction][instance][itemIndex].period =
          parseInt(data.period);
        formData.exit_indicators[clickedFunction][instance][itemIndex].offset =
          parseInt(data.offset);
        break;
      case "Standard Deviation":
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_stdPeriod = parseInt(
        data?.e_stdPeriod
      );
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_stdField = data?.e_stdField;
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_stdDev = parseInt(
        data?.e_stdDev
      );
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_maType = data?.e_maType;
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_offset = parseInt(
        data?.e_offset
      );
      break;
      case "ATR Trailing Stoploss" :
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_multiplier = parseInt(data?.e_multiplier);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_period = parseInt(data?.e_period);
        formData.exit_indicators[clickedFunction][instance][itemIndex].e_offset = parseInt(data?.e_offset);
        break;
        case "Camarilla Pivot Points" :
    case "Central Pivot Range" :
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_signal = data?.e_signal;
      break ;
    case "Pivot Points":
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_signal = data?.e_signal;
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_type = data?.e_type;
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_continous = data?.e_continous;
      break;

    case "Elder Ray Index" :
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_period = parseInt(data?.e_period);
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_power = data?.e_power;
      formData.exit_indicators[clickedFunction][instance][itemIndex].e_offset = parseInt(data?.e_offset);
      break;

    case "UBB (Upper Bollinger Band)":
    case "MBB (Middle Bollinger Band)":
    case "LBB (Lower Bollinger Band)": 
      formData.exit_indicators[clickedFunction][instance][
        itemIndex
      ].e_field = data?.e_field;
      formData.exit_indicators[clickedFunction][instance][
        itemIndex
      ].e_period = parseInt(data?.e_period);
      formData.exit_indicators[clickedFunction][instance][
        itemIndex
      ].e_dev = parseInt(data?.e_dev);
      formData.exit_indicators[clickedFunction][instance][
        itemIndex
      ].e_type = data?.e_type;
      formData.exit_indicators[clickedFunction][instance][
        itemIndex
      ].e_offset = parseInt(data?.e_offset);
      break;
      default:
        break;
    }
  };
  
  const updateExistIndicator = (formData, clickedFunction, data) => {
    switch (formData.selectedOption2) {
      case "Ulcer":
        formData.exit_indicators[clickedFunction].e_ulcerField =
          data?.e_ulcerField;
        formData.exit_indicators[clickedFunction].e_ulcerPeriod = parseInt(
          data?.e_ulcerPeriod
        );
        formData.exit_indicators[clickedFunction].e_ulcerOffset = parseInt(
          data?.e_ulcerOffset
        );
        break;
      case "TriMA (Triangular Moving Average)":
        formData.exit_indicators[clickedFunction].e_tmaField = data?.e_tmaField;
        formData.exit_indicators[clickedFunction].e_tmaPeriod = parseInt(
          data?.e_tmaPeriod
        );
        formData.exit_indicators[clickedFunction].e_tmaOffset = parseInt(
          data?.e_tmaOffset
        );
        break;
      case "VWMA (Volume Weighted Moving Average)":
        formData.exit_indicators[clickedFunction].e_vwmaField = data?.e_vwmaField;
        formData.exit_indicators[clickedFunction].e_vwmaPeriod = parseInt(
          data?.e_vwmaPeriod
        );
        formData.exit_indicators[clickedFunction].e_vwmaOffset = parseInt(
          data?.e_vwmaOffset
        );
        break;
      case "DEMA (Double Exponential Moving Average)":
        formData.exit_indicators[clickedFunction].e_field = data?.e_field;
        formData.exit_indicators[clickedFunction].e_period = parseInt(
          data?.e_period
        );
        formData.exit_indicators[clickedFunction].e_offset = parseInt(
          data?.e_offset
        );
        break;
      case "TEMA (Triple Exponential Moving Average)":
        formData.exit_indicators[clickedFunction].e_temaField = data?.e_temaField;
        formData.exit_indicators[clickedFunction].e_temaPeriod = parseInt(
          data?.e_temaPeriod
        );
        formData.exit_indicators[clickedFunction].e_temaOffset = parseInt(
          data?.e_temaOffset
        );
        break;
      case "Open price":
        formData.exit_indicators[clickedFunction].e_period = parseInt(
          data?.e_period
        );
        formData.exit_indicators[clickedFunction].e_offset = parseInt(
          data?.e_offset
        );
        break;
      case "Low price":
        formData.exit_indicators[clickedFunction].e_lowOffset = parseInt(
          data?.e_lowOffset
        );
        break;
      case "High price":
        formData.exit_indicators[clickedFunction].e_highOffset = parseInt(
          data?.e_highOffset
        );
        break;
      case "Close":
        formData.exit_indicators[clickedFunction].e_closeOffset = parseInt(
          data?.e_closeOffset
        );
        break;
      case "SMA (Simple Moving Average)":
        formData.exit_indicators[clickedFunction].e_field = data?.e_field;
        formData.exit_indicators[clickedFunction].e_period = parseInt(
          data?.e_period
        );
        formData.exit_indicators[clickedFunction].e_offset = parseInt(
          data?.e_offset
        );
        break;
      case "EMA (Exponential Moving Average)":
        formData.exit_indicators[clickedFunction].e_eField = data?.e_eField;
        formData.exit_indicators[clickedFunction].e_ePeriod = parseInt(
          data?.e_ePeriod
        );
        formData.exit_indicators[clickedFunction].e_eOffset = parseInt(
          data?.e_eOffset
        );
        break;
      case "Money Flow Index":
        formData.exit_indicators[clickedFunction].e_mfiPeriod = parseInt(
          data?.e_mfiPeriod
        );
        formData.exit_indicators[clickedFunction].e_mfiOffset = parseInt(
          data?.e_mfiOffset
        );
        break;
      case "Williams %R":
        formData.exit_indicators[clickedFunction].e_wrOffset = parseInt(
          data?.e_wrOffset
        );
        formData.exit_indicators[clickedFunction].e_wrPeriod = parseInt(
          data?.e_wrPeriod
        );
        break;
      case "Moving Average Deviation (MA Dev)":
        formData.exit_indicators[clickedFunction].e_madField = data?.e_madField;
        formData.exit_indicators[clickedFunction].e_madPeriod = parseInt(
          data?.e_madPeriod
        );
        formData.exit_indicators[clickedFunction].e_madType = data?.e_madType;
        formData.exit_indicators[clickedFunction].e_madOffset = parseInt(
          data?.e_madOffset
        );
        formData.exit_indicators[clickedFunction].e_madPop = data?.e_madPop;
        break;
      case "MACD-histogram":
      case "MACD (Moving Average Convergence/Divergence)":
        formData.exit_indicators[clickedFunction].e_macdsFastMaPeriod = parseInt(
          data?.e_macdsFastMaPeriod
        );
        formData.exit_indicators[clickedFunction].e_macdsSlowMaPeriod = parseInt(
          data?.e_macdsSlowMaPeriod
        );
        formData.exit_indicators[clickedFunction].e_macdsSignalPeriod = parseInt(
          data?.e_macdsSignalPeriod
        );
        formData.exit_indicators[clickedFunction].e_macdsOffset = parseInt(
          data?.e_macdsOffset
        );
        break;
      case "OBV (On Balance Volume)":
        formData.exit_indicators[clickedFunction].e_obvOffset = parseInt(
          data?.e_obvOffset
        );
        break;
      case "Aroon-Down":
        formData.exit_indicators[clickedFunction].e_aroonDownPeriod = parseInt(
          data?.e_aroonDownPeriod
        );
        formData.exit_indicators[clickedFunction].e_aroonDownOffset = parseInt(
          data?.e_aroonDownOffset
        );
        break;
      case "Aroon-Up":
        formData.exit_indicators[clickedFunction].e_aroonUpPeriod = parseInt(
          data?.e_aroonUpPeriod
        );
        formData.exit_indicators[clickedFunction].e_aroonUpOffset = parseInt(
          data?.e_aroonUpOffset
        );
        break;
      case "Awesome Oscillator":
        formData.exit_indicators[clickedFunction].e_aOscilatorOffset = parseInt(
          data?.e_aOscilatorOffset
        );
        break;
      case "VWAP (Volume Weighted Average Price)":
        formData.exit_indicators[clickedFunction].e_vwapOffset = parseInt(
          data?.e_vwapOffset
        );
        break;
      case "Parabolic SAR":
        formData.exit_indicators[clickedFunction].e_psarMinimumAF = parseInt(
          data?.e_psarMinimumAF
        );
        formData.exit_indicators[clickedFunction].e_psarMaximumAF = parseInt(
          data?.e_psarMaximumAF
        );
        formData.exit_indicators[clickedFunction].e_psarOffset = parseInt(
          data?.e_psarOffset
        );
        break;
      case "Vortex Indicator":
        formData.exit_indicators[clickedFunction].e_vortexIPeriod = parseInt(
          data?.e_vortexIPeriod
        );
        formData.exit_indicators[clickedFunction].e_vortexISignal =
          data?.e_vortexISignal;
        formData.exit_indicators[clickedFunction].e_vortexIOffset = parseInt(
          data?.e_vortexIOffset
        );
        break;
      case "Number":
        formData.exit_indicators[clickedFunction].e_period = parseInt(
          data?.e_period
        );
        break;
      case "Volume of a candle":
        formData.exit_indicators[clickedFunction].e_volume = parseInt(
          data?.e_volume
        );
        break;
      case "Donchian Channel":
        formData.exit_indicators[clickedFunction].e_donchianChannel =
          data?.e_donchianChannel;
        formData.exit_indicators[clickedFunction].e_donchianHighPeriod = parseInt(
          data?.e_donchianHighPeriod
        );
        formData.exit_indicators[clickedFunction].e_donchianLowPeriod = parseInt(
          data?.e_donchianLowPeriod
        );
        formData.exit_indicators[clickedFunction].e_donchianOffset = parseInt(
          data?.e_donchianOffset
        );
        break;
      case "Stochastic":
        formData.exit_indicators[clickedFunction].e_stochasticPeriod = parseInt(
          data?.e_stochasticPeriod
        );
        formData.exit_indicators[clickedFunction].e_stochasticType =
          data?.e_stochasticType;
        formData.exit_indicators[clickedFunction].e_stochasticSmooth =
          data?.e_stochasticSmooth;
        formData.exit_indicators[clickedFunction].e_stochasticOffset = parseInt(
          data?.e_stochasticOffset
        );
        break;
      case "Chaikin Money Flow":
        formData.exit_indicators[clickedFunction].e_chaikinPeriod = parseInt(
          data?.e_chaikinPeriod
        );
        formData.exit_indicators[clickedFunction].e_chaikinOffset = parseInt(
          data?.e_chaikinOffset
        );
        break;
      case "MACD-signal":
        formData.exit_indicators[clickedFunction].e_macdsFastMaPeriod = parseInt(
          data?.e_macdsFastMaPeriod
        );
        formData.exit_indicators[clickedFunction].e_macdsSlowMaPeriod = parseInt(
          data?.e_macdsSlowMaPeriod
        );
        formData.exit_indicators[clickedFunction].e_macdsSignalPeriod = parseInt(
          data?.e_macdsSignalPeriod
        );
        formData.exit_indicators[clickedFunction].e_macdsOffset = parseInt(
          data?.e_macdsOffset
        );
        break;
      case "Commodity Channel Index":
        formData.exit_indicators[clickedFunction].e_cciPeriod = parseInt(
          data?.e_cciPeriod
        );
        formData.exit_indicators[clickedFunction].e_cciOffset = parseInt(
          data?.e_cciOffset
        );
        break;
      case "Price Rate of Change":
        formData.exit_indicators[clickedFunction].e_rocField = data?.e_rocField;
        formData.exit_indicators[clickedFunction].e_rocPeriod = parseInt(
          data?.e_rocPeriod
        );
        formData.exit_indicators[clickedFunction].e_rocOffset = parseInt(
          data?.e_rocOffset
        );
        break;
      case "RSI (Relative Strength Index)":
        formData.exit_indicators[clickedFunction].e_rsiPeriod = parseInt(
          data?.e_rsiPeriod
        );
        formData.exit_indicators[clickedFunction].e_rsiOffset = parseInt(
          data?.e_rsiOffset
        );
        break;
      case "RSI Moving Average":
        formData.exit_indicators[clickedFunction].e_rsimaPeriod = parseInt(
          data?.e_rsimaPeriod
        );
        formData.exit_indicators[clickedFunction].e_rsimaType = data?.e_rsimaType;
        formData.exit_indicators[clickedFunction].e_rsimaMaPeriod = parseInt(
          data?.e_rsimaMaPeriod
        );
        formData.exit_indicators[clickedFunction].e_rsimaOffset = parseInt(
          data?.e_rsimaOffset
        );
        break;
      case "Supertrend":
        formData.exit_indicators[clickedFunction].e_superTPeriod = parseInt(
          data?.e_superTPeriod
        );
        formData.exit_indicators[clickedFunction].e_superTMultiply = parseInt(
          data?.e_superTMultiply
        );
        formData.exit_indicators[clickedFunction].e_superTOffset = parseInt(
          data?.e_superTOffset
        );
        break;
      case "TR (True Range)":
        formData.exit_indicators[clickedFunction].e_trOffset = parseInt(
          data?.e_trOffset
        );
        break;
      case "ATR (Average True Range)":
        formData.exit_indicators[clickedFunction].e_atrPeriod = parseInt(
          data?.e_atrPeriod
        );
        formData.exit_indicators[clickedFunction].e_atrOffset = parseInt(
          data?.e_atrOffset
        );
        break;
      case "NATR (Normalized Average True Range)":
        formData.exit_indicators[clickedFunction].e_natrPeriod = parseInt(
          data?.e_natrPeriod
        );
        formData.exit_indicators[clickedFunction].e_natrOffset = parseInt(
          data?.e_natrOffset
        );
        break;
      case "Bollinger %B":
        formData.exit_indicators[clickedFunction].e_bolingerBField =
          data?.e_bolingerBField;
        formData.exit_indicators[clickedFunction].e_bolingerBPeriod = parseInt(
          data?.e_bolingerBPeriod
        );
        formData.exit_indicators[clickedFunction].e_bolingerBDev = parseInt(
          data?.e_bolingerBDev
        );
        formData.exit_indicators[clickedFunction].e_bolingerBType =
          data?.e_bolingerBType;
        formData.exit_indicators[clickedFunction].e_bolingerBOffset = parseInt(
          data?.e_bolingerBOffset
        );
        break;
      case "MFI Moving Average (Money Flow Index Moving Average)":
        formData.exit_indicators[clickedFunction].e_mfiMAPeriod = parseInt(
          data?.e_mfiMAPeriod
        );
        formData.exit_indicators[clickedFunction].e_mfiMAMAPeriod = parseInt(
          data?.e_mfiMAMAPeriod
        );
        formData.exit_indicators[clickedFunction].e_mfiMAType = data?.e_mfiMAType;
        formData.exit_indicators[clickedFunction].e_mfiMAOffset = parseInt(
          data?.e_mfiMAOffset
        );
        break;
      case "Know Sure Thing":
        formData.exit_indicators[clickedFunction].e_kstField = data?.e_kstField;
        formData.exit_indicators[clickedFunction].e_kstLTRoc = data?.e_kstLTRoc;
        formData.exit_indicators[clickedFunction].e_kstLTSma = data?.e_kstLTSma;
        formData.exit_indicators[clickedFunction].e_kstRoc = data?.e_kstRoc;
        formData.exit_indicators[clickedFunction].e_kstLSma = data?.e_kstLSma;
        formData.exit_indicators[clickedFunction].e_kstHRoc = data?.e_kstHRoc;
        formData.exit_indicators[clickedFunction].e_kstHSma = data?.e_kstHSma;
        formData.exit_indicators[clickedFunction].e_kstHeRoc = data?.e_kstHeRoc;
        formData.exit_indicators[clickedFunction].e_kstHeSma = data?.e_kstHeSma;
        formData.exit_indicators[clickedFunction].e_kstSPeriod = parseInt(
          data?.e_kstSPeriod
        );
        formData.exit_indicators[clickedFunction].e_kstType = data?.e_kstType;
        formData.exit_indicators[clickedFunction].e_kstOffset = parseInt(
          data?.e_kstOffset
        );
        break;
      case "Ichimoku":
        formData.exit_indicators[clickedFunction].e_ichiConversion =
          data?.e_ichiConversion;
        formData.exit_indicators[clickedFunction].e_ichiBase = data?.e_ichiBase;
        formData.exit_indicators[clickedFunction].e_leadingSpanB =
          data?.e_leadingSpanB;
        formData.exit_indicators[clickedFunction].e_laggingSpan =
          data?.e_laggingSpan;
        formData.exit_indicators[clickedFunction].e_ichiLine = data?.e_ichiLine;
        formData.exit_indicators[clickedFunction].e_cloudShift =
          data?.e_cloudShift;
        formData.exit_indicators[clickedFunction].e_ichiOffset = parseInt(
          data?.e_ichiOffset
        );
        break;
      case "WMA (Weighted Moving Average)":
        formData.exit_indicators[clickedFunction].e_wmaField = data?.e_wmaField;
        formData.exit_indicators[clickedFunction].e_wmaPeriod = parseInt(
          data?.e_wmaPeriod
        );
        formData.exit_indicators[clickedFunction].e_wmaOffset = parseInt(
          data?.e_wmaOffset
        );
        break;
      case "MOM (Momentum Indicator)":
        formData.exit_indicators[clickedFunction].period = parseInt(data?.period);
        formData.exit_indicators[clickedFunction].offset = parseInt(data?.offset);
        break;
      case "Chande Momentum Oscillator":
        formData.exit_indicators[clickedFunction].e_period = parseInt(data?.e_period);
        formData.exit_indicators[clickedFunction].e_offset = parseInt(data?.e_offset);
        break;
      case "Standard Deviation":
        formData.exit_indicators[clickedFunction].e_stdPeriod = parseInt(
          data?.e_stdPeriod
        );
        formData.exit_indicators[clickedFunction].e_stdField = data?.e_stdField;
        formData.exit_indicators[clickedFunction].e_stdDev = parseInt(
          data?.e_stdDev
        );
        formData.exit_indicators[clickedFunction].e_maType = data?.e_maType;
        formData.exit_indicators[clickedFunction].e_offset = parseInt(
          data?.e_offset
        );
        break;
      case "Ultimate Oscillator": 
      formData.exit_indicators[clickedFunction].e_length1 = parseInt(data?.e_length1);
      formData.exit_indicators[clickedFunction].e_length2 = parseInt(data?.e_length2) ;
      formData.exit_indicators[clickedFunction].e_length3 = parseInt(data?.e_length3) ;
      formData.exit_indicators[clickedFunction].e_offset = parseInt(data?.e_offset) ;
      break;
      case "Nth Candle" : 
      formData.exit_indicators[clickedFunction].e_field = data?.e_field;
      formData.exit_indicators[clickedFunction].e_range = data?.e_range;
      formData.exit_indicators[clickedFunction].e_num = parseInt(data?.e_num)  
      break;
      case "Median Price": 
      formData.exit_indicators[clickedFunction].e_period = parseInt(data?.e_period);
      formData.exit_indicators[clickedFunction].e_offset = parseInt(data?.e_offset);
      break;
      case "ATR Trailing Stoploss" : 
      formData.exit_indicators[clickedFunction].e_multiplier = parseInt(data?.e_multiplier);
      formData.exit_indicators[clickedFunction].e_period = parseInt(data?.e_period);
      formData.exit_indicators[clickedFunction].e_offset = parseInt(data?.e_offset);
      break;
      default:
        break;
    }
  };
  export { replaceExistModal, updateExistIndicator };
  
import React from "react";
import { Button, Table } from "react-bootstrap";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
        
const ScannerTable = ({
  scannerData,
}) => {
  return (
    <>
      { 
        <Table
          className="mt-3"
          id="scanner-table"
          style={{
            border: "0.1px solid lightgray",
          }}
        >
          <thead>
            <tr style={{ borderBottom: "1px solid #D3D3D3" }}>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Symbol
              </th>
              <th className="text-center" colspan="3">
                LTP
              </th>
            </tr>
          </thead>
          <tbody>
            {scannerData?.map((item, i) => (
              <tr style={{ position: "relative" }} key={i}>
                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="/stockPlaceholder.png"
                      style={{ height: "18px", width: "18px" }}
                      alt="nt found"
                    ></img>
                    &nbsp;
                    <div className="ps-2">
                      <div style={{ fontWeight: "600" }}>
                        {item?.stock_name
                          ? item?.stock_name
                          : item?.script_name}
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        {item?.exch === "n" || item?.exch == ""
                          ? "NSE"
                          : item?.exch === "b"
                          ? "BSE"
                          : item?.exch}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    color:
                      item?.ltp !== 0 && item?.closePrice !== 0
                        ? item.ltp > item.closePrice
                          ? "#008000"
                          : "#BA0F04"
                        : "#060606",
                    textAlign: "end",
                  }}
                >
                  {item?.ltp}
                </td>
                <td
                  style={{
                    color:
                      item?.ltp !== 0 && item?.closePrice !== 0
                        ? item.ltp > item.closePrice
                          ? "#008000"
                          : "#BA0F04"
                        : "#060606",
                    textAlign: "center",
                    width: "20px",
                  }}
                >
                  {item?.ltp !== 0 && item?.closePrice !== 0 ? (
                    item.ltp > item.closePrice ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  ) : null}
                </td>
                <td style={{ width: "120px" }}>
                  {item?.ltp && item?.closePrice && (
                    <div>
                      {(
                        ((item?.ltp - item?.closePrice) / item?.closePrice) *
                        100
                      ).toFixed(3)}
                      %
                    </div>
                  )}
                  {item?.percentage ? "%" : ""}
                </td>
                <td> {item?.volume_traded}</td>

                <td style={{ textAlign: "right" }}>
                  <Button size="sm" className="btn-color">
                    Buy
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                  <Button size="sm">Sell</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      }
    </>
  );
};

export default ScannerTable;

import { helperModalReplaceFn777 } from "./helperModalSwitchFn";

const getCandleId = (formData) => {
  let candleId;
  if (formData.candleIntervalId === 1) {
    candleId = "1minute";
  } else if (formData.candleIntervalId === 3) {
    candleId = "3minute";
  } else if (formData.candleIntervalId === 5) {
    candleId = "5minute";
  } else if (formData.candleIntervalId === 10) {
    candleId = "10minute";
  } else if (formData.candleIntervalId === 15) {
    candleId = "15minute";
  } else if (formData.candleIntervalId === 30) {
    candleId = "30minute";
  } else if (formData.candleIntervalId === 60) {
    candleId = "60minute";
  } else if (formData.candleIntervalId === "1day") {
    candleId = "1day";
  } else if (formData.candleIntervalId === "2h") {
    candleId = "2h";
  } else if (formData.candleIntervalId === "3h") {
    candleId = "3h";
  } else if (formData.candleIntervalId === "4h") {
    candleId = "4h";
  }

  return candleId;
};

const replaceNewData = (formData, item, showInstead) => {
  let newFormData = { ...formData };
  let indicators = newFormData?.indicators;
  let candleId = getCandleId(formData);
  let baseObj = {
    function_id: item?.id,
    name: item.name,
    function_type_id: item?.function_type_id,
    candleIntervalId: candleId,
  };
  console.log("CHECKITEM", item, showInstead);
  if (item?.function_type_id === 2) {
    formData.isIncludeMathFunction = true;
    if (item.name === "Max") {
      baseObj.body1 = [];
      baseObj.body2 = [];
    } else if (item.name === "Min") {
      baseObj.body1 = [];
      baseObj.body2 = [];
    } else if (
      item.name === "Ceil" ||
      item.name === "Floor" ||
      item.name === "Abs"
    ) {
      baseObj.body = [];
    } else if (item.name === "Symbol") {
      baseObj.body = [];
      baseObj.scripCode = "";
    } else {
      baseObj.body = [];
      baseObj.period = 10;
    }
  }
  const indicatorConfigs = {
    Ulcer: { ulcerField: "close", ulcerPeriod: 14, ulcerOffset: 0 },
    "TriMA (Triangular Moving Average)": {
      tmaField: "close",
      tmaPeriod: 20,
      tmaOffset: 0,
    },
    "VWMA (Volume Weighted Moving Average)": {
      vwmaField: "close",
      vwmaPeriod: 20,
      vwmaOffset: 0,
    },
    "DEMA (Double Exponential Moving Average)": {
      field: "close",
      period: 20,
      offset: 0,
    },
    "TEMA (Triple Exponential Moving Average)": {
      temaField: "close",
      temaPeriod: 20,
      temaOffset: 0,
    },
    "Open price": { period: 2, offset: 0 },
    "Low price": { lowOffset: 0 },
    "High price": { offset: 0 },
    Close: { closeOffset: 0 },
    "SMA (Simple Moving Average)": { field: "close", period: 20, offset: 0 },
    "WMA (Weighted Moving Average)": {
      wmaField: "close",
      wmaPeriod: 20,
      wmaOffset: 0,
    },
    "EMA (Exponential Moving Average)": {
      field: "close",
      period: 20,
      offset: 0,
    },
    "Money Flow Index": { mfiPeriod: 20, mfiOffset: 0 },
    "Williams %R": { wrOffset: 0, wrPeriod: 14 },
    "Moving Average Deviation (MA Dev)": {
      madField: "close",
      madPeriod: 2,
      madType: 0,
      madOffset: 0,
      madPop: 0,
    },
    "MACD (Moving Average Convergence/Divergence)": {
      macd_fastMaPeriod: 12,
      macd_slowMaPeriod: 26,
      macd_signalPeriod: 9,
      macdOffset: 0,
    },
    "OBV (On Balance Volume)": { obvOffset: 0 },
    "Aroon-Down": { aroonDownPeriod: 14, aroonDownOffset: 0 },
    "Aroon-Up": { aroonUpPeriod: 14, aroonUpOffset: 0 },
    "Awesome Oscillator": { aOscilatorOffset: 0 },
    "VWAP (Volume Weighted Average Price)": { vwapOffset: 0 },
    "Parabolic SAR": { psarMinimumAF: 0.02, psarMaximumAF: 0.2, psarOffset: 0 },
    "Vortex Indicator": {
      vortexIPeriod: 14,
      vortexISignal: "+vi",
      vortexIOffset: 0,
    },
    Number: { period: 35 },
    "Volume of a candle": { volume: 0 },
    "Donchian Channel": {
      donchianChannel: "lower",
      donchianHighPeriod: 20,
      donchianLowPeriod: 20,
      donchianOffset: 0,
    },
    Stochastic: {
      stochasticPeriod: 14,
      stochasticType: "%k",
      stochasticSmooth: 0,
      stochasticOffset: 0,
    },
    "Chaikin Money Flow": { chaikinPeriod: 20, chaikinOffset: 0 },
    "MACD-signal": {
      macdsFastMaPeriod: 12,
      macdsSlowMaPeriod: 26,
      macdsSignalPeriod: 9,
      macdsOffset: 0,
    },
    "Commodity Channel Index": { cciPeriod: 20, cciOffset: 0 },
    "Price Rate of Change": { rocField: "close", rocPeriod: 14, rocOffset: 0 },
    "RSI (Relative Strength Index)": { rsiPeriod: 14, rsiOffset: 0 },
    "RSI Moving Average": {
      rsimaPeriod: 14,
      rsimaType: "SMA",
      rsimaMaPeriod: 20,
      rsimaOffset: 0,
    },
    Supertrend: { superTPeriod: 7, superTMultiply: 3, superTOffset: 0 },
    "TR (True Range)": { trOffset: 0 },
    "ATR (Average True Range)": { atrPeriod: 14, atrOffset: 0 },
    "NATR (Normalized Average True Range)": { natrPeriod: 14, natrOffset: 0 },
    "Bollinger %B": {
      bolingerBField: "close",
      bolingerBPeriod: 20,
      bolingerBDev: 2,
      bolingerBType: "SMA",
      bolingerBOffset: 0,
    },
    "MFI Moving Average (Money Flow Index Moving Average)": {
      mfiMAPeriod: 20,
      mfiMAMAPeriod: 20,
      mfiMAType: "SMA",
      mfiMAOffset: 0,
    },
    "Know Sure Thing": {
      kstField: "close",
      kstLTRoc: 10,
      kstLTSma: 10,
      kstRoc: 15,
      kstLSma: 10,
      kstHRoc: 20,
      kstHSma: 10,
      kstHeRoc: 30,
      kstHeSma: 15,
      kstSPeriod: 9,
      kstType: "kst",
      kstOffset: 0,
    },
    Ichimoku: {
      ichiConversion: 9,
      ichiBase: 26,
      leadingSpanB: 52,
      laggingSpan: 26,
      ichiLine: "conversion",
      cloudShift: "yes",
      ichiOffset: 0,
    },
    "MOM (Momentum Indicator)": { period: 26, offset: 52 },
    "CHANDE MOMENTUM OSCILLATOR": { period: 26, offset: 52 },
    "Standard Deviation" : {
      stdPeriod : 14,
      stdField : "close",
      stdDev : 2 ,
      maType : "simple",
      offset : 0
    }
  };
  const valuesArray = Object.values(indicators);
  const itemAtIndex = valuesArray[showInstead?.index];
  console.log("itemAtIndex", itemAtIndex, baseObj);
  const specificConfig = indicatorConfigs[item?.name] || {};
  const objOf = { ...baseObj, ...specificConfig };

  const getMaxSuffix = (indicatorObj, item) => {
    let maxSuffix = 0;
    Object.keys(indicatorObj).forEach((key) => {
      if (key.startsWith(item?.name)) {
        const suffix = key?.slice(item?.name?.length).match(/\d+$/);
        if (suffix) {
          maxSuffix = Math.max(maxSuffix, parseInt(suffix[0], 10));
        }
      }
    });
    return maxSuffix;
  };
  const createMinMaxIndex = (itemAtIndex) => {
    const body1Exists = itemAtIndex.hasOwnProperty("body1");
    const body2Exists = itemAtIndex.hasOwnProperty("body2");
    const body1Count = body1Exists ? itemAtIndex?.body1?.length : 0;
    const body2Count = body2Exists ? itemAtIndex?.body2?.length : 0;
    const endCount = body1Count + body2Count + 1;
    return endCount;
  };
  const createMathFnIndex = (itemAtIndex) => {
    const body1Exists = itemAtIndex.hasOwnProperty("body");
    const body1Count = body1Exists ? itemAtIndex?.body?.length : 0;
    const endCount = body1Count + 1;
    return endCount;
  };
  let index = showInstead?.index;
  let entries = Object.entries(indicators);
  if (index >= 0 && index < entries.length) {
    let [keyToDelete, value] = entries[index];
    let numericSuffix = keyToDelete.match(/\d+$/);
    let num = numericSuffix;
    let keyName;
    console.log("keyChange", keyName, item.name, num);
    if (item?.function_type_id == 4) {
      keyName = "operators" + num;
    } else if (item?.function_type_id == 5) {
      keyName = "comparator" + num;
    } else if (item?.function_type_id == 6) {
      if (newFormData?.indicators) {
      }
      keyName = item.name + (getMaxSuffix(newFormData?.indicators, item) + 1);
    } else {
      keyName = item.name + (getMaxSuffix(newFormData?.indicators, item) + 1);
    }

    entries[index] = [keyName, objOf];
    let newData = Object.fromEntries(entries);
    newFormData.clickedFunction = keyName;
    newFormData = { ...newFormData, indicators: newData };
    console.log("newData111", objOf);
    if (newFormData?.order) {
      newFormData?.order.splice(showInstead.index, 1, keyName);
    }
  }
  for (let i = 0; i < newFormData?.functions?.length; i++) {
    let replaceObje = {
      function_id: item?.id,
      name: item.name,
      function_type_id: item?.function_type_id,
      candleIntervalId: candleId,
    };

    if (item.function_type_id == 3) {
      helperModalReplaceFn777(objOf, replaceObje, item);
    } else if (item.function_type_id == 4) {
      replaceObje.comparator = item?.name;
    } else if (item.function_type_id == 5) {
      switch (item.name) {
        case "crosses above":
          replaceObje.comparator = ">";
          replaceObje.isCrossover = 2;
          break;
        case "crosses below":
          replaceObje.comparator = "<";
          replaceObje.isCrossover = 1;
          break;
        case "higher than":
          replaceObje.comparator = ">";
          break;
        case "lower than":
          replaceObje.comparator = "<";
          break;
        case "lower than equal to":
          replaceObje.comparator = "<=";
          break;
        case "higher than equal to":
          replaceObje.comparator = ">=";
          break;
        case "equal to":
          replaceObje.comparator = ">=";
          break;
      }
    }
    let index = newFormData?.functions[i].findIndex(
      (obj) =>
        obj.function_id == showInstead.function_id &&
        obj.function_type_id == showInstead.function_type_id
    );

    if (index !== -1) {
      newFormData.functions[i][index] = replaceObje;
      console.log("isIt", newFormData.functions[i][index]);
      break;
    }
  }
  if (newFormData && newFormData.functionsBox) {
    newFormData.functionsBox?.splice(showInstead?.index, 1, item?.name);
  }
  if (newFormData && newFormData.functionsBox777) {
    const entries = Object.entries(formData.indicators);
    const [keyAtIndex, nestedObject] = entries[index];
    const keys = Object.keys(formData.indicators);
    const keysBeforeIndex = keys.slice(0, index);
    const counts = {
      periodCount: 0,
      minCount: 0,
      maxCount: 0,
      symbolCount: 0,
      floorCount: 0,
      ceilCount: 0,
      absCount: 0,
    };
    keysBeforeIndex.forEach((key) => {
      if (/Period/.test(key)) counts.periodCount++;
      if (/Min/.test(key)) counts.minCount++;
      if (/Max/.test(key)) counts.maxCount++;
      if (/Symbol/.test(key)) counts.symbolCount++;
      if (/Floor/.test(key)) counts.floorCount++;
      if (/Ceil/.test(key)) counts.ceilCount++;
      if (/Abs/.test(key)) counts.absCount++;
    });
    let adjustedIndex = showInstead.index;

    if (Object.values(counts).some((count) => count > 0)) {
      adjustedIndex +=
        counts.periodCount * 1 +
        (counts.minCount + counts.maxCount) * 2 +
        counts.symbolCount * 1 +
        counts.floorCount * 1 +
        counts.ceilCount * 1 +
        counts.absCount * 1;
      if (showInstead.function_id == 2 || showInstead.function_id == 3) {
        const endCount = createMinMaxIndex(itemAtIndex);
        newFormData?.functionsBox777?.splice(adjustedIndex, endCount, objOf);
      } else if (
        showInstead.function_id == 1 ||
        showInstead.function_id == 4 ||
        showInstead.function_id == 5 ||
        showInstead.function_id == 6 ||
        showInstead.function_id == 7 ||
        showInstead.function_id == 8
      ) {
        const endCount = createMathFnIndex(itemAtIndex);
        newFormData?.functionsBox777?.splice(adjustedIndex, endCount, objOf);
      } else {
        newFormData?.functionsBox777?.splice(adjustedIndex, 1, objOf);
      }
    } else {
      newFormData?.functionsBox777?.splice(adjustedIndex, 1, objOf);
    }
  }

  let obj12 = {
    function_id: objOf?.function_id,
    name: objOf?.name,
    function_type_id: objOf?.function_type_id,
  };
  if (newFormData && newFormData.functionsBox2) {
    newFormData.functionsBox2 = newFormData.functionsBox2.map((func, index) => {
      if (
        func.function_id == showInstead?.function_id &&
        func.function_type_id == showInstead?.function_type_id &&
        index == showInstead?.index
      ) {
        return {
          function_type_id: objOf?.function_type_id,
          function_id: obj12.function_id,
          name: obj12.name,
        };
      } else {
        return func;
      }
    });
  }
  return newFormData;
};

export default replaceNewData;

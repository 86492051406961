    import React from 'react'
    import { useEffect } from 'react';
    import { useState } from 'react';
    import {getintouchdetails} from "../api/profileapi";
    import DataTable from "react-data-table-component";
    function Contactuserdetail() {
        const [userRecords , setUserRecords] = useState([]);
        const customStyles = {
            rows: {
            style: {
                border : "1px solid #8080805e", // override the row height
                minHeight: '40px',
            },
            },
            color:"#ffffff",
            headCells: {
            style: {
                border : "1px solid #8080805e",
                fontSize : "medium",
                fontWeight : "bold",
                borderBottom : "none",
                minHeight: '30px',
            },
            },
            cells: {
            style: {
                borderRight : "1px solid #8080805e", // override the row height
                // color:"#76838F"
                // height : "2px"
            },
            },
            
        };
        
        const columns =  [
            {
                name: "Sr. No",
                selector: (row, index) => index + 1,
                sortable: true,
            },
            {
                name: "Name",
                selector: row => row?.name,
            },
            {
              name : "Email",
              selector : row => row?.email,
              // sortable :true
            },
            {
                name : "Company",
                selector : row => row?.company,
                // sortable :true
              },
              {
                name : "Approximate budget",
                selector : row => row?.aprx_budget,
                // sortable :true
              },
              {
                name : "Optional",
                selector : row => row?.optional,
                // sortable :true
              },
        
        
        ]
        useEffect(() => {
            fetchUsersData(); 
        } ,[]);
        
        const fetchUsersData = async() =>{
            const result = await getintouchdetails(); 
            console.log("result@@",result);
            setUserRecords(result);
        }
    return (
        <div className="page">
        <div className="page-content container-fluid">
        <h3 className="panel-title">User Contact Details</h3>
        <div className="row">
        <div className="col-xl-12 col-lg-12">
        <div className="panel">
    
    <div className="panel-body ">      
        <DataTable 
            columns={columns}
            data = {userRecords}
            pagination
            fixedHeader
            customStyles={customStyles}
            highlightOnHover
            striped
            pointerOnHover
            responsive
        
        >
        </DataTable>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    )
    }

    export default Contactuserdetail
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button, Table } from "react-bootstrap";

const DeployedBox = ({ mode }) => {
  const navigate = useNavigate();

  return (
    <div 
      style={{
        // marginLeft: "80px",
        background: "white",
        padding: "12px",
        // borderRadius: "12px",
        borderLeft: "2px solid lightgray"
      }} id="deployed-side-bar"
    >
      <div
        className="fw-bold"
        style={{ fontSize: "20px", paddingLeft: "10px" }}
      >
        Deployed
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px"
        }}
      >
        <img
          src="/icon-images/livetrade.png"
          alt="Strategies Images"
          width={"45px"}
          height={"45px"}
          className="img-fluid"
        />
        <div
          style={{ marginLeft: "10px", width: "70%", cursor: "pointer" }}
          onClick={() => navigate("/deployed?tab=live")}
        >
          <div>Live Trades</div>

          <div style={{ color: "#9f9f9f" }}>Not Deployed</div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} color="#9f9f9f" />
      </div>
      <hr/>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px"
        }}
      >
        <img
          src="/icon-images/papertrade.png"
          alt="Strategies Images"
          width={"45px"}
          height={"45px"}
          className="img-fluid"
        />
        <div style={{ marginLeft: "10px", width: "70%", cursor: "pointer" }} onClick={() => navigate("/deployed?tab=paper")}>
          <div>Paper Trades</div>

          <div style={{ color: "#9f9f9f" }}>Not Deployed</div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} color="#9f9f9f" />
      </div>
      <hr/>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px"
        }}
      >
        <img
          src="/icon-images/scanner.png"
          alt="Strategies Images"
          width={"40px"}
          height={"40px"}
          className="img-fluid"
        />
        <div
          style={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={() => navigate("/scanners")}
        >
          <div>Scanners</div>
          <div style={{ color: "#9f9f9f" }}>Not Deployed</div>
        </div>
      </div>
      
      <hr/>
      <div style={{ marginTop: "20px", marginLeft: "10px" }}>
        <div className="fw-bold" style={{ fontSize: "20px" }}>
          Order Book
        </div>

        <div className="fs-2" style={{  color: "#2c308e" }}>0</div>

        <div className="pointer-cursor" style={{ color: "#9f9f9f" }} onClick={() => navigate("/trades?tab=pending")}>Open Orders</div>
        {/* <div
          style={{
            height: "1px",
            width: "90%",
            background: "lightgray",
            marginTop: "10px"
          }}
        ></div> */}
        <hr/>
      </div>
      <div style={{ marginTop: "20px", marginLeft: "10px" }}>
        <div className="fs-2" style={{ color: "#2c308e" }}>0</div>

        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
          <div style={{ color: "#9f9f9f", marginLeft: "10px" }} className="pointer-cursor" onClick={() => navigate("/trades?tab=executed")}>
            Executed Orders
          </div>
        </div>
        {/* <div
          style={{
            height: "1px",
            width: "90%",
            background: "lightgray",
            marginTop: "10px"
          }}
        ></div> */}
        <hr/>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          width: "100%",
          marginLeft: "10px"
        }}
      >
        <div className="fw-bold" style={{ fontSize: "20px" }}>
          Portfolio
        </div>
        <div className="pointer-cursor" onClick={() => navigate("/trades?tab=positions")}>View All</div>
      </div>

        <div className="row ">
          <div className="col-lg-6 col-md-6 p-0 px-1">
          <Button className="btn-position w-100">
              Positions
          </Button>&nbsp;&nbsp;
          </div>
          <div className="col-lg-6 col-md-6 p-0 px-1">
          <Button className="btn-fq w-100">
              F&O
          </Button>
        </div>
        <Table className="p-0">
            <thead className="text-center text-muted">
              <tr>
                <th>Instruments</th>
                <th>P&L</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                <td>
                  TATA MOTORS
                </td>
                <td>
                  320000
                </td>
              </tr>
              <tr>
                <td>
                  TATA MOTORS
                </td>
                <td>
                  320000
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <div
          style={{
            height: "40px",
            padding: "8px",
            background: "#e1f2fa",
            borderRadius: "8px",
            textAlign: "center",
            flex: 1,
            marginRight: "10px"
          }}
        >
          Positions
        </div>
        <div
          style={{
            height: "40px",
            flex: 1,
            padding: "8px",
            background: "#f5f5f5",
            borderRadius: "8px",
            textAlign: "center"
          }}
        >
          F&O
        </div> */}
      {/* </div> */}

         
    </div>
  );
};

export default DeployedBox;

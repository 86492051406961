// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Spinner.css - Ensure this is properly linked and the class names match */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4b9cdb;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* animation: spin 1s linear infinite; */
  margin: 20px auto; /* Center the spinner */
  display: block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/componentCommon/myspinner.css"],"names":[],"mappings":"AAAA,2EAA2E;AAC3E;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,iBAAiB,EAAE,uBAAuB;EAC1C,cAAc;AAChB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* Spinner.css - Ensure this is properly linked and the class names match */\n.spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-left-color: #4b9cdb;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  /* animation: spin 1s linear infinite; */\n  margin: 20px auto; /* Center the spinner */\n  display: block;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;



const getCandleId = (formData) => {
    let candleId;
    if (formData?.candleIntervalId === 1) {
      candleId = "1minute";
    } else if (formData?.candleIntervalId === 3) {
      candleId = "3minute";
    } else if (formData?.candleIntervalId === 5) {
      candleId = "5minute";
    } else if (formData?.candleIntervalId === 10) {
      candleId = "10minute";
    } else if (formData?.candleIntervalId === 15) {
      candleId = "15minute";
    } else if (formData?.candleIntervalId === 30) {
      candleId = "30minute";
    } else if (formData?.candleIntervalId === 60) {
      candleId = "60minute";
    } else if (formData?.candleIntervalId === "1day") {
      candleId = "1day";
    } else if (formData?.candleIntervalId === "2h") {
      candleId = "2h";
    } else if (formData?.candleIntervalId === "3h") {
      candleId = "3h";
    } else if (formData?.candleIntervalId === "4h") {
      candleId = "4h";
    }
  
    return candleId;
  };
  
  
  export default getCandleId ;
import formateFnObject from "../formateFnObject";
import generateMathOutput from "./generateMathOutput";

const mergeArrays = (
  firstArray,
  secondArray,
  onFormChange,
  formData,
  isSelectMax,
  isSelectMin,
  isIncludeMathFunction,
  isExist
) => {
  console.log("MERGEARRA", firstArray, secondArray,
    onFormChange,
  formData,
  isSelectMax,
  isSelectMin,
  isIncludeMathFunction,
  isExist
  )
  const comparisonOperators = ["<", ">", "<=", ">="];
  const mathOperators = ["+", "-", "*", "/"];
  let updatedArrays = [];
  let currentArray = [];
  function getComparatorValue(name) {
    switch (name) {
      case "crosses above":
        return ">";
      case "crosses below":
        return "<";
      case "higher than":
        return ">";
      case "lower than":
        return "<";
      case "lower than equal to":
        return "<=";
      case "higher than equal to":
        return ">=";
      case "equal to":
        return "==";
      default:
        return null;
    }
  }

  const processFunctionValues = (functionName) => {
    if (!functionName) return {};
    const functionValues1 = functionName[0].split(",");
    const includesLogicalOperator = /&&|\|\|/.test(functionValues1.join(" "));
    const includesComparisonOperator = comparisonOperators.some((operator) =>
      functionValues1.includes(operator)
    );
    const includeMathOperator = mathOperators.some((operator) =>
      functionValues1.includes(operator)
    );
    const mathOperatorObject = includeMathOperator
      ? { comparator: functionValues1.join(" ") }
      : {};
    const operatorObject = includesLogicalOperator
      ? { operator: functionValues1.join(" ") }
      : {};
    return {
      includesLogicalOperator,
      includesComparisonOperator,
      includeMathOperator,
      functionValues1,
      mathOperatorObject,
      operatorObject,
    };
  };

  firstArray?.forEach((item, index) => {
    const functionName = secondArray[index]
      ? secondArray[index].replace(/[()]/g, "").split(/\s+/)
      : null;
    console.log("secondArray", secondArray, functionName)
    if (functionName && functionName.length === 1) {
      const {
        includesLogicalOperator,
        includesComparisonOperator,
        includeMathOperator,
        functionValues1,
        mathOperatorObject,
        operatorObject,
      } = processFunctionValues(functionName);
      if (includesLogicalOperator) {
        // Push the current array if it is not empty
        if (currentArray.length > 0) {
          updatedArrays.push(currentArray);
          currentArray = [];
        }
      }
      if (includesLogicalOperator) {
        // Push the operator object in a separate array
        updatedArrays.push([{ ...item, ...operatorObject }]);
      } else if (includesComparisonOperator) {
        currentArray.push({
          ...item,
          name: item?.name,
          function: functionName || null,
          field: null,
          period: null,
          offset: null,
          ...(item?.name === "crosses above"
            ? { isCrossover: 2 }
            : item?.name === "crosses below"
            ? { isCrossover: 1 }
            : {}),
          comparator: includesComparisonOperator
            ? getComparatorValue(item?.name) || functionValues1.join(" ")
            : null,
          ...operatorObject,
        });
      } else if (includeMathOperator) {
        currentArray.push({
          function_id: item?.function_id,
          function_type_id: item?.function_type_id,
          name: item?.name,
          ...mathOperatorObject,
        });
      }
    }
    let newItem = formateFnObject(item, functionName);
    if (newItem) currentArray.push(newItem);
  });
  if (currentArray.length > 0) {
    updatedArrays.push(currentArray);
  }
  if (isExist === "exist") {
    formData.exit_functions = updatedArrays;
  } else {
    formData.functions = updatedArrays;
  }
  console.log("FORMDATA1", updatedArrays)
  onFormChange(formData);
  if (formData?.isIncludeMathFunction || isIncludeMathFunction?.current) {
    generateMathOutput(
      formData,
      onFormChange,
      isExist == "exist" ? formData?.exit_indicators : formData?.indicators,
      isSelectMax,
      isSelectMin,
      isExist == "exist" && "exist"
    );
  }
};

export default mergeArrays;

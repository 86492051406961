import React from 'react'
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import { useEffect } from 'react';
import { useState } from 'react';
import { GetAllSaleList } from '../api/adminapi';
import { getallfeedback, getallfeedbacks } from '../api/profileapi';
import ViewIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import ViewModal from '../Components/modal/Viewmodal';
function Feedback() {
    const [userRecords , setUserRecords] = useState([]);
    const [showModal, setShowModal] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState('');
    const customStyles = {
        rows: {
          style: {
            border : "1px solid #8080805e", // override the row height
            minHeight: '40px',
          },
        },
        color:"#ffffff",
        headCells: {
          style: {
            border : "1px solid #8080805e",
            fontSize : "medium",
            fontWeight : "bold",
            borderBottom : "none",
            minHeight: '30px',
          },
        },
        cells: {
          style: {
            borderRight : "1px solid #8080805e", // override the row height
            // color:"#76838F"
            // height : "2px"
          },
        },
        
      };
    
      const columns =  [
        {
            name: "Sr. No",
            selector: (row, index) => index + 1,
            sortable: true,
          },
        {
            name: "Title",
            selector: row => row?.title ?? '-',
          },
        {
          name : "Description",
          selector : row => row.description,
          // sortable :true
        },
        {
            name: "View",
            cell: (row) => (
              <IconButton onClick={() => handleViewClick(row.id)}>
                <ViewIcon style={{ color: 'blue' }} />
              </IconButton>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
      
      ]
    useEffect(() => {
        fetchUsersData();
        
      } ,[]);
      const handleCloseModal = () => {
        setShowModal(false);
        setSelectedDescription('');
      };
    const fetchUsersData = async() =>{
        const result = await getallfeedback(); 
        console.log("result@@",result);
        const reversedata=result?.data.reverse();
        setUserRecords(reversedata);
      }
      const handleViewClick = async (id) => {
            const result=await getallfeedbacks({id:id})
            console.log("909090",result?.data[0]);
            setSelectedDescription(result?.data ? result.data[0]?.description:'');
            setShowModal(true);
      };
 
  return (
    <div className="page">
    <div className="page-content container-fluid">
    <h3 className="panel-title">Feedback</h3>
    <div className="row">
    <div className="col-xl-12 col-lg-12">
     <div className="panel">
   
   <div className="panel-body ">      
      <DataTable 
        columns={columns}
        data = {userRecords}
        pagination
        fixedHeader
        customStyles={customStyles}
        highlightOnHover
        striped
        pointerOnHover
        responsive
       
      >

      </DataTable>
    </div>
    </div>
    </div>
    </div>
    </div>
    <ViewModal
        show={showModal}
        onHide={handleCloseModal}
        description={selectedDescription}
      />
    </div>
  )
}

export default Feedback
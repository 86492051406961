import React, { useState, useEffect, useMemo } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useMenuContext } from "./StrategyCreateComponent";
import StocksList from "./StocksListBox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CreateStockBox from "./CreateStockBox";
import SelStockBox from "./selStockBox";

import "moment/locale/de";
import {
  get5paisaToken,
  getZerodhaToken,
  save5paisaToken,
} from "../../Session";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import moment from "moment";
import { addNewStrategy, getStockLTPDataAPI } from "../../api/api";
import { useNavigate } from "react-router-dom";
import NumberInput from "../NumberInput";
// import AlertShow from "../../mcomponents/AlertShow";

const StrategyReviewComponent = ({
  formData,
  onFormChange,
  stocks,
  setStocks,
  handleDates,
  handleSave,
  handleSaveFalse,
  setCreateStratgyPage
}) => {
  console.log("DateCheck", formData);
  const { menu, setMenu } = useMenuContext();
  const [showList, setShowList] = useState(false);
  const [initialCapitalError, setInitialCapitalError] = useState(false);

  const [capital, setCapital] = useState(formData.initial_capital);

  const [ltpData, setLtpData] = useState();
  const [quantityReadOnly, setQuantityReadOnly] = useState(false);
  const [maxAllocationReadOnly, setMaxAllocationReadOnly] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [stockTokens, setStockTokens] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // const today = new Date();

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.stocks?.length > 0) {
      // setInterval(() => {
      console.log("e_stockTokens???", formData.stocks);
      // getStocksData();
      // }, 2000);
    }
  }, [formData.stocks]);

  // useEffect(() => {
  //   if (stocks?.length > 0) fetchLtpData();
  // }, [stocks]);

  // useEffect(() => {
  //   if (formData.stocks?.length > 0) fetchLtpDataExist();
  // }, [formData.stocks]);

  useEffect(() => {
    console.log("existingStrategy-->", formData?.strategy_id);
    if (formData.position_sizing_type == "risk based") {
      formData.maxSlPerTrade = formData.max_allocation;
      onFormChange(formData);
    }
  }, []);

  useEffect(() => {
    if (formData.candle_interval_id === "1d")
      onFormChange({
        ...formData,
        holding_type: "CNC/NRML",
      });
  }, [formData.candle_interval_id]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const addNewStrategey = async () => {
    console.log("formDataCheck>>==", formData);
    setLoading(true);
    try {
      let flag = 0; 
      if (formData.initial_capital === "") {
        flag = 1;
        setInitialCapitalError(true);
        console.log("checkdata");
      }
      if (flag === 0) {
        const isEmpty = isEmptyObject(formData?.indicators);
        if (isEmpty) {
          alert("Please add entry condition");
          setLoading(false);
          return;
        }
        if (stocks?.length === 0 && formData.stocks?.length === 0) {
          alert("Please select stock");
          return;
        }
      
        if(formData?.quantity===0 || formData?.quantity==="")
          {
              if(formData.position_sizing_type==='capital based' && (formData?.max_allocation === 0 || formData?.max_allocation === ""))
              {
                alert("Please select quantity");
                setLoading(false);
              }
              if(formData.position_sizing_type==='risk based' && (formData?.maxSlPerTrade === 0 || formData?.maxSlPerTrade === ""))
              {
                alert("Please select quantity");
                setLoading(false);
              }
               if((formData.position_sizing_type==='' || formData.position_sizing_type==='-') 
                && (formData?.max_allocation === 0 || formData?.max_allocation === ""))
               {
                alert("Please select quantity");
                setLoading(false);
               }
          }
 
        const addNew = await addNewStrategy(formData);
        // console.log("added???", addNew);
        if (addNew) setLoading(false);
        if(addNew.strategy_id===undefined || addNew.strategy_id==='undefined')
          {
              alert("Strategy not created successfully, Please try again");
              setCreateStratgyPage(false);
              navigate("/strategies");
          }
          else
          {
            navigate("/strategies/" + `${addNew?.strategy_id}`);
            handleSaveFalse();
          }
      }
      // navigate("/strategies/viewall");
    } catch (error) {
      console.error("Error fetching:", error);
    }

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  };
  const handleCheckboxChange = (value) => {
    onFormChange({
      ...formData,
      previous_day: value === true ? 1 : 0,
    });
    // setIsChecked(value);
  };

  const handleClickCandleInterval = (timeid) => {
    let startDate = "";
    let endDate = "";
    if (timeid === 1) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(29, "days").format("YYYY-MM-DD"); // Assign end date for 1min and 2min intervals
    } else if (
      timeid === 3 ||
      timeid === 5 ||
      timeid === 10 ||
      timeid === 15 ||
      timeid === 30
    ) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(89, "days").format("YYYY-MM-DD"); // Assign
    } else if (timeid === 60) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      // endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
      endDate = moment().subtract(6, "months").format("YYYY-MM-DD");
    } else {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(5, "year").format("YYYY-MM-DD"); // Assign
    }

    onFormChange({
      ...formData,
      candleIntervalId: timeid,
      start_date: endDate,
      end_date: startDate,
      candle_interval_id:
        timeid === 1
          ? "1m"
          : timeid === 3
          ? "3m"
          : timeid === 5
          ? "5m"
          : timeid === 10
          ? "10m"
          : timeid === 15
          ? "15m"
          : timeid === 30
          ? "30m"
          : timeid === 60
          ? "60m"
          : timeid === "1day"
          ? "1d"
          : "",
    });

    setMenu("entry");
  };

  const getLtpPnlData = async (exch, exch_seg, token1) => {
    console.log("My params", exch, exch_seg, token1);

    const token = await get5paisaToken();
    console.log("My token", token);

    if (token) {
      const method = "POST";
      return new Promise((resolve, reject) => {
        if (method === "POST") {
          const subscribeMessage = {
            Method: "MarketFeedV3",
            Operation: "Subscribe",
            ClientCode: "59489811",
            MarketFeedData: [
              {
                Exch: exch,
                ExchType: exch_seg,
                ScripCode: token1,
              },
            ],
          };
          const accessToken = token;
          const clientCode = "59489811";

          const url = `wss://openfeed.5paisa.com/Feeds/api/chat?Value1=${accessToken}|${clientCode}`;
          const ws = new WebSocket(url);

          ws.onopen = () => {
            ws.send(JSON.stringify(subscribeMessage));
          };

          ws.onmessage = (event) => {
            const marketFeedData = JSON.parse(event.data);
            console.log("My data from WS ", marketFeedData);
            resolve(marketFeedData);
          };

          ws.onclose = () => {
            reject(new Error("WebSocket connection closed"));
          };

          ws.onerror = (error) => {
            console.log("errrr>", error);
            reject(error);
          };
        }
      });
    } else {
      // console.log("Token not found");
    }
  };

  const fetchLtpData = async () => {
    try {
      const updatedStrategiesData = [];

      for (const item of stocks) {
        let lastRate = 0;
        let openRate = 0;
        if (item.ExchType && item.Scripcode) {
          const ltp = await getLtpPnlData(
            item?.Exch,
            item.ExchType,
            item.Scripcode
          );
          lastRate = ltp ? ltp[0].LastRate : null;
          openRate = ltp ? ltp[0].OpenRate : null;
        }
        console.log("returned", lastRate, openRate);
        updatedStrategiesData.push({ ...item, lastRate, openRate });
      }
      // console.log(":..:..:***", updatedStrategiesData);
      setLtpData(updatedStrategiesData); // Set updated data using setLtpData
    } catch (error) {
      console.error("Error fetching LTP data:", error);
    }
  };
  const fetchLtpDataExist = async () => {
    try {
      const updatedStrategiesData = [];

      for (const item of formData.stocks) {
        let lastRate = 0;
        let openRate = 0;
        if (item.ExchType && item.Scripcode) {
          const ltp = await getLtpPnlData(
            item?.Exch,
            item.ExchType,
            item.Scripcode
          );
          lastRate = ltp ? ltp[0].LastRate : null;
          openRate = ltp ? ltp[0].OpenRate : null;
        }
        console.log("returned", lastRate, openRate);
        updatedStrategiesData.push({ ...item, lastRate, openRate });
      }
      console.log("updatedStrategiesData", updatedStrategiesData);
      setLtpData(updatedStrategiesData); // Set updated data using setLtpData
    } catch (error) {
      console.error("Error fetching LTP data:", error);
    }
  };

  const handleClickHoldingType = (value) => {
    onFormChange({
      ...formData,
      holding_type: value,
    });
  };
  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    if (name === "max_allocation") {
      onFormChange({
        ...formData,
        quantity: 0,
        [name]: parseInt(value) || "",
      });
      value > 0 || value !== ""
        ? setQuantityReadOnly(true)
        : setQuantityReadOnly(false);
    } else if (name === "quantity") {
      onFormChange({
        ...formData,
        max_allocation: 0,
        [name]: parseInt(value) || "",
      });
      value > 0 || value !== ""
        ? setMaxAllocationReadOnly(true)
        : setMaxAllocationReadOnly(false);
    } else {
      onFormChange({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleSelectChange = (event) => {
    onFormChange((prevData) => ({
      ...prevData,
      position_id: event.target.value,
    }));
  };

  const handleReviewEnddate = (newValue) => {
    if (newValue) {
      // Format the selected date using Moment.js format
      const formattedDate = moment(newValue).format("YYYY-MM-DD");

      // Update the start_date property in the form data
      onFormChange((prevFormData) => ({
        ...prevFormData,
        end_date: formattedDate,
      }));
      /////////////CALCULATE START DATE////////////////////////
      const startDate = calculateStartDate(formattedDate);
      // formData.end_date = endDate;
      // onFormChange(formData);
      onFormChange((prevFormData) => ({
        ...prevFormData,
        start_date: startDate,
      }));
    }
  };

  const handleReviewStartdate = (newValue) => {
    if (newValue) {
     
      // Format the selected date using Moment.js format
      const formattedDate = moment(newValue).format("YYYY-MM-DD");

      // Update the end_date property in the form data
      onFormChange((prevFormData) => ({
        ...prevFormData,
        start_date: formattedDate,
      }));

      // Perform other actions if needed
      // For example, you might want to call another function here
      // handleClickCandleInterval3();

      // const startDate = "2024-01-22"; // Your start date
      const endDate = calculateEndDate(formattedDate);
      // formData.end_date = endDate;
      // onFormChange(formData);
      onFormChange((prevFormData) => ({
        ...prevFormData,
        end_date: endDate,
      }));
    }
  };

  function calculateStartDate(endDate) {
    const end = moment(endDate);
    const currentDate = moment();
    const currentStartDate = moment(formData.start_date);

    if (formData?.candle_interval_id === "1m") {
      const oneMonthEarlier = end.clone().subtract(1, "month");
      // Check if the difference is less than 1 month
      if (end.diff(currentStartDate, "days") < 30) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if one month earlier is before the current date
      if (oneMonthEarlier.isBefore(currentDate.clone().subtract(1, "month"))) {
        return oneMonthEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(1, "month").format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "3m" ||
      formData?.candle_interval_id === "5m" ||
      formData?.candle_interval_id === "10m" ||
      formData?.candle_interval_id === "15m" ||
      formData?.candle_interval_id === "30m" 
      // ||
      // (formData?.candle_interval_id === "60m" &&
      //   formData.monthOrWeekAdded === false) ||
      // (formData?.candle_interval_id === "1d" &&
      //   formData.monthOrWeekAdded === false)
    ) {
      const threeMonthsEarlier = end.clone().subtract(3, "month");
      // Check if the difference is less than 3 months
      if (end.diff(currentStartDate, "days") < 90) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if three months earlier is before the current date
      if (
        threeMonthsEarlier.isBefore(currentDate.clone().subtract(3, "month"))
      ) {
        return threeMonthsEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(3, "month").format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "60m" 
      // && formData.monthOrWeekAdded === true
    ) {
      const oneYearEarlier = end.clone().subtract(1, "year");
      // Check if the difference is less than 1 year
      if (end.diff(currentStartDate, "days") < 365) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if one year earlier is before the current date
      if (oneYearEarlier.isBefore(currentDate.clone().subtract(1, "year"))) {
        return oneYearEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(1, "year").format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "1d" 
      // && formData.monthOrWeekAdded === true
    ) {
      const fiveYearsEarlier = end.clone().subtract(5, "year");
      // Check if the difference is less than 5 years
      if (end.diff(currentStartDate, "days") < 1825) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if five years earlier is before the current date
      if (fiveYearsEarlier.isBefore(currentDate.clone().subtract(5, "year"))) {
        return fiveYearsEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(5, "year").format("YYYY-MM-DD");
      }
    }

    return endDate; // Fallback in case none of the conditions match
  }

  function calculateEndDate(startDate) {
    if (formData?.candle_interval_id === "1m") {
      const oneMonthLater = moment(startDate).add(1, "month");
      const currentDate = moment();
      // Check if one month later exceeds current date
      if (oneMonthLater.isAfter(currentDate)) {
        console.log("in isAfter");
        return currentDate.format("YYYY-MM-DD");
      } else {
        console.log("in isAfter2");

        return oneMonthLater.format("YYYY-MM-DD");
      }
    }
    if (
      formData?.candle_interval_id === "3m" ||
      formData?.candle_interval_id === "5m" ||
      formData?.candle_interval_id === "10m" ||
      formData?.candle_interval_id === "15m" ||
      formData?.candle_interval_id === "30m"
      //  ||
      // (formData?.candle_interval_id === "60m" &&
      //   formData.monthOrWeekAdded === false) ||
      // (formData?.candle_interval_id === "1d" &&
      //   formData.monthOrWeekAdded === false)
    ) {
      const oneMonthLater = moment(startDate).add(3, "month");
      const currentDate = moment();
      // Check if one month later exceeds current date

      if (oneMonthLater.isAfter(currentDate)) {
        return currentDate.format("YYYY-MM-DD");
      } else {
        return oneMonthLater.format("YYYY-MM-DD");
      }
    }
    if (
      formData?.candle_interval_id === "60m" 
      // && formData.monthOrWeekAdded === true
    ) {
      const oneYearLater = moment(startDate).add(1, "year");
      const currentDate = moment();
      // Check if one year later exceeds current date
      if (oneYearLater.isAfter(currentDate)) {
        return currentDate.format("YYYY-MM-DD");
      } else {
        return oneYearLater.format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "1d" 
      // && formData.monthOrWeekAdded === true
    ) {
      const fiveYearsLater = moment(startDate).add(5, "year");
      const currentDate = moment();
      // Check if five years later exceeds current date
      if (fiveYearsLater.isAfter(currentDate)) {
        return currentDate.format("YYYY-MM-DD");
      } else {
        return fiveYearsLater.format("YYYY-MM-DD");
      }
    }
  }

  const getSelectedItems = (items) => {
    console.log("items>", items);
    const itemArray = [];
    items.map((i) => {
      itemArray.push(i.token);
    });
    setStocks(items);

    let tokens = items?.map((i) => parseInt(i?.instrument_token));
    console.log("tokens>", tokens);
    setStockTokens(tokens);

    console.log("itemsUpdated2>", items);
    formData.stocks = items;
    onFormChange(formData);

    // onFormChange((prevData) => ({
    //   ...prevData,
    //   noStock: items?.length > 0 ? true : false,
    //   stock_name: items[0]?.name,
    //   script_code: items[0]?.token,
    //   scripCode: items[0]?.token,
    // }));
    console.log("JJJJ2", items[0]?.name);
  };

  const handleRemove = (index) => {
    const updatedSelectedItems = [...stocks];
    updatedSelectedItems.splice(index, 1);
    setStocks(updatedSelectedItems);

    if (updatedSelectedItems?.length === 0) setStockTokens([]);
    formData.stocks = updatedSelectedItems;

    onFormChange({
      ...formData,
      stocks: updatedSelectedItems,
    });
  };

  const getStocksData = async () => {
    console.log("stockTokensSent", stockTokens);
    const token = getZerodhaToken();

    const data = await getStockLTPDataAPI({
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
      token: stockTokens,
    });
    if (data) {
      console.log("stocksLTP>", data?.data);
      console.log("stocksLTP2>", stocks);

      updateStocksData(formData.stocks, data?.data);
    }
  };

  const updateStocksData = (stocks, data) => {
    data.forEach((dataItem) => {
      const stockToUpdate = stocks.find(
        (stock) => stock.instrument_token == dataItem.instrument_token
      );
      console.log("stockToUpdate", stockToUpdate);
      if (stockToUpdate) {
        stockToUpdate.ltp = dataItem.last_price;
        stockToUpdate.closePrice = dataItem.ohlc.close;
      }

      console.log("stocks===", stocks);
      setLtpData(stocks);
      formData.stocks = stocks;
      onFormChange(formData);
    });
  };

  // const MemoizedSelStockBox = useMemo(() => React.memo(SelStockBox), [stocks]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(1.5px)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="row" id="uplad-first-stock-div">
        {/* <>
        {stocks?.length !== 0 || formData.stocks?.length !== 0 ? (
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              marginBottom: "15px",
            }}
          >
            <CreateStockBox setShowList={setShowList} />
            {formData.stocks?.map((item, index) => (
              <MemoizedSelStockBox
                item={item}
                onRemove={handleRemove}
                index={index}
              />
            ))}
          </div>
        ) : (
          <>
            <div className="col-lg-12 p-0 d-flex justify-content-between">
              <span className="text-danger">Please select some stocks</span>
            </div>
            <Card className="uplad-first-stock-card">
              <Card.Body>
                <div className="col-lg-12 lh-lg">
                  <div>
                    <i className="fa fa-plus"></i>
                  </div>
                  <div>
                    <h3>Add your first stock</h3>
                  </div>
                  <div>
                    <Button
                      className="btn-color"
                      onClick={() => setShowList(true)}
                    >
                      <i className="fa fa-plus"></i> Add Symbol, F&O etc
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
        </> */}
        {/* <div className="pt-3 p-0" id="create-strategy-chart-tab">
          <h6 className="fw-5 fs-5">Chart</h6>
          <div
            className="col-lg-12 technical-nifty-tab"
            id="technical-nifty-tab"
          >
            <Card>
              <Card.Body>
                <div className="row p-0 justify-content-center">
                  <div className="col-lg-2 p-0">
                    <div style={{ marginLeft: "20px" }}>
                      <span className="fw-bold ">Chart Type</span>
                      <div id="strategy-chart-type-btn" className="pt-2">
                        <ListGroup
                          horizontal
                          style={{ height: "60%" }}
                          className="p-0"
                        >
                          <ListGroup.Item
                            action
                            variant="primary"
                            className="active"
                            // onClick={()=>{handleChangeCharType(1)}}
                          >
                            <i className="fa fa-sliders"></i>
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            disabled
                            variant="primary"
                            // onClick={()=>{handleChangeCharType(2)}}
                          >
                            <i className="fa fa-sliders"></i>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 col-md-5 col-sm-7"
                    style={{ paddingLeft: "100px" }}
                  >
                    <div>
                      <span className="fw-bold">Candle Interval</span>
                    </div>
                    <ListGroup
                      horizontal
                      style={{ height: "60%" }}
                      className="pt-2 p-0"
                    >
                      {[1, 3, 5, 10, 15, 30, 60, "1day"].map((interval) => (
                        <ListGroup.Item
                          key={interval}
                          action
                          variant="primary"
                          className={`custom-list-item${
                            formData.candleIntervalId === interval
                              ? " active"
                              : ""
                          }`}
                          onClick={() => handleClickCandleInterval(interval)}
                        >
                          {typeof interval === "number"
                            ? `${interval}min`
                            : interval}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                  <div className="col-lg-3 col-md-3 previous-day-alert-candle">
                    <div>
                      <span className="fw-bold">Previous Day Alert Candle</span>
                    </div>
                    <div
                      className="mt-2 d-flex"
                      style={{ paddingLeft: "30px" }}
                    >
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id={`default-checkbox`}
                        label={`Yes`}
                        value={1}
                        name="previous_day"
                        checked={formData.previous_day}
                        onChange={() => handleCheckboxChange(true)}
                        className="fw-5"
                      />{" "}
                      &nbsp;&nbsp;
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id={`default-checkbox`}
                        label={`No`}
                        value={0}
                        checked={!formData.previous_day}
                        onChange={() => handleCheckboxChange(false)}
                        name="previous_day"
                        className="fw-5"
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-2  col-md-3 col-sm-3 p-0 support-resistance-btn"
                    id="strategy-holding-type-btn"
                  >
                    <div>
                      <span className="fw-bold">Holding type</span>
                    </div>
                    <ListGroup horizontal className="pt-2 p-0">
                      <ListGroup.Item
                        action
                        variant="primary"
                        onClick={() => {
                          if (formData.candle_interval_id === "1d") {
                            alert("For 1Day candle interval cannot select MIS");
                            return;
                          }
                          handleClickHoldingType("MIS");
                        }}
                        className={
                          formData.holding_type === "MIS" ? "active" : ""
                        }
                      >
                        MIS
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        className={
                          formData.holding_type === "CNC/NRML" ? "active" : ""
                        }
                        onClick={() => {
                          handleClickHoldingType("CNC/NRML");
                        }}
                        variant="primary"
                      >
                        CNC/NRML
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="pt-3 p-0">
          <h6 className="fw-6 fs-5">Quantity</h6>
          <Card className="quantity-card">
            <Card.Body>
              <Form>
                <Row>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Quantity (in lots)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="quantity"
                        readOnly={quantityReadOnly}
                        className={quantityReadOnly ? "not-allowed-cursor" : ""}
                        onChange={handleChangeValues}
                        value={formData.quantity}
                        placeholder="Quantity in lots(1=1)"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">Max Allocation</Form.Label>
                      <Form.Control
                        type="text"
                        name="max_allocation"
                        readOnly={maxAllocationReadOnly}
                        className={
                          maxAllocationReadOnly ? "not-allowed-cursor" : ""
                        }
                        onChange={handleChangeValues}
                        value={formData.max_allocation}
                        placeholder="Amount per trade (Max allocation)"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Max Quantity ( in lots)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="max_quantity"
                        value={formData.max_quantity}
                        onChange={handleChangeValues}
                        placeholder="Max quantity in lots(1=1)"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Position Sizing type
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={formData.position_sizing_type}
                      >
                        <option value="">-</option>
                        <option value="capital based">Capital Based</option>
                        <option value="risk based">Risk Based</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div> */}
        {/* <div className="row pt-4 p-0">
          <div className="col-lg-6 entry-card ">
            <Card>
              <Card.Body>
                <h6 className="fw-6">Entry</h6>
                <h6 onClick={() => setMenu("entry")}>
                  {formData?.selectedOption1 ? null : (
                    <Button variant="outline-primary" size="sm">
                      <i className="fa fa-plus"></i>
                    </Button>
                  )}
                  &nbsp;
                  <span className="priview-sub-text">
                    {formData?.functionText1
                      ? `BUY ${formData?.quantity} share when (${formData?.functionText1} )) at ${formData?.candle_interval_id} candle interval using candlestick chart.
                        `
                      : "Add Entry Conditions here"}
                  </span>
                </h6>
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-6 exit-card p-0">
            <Card>
              <Card.Body>
                <h6 className="fw-6">Exit</h6>
                <h6 onClick={() => setMenu("exist")}>
                  {formData?.functionText2 ? null : (
                    <Button variant="outline-primary" size="sm">
                      <i className="fa fa-plus"></i>
                    </Button>
                  )}
                  &nbsp;
                  <span className="priview-sub-text">
                    {formData?.stop_loss && formData?.functionText2
                      ? `Sell ${formData?.quantity} share when (${formData?.functionText2} )) at ${formData?.candle_interval_id} candle interval using candlestick chart.`
                      : // `Sell ${formData?.quantity} share at stop loss % of ${formData?.stop_loss} or target profit % ${formData?.target_profit} at ${formData?.candle_interval_id} candle interval using candlestick chart`
                        "Add Exit Conditions here"}
                  </span>
                </h6>
              </Card.Body>
            </Card>
          </div>
        </div> */}

        <div className="col-lg-12 backet-parameters-card pt-4 p-0">
          <Card>
            <Card.Body>
              <h6 className="fs-5 fw-6">Backtest Parameters</h6>
              <Form>
                <Row>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">Initial Capital</Form.Label>
                      {/* <Form.Control
                        name="initial_capital"
                        type="text"
                        placeholder="100000"
                        value={capital}
                        onChange={(e) => {
                          setCapital(e.target.value);
                          formData.initial_capital = e.target.value;
                        }}
                      /> */}

                      <NumberInput
                        type="number"
                        placeholder="for eg. 100000"
                        value={formData.initial_capital}
                        name="initial_capital"
                        onChange={handleChangeValues}
                      />
                      {initialCapitalError && (
                        <span className="text-error">
                          {" "}
                          Please enter initial capital
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Daily strategy cycle
                      </Form.Label>
                      <Form.Select
                        onClick={() => {
                          setAlertMsg(
                            "Cannot select daily strategy cycle for holding type CNC/NRML"
                          );
                          if (formData.holding_type === "CNC/NRML") {
                            setShowAlert(true);
                            setSeverity("error");
                            setTimeout(() => {
                              setShowAlert(false);
                            }, 2000);
                          }
                        }}
                        aria-label="Default select example"
                        value={formData.daily_strategy_cycle}
                        name="daily_strategy_cycle"
                        onChange={
                          formData.holding_type === "CNC/NRML"
                            ? null
                            : handleChangeValues
                        }
                      >
                        <option value="-">-</option>
                        {[...Array(20)].map((_, index) => (
                          <option key={index} value={(index + 1) * 5}>
                            {(index + 1) * 5}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Backtesting period
                      </Form.Label>
                      <div className="d-flex pt-1 align-items-center">
                        <div>Start date</div>&nbsp;
                        <input
                          style={{ minWidth: "150px" }}
                          type="date"
                          className="form-control w-25"
                          value={formData?.start_date}
                          onChange={(e) =>
                            handleReviewStartdate(e.target.value)
                          }
                          max={formData?.end_date}
                          onKeyDown={(e) => e.preventDefault()}
                          // onFocus={(e) => e.currentTarget.showPicker()}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <div>End date</div>&nbsp;
                        <input
                          style={{ minWidth: "150px" }}
                          type="date"
                          className="form-control w-25"
                          value={formData?.end_date}
                          onChange={(e) => handleReviewEnddate(e.target.value)}
                          max={moment().format("YYYY-MM-DD")}
                          min={formData?.start_date}
                          onKeyDown={(e) => e.preventDefault()}
                          // onFocus={(e) => e.currentTarget.showPicker()}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label className="fw-6">
                        Strategy Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={formData.description}
                        onChange={handleChangeValues}
                        rows={4}
                        placeholder="Add your strategy description here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex align-items-end flex-column ">
          <div className="ml-auto p-2">
            <Button
              onClick={() => {
                // prepare();
                handleSave(true);
                // console.log("SSSSSS", formData);
                // if (formData?.position_sizing_type === "capital based") {
                //   if (formData.max_allocation === "") {
                //     alert("Please Enter Max Allocation");
                //     return;
                //   }
                // }
                // if (formData?.position_sizing_type === "risk based") {
                //   if (
                //     formData.maxSlPerTrade === "" ||
                //     formData.maxSlPerTrade === undefined
                //   ) {
                //     alert("Please Enter Max SL Per Trade");
                //     return;
                //   }
                // }
                setLoading(true);
                setTimeout(() => {
                  addNewStrategey();
                }, 2500);
              }}
              className="btn-color"
            >
              Save and Backtest
            </Button>
          </div>
        </div>
      </div>
      <div>
        {showList && (
          <StocksList
            storeSelectedItems={getSelectedItems}
            setShowList={setShowList}
            selectedStock={stocks}
            formData={formData}
          />
        )}
      </div>

      {/* <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      /> */}
    </>
  );
};

export default StrategyReviewComponent;

import axios from "axios";
import { getZerodhaToken } from "../Session";
const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const createScannerApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getAllSavedStrategies`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
// /demataccount/getLiveScannerDetails
export const LiveScannerApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getLiveScannerDetails`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const getStrategyListapi = async () => {
  try {
    const response = await axios.get(
      "https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
    );
    return response.data;
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getScannerResultApi = async (data) => {
  try{
    const response = await axios.post(`${API_BASE_URL}/demataccount/getDiscoverScannerResult`,data);
    if(response.data.success){
       return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const getAllSavedScannersApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getAllSavedScanners`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("ERROR :", error);
  }
};

export const getIndices = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/strategies/getIndicesList`
    );
    if (response.data.success) {
      return response.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getIndices ERROR :", error);
  }
};

export const getStocksUnderIndices = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getStocksUnderIndices`,
      data
    );
    if (response.data.success) {
      return response.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getIndices ERROR :", error);
  }
};

export const deployScanner = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/deployScanner`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("deployScanner ERROR :", error);
  }
};

export const stopLiveScanner = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/stopLiveScan`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("stopLiveScan ERROR :", error);
  }
};

export const getLiveScannerResultAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getLiveScannerResult`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getLiveScannerResult ERROR :", error);
  }
};

export const getScannerResultAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getScannerResult`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getScannerResult ERROR :", error);
  }
};
export const deleteScanner=async(data)=>{
  try{
    const response= await axios.post(`${API_BASE_URL}/strategies/deleteDiscoverScanner`,data)
    if(response.data.success)
      return response.data;
      else 
        return false;
  }
  catch(error)
  {
    console.log("Error",error);
  }
}
export const addNewScannerAPI = async (data) => {
  data.access_token=getZerodhaToken();
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/addNewScanner`,
      data
    );

    if (response.data.success) 
      return response.data;
    else
      return false;
  } catch (error) {
    console.error("Error Adding New Scanner:", error);
  }
};

export const getLiveScanLogAPi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getLiveScanLogs`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getLiveScanLogs ERROR :", error);
  }
};

export const existScannerNameApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/adminpanel/checkScannerNameExist`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("checkScannerkNameExists Error :", error);
  }
};

export const deleteScannerAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/deleteScanner`,
      data
    );
    if (response.data.success) {
      console.log("deleted scanner?", data);
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getAllDiscoveredScannerApi = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/demataccount/getAllSavedDiscoveredScanners`
    );
    if (response.data.success) {
      console.log("getAllSavedDiscoveredScanners", response.data.data);
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getDiscoverScannerResultApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getDiscoverScannerResult`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const getScannerCategoriesApi = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/strategies/getScannerCategories`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getDiscoverScannersByIdApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getSavedDiscoveredScannersByCategoryId`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

import React from 'react'
import {GetBlogLists, GetBlogs,RemoveBlogs} from "../api/adminapi";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import { useEffect } from 'react';
import { useState } from 'react';
import ViewIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import ViewModal from '../Components/modal/Viewmodal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
function Blogs() {
  const navigate = useNavigate();
  const [userRecords , setUserRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const customStyles = {
    rows: {
      style: {
        border : "1px solid #8080805e", // override the row height
        minHeight: '40px',
      },
    },
    color:"#ffffff",
    headCells: {
      style: {
        border : "1px solid #8080805e",
        fontSize : "medium",
        fontWeight : "bold",
        borderBottom : "none",
        minHeight: '30px',
      },
    },
    cells: {
      style: {
        borderRight : "1px solid #8080805e", // override the row height
        // color:"#76838F"
        // height : "2px"
      },
    },
    
  };

  const columns =  [
    {
        name: "Sr. No",
        selector: (row, index) => index + 1,
        sortable: true,
        width:'100px',
      },
      {
        name: "Image",
        width:'200px',
        selector: row => (
          row.blog_img ? (<img className='my-2'
        src={"https://dalrotibackend.shrikanttapkeer.com/"+row.blog_img}
        alt={row.title}
        style={{ width: '120px', height: '120px', objectFit: 'cover' }}
      />):(<span><HorizontalRuleIcon/></span>)
        ),
         
      },
    {
        name: "Title",
        selector: row => row.title,
      },
      {
        name: 'Actions',
        width:'150px',
        cell: row => (
          <>
            <EditIcon
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => handleEditClick(row.id)}
            />
            <DeleteIcon
              style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
              onClick={() => handleDeleteClick(row.id)}
            />
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
  
  ]

  
useEffect(() => {
    fetchUsersData(); 
  } ,[]);
  
  const handleCloseModal = () => {
    setShowModal(false);
  };  
  
  const fetchUsersData = async() =>{
    const result = await GetBlogs(); 
    console.log("result@@",result);
    setUserRecords(result);
  }
  const handleEditClick = async (id) => {
    navigate(`/user/edit-blog/${id}`);
        // const result=await getallfeedbacks({id:id})
        // console.log("909090",result?.data[0]);
        // setSelectedDescription(result?.data ? result.data[0]?.description:'');
        // setShowModal(true);
  };
  const handleDeleteClick = async (id) => {
        const result=await RemoveBlogs({id:id})
        console.log("111",result);
        fetchUsersData();
  };
  const addnewblog=()=>{
    navigate(`/user/edit-blog/new`)
  }
  return (
    <div className="page">
    <div className="page-content container-fluid">
        <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Blogs</h3>

              <div className="panel-actions text-white">
                <button  className="btn btn-primary" onClick={addnewblog}>
                  <span className="hidden-sm-down">
                    <i className="fa fa-plus" aria-hidden="true"></i> Add New
                  Blogs
                    {/* <FontAwesomeIcon className="pl-2" icon={faPlus} /> */}
                  </span>
                </button>
              </div>
            </div>
            <div className="row pt-2">
    <div className="col-xl-12 col-lg-12">
     <div className="panel">
   
   <div className="panel-body w-100 ">      
      <DataTable 
        columns={columns}
        data = {userRecords}
        pagination
        fixedHeader
        customStyles={customStyles}
        highlightOnHover
        striped
        pointerOnHover
        responsive 
      >
      </DataTable>
    </div>
    </div>
    </div>
    </div>
            </div>
            </div>
        </div>
   
    </div>
    {/* <ViewModal
        show={showModal}
        onHide={handleCloseModal}
        description={selectedDescription}
      /> */}
    </div>
  )
}

export default Blogs
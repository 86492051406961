import React from "react";
import InputBox from "../Components/InputBox";
import { useState } from "react";
import App from "../App";
import { loginapi } from "../api/apiService";
import { getzerodhaacc } from "../api/apiService";
import { saveData ,saveloginuser,getid} from "../Session";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlelogin = async () => {
    if(email && password)
    {
    const result = await loginapi(email,password);
    const setLoginuserdata=saveloginuser(result);
        if (result?.success === true)
         {
              navigate("/Dashboard");
              getdetail();
         }
        
  }
  else
  {
    alert("Please Enter login credential");
  }
  };
  const getdetail = async () => {
    const id=await getid();
    console.log("MYID",id);
    const accdetails = await getzerodhaacc(id);
    console.log("MYID12", accdetails);
    const setSessionData = saveData(accdetails);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const h = {
    height: "100vh",
    marginTop: "100px",
  };
  const w = {
    width: "100%",
    border: "none",
  };
  return (
    <div className="d-flex justify-content-center" style={h}>
      <div>
        <div>
          <img src="./dalrotilogo.png" className="mb-3"></img>
        </div>

        {/* <br></br> */}
        <div className="mt-2">
            <InputBox
              type="email"
              placeholder="Email"
              onChange={handleEmailChange}
              value={email}
            />
          </div>
          <div className="mt-2">
            <InputBox
              type="password"
              placeholder="Password"
              onChange={handlePasswordChange}
              value={password}
            />
        </div>

        <div className="mx-auto mt-4">
          <Button variant="primary" onClick={handlelogin} style={w}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useMenuContext } from "./StrategyCreateComponent";
import { getfunctionTypeWisefunction } from "../../api/strategyapi";
import Dropdown from "../DropDown";
import SMAModal from "./SMAModal";
import SMAModal2 from "./SMAModal2";
import CompartorModal from "./ComparatorModal";
import ExitFormulaTab from "./ExitFormulaTab";

import Ulcer from "../modal/Ulcer";
import TriMA from "../modal/TriMA";
import Vwma from "../modal/Vwma";
import DEMA from "../modal/DEMA";
import TEMA from "../modal/TEMA";
import Open from "../modal/Open";
import Low from "../modal/Low";
import Close from "../modal/Close";
import HighPrice from "../modal/HighPrice";
import EMA from "../modal/EMA";
import SMA from "../modal/SMA";
import FormulaTab from "./FormulaTab";
import Number from "../modal/Number";
import MoneyFlowIndex from "../modal/MoneyFlowIndex";
import WilliamsR from "../modal/WilliamsR";
import MovingAverageDeviation from "../modal/MovingAverageDeviation";
import MACD from "../modal/MACD";
import OBV from "../modal/OBV";
import AroonDown from "../modal/AroonDown";
import AroonUp from "../modal/AroonUp";
import ParabolicSAR from "../modal/ParabolicSAR";
import VortexIndicator from "../modal/VortexIndicator";
import DonchianChannel from "../modal/DonchianChannel";
import Stochastic from "../modal/Stochastic";
import ChaikinMoneyFlow from "../modal/ChaikinMoneyFlow";
import CCI from "../modal/CommodityChannelIndex";
import PriceRateofChange from "../modal/PriceRateofChange";
import MACDsignal from "../modal/MACDsignal";
import AwesomeOscillator from "../modal/AwesomeOscillator";
import VWAP from "../modal/VWAP";
import Volume from "../modal/Volume";
import moment from "moment";
import RSIMovingAverage from "../modal/RSIMovingAverage";
import RSI from "../modal/RSI";
import BollingerB from "../modal/BollingerB";
import TR from "../modal/TR";
import ATR from "../modal/ATR";
import NATR from "../modal/NATR";
import Supertrend from "../modal/Supertrend";
import MfiMovingAverage from "../modal/MfiMovingAverage";
import KnowSureThing from "../modal/KnowSureThing";
import Ichimoku from "../modal/Ichimoku";
import CandleInterval from "../modal/CandleInterval";
import WMA from "../modal/WMA";
import MOM from "../modal/MOM";
import ChandeMomentumOscillator from "../modal/ChandeMomentumOscillator";
import PeriodMinModal from "../modal/PeriodMinModal";
import PeriodMaxModal from "../modal/PeriodMaxModal";
import replaceNewExistData from "../../Utils/replaceNewExistData";
import indicatorsSwitchCase from "../../Utils/indicatorsSwitchCase";
import {
  calculateAdjustedIndex,
  filterFunctionsBox888,
} from "../../Utils/indextoRemoveMath";
import {
  replaceExistModal,
  updateExistIndicator,
} from "../../Utils/replaceExistModal";
import { helperModalReplaceFn888 } from "../../Utils/helperModalSwitchFn";
import formateFnObject from "../../Utils/formateFnObject";
import generateMathOutput from "../../Utils/CommonFunction/generateMathOutput";
import getCandleId from "../../Utils/CommonFunction/getCandleId";
import FunctionListExist from "../componentCommon/FunctionListExist";
import mergeArrays from "../../Utils/CommonFunction/mergeArrays";
import VwapStandarddeviation from "../modal/VwapStandarddeviation";

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};

const selectedItemStyle = {
  backgroundColor: "#00aeee", // Customize the background color as needed
  fontWeight: "bold", // Add additional styles if needed
  border: "1px solid blue",
};

let eflag = 0;
const ExistCondition = ({ formData, onFormChange, save }) => {
  const isIncludeMathFunction = useRef(null);
  const { menu, setMenu, allStockStore } = useMenuContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [functionType, setFunctionType] = useState([]);
  const [conditionTab, setConditionTab] = useState(false);
  const [flag, setFlag] = useState(0);
  // const [showSMA, setIsShow] = useState(false);
  const [showSMA2, setIsShow2] = useState(false);
  const [showSMA3, setIsShow3] = useState(false);
  const [showList, setIsShow] = useState(false);
  const [mode, setMode] = useState("");
  const [remove, setRemove] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState(false);
  const [offset, setOffset] = useState(false);
  const [input1, setInput1] = useState(10);
  const [selectedItem, setSelectedItem] = useState("1");
  const [currentId, setCurrentId] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [innerAdd, setInnerAdd] = useState("");
  const [outerAdd, setOuterAdd] = useState("");
  //Modal state variables
  const [showUlcer, setShowUlcer] = useState(false);
  const [showTMA, setShowTMA] = useState(false);
  const [showVWMA, setShowVWMA] = useState(false);
  const [showDEMA, setShowDEMA] = useState(false);
  const [showTEMA, setShowTEMA] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [showLowPrice, setShowLowPrice] = useState(false);
  const [showHighPrice, setShowHighPrice] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showSMA1, setShowSMA] = useState(false);
  const [showEMA, setShowEMA] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [showMoneyFlowIndex, setShowMoneyFlowIndex] = useState(false);
  const [showWilliams, setShowWilliams] = useState(false);
  const [showMad, setShowMad] = useState(false);
  const [showMacd, setShowMacd] = useState(false);
  const [showObv, setShowObv] = useState(false);
  const [showOscilator, setShowOscilator] = useState(false);
  const [showAroonUp, setShowAroonUp] = useState(false);
  const [showAroonDown, setShowAroonDown] = useState(false);
  const [showpsar, setShowpsar] = useState(false);
  const [showVortexI, setShowVortexI] = useState(false);
  const [showDonchian, setShowDonchian] = useState(false);
  const [showStochastic, setShowStochastic] = useState(false);
  const [showChaikin, setShowChaikin] = useState(false);
  const [showCCI, setShowCCI] = useState(false);
  const [showRoc, setShowRoc] = useState(false);
  const [showMacdSignal, setShowMacdSignal] = useState(false);
  const [showVWAP, setShowVWAP] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [showRSI, setShowRSI] = useState(false);
  const [showRSIMA, setShowRSIMA] = useState(false);
  const [showBB, setShowBB] = useState(false);
  const [showTR, setShowTR] = useState(false);
  const [showATR, setShowATR] = useState(false);
  const [showNATR, setShowNATR] = useState(false);
  const [showSuperT, setShowSuperT] = useState(false);
  const [showMfiMA, setShowMfiMa] = useState(false);
  const [showKST, setShowKST] = useState(false);
  const [showIchimoku, setShowIchimoku] = useState(false);
  const [showWMA, setShowWMA] = useState(false);
  const [showMOM, setShowMOM] = useState(false);
  const [showChande, setShowChande] = useState(false);
  const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
  const [showPeriodMinModal, setPeriodMinModal] = useState(false);
  const [showCandleModal, setShowCandleModal] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [instance, setInstance] = useState();
  const [isSelectMax, setIsSelectMax] = useState(false);
  const [isSelectMin, setIsSelectMin] = useState(false);
  const [showStdDeviation, setisStdDeviation] = useState(false);
  const [removedInsideMath, setRemovedInsideMath] = useState(false);
  const [mathIndexRemove, setMathIndexRemove] = useState(-1);

  const divRef = useRef(null);
  const [showInstead, setAddInstead] = useState({
    function_id: 0,
    function_type_id: 0,
    isOpen: false,
    index: -1,
  });
  const handleEdit = (instanceData, index) => {
    console.log("instanceData", instanceData, index);
    setAddInstead({
      function_id: instanceData?.function_id,
      function_type_id: instanceData?.function_type_id,
      isOpen: true,
      index: index,
    });

    // call dropdown function particular type id //
    handleClickAll(instanceData?.function_type_id?.toString());
  };

  const handleGetFunction1 = (item) => {
    console.log("click", item, formData);
    setSearchQuery("");
    formData.selectedOption2 = item?.name;
    setShowDropdown(false);
    let replaceData = replaceNewExistData(formData, item, showInstead);
    formData = replaceData;
    // console.log("REPLACE", formData )
    if (replaceData) {
      setAddInstead({
        function_id: 0,
        function_type_id: 0,
        isOpen: false,
        index: -1,
      });
    }
  };
  useEffect(() => {
    eflag = 0;
  }, []);

  useEffect(() => {
    if (save && formData.functionText2) {
      getEntryText();

      console.log("functionText check", formData.functionText2);

      let text1 = handleReplacement(formData.functionText2);
      console.log("formula>", formData.functionsBox888, text1?.split("\n"));

      console.log(
        "formula2>",
        formData.functionsBox888,
        text1
          ?.split("\n")
          .map((line) => line.trim())
          .filter((line) => line !== "")
      );

      mergeArrays(
        formData.functionsBox888,
        text1
          .split("\n")
          .map((line) => line.trim())
          .filter(
            (line) =>
              line !== "" &&
              !line.startsWith("1minute") &&
              !line.startsWith("3minute") &&
              !line.startsWith("5minute") &&
              !line.startsWith("10minute") &&
              !line.startsWith("15minute") &&
              !line.startsWith("30minute") &&
              !line.startsWith("60minute") &&
              !line.startsWith("1day") &&
              !line.startsWith("2h") &&
              !line.startsWith("3h") &&
              !line.startsWith("4h") &&
              !line.startsWith("week") &&
              !line.startsWith("month")
          ),
        onFormChange,
        formData,
        isSelectMax,
        isSelectMin,
        isIncludeMathFunction,
        "exist"
      );
    }
  }, [save]);

  useEffect(() => {
    // console.log("functionsBox888>", formData.functionsBox888);
    if (eflag === 1) {
      updateCandleIntervalId();
      updateCandleInterval();
    }
    handleClickAll("1");
  }, [formData.candleIntervalId]);

  useEffect(() => {
    setTimeout(() => {
      eflag = 1;
    }, 2000);
  }, []); // This effect runs once when the component mounts
  const updateCandleInterval = () => {
    // Create a copy of the original data
    let updatedData = [];
    if (Array.isArray(formData?.functionsBox777)) {
      updatedData = [...formData.functionsBox888];
    }
    let candleId = getCandleId(formData);

    updatedData.forEach((item) => {
      item.candleIntervalId = candleId;
    });

    formData.functionsBox888 = updatedData;
    onFormChange(formData);
  };

  const updateCandleIntervalId = () => {
    const updatedIndicators = { ...formData.exit_indicators };

    let candleId = getCandleId(formData);

    for (const key in updatedIndicators) {
      if (updatedIndicators.hasOwnProperty(key)) {
        const indicator = updatedIndicators[key];
        // Check if candleIntervalId property exists
        if (indicator.hasOwnProperty("candleIntervalId")) {
          // Update candleIntervalId property value

          indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
        }
        if (indicator?.body) {
          if (Array.isArray(indicator.body)) {
            indicator.body.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body1) {
          if (Array.isArray(indicator.body1)) {
            indicator.body1.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body2) {
          if (Array.isArray(indicator.body2)) {
            indicator.body2.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
      }
    }

    formData.exit_indicators = updatedIndicators;
    onFormChange(formData);
  };

  useEffect(() => {
    handleClickAll("1");
  }, []);

  const { stop_loss, target_profit, trailing_sl } = formData;
  const handleChangeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    onFormChange((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClickAll = async (type) => {
    setSelectedItem(type);
    const data = await getfunctionTypeWisefunction({ type_id: type });
    // console.log("data,,,", data);
    setFunctionType(data);
  };

  const handleButtonClick = (position, subPosition) => {
    setShowDropdown(true);
  };

  const getEntryText = () => {
    // Get the element by its ID
    const divElement = document.getElementById("strategyBox2");

    // Check if the div element exists
    if (divElement) {
      // Get the existing text content of the div
      let existingContent = divElement.innerText;

      // Replace {input1Placeholder} with formData?.input1 value
      existingContent = existingContent.replace(
        "{input1Placeholder}",
        formData?.input1
      );
      let text1 = handleReplacement(existingContent);
      console.log("existingContent>>>", existingContent);
      console.log("formula>>>>", text1?.split("\n"));

      formData.functionText2 = existingContent;
      onFormChange(formData);

      // Do something with the modified text content
    }
  };

  const handleReplacement = (text) => {
    console.log("checkText-", text);
    let modifiedText = text.replace(/lower than or equal to/gi, "<=");
    modifiedText = modifiedText.replace(/higher than or equal to/gi, ">=");
    modifiedText = modifiedText.replace(/lower than/gi, "<");
    modifiedText = modifiedText.replace(/higher than/gi, ">");
    modifiedText = modifiedText.replace(/> equal to/gi, ">=");
    modifiedText = modifiedText.replace(/< equal to/gi, "<=");
    modifiedText = modifiedText.replace(/and/gi, "&&");
    modifiedText = modifiedText.replace(/or/gi, "||");
    modifiedText = modifiedText.replace(/equal to/gi, "===");
    modifiedText = modifiedText.replace(/crosses above/gi, ">");
    modifiedText = modifiedText.replace(/crosses below/gi, ">");

    return modifiedText;
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    toggleDropdown();
  };

  // Function to toggle the visibility of the dropdown
  const toggleDropdown = () => {
    setRemove(false);
    setShowDropdown(true);
  };
  const options = [
    { label: "Percentage (%)", value: "per" },
    { label: "Absolute (abs)", value: "abs" },
  ];

  const handleSelect = (selectedOption) => {
    console.log("Selected option:", selectedOption);

    onFormChange({
      ...formData,
      tpslType: selectedOption?.value,
    });
    // You can perform additional actions with the selected option
  };
  const handleAdd = (instanceData, index) => {
    toggleDropdown();
  };

  const handleRemiceInstead = () => {
    setAddInstead((prev) => ({ ...prev, isOpen: false }));
  };
  const handleSetInstead = () => {
    setAddInstead({
      function_id: 0,
      function_type_id: 0,
      isToggle: false,
    });
  };
  const handleGetFunction = (item) => {
    console.log("Adding...", item);
    setInnerAdd(false);
    setOuterAdd(true);
    handleRemiceInstead();
    formData.selectedOption2 = item?.name;
    if (formData?.exit_indicators) {
      const existingKeys = Object.keys(formData.exit_indicators);

      // Find existing instances for the same indicator type
      const existingInstances = existingKeys.filter((key) =>
        key.startsWith(item?.name)
      );

      if (existingInstances.length > 0) {
        // If instances for the same indicator type exist, find the last number
        let lastNumber = 0;

        existingInstances.forEach((key) => {
          const number = parseInt(key.replace(item?.name, ""));
          if (!isNaN(number) && number > lastNumber) {
            lastNumber = number;
          }
        });

        formData.clickedFunction = `${item?.name}${lastNumber + 1}`;

        console.log(
          `${item?.name} is present in formData.exit_indicators`,
          formData.clickedFunction
        );
      } else {
        // If the name doesn't exist, append "1" to the name
        formData.clickedFunction = `${item?.name}1`;

        console.log(
          `${item?.name} is not present in formData.exit_indicators`,
          formData.clickedFunction
        );
      }
    } else {
      // If formData.exit_indicators doesn't exist, create the first instance
      formData.clickedFunction = `${item?.name}1`;

      console.log(
        `${item?.name} is not present in formData.exit_indicators (formData.exit_indicators doesn't exist)`,
        formData.clickedFunction
      );
    }

    formData.count++;
    formData.conditionTab = true;
    onFormChange({
      ...formData,
      formData,
    });

    formData.functionsBox3.push(item?.name);
    let obj = {};
    obj.function_id = item?.id;

    const comparisonOperators = [
      "higher than",
      "lower than",
      "higher than equal to",
      "lower than equal to",
      "crosses above",
      "crosses below",
    ];

    // Check if item?.name includes any of the comparison operators
    const includesComparisonOperator = comparisonOperators.some((operator) =>
      item?.name.includes(operator)
    );

    if (includesComparisonOperator) {
      console.log("crosss?", item?.name);
      if (item?.name.includes("crosses above")) {
        obj.isCrossover = 2;
        obj.comparator = ">";
      } else if (item?.name.includes("crosses below")) {
        obj.isCrossover = 1;
        obj.comparator = "<";

        console.log("crosss?", obj);
      } else {
        obj.comparator = item?.name;
      }
    } else {
      obj.name = item?.name;
    }
    obj.field = "";
    obj.period = "";
    obj.offset = "";
    obj.function_type_id = item.function_type_id;

    let candleId = getCandleId(formData);

    obj.candleIntervalId = candleId;
    console.log("crosss?", obj);
    if (removedInsideMath) {
      formData.functionsBox888.splice(mathIndexRemove, 0, obj);
      console.log("MATHINDEX", mathIndexRemove);
    } else {
      formData.functionsBox888.push(obj);
    }

    setCurrentId(item?.id);
    console.log("Came5-", formData.functionsBox888);
    console.log("value---", item);
    if (item.function_type_id === 3) handleClickAll("5");
    if (item.function_type_id === 5) handleClickAll("3");
    if (item.function_type_id === 2) handleClickAll("3");
    formData.selectedOption2 = item?.name;

    setConditionTab(true);

    if (flag === 0) {
      console.log("Inital->", flag);
      setSelectedOption1(item?.name);
      formData.firstFunctionId = item?.id;
      onFormChange({
        ...formData,
        formData,
      });

      if (item?.function_type_id === 2) {
        console.log("Came6-");
        setMode("Function");

        formData.mode = "Function";
        onFormChange({
          ...formData,
          formData,
        });

        onFormChange({
          ...formData,
          mode: "Function",
          conditionTab: true,
          selectedOption1: item?.name,
          function_name: item?.id,
        });
        let obj = {};
        obj.function_id = item?.id;
        obj.function_type_id = item.function_type_id;
        obj.name = item?.name;
        formData.functionsBox3.push(obj);
        console.log("here>");
      }

      if (item?.function_type_id === 3) {
        console.log("Came7-");
        setMode("Indicators");
        onFormChange({
          ...formData,
          mode: "Indicators",
          conditionTab: true,
          selectedOption1: item?.name,
          function_name: item?.id,
        });
        console.log("here2>");
      }

      setFlag(1);
    }

    console.log("CHECK COUNT", formData.count, formData.functionsBox3);
    if (
      formData.functionsBox3[0]?.function_type_id === 2 &&
      flag > 0 &&
      !outerAdd
    ) {
      let obj2 = {};
      obj2.function_id = item?.id;
      obj2.name = item?.name;

      if (formData.functionsBox3[0].body)
        formData.functionsBox3[0].body.push(obj2);
      else {
        formData.functionsBox3[0].body = [];
        // formData.functionsBox2[0].inner.push(obj2);
      }
    }

    if (
      item?.function_type_id === 3 ||
      item?.function_type_id === 2 ||
      item?.function_type_id === 1 ||
      item?.function_type_id === 6
    ) {
      console.log("Came7-");

      // Assuming 'item' is the function object from your data
      let functionName = item?.name;

      // Check if the formData.exit_indicators object exists
      if (!formData.exit_indicators) {
        formData.exit_indicators = {};
      }

      // Find existing instances for the same indicator type
      const existingInstances = Object.keys(formData.exit_indicators).filter(
        (key) => key.startsWith(functionName)
      );

      // Create a new instance name based on the function name and the number of existing instances
      let instanceName;
      if (existingInstances.length > 0) {
        let lastNumber = 0;

        existingInstances.forEach((key) => {
          const number = parseInt(key.replace(functionName, ""));
          if (!isNaN(number) && number > lastNumber) {
            lastNumber = number;
          }
        });

        instanceName = `${functionName}${lastNumber + 1}`;
      } else {
        // If no existing instances, start with "1"
        instanceName = `${functionName}1`;
      }

      let candleId = getCandleId(formData);

      // Create a new instance of the function
      let obj = {
        function_id: item?.id,
        name: functionName,
        function_type_id: item?.function_type_id,
        candleIntervalId: candleId,
        // Add any additional properties as needed
      };
      indicatorsSwitchCase(obj, formData.selectedOption2, "e_");
      console.log("FOBJ", instanceName, obj);

      // Add the new instance to the object with a unique name
      if (item?.function_type_id === 2) {
        formData.isIncludeMathFunctionInExit = true;
        isIncludeMathFunction.current = true;

        if (item.name === "Max") {
          obj.body1 = [];
          obj.body2 = [];
          setIsSelectMax(true);
          formData.exit_indicators[instanceName] = obj;
        } else if (item.name === "Min") {
          obj.body1 = [];
          obj.body2 = [];
          setIsSelectMin(true);
          formData.exit_indicators[instanceName] = obj;
        } else if (
          item.name === "Ceil" ||
          item.name === "Floor" ||
          item.name === "Abs"
        ) {
          obj.body = [];
          formData.exit_indicators[instanceName] = obj;
        } else if (item.name === "Symbol") {
          obj.body = [];
          obj.scripCode = "";
          formData.exit_indicators[instanceName] = obj;
        } else {
          obj.body = [];
          obj.period = 10;
          formData.exit_indicators[instanceName] = obj;
        }
      }
      if (!formData.selectedOption3 || outerAdd)
        formData.exit_indicators[instanceName] = obj;
      if (formData.exit_indicators[formData.selectedOption3]?.body && innerAdd)
        formData.exit_indicators[formData.selectedOption3]?.body.push(obj);

      if (instance === "body1" && innerAdd)
        formData.exit_indicators[formData.selectedOption3]?.body1.push(obj);

      if (instance === "body2" && innerAdd)
        formData.exit_indicators[formData.selectedOption3]?.body2.push(obj);

      console.log("sorororo", formData.exit_indicators);

      // Update the order array
      formData.order.push(instanceName);

      setMode("Indicators");

      console.log("here check>", formData.exit_indicators);
    }

    if (item?.function_type_id === 4) {
      // Assuming 'item' is the function object from your data
      let functionName = item?.name;

      // Check if the formData.exit_indicators object exists
      if (!formData.exit_indicators) {
        formData.exit_indicators = {};
      }

      // Find existing instances for the same indicator type
      const existingInstances = Object.keys(formData.exit_indicators).filter(
        (key) => key.startsWith("operators")
      );

      // Create a new instance name based on the function name and the number of existing instances
      let instanceName;
      if (existingInstances.length > 0) {
        let lastNumber = 0;

        existingInstances.forEach((key) => {
          const number = parseInt(key.replace("operators", ""));
          if (!isNaN(number) && number > lastNumber) {
            lastNumber = number;
          }
        });

        instanceName = `operators${lastNumber + 1}`;
      } else {
        // If no existing instances, start with "1"
        instanceName = `operators1`;
      }

      let obj = {
        function_id: item?.id,
        name: item?.name,
        function_type_id: item?.function_type_id,
        candleIntervalId: candleId,
        operator: item?.name,
        // Add any additional properties as needed
      };
      console.log("added plus", obj);

      if (formData.exit_indicators[formData.selectedOption3]?.body && innerAdd)
        formData.exit_indicators[formData.selectedOption3]?.body.push(obj);
      else formData.exit_indicators[instanceName] = obj;

      console.log("here check2>", formData.exit_indicators);
    }

    if (item?.function_type_id === 5) {
      console.log("Came7-");
      let functionName = item?.name;

      // Use "comparator" as the key instead of functionName
      let comparatorObject = formData.exit_indicators;

      // If formData.exit_indicators doesn't exist, create it
      if (!comparatorObject) {
        formData.exit_indicators = {};
        comparatorObject = formData.exit_indicators;
      }

      // Find existing instances for the same comparator type
      const existingInstances = Object.keys(comparatorObject);

      // Create a new instance name based on "comparator" and the number of existing instances
      let instanceName;
      if (existingInstances.length > 0) {
        let lastNumber = 0;

        existingInstances.forEach((key) => {
          const number = parseInt(key.replace("comparator", ""));
          if (!isNaN(number) && number > lastNumber) {
            lastNumber = number;
          }
        });

        instanceName = `comparator${lastNumber + 1}`;
      } else {
        // If no existing instances, start with "1"
        instanceName = "comparator1";
      }

      // Create a new instance of the function
      let obj = {
        function_id: item?.id,
        name: functionName, // Use "comparator" as the name
        function_type_id: item?.function_type_id,
        // Add any additional properties as needed
      };

      // Add the new instance directly to formData.exit_indicators without the top-level "comparator" key
      formData.exit_indicators[instanceName] = obj;

      // Update the order array
      formData.order.push(instanceName);

      setMode("Indicators");

      console.log("here check2>", formData.exit_indicators);
    }

    if (outerAdd) {
      let obj2 = {};
      obj2.function_id = item?.id;
      obj2.name = item?.name;
      formData.functionsBox3.push(obj2);
    }

    formData.secondFunctionId = item?.id;
    onFormChange({
      ...formData,
      formData,
    });

    console.log("functionsBox---", formData?.functionsBox3, formData.count);
    setSearchQuery("");
    setShowDropdown(false);
    if (innerAdd) setRemovedInsideMath(false);
  };

  const handleModalData = (data) => {
    console.log(
      "ModalClosed-",
      formData.selectedOption2,
      formData.clickedFunction,
      data,
      itemIndex
    );
    console.log("data>>>>", data);
    console.log("CLICK", formData.selectedOption2);
    setIsShow(false);
    const validFunctions = [
      "Period min1",
      "Period max1",
      "Symbol1",
      "Floor1",
      "Ceil1",
      "Abs1",
    ];
    const additionalValidFunctions = ["Max1", "Min1"];
    if (validFunctions?.includes(formData?.clickedFunction)) {
      console.log(
        "seee>",
        formData.exit_indicators[formData.clickedFunction]?.body[itemIndex]
      );
      switch (data.name) {
        case "Period max":
          formData.exit_indicators[formData.clickedFunction].period =
            data?.period;
        case "Period min":
          formData.exit_indicators[formData.clickedFunction].period =
            data?.period;
        default:
          break;
      }
      replaceExistModal(
        formData,
        formData.clickedFunction,
        itemIndex,
        data,
        "body"
      );

      setTimeout(() => {
        getEntryText();
      }, 1000);
      console.log("formData??", formData);
      onFormChange(formData);
    } else if (additionalValidFunctions.includes(formData.clickedFunction)) {
      console.log(
        "seee??>",
        formData.exit_indicators[formData.clickedFunction][instance][0],
        instance
        // formData.exit_indicators[formData.clickedFunction]?.body[itemIndex]
      );

      replaceExistModal(
        formData,
        formData.clickedFunction,
        itemIndex,
        data,
        instance
      );

      setTimeout(() => {
        getEntryText();
      }, 1000);
      console.log("formData??", formData);
      onFormChange(formData);
    } else {
      // Check if formData.exit_indicators object exists
      let keys = Object.keys(formData?.exit_indicators);
      let index = keys.indexOf(formData?.clickedFunction);
      if (!formData.exit_indicators) {
        formData.exit_indicators = {};
      }

      // Check if the instance exists in formData.exit_indicators
      if (!formData.exit_indicators[formData.clickedFunction]) {
        formData.exit_indicators[formData.clickedFunction] = {};
      }
      if (formData.clickedFunction.startsWith("comparator")) {
        let c = formData.exit_indicators?.[formData.clickedFunction];
        if (c) {
          formData.exit_indicators[formData.clickedFunction].name = data?.name;
          formData.exit_indicators[formData.clickedFunction].function_id =
            data?.function_id;
          formData.functionsBox888[index] = data;
        }
      }
      if (formData?.functionsBox888) {
        let replaceObje = {
          function_id: data?.function_id,
          name: data?.name,
          function_type_id: data?.function_type_id,
        };
        helperModalReplaceFn888(data, replaceObje);
        formData.functionsBox888[index] = replaceObje;
      }
      updateExistIndicator(formData, formData.clickedFunction, data);
      console.log("exit_indicators", formData);
      setTimeout(() => {
        getEntryText();
      }, 1000);
      onFormChange(formData);
    }
  };

  const handleSMAComparator = (data) => {
    console.log("Came in exit-", data);
    // console.log("modalIndi data", data);
    setIsShow3(false);

    onFormChange({
      ...formData,
      exit_condition: data?.exit_condition,
    });
  };

  const handleRemoveButtonClick = (
    instanceToRemove,
    indexToRemove,
    mInstance
  ) => {
    const validFunctions = [
      "Period min1",
      "Period max1",
      "Symbol1",
      "Floor1",
      "Ceil1",
      "Abs1",
    ];
    const additionalValidFunctions = ["Max1", "Min1"];
    if (validFunctions?.includes(instanceToRemove)) {
      console.log(
        "instanceToRemove??",
        instanceToRemove,
        indexToRemove,
        formData?.exit_indicators[instanceToRemove]?.body[indexToRemove]
      );

      const updatedIndicators = { ...formData?.exit_indicators };

      updatedIndicators[instanceToRemove]?.body.splice(indexToRemove, 1);

      let filteredBox = formData?.functionsBox2?.filter(
        (item, index) => index !== indexToRemove
      );
      let keyToFind = instanceToRemove;
      const keys = Object.keys(formData?.exit_indicators);
      const indexRemove = keys.indexOf(keyToFind);
      const keysBeforeIndex = keys.slice(0, indexRemove - 1);
      let adjustedIndex = calculateAdjustedIndex(keysBeforeIndex);
      let filteredBox2 = filterFunctionsBox888(
        formData,
        indexRemove,
        adjustedIndex,
        "body"
      );
      setMathIndexRemove(indexRemove + adjustedIndex + 1);
      formData.functionsBox2 = filteredBox;
      formData.functionsBox888 = filteredBox2;
      console.log("testremove>", formData);
      setRemovedInsideMath(true);
      onFormChange(formData);

      onFormChange((prevState) => ({
        ...prevState,
        exit_indicators: updatedIndicators,
      }));
    } else if (additionalValidFunctions?.includes(instanceToRemove)) {
      console.log(
        "instanceToRemovemax",
        instanceToRemove,
        indexToRemove,
        formData?.exit_indicators[instanceToRemove],
        mInstance
      );
      const updatedIndicators = { ...formData?.exit_indicators };

      updatedIndicators[instanceToRemove][mInstance]?.splice(indexToRemove, 1);

      let filteredBox = formData?.functionsBox2?.filter(
        (item, index) => index !== indexToRemove
      );
      let keyToFind = instanceToRemove;
      const keys = Object.keys(formData?.exit_indicators);
      const indexRemove = keys.indexOf(keyToFind);
      const keysBeforeIndex = keys.slice(0, indexRemove - 1);
      let adjustedIndex = calculateAdjustedIndex(keysBeforeIndex);
      let filteredBox2 = filterFunctionsBox888(
        formData,
        indexRemove,
        adjustedIndex,
        "body"
      );
      setMathIndexRemove(
        indexRemove + adjustedIndex + (mInstance === "body2" ? 2 : 1)
      );
      formData.functionsBox2 = filteredBox;
      formData.functionsBox888 = filteredBox2;
      console.log("testremove>", formData);
      setRemovedInsideMath(true);
      onFormChange(formData);

      onFormChange((prevState) => ({
        ...prevState,
        exit_indicators: updatedIndicators,
      }));
    } else {
      // Make a copy of the formData?.exit_indicators object to avoid directly modifying the state
      const updatedIndicators = { ...formData?.exit_indicators };

      // Check if the instanceToRemove is an "and" instance
      if (updatedIndicators.hasOwnProperty(instanceToRemove)) {
        // Remove the "and" instance
        delete updatedIndicators[instanceToRemove];
      } else {
        // Remove the regular instance
        Object.keys(updatedIndicators).forEach((key) => {
          if (updatedIndicators[key].hasOwnProperty(instanceToRemove)) {
            delete updatedIndicators[key][instanceToRemove];
          }
        });
      }

      if (isIncludeMathFunction.current) {
        let filteredBox = formData?.functionsBox2?.filter(
          (item, index) => index !== indexToRemove + 1
        );
        let filteredBox2 = formData?.functionsBox888?.filter(
          (item, index) => index !== indexToRemove + 1
        );
        formData.functionsBox2 = filteredBox;
        formData.functionsBox888 = filteredBox2;
      } else {
        let filteredBox = formData?.functionsBox2?.filter(
          (item, index) => index !== indexToRemove
        );
        let filteredBox2 = formData?.functionsBox888?.filter(
          (item, index) => index !== indexToRemove
        );
        formData.functionsBox2 = filteredBox;
        formData.functionsBox888 = filteredBox2;
      }
      onFormChange(formData);
      // Update the state with the new indicators object
      onFormChange((prevState) => ({
        ...prevState,
        exit_indicators: updatedIndicators,
      }));
    }
  };

  const handleCandleModal = (data) => {
    console.log("handleCandleModal-", formData.clickedFunction, data);
    if (data?.candleIntervalId)
      formData.exit_indicators[formData.clickedFunction].candleIntervalId =
        data?.candleIntervalId;

    formData.functionsBox888[itemIndex].candleIntervalId =
      data?.candleIntervalId;

    if (formData.exit_indicators[formData.clickedFunction]?.body)
      formData.exit_indicators[formData.clickedFunction].body[
        itemIndex
      ].candleIntervalId = data?.candleIntervalId;

    if (formData.exit_indicators[formData.clickedFunction][instance])
      formData.exit_indicators[formData.clickedFunction][
        instance
      ][0].candleIntervalId = data?.candleIntervalId;
    console.log(
      "formData sosp",
      formData.exit_indicators[formData.clickedFunction][instance]
    );

    onFormChange(formData);
  };

  const getIndex = (index, instance) => {
    console.log("getIndex", instance, index, formData.functionsBox777);
    setItemIndex(index);
    setInstance(instance);
  };

  const handleKeyDown = (event) => {
    const charCode = event.keyCode || event.which;
    // Allow numeric characters and the decimal point
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
    }
  };

  const handleAddButtonClick = (instanceName, bodyInstance) => {
    console.log("ADDING", bodyInstance, formData.exit_indicators[instanceName]);
    formData.selectedOption3 = instanceName;
    setInstance(bodyInstance);
  };
  const Removeformula = () => {
    const ans = window.confirm("Are you sure you want to remove formula?");
    if (ans === true) {
      setFlag(0);
      setRemove(true);
      onFormChange((prevState) => ({
        ...prevState,
        functionsBox3: [],
        functionsMainBox: [],
        functionsBox888: [],
        exit_indicators: {},
        exit_functions: [],
        functionText2: "",
        selectedOption1: "",
        order: [],
        isIncludeMathFunctionInExit: false,
        mode: "",
      }));
      handleSetInstead();
      isIncludeMathFunction.current = false;
    }
  };

  return (
    <>
      <br />
      <div id="strategy-exist-condition" style={{ marginTop: "12px" }}>
        <Form>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg="6" xs sm className="top-header">
              <h6>Exit Condition</h6>
            </Col>
            <Col
              lg="6"
              xs
              sm
              className="top-header"
              style={{
                width: "30%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: "600", fontSize: "16px" }}>
                TPSL Type
              </div>
              <Dropdown
                options={options}
                onSelect={handleSelect}
                value={formData?.tpslType}
              />
            </Col>
          </Row>
        </Form>

        <div
          className="row tab-stop-target-trailing p-3"
          style={{ background: "#f6f6f6", marginTop: "20px" }}
        >
          <div className="col-lg-4">
            <label className="stop-loss">
              Stop loss {formData?.tpslType === "per" && "%"}
            </label>
            <input
              type="number"
              value={stop_loss}
              name="stop_loss"
              onChange={handleChangeValue}
              className="w-25"
              placeholder="00"
              onKeyPress={handleKeyDown}
            />
          </div>
          <div className="col-lg-4">
            <label className="target-profit">
              Target profit {formData?.tpslType === "per" && "%"}
            </label>
            <input
              type="number"
              name="target_profit"
              onChange={handleChangeValue}
              value={target_profit}
              className="w-50"
              placeholder="00"
              onKeyPress={handleKeyDown}
            />
          </div>
          <div className="col-lg-4">
            <label className="Trailing-sl">
              Trailing SL {formData?.tpslType === "per" && "%"} (optional)
            </label>
            <input
              type="number"
              className="w-25"
              onChange={handleChangeValue}
              value={trailing_sl}
              name="trailing_sl"
              placeholder="00"
              onKeyPress={handleKeyDown}
            />
          </div>
        </div>

        <div
          className="input-group mb-3"
          id="tab-function-type-dropdown"
          style={{ marginTop: "30px" }}
        >
          <div className="d-flex row" style={{ minWidth: "300px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Form.Control
                type="text"
                placeholder="What would you like to add?"
                className="strategy-entry-search"
                onClick={toggleDropdown}
                value={searchQuery}
                onChange={handleSearch}
              />
              <span
                className="close-icon"
                onClick={() => {
                  setSearchQuery("");
                  setShowDropdown(false);
                }}
                style={{ cursor: "pointer", marginLeft: "-25px" }}
              >
                X
              </span>
            </div>
            {/* Dropdown menu */}
            {showDropdown && (
              <FunctionListExist
                showInstead={showInstead}
                formData={formData}
                isSelectMax={isSelectMax}
                removedInsideMath={removedInsideMath}
                selectedItem={selectedItem}
                selectedItemStyle={selectedItemStyle}
                handleClickAll={handleClickAll}
                handleGetFunction1={handleGetFunction1}
                handleGetFunction={handleGetFunction}
                getEntryText={getEntryText}
                searchQuery={searchQuery}
                functionType={functionType}
                remove={remove}
              />
            )}
          </div>
        </div>
        <div>
          <div
            className="row"
            id="strategy-entry-condition-card"
            ref={divRef}
            style={{ marginTop: "40px" }}
          >
            <div className="col-lg-12 p-0">
              <Card>
                <Card.Body>
                  <div className="row">
                    <div
                      className="col-lg-12 p-3"
                      id="strategyBox2"
                      style={{
                        display: "contents",
                      }}
                    >
                      {/* Formula Tab*/}
                      <ExitFormulaTab
                        formData={formData}
                        allStocks={allStockStore}
                        handleRemoveButtonClick={handleRemoveButtonClick}
                        setIsShow={setIsShow}
                        setIsShow3={setIsShow3}
                        onFormChange={onFormChange}
                        handleButtonClick={handleButtonClick}
                        setShowDropdown={setShowDropdown}
                        setInnerAdd={setInnerAdd}
                        setOuterAdd={setOuterAdd}
                        handleAddButtonClick={handleAddButtonClick}
                        outerAdd={outerAdd}
                        setShowClose={setShowClose}
                        setShowOpen={setShowOpen}
                        setShowLowPrice={setShowLowPrice}
                        setShowHighPrice={setShowHighPrice}
                        setShowUlcer={setShowUlcer}
                        setShowDEMA={setShowDEMA}
                        setShowTEMA={setShowTEMA}
                        setShowTMA={setShowTMA}
                        setShowVWMA={setShowVWMA}
                        setShowEMA={setShowEMA}
                        setShowSMA={setShowSMA}
                        setShowNumber={setShowNumber}
                        setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                        setShowWilliams={setShowWilliams}
                        setShowMad={setShowMad}
                        setShowObv={setShowObv}
                        setShowAroonDown={setShowAroonDown}
                        setShowAroonUp={setShowAroonUp}
                        setShowOscilator={setShowOscilator}
                        setShowVWAP={setShowVWAP}
                        setShowpsar={setShowpsar}
                        setShowVortexI={setShowVortexI}
                        setShowVolume={setShowVolume}
                        setShowDonchian={setShowDonchian}
                        setShowStochastic={setShowStochastic}
                        setShowChaikin={setShowChaikin}
                        setShowMacdSignal={setShowMacdSignal}
                        setShowCCI={setShowCCI}
                        setShowRoc={setShowRoc}
                        setShowRSI={setShowRSI}
                        setShowRSIMA={setShowRSIMA}
                        setShowTR={setShowTR}
                        setShowATR={setShowATR}
                        setShowNATR={setShowNATR}
                        setShowSuperT={setShowSuperT}
                        setShowBB={setShowBB}
                        setShowMfiMa={setShowMfiMa}
                        setShowIchimoku={setShowIchimoku}
                        setShowKST={setShowKST}
                        setShowCandleModal={setShowCandleModal}
                        getIndex={getIndex}
                        setShowWMA={setShowWMA}
                        setShowMOM={setShowMOM}
                        setAddInstead={setAddInstead}
                        showInstead={showInstead}
                        handleEdit={handleEdit}
                        handleAdd={handleAdd}
                        setPeriodMaxModal={setPeriodMaxModal}
                        setPeriodMinModal={setPeriodMinModal}
                        setisStdDeviation={setisStdDeviation}
                      />
                      &nbsp; &nbsp;
                      {formData.isIncludeMathFunctionInExit && (
                        <div
                          onClick={() => {
                            setShowDropdown(true);
                            setOuterAdd(true);
                          }}
                          className="pluseIcon"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </div>
                        // <button
                        //   onClick={() => {
                        //     setShowDropdown(true);
                        //     setOuterAdd(true);
                        //   }}
                        //   style={addButton}
                        // >
                        //   {"Add"}
                        // </button>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <div className="row ">
                <div
                  className="col-lg-12 d-flex flex-row-reverse"
                  style={{ marginTop: "10px" }}
                >
                  <span
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => Removeformula()}
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <CompartorModal
          showSMA={showSMA3}
          setIsShow={setIsShow3}
          handleSMAClickedPage={handleSMAComparator}
          type={formData.ctype}
          typeName={formData.selectedOption2}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Ulcer
          show={showUlcer}
          setIsShow={setShowUlcer}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <TriMA
          show={showTMA}
          setIsShow={setShowTMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Vwma
          show={showVWMA}
          setIsShow={setShowVWMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <DEMA
          show={showDEMA}
          setIsShow={setShowDEMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <TEMA
          show={showTEMA}
          setIsShow={setShowTEMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Open
          show={showOpen}
          setIsShow={setShowOpen}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Low
          show={showLowPrice}
          setIsShow={setShowLowPrice}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <HighPrice
          show={showHighPrice}
          setIsShow={setShowHighPrice}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Close
          show={showClose}
          setIsShow={setShowClose}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <EMA
          show={showEMA}
          setIsShow={setShowEMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <SMA
          show={showSMA1}
          setIsShow={setShowSMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <WMA
          show={showWMA}
          setIsShow={setShowWMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Number
          show={showNumber}
          setIsShow={setShowNumber}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <MoneyFlowIndex
          show={showMoneyFlowIndex}
          setIsShow={setShowMoneyFlowIndex}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <WilliamsR
          show={showWilliams}
          setIsShow={setShowWilliams}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <MovingAverageDeviation
          show={showMad}
          setIsShow={setShowMad}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <MACD
          show={showMacd}
          setIsShow={setShowMacd}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <OBV
          show={showObv}
          setIsShow={setShowObv}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <AroonDown
          show={showAroonDown}
          setIsShow={setShowAroonDown}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <AroonUp
          show={showAroonUp}
          setIsShow={setShowAroonUp}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <AwesomeOscillator
          show={showOscilator}
          setIsShow={setShowOscilator}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <VWAP
          show={showVWAP}
          setIsShow={setShowVWAP}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <ParabolicSAR
          show={showpsar}
          setIsShow={setShowpsar}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <VortexIndicator
          show={showVortexI}
          setIsShow={setShowVortexI}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Volume
          show={showVolume}
          setIsShow={setShowVolume}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <DonchianChannel
          show={showDonchian}
          setIsShow={setShowDonchian}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Stochastic
          show={showStochastic}
          setIsShow={setShowStochastic}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <ChaikinMoneyFlow
          show={showChaikin}
          setIsShow={setShowChaikin}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <CCI
          show={showCCI}
          setIsShow={setShowCCI}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <PriceRateofChange
          show={showRoc}
          setIsShow={setShowRoc}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <MACDsignal
          show={showMacdSignal}
          setIsShow={setShowMacdSignal}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <RSI
          show={showRSI}
          setIsShow={setShowRSI}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <RSIMovingAverage
          show={showRSIMA}
          setIsShow={setShowRSIMA}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <BollingerB
          show={showBB}
          setIsShow={setShowBB}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <TR
          show={showTR}
          setIsShow={setShowTR}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <ATR
          show={showATR}
          setIsShow={setShowATR}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <NATR
          show={showNATR}
          setIsShow={setShowNATR}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Supertrend
          show={showSuperT}
          setIsShow={setShowSuperT}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <MfiMovingAverage
          show={showMfiMA}
          setIsShow={setShowMfiMa}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <KnowSureThing
          show={showKST}
          setIsShow={setShowKST}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <Ichimoku
          show={showIchimoku}
          setIsShow={setShowIchimoku}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <CandleInterval
          show={showCandleModal}
          setIsShow={setShowCandleModal}
          handleModalData={handleCandleModal}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <MOM
          show={showMOM}
          setIsShow={setShowMOM}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />

        <ChandeMomentumOscillator
          show={showChande}
          setIsShow={setShowChande}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <PeriodMaxModal
          setIsShow={setPeriodMaxModal}
          showPeriodMaxModal={showPeriodMaxModal}
          handleModalData={handleModalData}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          exit={true}
          index={itemIndex}
          instance={instance}
        />
        <PeriodMinModal
          setIsShow={setPeriodMinModal}
          showPeriodMinModal={showPeriodMinModal}
          handleModalData={handleModalData}
          exit={true}
          mainData={formData}
          clickedFunction={formData.clickedFunction}
          index={itemIndex}
          instance={instance}
        />
        <VwapStandarddeviation
          show={showStdDeviation}
          setIsShow={setisStdDeviation}
          handleModalData={handleModalData}
          exit={true}
          mainData={formData}
          clickedFunction={formData?.clickedFunction}
          index={itemIndex}
          instance={instance}
        />
      </div>
    </>
  );
};

export default ExistCondition;

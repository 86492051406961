import React from "react";
import MiniDrawer from "../Components/MiniDrawer";

function About() {
  return (
    <div className="d-flex">
      <MiniDrawer />
      <div>sklkll xssxmskmx</div>
    </div>
  );
}

export default About;

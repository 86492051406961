import React, { useEffect, useState } from "react";
import "./Strategy.css";
import EMA_I from "./Indicators/EMA_I";
import SMA_I from "./Indicators/SMA_I";
import Close_I from "./Indicators/Close_I";
import Number_I from "./Indicators/Number_I";
import SearchableDropdown from "./SearchDropdown";

const buttonStyle = {
  padding: "3px",
  marginRight: "10px",
  fontSize: "14px",
  backgroundColor: "white",
  color: "black",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  border: "1px solid #1579c9",
  position: "relative",
};

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};
const ExitFormulaTab = ({ ...restProps }) => {
  const {
    formData,
    allStocks,
    handleRemoveButtonClick,
    setIsShow,
    setIsShow3,
    onFormChange,
    handleButtonClick,
    setShowDropdown,
    setInnerAdd,
    setOuterAdd,
    handleAddButtonClick,
    outerAdd,
    setShowClose,
    setShowOpen,
    setShowLowPrice,
    setShowHighPrice,
    setShowUlcer,
    setShowDEMA,
    setShowTEMA,
    setShowTMA,
    setShowVWMA,
    setShowSMA,
    setShowEMA,
    setShowNumber,
    setShowMoneyFlowIndex,
    setShowWilliams,
    setShowMad,
    setShowObv,
    setShowAroonDown,
    setShowAroonUp,
    setShowOscilator,
    setShowVWAP,
    setShowpsar,
    setShowVortexI,
    setShowVolume,
    setShowDonchian,
    setShowStochastic,
    setShowChaikin,
    setShowMacdSignal,
    setShowCCI,
    setShowRoc,
    setShowRSI,
    setShowRSIMA,
    setShowTR,
    setShowATR,
    setShowNATR,
    setShowSuperT,
    setShowBB,
    setShowMfiMa,
    setShowIchimoku,
    setShowKST,
    setShowCandleModal,
    getIndex,
    setShowWMA,
    setShowMacd,
    setShowMOM,
    showInstead,
    handleEdit,
    handleAdd,
    setPeriodMaxModal,
    setPeriodMinModal,
    setisStdDeviation
  } = restProps;

  console.log("Exit_restProps", formData);

  return (
    <>
      {Object.entries(formData?.exit_indicators || {}).map(
        ([instanceName, instanceData], index) => {
          const isShowingAddButton =
            showInstead?.isOpen &&
            showInstead?.function_id == instanceData?.function_id &&
            showInstead.index == index;

          return (
            <span
              key={instanceName}
              className={`ms-2 ${
                instanceData?.name === "&&" || instanceData?.name === "||"
                  ? "w-100 py-2"
                  : ""
              }`}
            >
              {instanceData?.name === "Max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                {item?.name ===
                                "SMA (Simple Moving Average)" ? (
                                  <SMA_I
                                    item={item}
                                    formData={formData}
                                    setShowSMA={setShowSMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name ===
                                  "EMA (Exponential Moving Average)" ? (
                                  <EMA_I
                                    item={item}
                                    formData={formData}
                                    setShowEMA={setShowEMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name === "Close" ? (
                                  <Close_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowClose={setShowClose}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name === "Open price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowOpen(true);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "&&" ? (
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    and
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                ) : item?.name === "||" ? (
                                  <div>
                                    <button
                                      onClick={() => {
                                        setShowClose(true);
                                        formData.selectedOption2 =
                                          instanceData?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      or
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "High price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowHighPrice(true);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Low price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowLowPrice(true);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_lowOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "RSI (Relative Strength Index)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                        setShowRSI(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ulcer" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowUlcer(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "DEMA (Double Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TEMA (Triple Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log(
                                          "showDrop",
                                          instanceData,
                                          data
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TriMA (Triangular Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWMA (Volume Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "WMA (Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Number" ? (
                                  <Number_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowNumber={setShowNumber}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name === "Money Flow Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMoneyFlowIndex(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Williams %R" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWilliams(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "Moving Average Deviation (MA Dev)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMad(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "OBV (On Balance Volume)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowObv(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_obvOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Down" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonDown(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Up" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonUp(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Awesome Oscillator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowOscilator(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWAP (Volume Weighted Average Price)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWAP(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_vwapOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Parabolic SAR" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowpsar(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Vortex Indicator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVortexI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Volume of a candle" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVolume(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${`${data?.e_volume}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Donchian Channel" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDonchian(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Stochastic" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowStochastic(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Chaikin Money Flow" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowChaikin(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "MACD-signal" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacdSignal(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Commodity Channel Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCCI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Price Rate of Change" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRoc(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "RSI Moving Average" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRSIMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "TR (True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_trOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "ATR (Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "NATR (Normalized Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowNATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Supertrend" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowSuperT(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Bollinger %B" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowBB(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.bolingerBType},${data?.e_bolingerBOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMfiMa(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAMAPeriod}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ichimoku" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowIchimoku(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Know Sure Thing" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowKST(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "+" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "-" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "/" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "*" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : (
                                  item?.name === "MOM (Momentum Indicator)" && (
                                    <div className="d-flex">
                                      <div
                                        className="hover-underline"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          console.log("showDrop", data);
                                          formData.selectedOption2 = data?.name;
                                          formData.clickedFunction =
                                            instanceName;
                                          setShowCandleModal(true);
                                          getIndex(index, "body1");
                                        }}
                                      >
                                        {data?.candleIntervalId}
                                      </div>
                                      &ensp;
                                      <button
                                        onClick={() => {
                                          console.log(
                                            "pppp",
                                            instanceName,
                                            data
                                          );
                                          formData.selectedOption2 = data?.name;
                                          formData.clickedFunction =
                                            instanceName;
                                          setShowMOM(true);
                                          getIndex(index, "body1");
                                        }}
                                        style={{
                                          ...buttonStyle,
                                          color: "#0e6efd",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {`${
                                          data?.name
                                        } (${`${data.period},${data.offset}`})`}
                                        <i
                                          onClick={(e) => {
                                            getIndex(index, "body1");
                                            handleRemoveButtonClick(
                                              instanceName,
                                              index,
                                              "body1"
                                            );
                                          }}
                                          className={`fa-solid fa-xmark iconExitContainer`}
                                          style={{
                                            marginLeft: "6px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                        &ensp;
                                      </button>
                                    </div>
                                  )
                                )}
                              </>
                            );
                          })}
                        {instanceData?.body1?.length === 0 && (
                          <button
                            onClick={() => {
                              console.log(
                                "instanceData?.name",
                                instanceData,
                                instanceName
                              );
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAMAPeriod}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body2");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                        getIndex(index, "body2");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          getIndex(index, "body2");
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body2"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        {instanceData?.body2?.length === 0 && (
                          <button
                            onClick={() => {
                              console.log("instanceData?.name", instanceName);
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                           }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                {item?.name ===
                                "SMA (Simple Moving Average)" ? (
                                  <SMA_I
                                    item={item}
                                    formData={formData}
                                    setShowSMA={setShowSMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name ===
                                  "EMA (Exponential Moving Average)" ? (
                                  <EMA_I
                                    item={item}
                                    formData={formData}
                                    setShowEMA={setShowEMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name === "Close" ? (
                                  <Close_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowClose={setShowClose}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name === "Open price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowOpen(true);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "&&" ? (
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    and
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                ) : item?.name === "||" ? (
                                  <div>
                                    <button
                                      onClick={() => {
                                        setShowClose(true);
                                        formData.selectedOption2 =
                                          instanceData?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      or
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "High price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowHighPrice(true);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Low price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowLowPrice(true);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_lowOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "RSI (Relative Strength Index)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                        setShowRSI(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ulcer" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowUlcer(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "DEMA (Double Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TEMA (Triple Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log(
                                          "showDrop",
                                          instanceData,
                                          data
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TriMA (Triangular Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWMA (Volume Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "WMA (Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Number" ? (
                                  <Number_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowNumber={setShowNumber}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                    exit={true}
                                  />
                                ) : item?.name === "Money Flow Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMoneyFlowIndex(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Williams %R" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWilliams(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "Moving Average Deviation (MA Dev)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMad(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "OBV (On Balance Volume)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowObv(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_obvOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Down" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonDown(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Up" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonUp(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Awesome Oscillator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowOscilator(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWAP (Volume Weighted Average Price)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWAP(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_vwapOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Parabolic SAR" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowpsar(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Vortex Indicator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVortexI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Volume of a candle" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVolume(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${`${data?.e_volume}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Donchian Channel" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDonchian(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Stochastic" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowStochastic(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Chaikin Money Flow" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowChaikin(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "MACD-signal" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacdSignal(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Commodity Channel Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCCI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Price Rate of Change" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRoc(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "RSI Moving Average" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRSIMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "TR (True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_trOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "ATR (Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "NATR (Normalized Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowNATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Supertrend" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowSuperT(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Bollinger %B" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowBB(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.bolingerBType},${data?.e_bolingerBOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMfiMa(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAMAPeriod}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ichimoku" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowIchimoku(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Know Sure Thing" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", instanceData);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowKST(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "+" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "-" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "/" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "*" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        console.log(
                                          "pppp",
                                          instanceName,
                                          instanceData
                                        );
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : (
                                  item?.name === "MOM (Momentum Indicator)" && (
                                    <div className="d-flex">
                                      <div
                                        className="hover-underline"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          console.log("showDrop", data);
                                          formData.selectedOption2 = data?.name;
                                          formData.clickedFunction =
                                            instanceName;
                                          setShowCandleModal(true);
                                          getIndex(index, "body1");
                                        }}
                                      >
                                        {data?.candleIntervalId}
                                      </div>
                                      &ensp;
                                      <button
                                        onClick={() => {
                                          console.log(
                                            "pppp",
                                            instanceName,
                                            data
                                          );
                                          formData.selectedOption2 = data?.name;
                                          formData.clickedFunction =
                                            instanceName;
                                          setShowMOM(true);
                                          getIndex(index, "body1");
                                        }}
                                        style={{
                                          ...buttonStyle,
                                          color: "#0e6efd",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {`${
                                          data?.name
                                        } (${`${data.period},${data.offset}`})`}
                                        <i
                                          onClick={(e) => {
                                            getIndex(index, "body1");
                                            handleRemoveButtonClick(
                                              instanceName,
                                              index,
                                              "body1"
                                            );
                                          }}
                                          className={`fa-solid fa-xmark iconExitContainer`}
                                          style={{
                                            marginLeft: "6px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                        &ensp;
                                      </button>
                                    </div>
                                  )
                                )}
                              </>
                            );
                          })}
                        {instanceData?.body1?.length === 0 && (
                          <button
                            onClick={() => {
                              console.log(
                                "instanceData?.name",
                                instanceData,
                                instanceName
                              );
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                  exit={true}
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAMAPeriod}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body2");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                        getIndex(index, "body2");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          getIndex(index, "body2");
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body2"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        {instanceData?.body2?.length === 0 && (
                          <button
                            onClick={() => {
                              console.log("instanceData?.name", instanceName);
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Floor" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.e_bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.e_kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index);
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Ceil" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.e_bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.e_kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index);
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Abs" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.e_bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.e_kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index);
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Symbol" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <SearchableDropdown
                          instanceName={instanceName}
                          formData={formData}
                          onFormChange={onFormChange}
                          stocks={allStocks}
                        />
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.e_bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.e_kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index);
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMinModal(true);
                            formData.selectedOption1 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.exit_indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.e_bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.e_kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index);
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMaxModal(true);
                            formData.selectedOption1 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.exit_indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption2 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption2 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsiPeriod},${data.e_rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_ulcerField},${data.e_ulcerPeriod},${data.e_ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_field},${data.e_period},${data.e_offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_temaField},${data.e_temaPeriod},${data.e_temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_tmaField},${data.e_tmaPeriod},${data.e_tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vwmaField},${data.e_vwmaPeriod},${data.e_vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_wmaField},${data.e_wmaPeriod},${data.e_wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  exit={true}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_mfiPeriod},${data.e_mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.e_wrPeriod}`},${`${data?.e_wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_madField},${data.e_madPeriod},${data.e_madType},${data.e_madOffset},${data.e_madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macd_fastMaPeriod},${data.e_macd_slowMaPeriod},${data.e_macd_signalPeriod},${data.e_macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonDownPeriod},${data.e_aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_aroonUpPeriod},${data.e_aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.e_aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_psarMinimumAF},${data.e_psarMaximumAF},${data.e_psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_vortexIPeriod},${data.e_vortexISignal},${data.e_vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_donchianChannel},${data.e_donchianHighPeriod},${data.e_donchianLowPeriod},${data.e_donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_stochasticPeriod},${data.e_stochasticType},${data.e_stochasticSmooth},${data.e_stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_chaikinPeriod},${data.e_chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_macdsFastMaPeriod},${data.e_macdsSlowMaPeriod},${data.e_macdsSignalPeriod},${data.e_macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_cciPeriod},${data.e_cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rocField},${data.e_rocPeriod},${data.e_rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.e_rsimaPeriod},${data.e_rsimaType},${data.e_rsimaMaPeriod},${data.e_rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.e_trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_atrPeriod},${data?.e_atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_natrPeriod},${data?.e_natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_superTPeriod},${data?.e_superTMultiply},${data?.e_superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_bolingerBField},${data?.e_bolingerBPeriod},${data?.e_bolingerBDev},${data?.e_bolingerBType},${data?.e_bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_mfiMAPeriod},${data?.e_mfiMAType},${data?.e_mfiMAMAPeriod},${data?.e_mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_ichiConversion},${data?.e_ichiBase},${data?.e_leadingSpanB},${data?.e_laggingSpan},${data?.e_ichiLine},${data?.e_cloudShift},${data?.e_ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.e_kstField},${data?.e_kstLTRoc},${data?.e_kstLTSma},${data?.e_kstRoc},${data?.e_kstLSma},${data?.e_kstHRoc},${data?.e_kstHSma},${data?.e_kstHeRoc},${data?.e_kstHeSma},${data?.e_kstSPeriod},${data?.e_kstType},${data?.e_kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconExitContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption2 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconExitContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                item?.name === "MOM (Momentum Indicator)" && (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        console.log("showDrop", data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index);
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        console.log("pppp", instanceName, data);
                                        formData.selectedOption2 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconExitContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop : "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Close" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowClose(true);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.e_closeOffset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Open price" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowOpen(true);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.e_offset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "High price" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowHighPrice(true);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.e_highOffset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Low price" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowLowPrice(true);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.e_lowOffset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            marginLeft: "6px",
                            cursor: "pointer",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "RSI (Relative Strength Index)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowRSI(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_rsiPeriod},${instanceData.e_rsiOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "Ulcer" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowUlcer(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_ulcerField},${instanceData.e_ulcerPeriod},${instanceData.e_ulcerOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "DEMA (Double Exponential Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowDEMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_field},${instanceData.e_period},${instanceData.e_offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "TEMA (Triple Exponential Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowTEMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_temaField},${instanceData.e_temaPeriod},${instanceData.e_temaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "TriMA (Triangular Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowTMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_tmaField},${instanceData.e_tmaPeriod},${instanceData.e_tmaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "VWMA (Volume Weighted Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVWMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_vwmaField},${instanceData.e_vwmaPeriod},${instanceData.e_vwmaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "WMA (Weighted Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowWMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_wmaField},${instanceData.e_wmaPeriod},${instanceData.e_wmaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "SMA (Simple Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowSMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_field},${instanceData.e_period},${instanceData.e_offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "EMA (Exponential Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowEMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_eField},${instanceData.e_ePeriod},${instanceData.e_eOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Number" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowNumber(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_period}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Money Flow Index" && (
                <div className="d-flex">
                  <div
                    className="hover-underline"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log("showDrop", instanceData);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                      setShowCandleModal(true);
                      getIndex(index);
                    }}
                  >
                    {instanceData?.candleIntervalId}
                  </div>
                  &ensp;
                  <button
                    onClick={() => {
                      console.log("pppp", instanceName, instanceData);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                      setShowMoneyFlowIndex(true);
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                      fontWeight: "500",
                    }}
                  >
                    {`${
                      instanceData?.name
                    } (${`${instanceData.e_mfiPeriod},${instanceData.e_mfiOffset}`})`}
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveButtonClick(instanceName, index);
                      }}
                      className={`fa-solid fa-xmark iconExitContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                </div>
              )}

              {instanceData?.name === "Williams %R" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowWilliams(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_wrOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Moving Average Deviation (MA Dev)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMad(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_madField},${instanceData.e_madPeriod},${instanceData.e_madType},${instanceData.e_madOffset1},${instanceData.e_madOffset2}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "MACD (Moving Average Convergence/Divergence)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMad(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_macd_fastMaPeriod},${instanceData.e_macd_slowMaPeriod},${instanceData.e_macd_signalPeriod},${instanceData.e_macdOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "OBV (On Balance Volume)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowObv(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_obvOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Aroon-Down" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowAroonDown(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_aroonDownPeriod},${instanceData.e_aroonDownOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Aroon-Up" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowAroonUp(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_aroonUpPeriod},${instanceData.e_aroonUpOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Awesome Oscillator" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowOscilator(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_aOscilatorOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "VWAP (Volume Weighted Average Price)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVWAP(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_vwapOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                  <div
                    className="hover-underline"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log("showDrop", instanceData);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                      setShowCandleModal(true);
                      getIndex(index);
                    }}
                  >
                    {instanceData?.candleIntervalId}
                  </div>
                  &ensp;
                  <button
                    onClick={() => {
                      console.log("pppp", instanceName, instanceData);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                      setShowVWAP(true);
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                      fontWeight: "500",
                    }}
                  >
                    {`${
                      instanceData?.name
                    } (${`${instanceData?.e_vwapOffset}`})`}
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveButtonClick(instanceName, index);
                      }}
                      className={`fa-solid fa-xmark iconExitContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                </div>
              )}

              {instanceData?.name === "Parabolic SAR" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowpsar(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_psarMinimumAF},${instanceData.e_psarMaximumAF},${instanceData.e_psarOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Vortex Indicator" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVortexI(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_vortexIPeriod},${instanceData.e_vortexISignal},${instanceData.e_vortexIOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Volume of a candle" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVolume(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_volume}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Donchian Channel" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowDonchian(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_donchianChannel},${instanceData.e_donchianHighPeriod},${instanceData.e_donchianLowPeriod},${instanceData.e_donchianOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Stochastic" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowStochastic(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_stochasticPeriod},${instanceData.e_stochasticType},${instanceData.e_stochasticSmooth},${instanceData.e_stochasticOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Chaikin Money Flow" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowChaikin(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_chaikinPeriod},${instanceData.e_chaikinOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "MACD-signal" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMacdSignal(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_macdsFastMaPeriod},${instanceData.e_macdsSlowMaPeriod},${instanceData.e_macdsSignalPeriod},${instanceData.e_macdsOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Commodity Channel Index" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCCI(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_cciPeriod},${instanceData.e_cciOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Price Rate of Change" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowRoc(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_rocField},${instanceData.e_rocPeriod},${instanceData.e_rocOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "&&" &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setShowClose(true);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                    }}
                  >
                    and
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        handleEdit(instanceData, index);
                        // handleRemoveButtonClick(instanceName, index);
                      }}
                      className={`fa-solid fa-xmark iconExitContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}

              {instanceData?.name === "||" &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setShowClose(true);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                    }}
                  >
                    or
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        handleEdit(instanceData, index);
                        // handleRemoveButtonClick(instanceName, index);
                      }}
                      className={`fa-solid fa-xmark iconExitContainer`}
                      style={{
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}

              {instanceName.startsWith("comparator") &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    key={instanceName}
                    onClick={() => {
                      // Handle button click logic here
                      // setIsShow3(true);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                    }}
                  >
                    {`${instanceData?.name}`}
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        // handleRemoveButtonClick(instanceName, index);
                        handleEdit(instanceData, index);
                      }}
                      className={`fa-solid fa-xmark iconExitContainer`}
                      style={{
                        color : "#7AB1DD",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}
              {instanceName.startsWith("operators") &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    key={instanceName}
                    onClick={() => {
                      // Handle button click logic here
                      // setIsShow3(true);
                      formData.selectedOption2 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    {`${instanceData?.name}`}
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        // handleRemoveButtonClick(instanceName, index);
                        handleEdit(instanceData, index);
                      }}
                      className={`fa-solid fa-xmark iconExitContainer`}
                      style={{
                        color: "#0e6efd",
                        cursor: "pointer",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}
              {instanceData?.name === "RSI Moving Average" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowRSIMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_rsimaPeriod},${instanceData.e_rsimaType},${instanceData.e_rsimaMaPeriod},${instanceData.e_rsimaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "TR (True Range)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowTR(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_trOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "ATR (Average True Range)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowATR(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_atrPeriod},${instanceData?.e_atrOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name ===
                "NATR (Normalized Average True Range)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowNATR(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_natrPeriod},${instanceData?.e_natrOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "Supertrend" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowSuperT(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_superTPeriod},${instanceData?.e_superTMultiply},${instanceData?.e_superTOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Bollinger %B" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowBB(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_bolingerBField},${instanceData?.e_bolingerBPeriod},${instanceData?.e_bolingerBDev},${instanceData?.e_bolingerBType},${instanceData?.e_bolingerBOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "MFI Moving Average (Money Flow Index Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMfiMa(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_mfiMAPeriod},${instanceData?.e_mfiMAType},${instanceData?.e_mfiMAMAPeriod},${instanceData?.e_mfiMAOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Ichimoku" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowIchimoku(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_ichiConversion},${instanceData?.e_ichiBase},${instanceData?.e_leadingSpanB},${instanceData?.e_laggingSpan},${instanceData?.e_ichiLine},${instanceData?.e_cloudShift},${instanceData?.e_ichiOffset}`})`}                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Know Sure Thing" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowKST(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.e_kstField},${instanceData?.e_kstLTRoc},${instanceData?.e_kstLTSma},${instanceData?.e_kstRoc},${instanceData?.e_kstLSma},${instanceData?.e_kstHRoc},${instanceData?.e_kstHSma},${instanceData?.e_kstHeRoc},${instanceData?.e_kstHeSma},${instanceData?.e_kstSPeriod},${instanceData?.e_kstType},${instanceData?.e_kstOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "MOM (Momentum Indicator)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMOM(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.period},${instanceData.offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
               {instanceData?.name === "Standard Deviation" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption2 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setisStdDeviation(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.e_stdPeriod},${instanceData.e_stdField},${instanceData.e_stdDev},${instanceData.e_maType},${instanceData.e_offset}`})`}

                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconExitContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
            </span>
          );
        }
      )}
    </>
  );
};

export default ExitFormulaTab;

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import {Addnewcoupon,GetCouponListApi,GetAppliedCouponListApi,
  Exportcouponlist,Exportappliedcouponlist,editCouponApi,deleteCouponApi} from "../api/adminapi"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Createcouponpopup from "../Components/modal/Createcouponpopup";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  export default function Coupons() {
    const [value, setValue] = React.useState(0);
    const [userRecords , setUserRecords] = useState([]);
    const [userAllData,setUserAllData] = useState([]);
    const [usersCount ,setUsersCount] = useState('');
    const [expiredSubscriptionsAll , setExpiredSubscriptionsAll] = useState([]);
    const [expiredSubscriptions , setExpiredSubscriptions] = useState([]);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [strategiesNameError, setStrategiesNameError] = useState(false);
    const [createStratgyPage, setCreateStratgyPage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editcoupon,setEditcoupon]=useState(false);
    const [id,setId]=useState();
    const [updatedata,setUpdatedata]=useState({ 
       couponId : '',
       is_active : ''
    });
    const [couponDetails,setCoupondetails]=useState({
        code:'',
        description:'',
        amount:'',
        expiry_date:'',
        added_by:2,
        is_active:"",
    })
    const [checked, setChecked] = React.useState(false);
    const customStyles = {
      rows: {
        style: {
          border : "1px solid #8080805e", // override the row height
          minHeight: '40px',
        },
      },
      color:"#ffffff",
      headCells: {
        style: {
          border : "1px solid #8080805e",
          fontSize : "medium",
          fontWeight : "bold",
          borderBottom : "none",
          minHeight: '30px',
        },
      },
      cells: {
        style: {
          borderRight : "1px solid #8080805e", // override the row height
          // color:"#76838F"
          // height : "2px"
        },
      },
      
    };
  
    const columns =  [
      {
        id : "id",
        selector : row => row.id,
        omit : true
      },
      {
        name : "Code",
        selector : row => row.code,
        // sortable :true
      },
      {
        name : "Amount",
        selector : row => row.amount,
        // sortable :true
      },
      {
        name : "Description",
        selector : row => row.description,
      },
      {
        name : "Expiry date",
        selector : (row) => moment(row.expiry_date).format('DD MMMM YYYY'),
      },
      {
        name : "Added By",
        selector :row=> row.user_name,
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            <IconButton onClick={() => handleEditClick(row)}>
              <EditIcon style={{ color: 'green' }} />
            </IconButton>
            <IconButton onClick={() => handleRemoveClick(row.id)}>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]
    const fetchUsersData = async() =>{
      const result = await GetCouponListApi();
      setUserRecords(result?.data);
      setUserAllData(result?.data);
    }
    const fetchExpiredSubscriptions = async () => {
      const result =  await GetAppliedCouponListApi();
      console.log("result@",result);
      setExpiredSubscriptionsAll(result?.data);
      setExpiredSubscriptions(result?.data);
    }
   
    useEffect(() => {
      fetchUsersData();
      fetchExpiredSubscriptions();
    } ,[]);
    const handleFilter = (event) => {
      const searchTerm = event.target.value?.toLowerCase();
      const newData = userAllData?.filter(row => 
        (row.code?.toLowerCase().includes(searchTerm) || 
        row.email?.toLowerCase().includes(searchTerm))
    );
      setUserRecords(newData);
      console.log("Filter",searchTerm,newData,userRecords);
    }
    const handleFilterExpired = (event) => {
      const searchTerm = event.target.value?.toLowerCase();
      const newData = expiredSubscriptionsAll?.filter(row => 
        (row.user_name?.toLowerCase().includes(searchTerm) || 
        row.email?.toLowerCase().includes(searchTerm))
    );
      // setExpiredSubscriptionsAll(newData);
      setExpiredSubscriptions(newData);
      console.log("Filter12",searchTerm,newData,expiredSubscriptions);
    }
    const exportActive=async()=>{
      const result=await Exportcouponlist();
      console.log("Exported file",result);
      const downloadUrl = `https://dalrotibackend.shrikanttapkeer.com/exports/${result.filename}`;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', result.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    const handleExportExpired=async()=>{
      const result=await Exportappliedcouponlist();
      console.log("Exported file",result);
      const downloadUrl = `https://dalrotibackend.shrikanttapkeer.com/exports/${result.filename}`;
        const link = document.createElement('a');
        link.href = downloadUrl;
      link.setAttribute('download', result.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangecoupondetails=(e)=>{
    
       const {name,value}=e.target;
       setCoupondetails({...couponDetails,[name]:name==="amount"? parseInt(value):value});
    }
    const handleChangeswitch=(event)=>{
        setChecked(event.target.checked);
        setCoupondetails({...couponDetails,is_active:event.target.checked ? true:false});
    }
    const handleClickStrategyModal = () => {
       setCoupondetails({});
       setEditcoupon(false);
       setIsModalOpen(true);
      };
      const addcoupon=async()=>{
        const {code,description,amount,expiry_date}=couponDetails;
        const added_by=2;
        const couponData={code,description,amount,expiry_date,added_by};
            const result=await Addnewcoupon(couponData);
            if(result.success===true)
            {
              alert("Coupon Added successfully!!!");
            }
            setIsModalOpen(false);
            fetchUsersData();
           
        // http://localhost:8008/api/v1/adminpanel/AddSingleCoupon
      }
      const updatecoupon=async()=>{
        setUpdatedata({...updatedata,is_active:updatedata.is_active==="on"?"off":"on"});
        const result=await editCouponApi({couponId:parseInt(id),is_active:checked?"on":"off"}); 
        setIsModalOpen(false);
        fetchUsersData();
      }
      const handleEditClick=(row)=>{
        setIsModalOpen(true);
        setEditcoupon(true);
        setCoupondetails({code:row.code,
          description:row.description,
          amount:row.amount,
          expiry_date:row.expiry_date,
          is_active:row.is_active,
          
      });
      setId(row?.id);
      setUpdatedata({...updatedata,couponId:parseInt(row.id)})
      }

      const handleRemoveClick=async(id)=>{
        const isconfirmed=window.confirm("Are you want to delete this coupon?");
        if(isconfirmed)
        {
          const result=await deleteCouponApi({couponId:id});
          if(result===true)
          {
            fetchUsersData();
            alert("Records Deleted Successfully...")
          }
        }
      }
    return (
      <div className="page">
      <div className="page-content container-fluid">
      <h3 className="panel-title">Coupons</h3>
      <div className="panel-heading">
              <div className="panel-actions text-white">
                <button  className="btn btn-success" 
                onClick={handleClickStrategyModal} 
                // disabled={loading}
                >
                  <span className="hidden-sm-down">
                    <i className="fa fa-plus" aria-hidden="true"
                    ></i> Add New
                    Coupon
                  </span>
                </button>
              </div>
            </div>
      <div className="row justify-content-center" style={{margin:"0px 30px"}}>
      <Box sx={{ width: '100%',margin:"0px auto", backgroundColor:"white",margin:"30px 30px"}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Coupons" {...a11yProps(0)} />
            <Tab label="Applied Coupons" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
        <div className="row">
              <div className="col-xl-12 col-lg-12">
               <div className="panel">
              <div className="panel-heading">
                <div className="panel-actions text-white mt-4">
                  <a className="btn btn-success mx-2" id="export-users" onClick={exportActive}>
                    <i className="fa fa-download"></i> Export
                  </a>
                  
                </div>
              </div>
             <div className="panel-body ">
                <div className="mb-2 pt-4">
                  <Form className="d-flex align-items-baseline justify-content-end mt-4">
                  <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                  <Form.Control className="w-25 float-end ms-2" size="sm" type="text" 
                  placeholder="Search..." style={{color:"#76838F"}}
                  onChange={handleFilter} />
                  </Form>
                </div>
                
                <DataTable 
                  columns={columns}
                  data = {userRecords}
                  pagination
                  fixedHeader
                  customStyles={customStyles}
                  highlightOnHover
                  striped
                  pointerOnHover
                  responsive
                  // onRowClicked={handleShowUser}
                >
                </DataTable>
              </div>
              </div>
              </div>
              </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <div className="row">
              <div className="col-xl-12 col-lg-12">
               <div className="panel">
              <div className="panel-heading">
                <div className="panel-actions text-white mt-4">
                  <a className="btn btn-success mx-2" id="export-users" onClick={ handleExportExpired}>
                    <i className="fa fa-download"></i> Export
                  </a>
                  
                </div>
              </div>
             <div className="panel-body ">
                <div className="mb-2 pt-4">
                  <Form className="d-flex align-items-baseline justify-content-end mt-4">
                  <Form.Label style={{color:"#76838F"}}>Search: </Form.Label>
                  <Form.Control className="w-25 float-end ms-2" size="sm" type="text" 
                  placeholder="Search..." style={{color:"#76838F"}}
                  onChange={ handleFilterExpired} />
                  </Form>
                </div>
                
                <DataTable 
                  columns={columns}
                  data = {expiredSubscriptions}
                  pagination
                  fixedHeader
                  customStyles={customStyles}
                  highlightOnHover
                  striped
                  pointerOnHover
                  responsive
                  // onRowClicked={handleShowUser}
                >
  
                </DataTable>
              </div>
              </div>
              </div>
              </div>
        </CustomTabPanel>
      </Box>
            </div>
          </div>
      
          <Createcouponpopup show={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            couponDetails={couponDetails}
            setEditcoupon={editcoupon}
            onChange={handleChangecoupondetails}
            addcoupon={addcoupon}
            updatecoupon={updatecoupon}
            handleChangeswitch={handleChangeswitch}
            checked={checked}
           />
          </div>
     
    );
}
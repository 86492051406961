import React, { useState,useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const PivotPoints = ({
  show,
  setIsShow,
  handleModalData,
  mainData,
  clickedFunction,
  exit,
  index,
  instance,
}) => {
let data;
if (exit) {
  const exitIndicators = mainData?.exit_indicators?.[clickedFunction];

  if (exitIndicators?.body) {
    data = exitIndicators.body[index];
  } else if (instance && exitIndicators?.body1) {
    data = exitIndicators[instance]?.[0];
  } else if (exitIndicators?.body2) {
    data = exitIndicators[instance]?.[0];
  } else {
    data = exitIndicators;
  }
} else {
  const indicators = mainData?.indicators?.[clickedFunction];

  if (indicators?.body) {
    data = indicators.body[index];
  } else if (instance && indicators?.body1) {
    data = indicators[instance]?.[0];
  } else if (indicators?.body2) {
    data = indicators[instance]?.[0];
  } else {
    data = indicators;
  }
}
const [formData, setFormData] = useState(data);
useEffect(() => {
  setFormData(data);
}, [data]);
const handleClose = () => {
  setIsShow(false);
};

const handelChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};
const handleDone = () => {
  handleModalData(formData);
  console.log("VALUE", exit, formData);

  setIsShow(false);
};



  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">Pivot points</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Signal
                </Form.Label>
                <Col sm="4">
                  <Form.Select
                    size="sm"
                    name={exit ? "e_signal" : "signal"}
                    value={exit ? formData?.e_signal : formData?.signal}
                    onChange={handelChange}
                  >
                    <option value="r3">r3</option>
                    <option value="r2">r2</option>
                    <option value="r1">r1</option>
                    <option value="pp">pp</option>
                    <option value="s1">s1</option>
                    <option value="s2">s2</option>
                    <option value="s3">s3</option>
                  </Form.Select>
                </Col>
                <Form.Label column sm="2">
                  Type
                </Form.Label>
                <Col sm="4">
                  <Form.Select
                    size="sm"
                    name={exit ? "e_type" : "type"}
                    value={exit ? formData?.e_type : formData?.type}
                    onChange={handelChange}
                  >
                    <option value="standard">standard</option>
                    <option value="fibonacci">fibonacci</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Continous
                </Form.Label>
                <Col sm="4">
                  <Form.Select
                    size="sm"
                    name={exit ? "e_continous" : "continous"}
                    value={exit ? formData?.e_continous : formData?.continous}
                    onChange={handelChange}
                  >
                    <option value="no">no</option>
                    <option value="yes">yes</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="danger" size="sm" onClick={handleClose}>
            Delete
          </Button>
          <Button variant="primary" size="sm" onClick={handleDone}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PivotPoints;

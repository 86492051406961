import React, { useState, useMemo, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import StrategyEntryComponent from "./StrategyEntryComponent";
import StrategyPositionComponent from "./StrategyPositionComponent";
import StrategyReviewComponent from "./StrategyReviewComponent";
import ExistCondition from "./ExistCondtion";
import dayjs from "dayjs";
import moment from "moment";
import axios from "axios";
import { getZerodhaToken } from "../../Session";
import { getInstrumentsAPI } from "../../api/api";


const MenuContext = React.createContext();

const StrategyCreateComonent = (props) => {
  const location = useLocation();
  const token = getZerodhaToken();
  const { strategyName,  setStrategiesNameError, setCreateStratgyPage,categorynm } = props;
  const [stocks, setStocks] = useState([]);
  const [allStocks, setAllStocks] = useState([]);
  const [newStockes, setNewStockes] = useState([]);
  const [existingStrategy, setExistingStrategy] = useState(false);
  const [allStockStore, setAllStockStore] = useState([]);
  const [activeButton, setActiveButton] = useState(1);
  const [positionFormData, setPositionFormData] = useState({
    function_name: 2,
    strategy_name: strategyName,
    stock_name: "Nifty 50",
    position_id: 1,
    holding_type: "MIS",
    candleIntervalId: 1,
    category_id:categorynm,
    candle_interval_id: "1m",
    quantity: 1,
    entry_condition: "",
    isAdmin:1,
    stop_loss: "5",
    target_profit: "5",
    trailing_sl: "",
    max_allocation: "",
    max_quantity: 0,
    position_sizing_type: "-",
    start_time: "00:00",
    end_time: "23:59",
    initial_capital: "100000",
    start_date: moment().subtract(29, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    description: "",
    user_id: JSON.parse(sessionStorage.getItem("user"))?.id,
    exch: "b",
    exchType: "c",
    scripCode: "500112",
    interval: "1m",
    fromDate: "",
    endDate: "",
    periods: "12",
    field: "close",
    offset: "-1",
    periods2: "26",
    field2: "close",
    offset2: "10",
    input1: "10",
    conditionTab: false,
    selectedOption1: "",
    selectedOption2: "",
    mode: "",
    comparator: "higher than",
    comparator2: "lower than",
    comparator3: "higher than",
    noStock: true,
    function_id: 83,
    tpslType: "per",
    firstFunctionId: "",
    secondFunctionId: "",
    functionsMainBox: [],
    functionsBox: [],
    functionsBox2: [],
    functionsBox777: [],
    inner_function1: {},
    inner_function2: {},
    inner_function3: {},
    operator: "and",
    function_id1: "",
    period1: "",
    offset1: "0",

    smaField: "close",
    smaPeriod: "12",
    smaOffset: "0",

    eField: "close",
    ePeriod: "12",
    eOffset: "0",
    functionText1: "",

    exit_field1: "close",
    exit_period1: 7,
    exit_offset1: 0,
    exit_field2: "close",
    exit_period2: 21,
    exit_offset2: 0,
    exit_condition: "lower than",

    rsiPeriod: "14",
    rsiOffset: "0",
    number: "10",
    rsiOffset2: "",
    rsiPeriod2: "",
    rsiType: "exponential",
    maPeriod: "",
    count: 0,

    ulcerField: "close",
    ulcerPeriod: "12",
    ulcerOffset: "0",
    tmaField: "close",
    tmaPeriod: "12",
    tmaOffset: "0",
    vwmaField: "close",
    vwmaPeriod: "12",
    vwmaOffset: "0",
    lowOffset: "",
    openOffset: "",
    openPeriod: "",
    closeOffset: "",
    highOffset: "",
    mfiPeriod: "",
    mfiOffset: "",
    wrOffset: "",
    aroonDownPeriod: "",
    aroonDownOffset: "",
    aroonUpPeriod: "",
    aroonUpOffset: "",
    obvOffset: "",
    macd_maPeriod: "",
    macd_slowMaPeriod: "",
    macd_signalPeriod: "",
    madField: "",
    madPeriod: "",
    madType: "",
    madOffset: "",
    vwapOffset: "",
    psarMinimumAF: "",
    psarMaximumAF: "",
    psarOffset: "",
    aOscilatorOffset: "",
    vortexIPeriod: "",
    vortexISignal: "",
    vortexIOffset: "",
    volume: "",
    donchianChannel: "",
    donchianHighPeriod: "",
    donchianLowPeriod: "",
    donchianOffset: "",
    stochasticPeriod: "",
    stochasticType: "",
    stochasticSmooth: "",
    chaikinOffset: "",
    chaikinPeriod: "",
    cciPeriod: "",
    cciOffset: "",
    macdsFastMaPeriod: "",
    macdsSlowMaPeriod: "",
    macdsSignalPeriod: "",
    macdsOffset: "",
    rocField: "",
    rocPeriod: "",
    rocOffset: "",
    rsimaPeriod: "",
    rsimaType: "",
    rsimaMaPeriod: "",
    rsimaOffset: "",

    e_ulcerField: "close",
    e_ulcerPeriod: "12",
    e_ulcerOffset: "0",
    e_tmaField: "close",
    e_tmaPeriod: "12",
    e_tmaOffset: "0",
    e_vwmaField: "close",
    e_vwmaPeriod: "12",
    e_vwmaOffset: "0",
    e_lowOffset: "",
    e_openOffset: "",
    e_openPeriod: "",
    e_closeOffset: "",
    e_highOffset: "",
    e_mfiPeriod: "",
    e_mfiOffset: "",
    e_wrOffset: "",
    e_aroonDownPeriod: "",
    e_aroonDownOffset: "",
    e_aroonUpPeriod: "",
    e_aroonUpOffset: "",
    e_obvOffset: "",
    e_macd_maPeriod: "",
    e_macd_slowMaPeriod: "",
    e_macd_signalPeriod: "",
    e_madField: "",
    e_madPeriod: "",
    e_madType: "",
    e_madOffset: "",
    e_vwapOffset: "",
    e_psarMinimumAF: "",
    e_psarMaximumAF: "",
    e_psarOffset: "",
    e_aOscilatorOffset: "",
    e_vortexIPeriod: "",
    e_vortexISignal: "",
    e_vortexIOffset: "",
    e_volume: "",
    e_donchianChannel: "",
    e_donchianHighPeriod: "",
    e_donchianLowPeriod: "",
    e_donchianOffset: "",
    e_stochasticPeriod: "",
    e_stochasticType: "",
    e_stochasticSmooth: "",
    e_chaikinOffset: "",
    e_chaikinPeriod: "",
    e_cciPeriod: "",
    e_cciOffset: "",
    e_macdsFastMaPeriod: "",
    e_macdsSlowMaPeriod: "",
    e_macdsSignalPeriod: "",
    e_macdsOffset: "",
    e_rocField: "",
    e_rocPeriod: "",
    e_rocOffset: "",
    e_rsimaPeriod: "",
    e_rsimaType: "",
    e_rsimaMaPeriod: "",
    e_rsimaOffset: "",

    IndicatorFormula: "",
    indicators: {},
    order: [],
    stocks: [],
    starttime: dayjs(
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    ),
    endtime: dayjs(
      moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
    ),

    //Exit condtion data
    exit_indicators: {},
    e_smaField: "",
    e_smaPeriod: "",
    e_smaOffset: "",
    functionsBox888: [],
    functionsBox3: [],
    previous_day: 1,
    daily_strategy_cycle: "-",

    api_key: "3wxj01wpd2rnnn9c",
    access_token: token,
  });
  useEffect(() => {
    console.log("first,positionFormData", positionFormData);
  }, [positionFormData]);

  const [save, setSave] = useState();
  const fetchData = async () => {
    try {
      // Fetch your stocks data from the API
      const response = await axios.get(
        "https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
      );
      const allStocks = response.data;
      const first20Stocks = allStocks.slice(0, 20);
      console.log("seeeMynewstocks", allStocks);
      setAllStocks(first20Stocks);
      setNewStockes(first20Stocks);
    } catch (error) {
      console.error("Error fetching stocks:", error);
    }
  };

  const parseCSV = (csvText) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",");

    const jsonData = [];
    for (let i = 1; i < lines?.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine?.length === headers?.length) {
        const obj = {};
        for (let j = 0; j < headers?.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        jsonData.push(obj);
      }
    }

    return jsonData;
  };

  const fetchCSVData = async () => {
    try {
      const response = await fetch(
        "http://206.189.142.143:8008/scriptMaster/scripmaster.csv"
      );
      if (!response.ok) {
        console.log("Failed to fetch data");
      }
      const csvText = await response.text();
      const jsonData = parseCSV(csvText);

      const first20Stocks = jsonData?.slice(0, 20);
      setAllStocks(first20Stocks);
      setNewStockes(first20Stocks);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchInstruments = async () => {
    const token = getZerodhaToken();
    console.log("accessToken-", token);
    try {
      const response = await getInstrumentsAPI({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
      });

      const data = response.data;
      console.log("stockDATASTRATEGY", data);

      // Split data into lines
      const lines = data.split("\n");

      // Get field names from the first line
      const fieldNames = lines[0].split(",").map((field) => field.trim());

      // Create JSON objects for each line
      const jsonData = [];
      for (let i = 1; i < lines?.length; i++) {
        const values = lines[i].split(",");

        // Check if the line has the correct number of fields
        if (values?.length === fieldNames?.length) {
          const jsonEntry = {};
          for (let j = 0; j < fieldNames?.length; j++) {
            jsonEntry[fieldNames[j]] = values[j].trim();
          }
          jsonData.push(jsonEntry);
        } else {
          console.error(
            `Line ${
              i + 1
            } does not have the correct number of fields. Content: ${lines[i]}`
          );
        }
      }

      console.log("jsonData", jsonData);
      let sss = jsonData?.filter((item) => item.exchange == "NSE");
      console.log("jsonDataFilter", sss);

      let sortedJsonData = jsonData?.sort(
        (a, b) => a.tradingsymbol?.length - b.tradingsymbol?.length
      );
      console.log("sortedJsonData@", sortedJsonData);
      setAllStockStore(sortedJsonData);

      const first20Stocks = jsonData?.slice(0, 20);
      setAllStocks(first20Stocks);
      setNewStockes(first20Stocks);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  useEffect(() => {
    // fetchData();
    // fetchCSVData();
    fetchInstruments();
  }, []);

  useEffect(() => {
    console.log("HIt111", token, location?.state);
    if (location?.state != undefined) {
      location.state.existingData.access_token = token;
      setPositionFormData(location?.state?.existingData);
    }
  }, [location?.state]);

  useEffect(() => {
    console.log("HITTT2++++++", positionFormData);
  }, [positionFormData]);

  const [menu, setMenu] = useState("position");

  const handlePositionFormChange = (newFormData) => {
    console.log("newFormData->", newFormData);
    setPositionFormData(newFormData);
  };
  const handleSave = () => {
    setSave(true);
  };

  const handleSaveFalse = () => {
    setSave(false);
  };
  const handleChangeCharType = (buttonNumber) => {
    setActiveButton(buttonNumber);
    setPositionFormData((prevFormData) => ({
      ...prevFormData,
      chart_type: buttonNumber,
    }));
  };
  const renderComponent = useMemo(() => {
    return (
      <>
        <StrategyPositionComponent
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          stocks={stocks}
          setStocks={setStocks}
          allStocks={allStocks}
          save={save}
          strategyName={
            positionFormData?.strategy_name
              ? positionFormData?.strategy_name
              : strategyName
          }
          activeButton={activeButton}
          handleChangeCharType={handleChangeCharType}
          
        />

        <StrategyEntryComponent
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          allStocks={allStocks}
          handleNext={menu}
          save={save}
        />

        <ExistCondition
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          save={save}
        />

        <StrategyReviewComponent
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          stocks={stocks}
          setStocks={setStocks}
          handleSave={handleSave}
          handleSaveFalse={handleSaveFalse}
          setCreateStratgyPage={setCreateStratgyPage}
        />
      </>
    );
  }, [menu, positionFormData, stocks, allStocks, save, activeButton]);

  return (
    <>
      <MenuContext.Provider
        value={{ menu, setMenu, newStockes, setNewStockes, allStockStore }}
      >
        <section
          className="mobile-view"
          style={{
            background: positionFormData.noStock ? "white" : "white",
          }}
        >
          <div className="container web-cotainer">
            {/* <StrategyCreateBarComponent
              strategyName={
                positionFormData?.strategy_name
                  ? positionFormData?.strategy_name
                  : strategyName
              }
              setCreateStratgyPage={setCreateStratgyPage}
              formData={positionFormData}
              stocks={stocks}
            /> */}
            {/* <br />
            <br /> */}
            <div className="pt-4">{renderComponent}</div>
          </div>
        </section>
      </MenuContext.Provider>
    </>
  );
};

export default StrategyCreateComonent;

export const useMenuContext = () => useContext(MenuContext);
